import React from "react";
import styles from "./styles.module.scss";

function TextAreaInputField({ label, name, isBlur, onChange }) {
    return (
        <div>
            <h4 className={`${isBlur ? styles.label : styles.blackLabel}`}>
                {label}
            </h4>
            <textarea
                name={name}
                id={name}
                rows="5"
                className={`${isBlur ? styles.inputField : styles.nonBlur}`}
                onChange={onChange}
            ></textarea>
        </div>
    );
}

export default TextAreaInputField;
