import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { topDestinations } from "../../../JsonData/TopDestinatons";
import NavBar from "../../../Components/Navbar/NavBar";
import logo2 from "../../../Assets/Image/logo2.png";
import ParentContainer from "../../../Components/ParentContainer/ParentContainer";
import styles from "../styles.module.scss";
import Subheader from "../../../Components/SubHeader/Subheader";
import Footer from "../../../Components/Footer/Footer";
import MobileFooter from "../../../Components/Footer/MobileFooter";
function TopDestinationDetails() {
    const [destination, setDestination] = useState({});
    let { id, name } = useParams();

    useEffect(() => {
        const data = topDestinations?.filter(
            (item) => item.title === name.toString()
        );
        const res = data[0]?.places?.filter((item) => item.id === parseInt(id));
        setDestination(res[0]);
    }, [id, name]);

    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <ParentContainer>
                <div className={styles.banner}>
                    <div
                        className={`${styles.innerBanner} mt-32 flex justify-center items-center`}
                        style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${destination?.image})`,
                        }}
                    >
                        <h1 className="font-bold text-white text-5xl sm:text-3xl 2xs:text-3xl">
                            {destination?.title}
                        </h1>
                    </div>
                </div>
            </ParentContainer>
            <ParentContainer>
                {destination?.description?.map((item, index) => (
                    <Subheader key={index}>{item}</Subheader>
                ))}
            </ParentContainer>
            <div className="mb-16"></div>
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default TopDestinationDetails;
