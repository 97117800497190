import React from "react";
import styles from "./styles.module.scss";
import { Grid } from "@mui/material";
import messages from "./messages";

function CarCardComponent({ lang="en", title, img, seating_capacity }) {
    return (
        <Grid container>
            <Grid item xs={1} md={12} lg={12}></Grid>
            <Grid item xs={10} md={12} lg={12}>
            <div className={styles.card}>
            <p className="text-2xl text-secondaryColor font-bold mb-8">
                {title}
            </p>
            <div className="md:flex justify-center items-center mb-8">
                <img
                    src={img}
                    alt="banner"
                    width={300}
                    className="rounded-md"
                />
            </div>

            <div className="absolute bottom-6">
                <p className="font-medium text-center text-secondaryColor">
                    {messages.heading1[lang]} :{" "}
                    <span className="font-bold">
                        {seating_capacity} ({messages.heading2[lang]})
                    </span>
                </p>
            </div>
        </div>
            </Grid>
            <Grid item xs={1} md={12} lg={12}></Grid>
        </Grid>
       
    );
}

export default CarCardComponent;
