export const headerTitle =
    "All correspondence/s in respect of Tours / Travel Service bookings should be addressed to M/s.Heritage Global India Tours.";

export const paymentsContent1 =
    "For all the services contracted, certain advance payment should be made to hold the booking, on confirmed basis & the balance amount can be paid either before your departure from your country or upon arrival in INDIA but definitely before the commencement of the services. Management personnel hold the right to decide upon the amount to be paid as advance payment, based on the nature of the service & the time left for the commencement of the service.";

export const paymentsContent2 =
    "Apart from above in some cases like Special Train Journeys, hotels or resorts bookings during the peak season (X-Mas, New Year), full payment is required to be sent in advance. Wire or Swift Bank Transfer";

export const accountDetailsContent1 =
    "Wire or Swift Bank Transfer can be made by your Bank to our Bank. Please ask your bank to transfer the amount to:";

export const accountDetails = [
    {
        label: "Beneficiary Name: ",
        value: "Heritage Global India Tours",
    },
    {
        label: "Beneficiary Account Number:",
        value: "68007413483",
    },
    {
        label: "Bank Name & Branch:",
        value: "BANK OF MAHARASTRA, P.N.96-Kalyan Kunj, Kalwad Road Jhotwara,Jaipur(Rajasthan)",
    },
    {
        label: "Swift Code:",
        value: "MAHBINBBJPR",
    },
];

export const accountDetailsContent2 = [
    "Please let us know purpose of remittance..................................................",
    "For Mr/Ms................................................................(Name of Passenger)",
    "The transfer details should be sent by E-mail. On confirmation from our Bank we shall provide you the ordered goods / services instantly",
    "If your travel starting time is with in 20 days to your tour confirmation date then we required your payment by WESTERN UNION and MONEY GRAM money transfer because it is the fast way to money transfer.",
];

export const cancellationTitle =
    "In the event of cancellation of tour / travel services due to any avoidable / unavoidable reasons we must be notified of the same in writing. Cancellation charges will be effective from the date we receive advice in writing, and cancellation charges would be as follows: -";

export const cancellationContent = [
    "60 days prior to arrival: 50% of the Tour / service cost",
    "45 days prior to arrival: 75% of the Tour / service cost",
    "30 days prior to arrival: 100% of the Tour / service cost",
    // "15 days prior to arrival: 75% of the Tour / service cost",
];

export const cancellationContent1 = [
    "In case of Special Trains Journeys and peak season hotel bookings a separate cancellation policy is applicable (which can be advised as and when required).",
    "In case you cancel the trip after commencement, refund would be restricted to a limited amount only which too would depend on the amount that we would be able to recover from the hoteliers/ contractors we patronize. For unused hotel accommodation, chartered transportation & missed meals etc. we do not bear any responsibility to refund.",
];
export const cancellationContent2 =
    "Please note that after the finalization of the Tour/ service Cost, if there are any Hike in entrance fees of monuments / museums, Taxes, fuel cost or guide charges – by Govt of India, the same would be charged as extra.";

// export const cancellationTitle1 = "Less than 7 days or No Show :No Refund";

export const cancellationTitle2 = "Our Liabilities & Limitations:-";
