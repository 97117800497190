import React from "react";
import { withRouter } from "react-router-dom";
import "../../index.css";
import NavBar from "../../Components/Navbar/NavBar";
import logo2 from "../../Assets/Image/logo2.png";

import "./style.css";
import SearchBar from "../../Components/Search/SearchBar";
import {searchData} from "../../JsonData/Search";
import FloatingButton from "../../Components/FloatingButton/FloatingButton";

function Search() {
  return (
    <div className="relative bg-white">
      <NavBar logo2={logo2} isBanner={true} isHome={true} />
      <FloatingButton/>
      <SearchBar placeholder="Type to search..." data={searchData} />
    </div>
  );
}

export default withRouter(Search);
