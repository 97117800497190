import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tripadvisor from "../../Assets/Image/tripAdvisor.png";
function Sidebar({
    setShowSideBar,
    showSideBar,
    cross,
    styles,
    navLinks,
    selected,
    setSelected,
}) {
    return (
        <div
            className={
                showSideBar
                    ? `${styles.sideNavBar} ${styles.sideBarActive}`
                    : styles.sideNavBar
            }
        >
            <div className={`bg-slider2 ${styles.banner} relative`}>
                <div onClick={() => setShowSideBar(false)}>
                    <img
                        src={cross}
                        alt="Cross"
                        width={"25"}
                        className="absolute right-5 top-5 cursor-pointer"
                    />
                </div>
                <div className="h-full flex justify-center items-center w-full">
                    <p className="text-2xl p-3 text-center text-white font-semibold">
                        Make Memories with{" "}
                        <span className="text-primaryColor font-bold">
                            GLOBAL INDIA TOURS
                        </span>
                    </p>
                </div>
            </div>
            <div className={`${styles.sideBarLinks} py-9 px-10`}>
                <ul
                    className={`text-secondaryColor flex justify-between items-start flex-col h-full`}
                >
                    {navLinks.map((item, index) => (
                        <div
                            className={`${
                                selected === item.path &&
                                "bg-ternaryBlue w-full p-3 rounded-myBorder"
                            } flex justify-start items-center cursor-pointer  w-full p-3 ${
                                styles.hoverItem
                            }`}
                            key={index}
                        >
                            <div className="pr-3">
                                <FontAwesomeIcon
                                    icon={item.icon}
                                    color={
                                        selected === item.path
                                            ? "white"
                                            : "#565a71"
                                    }
                                />
                            </div>
                            <li
                                className={`${
                                    selected === item.path && "text-white"
                                }`}
                                key={index}
                                onClick={() => setSelected(item.path)}
                            >
                                <Link to={item.path}>{item.label}</Link>
                            </li>
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;
