import React, { useState } from "react";
import logo2 from "../../Assets/Image/logo2.png";
import NavBar from "../../Components/Navbar/NavBar";
import ParentContainer from "../../Components/ParentContainer/ParentContainer";
import TopBanner from "../../Components/TopBanner/TopBanner";
import banner from "../../Assets/Image/banner2.png";
import { Grid } from "@mui/material";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import InputField from "../../utilitiesComponents/InputField/InputField";
import styles from "./styles.module.scss";
import Button from "../../utilitiesComponents/Button/Button";
import Footer from "../../Components/Footer/Footer";
import MobileFooter from "../../Components/Footer/MobileFooter";
import { packageTour } from "../../JsonData/PackageTour";
import {
    adults,
    children,
    countries,
    hotelCategory,
    travelMode,
} from "../../JsonData/DropdownOptions";
import InputSelectField from "../../utilitiesComponents/SelectInputField/InputSelectField";
import TextAreaInputField from "../../utilitiesComponents/TextAreaInputField/TextAreaInputField";
import FloatingButton from "../../Components/FloatingButton/FloatingButton";
import { sendEmail } from "../../utils/mail";

function ReservationForm() {
    const [values, setValues] = useState({
        mailType: "reservation"
    });

    function handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        setValues({
            ...values,
            [name]: value
        });
    }

    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton/>
            <ParentContainer>
                <TopBanner
                    showBanner={true}
                    img={banner}
                    label={"Reservation Form"}
                />
            </ParentContainer>

            <div className="bg-greyBackgorund py-10 mt-10">
                {" "}
                <div
                    className={`rounded-md mx-32 md:mx-16 sm:mx-8 
                    2xs:mx-4 bg-white  py-14 ${styles.formBox}`}
                >
                    <h1 className="text-2xl text-secondaryColor font-bold text-center md:text-2xl sm:text:3xl ">
                        For Booking Information/ Reservation, Please fill the
                        form
                    </h1>
                    <form 
                        onSubmit={(event) => { 
                            event.preventDefault();
                            sendEmail(values);
                        }}
                    >
                    <GoogleReCaptchaProvider reCaptchaKey="6LdqwQgkAAAAAOhlmQB31Hqo1iCWFHjaWJlSSKMf">
                    <Grid container>
                    <Grid item sm={6} className="w-full p-3">
                        <InputField className="" label={"Name :"} name="Name" isBlur={false} onChange={handleInputChange} />
                    </Grid>
                    <Grid item sm={6} className="w-full p-3">
                        <InputField label={"Address :"} name="Address" isBlur={false} onChange={handleInputChange} />
                    </Grid>
                    <Grid item sm={6} className="w-full p-3">
                        <InputSelectField
                            label={"Country :"}
                            isBlur={false}
                            option={countries.map((item, index) => (
                                item.name
                            ))}
                            name="Country"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item sm={6} className="w-full p-3">
                        <InputField label={"Email :"} name="Email" isBlur={false} onChange={handleInputChange} />
                    </Grid>
                    <Grid item sm={12} className="w-full flex p-3">
                        <div style={{ width: "100%", marginRight: "20px" }}>
                            <InputField label={"Code :"} name="Code" isBlur={false} onChange={handleInputChange} />
                        </div>
                        <div style={{ width: "100%" }}>
                            <InputField
                                label={"Phone Number :"}
                                isBlur={false}
                                name="Phone"
                                onChange={handleInputChange}
                            />
                        </div>
                    </Grid>
                    </Grid>
                    <h1 className=" mt-10 text-xl text-secondaryColor font-bold text-center md:text-2xl sm:text:3xl ">
                        Details of Journey
                    </h1>
                    <Grid container>
                        <Grid item sm={6} className="w-full p-3">
                            <InputField
                                label={"Duration of travel :"}
                                isBlur={false}
                                name="Duration"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item sm={6} className="w-full p-3">
                            <InputField
                                type="date"
                                label={"Arrival Date :"}
                                isBlur={false}
                                name="Arrival"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item sm={12} className="w-full flex p-3">
                            <div style={{ width: "50%", marginRight: "20px" }}>
                                <InputSelectField
                                    label={"Adults :"}
                                    option={adults}
                                    name="Adults"
                                    isBlur={false}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div style={{ width: "50%" }}>
                                <InputSelectField
                                    label={"Children :"}
                                    option={children}
                                    name="Children"
                                    isBlur={false}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Grid>
                        <Grid item sm={6} className="w-full p-3">
                            <InputField
                                label={"Age of Children :"}
                                isBlur={false}
                                name="Age"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item sm={6} className="w-full p-3">
                            <InputSelectField
                                label={"Tour packages :"}
                                option={packageTour.map((item) => (item.titleName))}
                                name="Package"
                                isBlur={false}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item sm={6} className="w-full p-3">
                            <InputSelectField
                                label={"Travel Mode :"}
                                option={travelMode}
                                name="Mode"
                                isBlur={false}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item sm={6} className="w-full p-3">
                            <InputSelectField
                                label={"Hotel Category :"}
                                option={hotelCategory}
                                name="Hotel"
                                isBlur={false}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item sm={12} className="w-full p-3">
                            <TextAreaInputField
                                label={"Preferences"}
                                name="Preferences"
                                isBlur={false}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <div className="flex justify-center items-center  pt-20 w-full">
                            <Button
                                type="submit"
                                title="Submit"
                                style={{
                                    background: "var(--primaryColor)",
                                    color: "white",
                                    borderRadius: "10px",
                                    width: "400px",
                                    height: "40px",
                                    fontSize: "14px",
                                }}
                            />
                            <Button
                                type="button"
                                title="Reset"
                                style={{
                                    border: "1px solid var(--primaryColor)",
                                    color: "var(--primaryColor)",
                                    borderRadius: "10px",
                                    marginLeft: "20px",
                                    width: "130px",
                                    height: "40px",
                                    fontSize: "14px",
                                }}
                                onClick={() => window.location.reload()}
                            />
                        </div>
                    </Grid>
                    <GoogleReCaptcha />
                    </GoogleReCaptchaProvider>
                    </form>
                </div>
            </div>
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default ReservationForm;
