import React from "react";
import NavBar from "../../Components/Navbar/NavBar";
import logo2 from "../../Assets/Image/logo2.png";
import TopBanner from "../../Components/TopBanner/TopBanner";
import ParentContainer from "../../Components/ParentContainer/ParentContainer";
import FilledBackground from "../../Components/FilledBackground/FilledBackground";
import styles from "./style.module.scss";
import {
    headerTitle,
    paymentsContent1,
    paymentsContent2,
    accountDetailsContent1,
    accountDetailsContent2,
    accountDetails,
    cancellationTitle,
    cancellationContent,
    // cancellationTitle1,
    cancellationTitle2,
    cancellationContent1,
    cancellationContent2,
} from "../../JsonData/TermsAndCondition";
import Heading2 from "../../Components/Heading2/Heading2";
import Subheader from "../../Components/SubHeader/Subheader";
import Footer from "../../Components/Footer/Footer";
import MobileFooter from "../../Components/Footer/MobileFooter";
import FloatingButton from "../../Components/FloatingButton/FloatingButton";

function TermsAndCondition() {
    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton/>
            <ParentContainer>
                <TopBanner showBanner={false} label={"Terms and Condition"} />
            </ParentContainer>

            <FilledBackground>
                <h4 className="text-lg text-primaryColor font-semibold md:text-base sm:text-base 2xs:text-sm">
                    {headerTitle}
                </h4>
            </FilledBackground>

            <FilledBackground>
                <Heading2
                    isVerticalLine={true}
                    heading={"Payments"}
                    blueText={true}
                />
                <Subheader>
                    <li>{paymentsContent1}</li>
                </Subheader>
                <div
                    style={{ background: "rgba(87, 132, 220, 0.5)" }}
                    className="text-white px-10 py-5 rounded-md mt-5 sm:px-5 2xs:px-5"
                >
                    <p className="font-medium leading-10 sm:leading-8 2xs:leading-8">
                        {paymentsContent2}
                    </p>
                </div>
            </FilledBackground>

            <FilledBackground>
                <Heading2
                    isVerticalLine={true}
                    heading={"Account Details"}
                    blueText={true}
                />
                <Subheader>
                    <li>{accountDetailsContent1}</li>
                </Subheader>
                <div className="text-white p-5 rounded-md mt-5 bg-slider2 bg-cover bg-no-repeat flex justify-center items-center">
                    <div
                        className={`${styles.blurBackground} m-10 md:m-8 sm:m-2 2xs:m-2`}
                    >
                        {accountDetails.map((item, index) => (
                            <p
                                key={index}
                                className="font-medium leading-10 sm:leading-8 2xs:leading-8"
                            >
                                <span className="text-white">{item.label}</span>{" "}
                                {item.value}
                            </p>
                        ))}
                    </div>
                </div>
                <Subheader>
                    {accountDetailsContent2.map((item, index) => (
                        <li className="font-medium" key={index}>
                            {item}
                        </li>
                    ))}
                </Subheader>
            </FilledBackground>
            <FilledBackground>
                <Heading2
                    isVerticalLine={true}
                    heading={"Cancellation Policy"}
                    blueText={true}
                    isDanger={true}
                />
                <Subheader>{cancellationTitle}</Subheader>
                <div
                    style={{ background: "rgba(233, 98, 98, 0.8)" }}
                    className="text-white px-10 py-5 rounded-md mt-5 sm:px-5 2xs:px-5"
                >
                    {cancellationContent.map((item, index) => (
                        <p
                            key={index}
                            className="font-medium leading-10 sm:leading-8 2xs:leading-8"
                        >
                            {item}
                        </p>
                    ))}
                </div>
                <Subheader>
                    {accountDetailsContent2.map((item, index) => (
                        <li className="font-medium" key={index}>
                            {item}
                        </li>
                    ))}
                </Subheader>

                {/* <div className={styles.heading}>
                    <p className="ml-2">{cancellationTitle1}</p>
                </div> */}
                <Subheader>
                    {cancellationContent1.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </Subheader>
                <div className={styles.heading}>
                    <p className="ml-2">{cancellationTitle2}</p>
                </div>
                <Subheader>
                    <li>{cancellationContent2}</li>
                </Subheader>
            </FilledBackground>
            <div className="mb-10"></div>

            <Footer />
            <MobileFooter />
        </div>
    );
}

export default TermsAndCondition;
