import {
    faHome,
    faInfoCircle,
    faCar,
    faClipboardQuestion,
    faPlaneDeparture,
    // faTicket,
    faList,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";
export const navLinks = [
    { label: "Home", path: "/", icon: faHome },
    { label: "About Us", path: "/about-us", icon: faInfoCircle },
    { label: "Our Services", path: "/our-service", icon: faList },
    { label: "Tour Packages", path: "/tour-package", icon: faPlaneDeparture },
    // { label: "Reservation", path: "/reservation-form", icon: faTicket },
    { label: "Car Rental", path: "/car-rental", icon: faCar },
    { label: "Contact Us", path: "/contact-us", icon: faClipboardQuestion },
    { label: "Search", path: "/search", icon: faSearch, mobileView: true },
];

