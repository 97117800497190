import React from "react";
import NavBar from "../../Components/Navbar/NavBar";
import logo2 from "../../Assets/Image/logo2.png";
import ParentContainer from "../../Components/ParentContainer/ParentContainer";
import TopBanner from "../../Components/TopBanner/TopBanner";
import banner from "../../Assets/Image/banner3.png";
import Heading2 from "../../Components/Heading2/Heading2";
import InnerContent2 from "../../Components/InnerContent/InnerContent2";
import { bottomService, ourService } from "../../JsonData/OurService";
import InnerContent from "../../Components/InnerContent/InnerContent";
import { Grid } from "@mui/material";
import Footer from "../../Components/Footer/Footer";
import MobileFooter from "../../Components/Footer/MobileFooter";
import FloatingButton from "../../Components/FloatingButton/FloatingButton";
import messages from "./messages";

function OurService({ lang }) {
    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton/>
            <ParentContainer>
                <TopBanner
                    showBanner={true}
                    img={banner}
                    label={messages.title[lang]}
                    imgAlt="banner"
                />
            </ParentContainer>

            <ParentContainer>
                <div>
                    <div className=" pb-0">
                        <Heading2
                            isVerticalLine={false}
                            heading={
                                messages.heading1[lang]
                            }
                        />
                    </div>
                    <InnerContent2
                        details={
                            messages.paragraph1[lang]
                        }
                    />
                </div>
            </ParentContainer>
            <div className="bg-greyBackgorund pb-10">
                <ParentContainer>
                    <div className="pt-10">
                        <Heading2
                            isVerticalLine={false}
                            heading={messages.title[lang]}
                        />
                    </div>

                    <div>
                        {ourService.map((item, index) => (
                            <InnerContent
                                isSvg={true}
                                key={index}
                                img={item.img}
                                details={item.details[lang]}
                                bg={item.code}
                                spanText={item.spanText[lang]}
                            />
                        ))}
                    </div>
                </ParentContainer>
            </div>

            <div className="bg-white pb-10">
                <ParentContainer>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            md={8}
                            className="flex justify-center items-center"
                        >
                            <h1 className="text-left font-medium text-secondaryColor text-3xl sm:text-2xl 2xs:text-2xl">
                                {messages.heading2[lang]}
                            </h1>
                        </Grid>
                        <Grid item md={4}>
                            <div className="p-5 flex flex-wrap">
                                {bottomService.map((item, index) => (
                                    <div
                                        key={index}
                                        className="flex justify-center items-center"
                                        style={{
                                            background: item.code,
                                            borderRadius: "5px",
                                            margin: "12px",
                                            width: "120px",
                                            height: "120px",
                                        }}
                                    >
                                        <img
                                            src={item.img}
                                            alt={item.img}
                                            width={50}
                                        />
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                </ParentContainer>
            </div>
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default OurService;
