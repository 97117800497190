import english from "../Assets/Svg/england.svg";
import spanish from "../Assets/Svg/spanish.svg";
import french from "../Assets/Svg/french.svg";
import german from "../Assets/Svg/german.svg";
import italian from "../Assets/Svg/italian.svg";

export const FlagLink = [
    { img: english, title: "English", code: "en" },
    { img: spanish, title: "Spanish", code: "es" },
    { img: french, title: "French", code: "fr" },
    { img: italian, title: "Italian", code: "it" },
    { img: german, title: "German", code: "de" },
];
