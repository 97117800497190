export default {
    "title": {
        "en": "About Us",
        "es": "Sobre nosotras",
        "fr": "À propos de nous",
        "it": "Chi siamo",
        "de": "Über uns"
    },
    "heading1": {
        "en": "All started with a small step",
        "es": "Todo empezó con un pequeño paso",
        "fr": "Tout a commencé par un petit pas",
        "it": "Tutto è iniziato con un piccolo passo",
        "de": "Alles begann mit einem kleinen Schritt"
    },
    "heading2": {
        "en": "A step called",
        "es": "Un paso llamado",
        "fr": "Une étape appelée",
        "it": "Un passo chiamato",
        "de": "Ein Schritt rief"
    },
    "heading3": {
        "en": "Foresight",
        "es": "Previsión",
        "fr": "Prévoyance",
        "it": "Preveggenza",
        "de": "Voraussicht"
    },
    "subheading": {
        "en": "Global India Tour formed with the mission to carve for itself a niche as specialist in the delivery of unique experience. We organize Luxury, Exclusive, Deluxe, Special interest tours, Cultural India tour, Heritage India Tour ,Rural India Tour, Trekking Tour, Royal India Tour, Safari and Wildlife Tour, Adventure Tour, Beaches Tour, Ethnic India Tour, Medical Tour, Luxury Nepal Travel package with emphasis on cultural, Historical, Rural Tribal India, Art and Craft, Rejuvenations Ayurveda, Yoga Meditation, Wildlife.",
        "es": "'Global India Tour' se formó con la misión de hacerse un hueco como especialista en la entrega de una experiencia única. Organizamos tours de lujo, exclusivos, de lujo, de interés especial, tour cultural de la India, tour del patrimonio de la India, tour de la India rural, tour de trekking, tour de la India real, tour de safari y vida salvaje, tour de aventura, tour de playas, tour de la India étnica, tour médico, lujo Paquete de viaje a Nepal con énfasis en cultura, historia, India tribal rural, arte y artesanía, rejuvenecimiento ayurvédico, meditación de yoga, vida silvestre.",
        "fr": "'Global India Tour' s'est formé avec la mission de se tailler une niche en tant que spécialiste dans la livraison d'une expérience unique. Nous organisons des circuits de luxe, exclusifs, de luxe, d'intérêt spécial, circuit culturel de l'Inde, circuit de l'Inde patrimoniale, circuit de l'Inde rurale, circuit de trekking, circuit de l'Inde royale, circuit safari et faune, circuit d'aventure, circuit des plages, circuit de l'Inde ethnique, circuit médical, luxe Forfait voyage au Népal mettant l'accent sur l'Inde tribale culturelle, historique, rurale, l'art et l'artisanat, le rajeunissement Ayurveda, la méditation yoga, la faune.",
        "it": "'Global India Tour' si è formato con la missione di ritagliarsi una nicchia come specialista nella fornitura di esperienze uniche. Organizziamo tour di lusso, esclusivi, deluxe, di interesse speciale, tour dell'India culturale, tour dell'India del patrimonio, tour dell'India rurale, tour di trekking, tour dell'India reale, tour di safari e fauna selvatica, tour di avventura, tour delle spiagge, tour dell'India etnica, tour medico, lusso Pacchetto viaggio Nepal con enfasi sull'India tribale culturale, storica, rurale, arte e artigianato, ringiovanimento, ayurveda, meditazione yoga, fauna selvatica.",
        "de": "„Global India Tour“ wurde mit der Mission gegründet, sich eine Nische als Spezialist für die Bereitstellung einzigartiger Erlebnisse zu schaffen. Wir organisieren Luxus-, Exklusiv-, Deluxe-, Special-Interest-Touren, kulturelle Indien-Tour, Heritage India-Tour, ländliche Indien-Tour, Trekking-Tour, königliche Indien-Tour, Safari- und Wildtier-Tour, Abenteuer-Tour, Strand-Tour, ethnische Indien-Tour, medizinische Tour, Luxus Nepal-Reisepaket mit Schwerpunkt auf kulturellem, historischem, ländlichem Stammes-Indien, Kunst und Handwerk, Ayurveda-Verjüngung, Yoga-Meditation, Tierwelt."
    },
    "questionheading": {
        "en": "WHY US!",
        "es": "¡PORQUE NOSOTROS!",
        "fr": "POURQUOI NOUS!",
        "it": "PERCHÉ NOI!",
        "de": "WARUM WIR!"
    },
    "thankyounote": {
        "en": "We would again like to thank you for considering our services and would like to invite you, your family and your friends to enjoy our tour packages.",
        "es": "Nuevamente nos gustaría agradecerle por considerar nuestros servicios y nos gustaría invitarlo a usted, a su familia y a sus amigos a disfrutar de nuestros paquetes turísticos.",
        "fr": "Nous tenons à nouveau à vous remercier d'avoir pensé à nos services et nous vous invitons, vous, votre famille et vos amis, à profiter de nos voyages organisés.",
        "it": "Vorremmo ancora ringraziarvi per aver preso in considerazione i nostri servizi e vorremmo invitare voi, la vostra famiglia e i vostri amici a godervi i nostri pacchetti turistici.",
        "de": "Wir möchten Ihnen noch einmal dafür danken, dass Sie unsere Dienstleistungen in Betracht gezogen haben, und möchten Sie, Ihre Familie und Ihre Freunde einladen, unsere Tourpakete zu genießen."
    }
}