import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "../../index.css";
import Slider from "../../Components/Slider/Slider";
import NavBar from "../../Components/Navbar/NavBar";
import logo2 from "../../Assets/Image/logo2.png";
import Heading from "../../Components/Heading/Heading";
import Subheader from "../../Components/SubHeader/Subheader";
import ParentContainer from "../../Components/ParentContainer/ParentContainer";
import CardsCarousel from "../../Components/CardsCarousel/CardsCarousel";
import CardsCarousel2 from "../../Components/CardsCarousel2/CardsCarousel2";
import CardArrangement from "../../Components/CardsArrangement/CardsArrangment";
import arrow from "../../Assets/Svg/arrow.svg";
import FloatingButton from "../../Components/FloatingButton/FloatingButton";
import {
    destinationCardCarousel,
    DestinationCardOptions,
} from "../../JsonData/CarouselCards";
import styles from "./Styles.module.scss";
import { Grid } from "@mui/material";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import CardsArrangement2 from "../../Components/CardsArrangement/CardsArrangement2";
import ReviewsCarousel from "../../Components/ReviewsCarousel/ReviewsCarousel";
import InputField from "../../utilitiesComponents/InputField/InputField";
import GridComponents from "../../Components/GridComponents/GridComponents";
import InputSelectField from "../../utilitiesComponents/SelectInputField/InputSelectField";
import { packageTour } from "../../JsonData/PackageTour";
import {
    adults,
    children,
    countries,
    travelMode,
    hotelCategory,
} from "../../JsonData/DropdownOptions";

import tripadvisor from "../../Assets/Image/tripAdvisor.png";
import Button from "../../utilitiesComponents/Button/Button";
import TextAreaInputField from "../../utilitiesComponents/TextAreaInputField/TextAreaInputField";
import Footer from "../../Components/Footer/Footer";
import MobileFooter from "../../Components/Footer/MobileFooter";
import { useHistory } from "react-router-dom";
import { sendEmail } from "../../utils/mail";

function Home() {
    const node = document.createElement("div");
    node.className = "owl-new";

    const history = useHistory();

    const [values, setValues] = useState({
        mailType: "reservation"
    });

    function handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        setValues({
            ...values,
            [name]: value
        });
    }

    return (
        <div className="relative bg-greyBackgorund ">
            <NavBar logo2={logo2} isBanner={true} isHome={true} />
            <Slider />
            <FloatingButton/>
            <ParentContainer>
                <Heading>Welcome to India</Heading>
                <Subheader>
                    <p className="mb-20">
                    <span className="text-5xl md:text-4xl sm:text-3xl 2xs:text-2xl">
                        Explore
                    </span>{" "}
                    the most fascinating regions of the world. One of the oldest
                    civilizations with extremely rich cultural heritage, where feudal
                    traditions still carry on amidst forts and palace hotels. Indian
                    Subcontinent – bestowed with spectacular desert, shifting sand dunes,
                    busting colorful towns and quiet villages, myriad lagoons and
                    magnificent havelis. A land of heroism, chivalry, magical fantasies.
                    Rich in art and craft, music, dance and adventure......
                    </p>
                </Subheader>
            </ParentContainer>
            <div className={`relative bg-udaipurFormBanner ${styles.formBanner}`}>
                <div className="px-52 md:px-16 sm:px-8 2xs:px-4">
                    <h1 className="uppercase pt-14 pb-5 text-4xl text-white font-bold text-center md:text-2xl sm:text:3xl sm:pt-10 2xs:text-2xl">
                        Get a free quote
                    </h1>{" "}
                    <form 
                        onSubmit={(event) => { 
                            event.preventDefault();
                            sendEmail(values);
                        }}
                    >
                    <GoogleReCaptchaProvider reCaptchaKey="6LdqwQgkAAAAAOhlmQB31Hqo1iCWFHjaWJlSSKMf">
                    <Grid container spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                        <GridComponents>
                            <InputField label={"Name :"} name="Name" isBlur={true} onChange={handleInputChange} />
                        </GridComponents>
                        <GridComponents>
                            <InputField label={"Email :"} name="Email" isBlur={true} onChange={handleInputChange} />
                        </GridComponents>
                        <GridComponents>
                            <InputField label={"Phone :"} name="Phone" isBlur={true} onChange={handleInputChange} />
                        </GridComponents>
                        <GridComponents>
                            <InputSelectField
                                label={"Country :"}
                                isBlur={true}
                                option={countries.map((item, index) => (
                                    item.name
                                ))}
                                name="Country"
                                onChange={handleInputChange}
                            />
                        </GridComponents>
                        <GridComponents>
                            <InputField label={"Address :"} name="Address" isBlur={true} onChange={handleInputChange} />
                        </GridComponents>
                        <GridComponents>
                            <Grid container spacing={4}>
                               <Grid item xs={12} md={6} lg={6}>
                                    <InputSelectField
                                        label={"Adults :"}
                                        option={adults}
                                        name="Adults"
                                        isBlur={true}
                                        onChange={handleInputChange}
                                    />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                               
                               
                                    <InputSelectField
                                        label={"Children :"}
                                        option={children}
                                        name="Children"
                                        isBlur={true}
                                        onChange={handleInputChange}
                                    />
                                    </Grid>
                                
                            </Grid>
                        </GridComponents>
                    </Grid>
                    <div className={styles.line}></div>
                    <Grid container spacing={{ xs: 2, sm: 2, md: 3, lg: 2 }}>
                        <GridComponents>
                            <InputField label={"Age of Children :"} name="Age" isBlur={true} onChange={handleInputChange} />
                        </GridComponents>
                        <GridComponents>
                            <InputField label={"Duration of Travel :"} name="Duration" isBlur={true} onChange={handleInputChange} />
                        </GridComponents>
                        <GridComponents>
                            <InputField type="date" label={"Arrival Date :"} name="Arrival" isBlur={true} onChange={handleInputChange} />
                        </GridComponents>

                        <GridComponents>
                            <InputSelectField
                                label={"Tour Packages :"}
                                isBlur={true}
                                option={packageTour.map((item) => item.titleName)}
                                name="Package"
                                onChange={handleInputChange}
                            />
                        </GridComponents>
                        <GridComponents>
                            <InputSelectField
                                label={"Travel Mode :"}
                                isBlur={true}
                                option={travelMode}
                                name="Mode"
                                onChange={handleInputChange}
                            />
                        </GridComponents>
                        <GridComponents>
                            <InputSelectField
                                label={"Hotel Category :"}
                                option={hotelCategory}
                                isBlur={true}
                                name="Hotel"
                                onChange={handleInputChange}
                            />
                        </GridComponents>
                        <Grid item md={12} className="w-full ">
                            <TextAreaInputField
                                label={"Preferences"}
                                name="Preferences"
                                isBlur={true}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <div className="flex justify-center items-center pt-10 pb-5">
                        <Button
                            type="submit"
                            title="Submit"
                            style={{
                                background: "var(--primaryColor)",
                                color: "white",
                                borderRadius: "10px",
                                width: "400px",
                                height: "40px",
                                fontSize: "14px",
                            }}
                        />
                    </div>
                    <GoogleReCaptcha
                        onVerify={(token) => {
                            console.log("You are human");
                        }}
                    />
                    </GoogleReCaptchaProvider>
                    </form>
                </div>
            </div>

            <div className="bg-white">
                <ParentContainer>
                    <div className="py-20">
                        <Heading>
                            MOST <span className="text-primaryColor">POPULAR</span> TOURS
                        </Heading>
                        <CardsCarousel cardsList={packageTour.filter((item) => item.popular_tour)} />
                    </div>
                </ParentContainer>
            </div>

            <div>
                <ParentContainer>
                    <Heading>
                        THE <span className="text-primaryColor">POPULAR</span> DESTINATIONS
                    </Heading>
                    <CardsCarousel2
                        cardsList={destinationCardCarousel}
                        options={DestinationCardOptions}
                        path={"/top-destination"}
                        isHome={true}
                    />
                </ParentContainer>
            </div>

            <div className="bg-white">
                <ParentContainer>
                    <div className="py-20">
                        <Heading>
                            OUR <span className="text-primaryColor">SPECIAL</span> PACKAGES
                        </Heading>
                        <div className="mt-16">
                            <Grid container spacing={4}>
                                <Grid
                                    item
                                    md={6}
                                    style={{ width: "100%" }}
                                    onClick={() => history.push("/tour-package#golden")}
                                >
                                    <CardsArrangement2
                                        arrow={arrow}
                                        styles={styles}
                                        banner={"bg-agraBanner"}
                                        label={"Golden Triangle Tours"}
                                    />
                                </Grid>
                                <Grid item md={6} style={{ width: "100%" }}>
                                    <Grid container direction="column" spacing={4}>
                                        <Grid
                                            item
                                            md={12}
                                            style={{ width: "100%" }}
                                            onClick={() => history.push("/tour-package#rajasthan")}
                                        >
                                            <CardArrangement
                                                arrow={arrow}
                                                styles={styles}
                                                banner={"bg-udaipurBanner"}
                                                label={"Rajasthan Tours"}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            style={{ width: "100%" }}
                                            onClick={() => history.push("/tour-package#exclusive")}
                                        >
                                            <CardArrangement
                                                arrow={arrow}
                                                styles={styles}
                                                banner={"bg-exclusiveBanner"}
                                                label="Exclusive Tours"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </ParentContainer>
            </div>

            <div>
                <ParentContainer>
                    <Heading>
                        OUR <span className="text-primaryColor">INDIAS</span> BEAUTY
                    </Heading>
                    <div className="mt-16  pb-20">
                        <Grid container spacing={4}>
                            <Grid item md={6} style={{ width: "100%" }}>
                                <CardsArrangement2
                                    arrow={arrow}
                                    styles={styles}
                                    banner={"bg-mevarBanner"}
                                    label={"Fair and Festivals"}
                                    onclick={() => history.push("/fair-and-festivals")}
                                />
                            </Grid>
                            <Grid item md={6} style={{ width: "100%" }}>
                                <Grid container direction="column" spacing={4}>
                                    <Grid item md={12} style={{ width: "100%" }}>
                                        <CardArrangement
                                            arrow={arrow}
                                            styles={styles}
                                            banner={"bg-tigerBanner"}
                                            label={"Indian Wildlife"}
                                            onclick={() => history.push("/national-life")}
                                        />
                                    </Grid>
                                    <Grid item md={12} style={{ width: "100%" }}>
                                        <CardArrangement
                                            arrow={arrow}
                                            styles={styles}
                                            banner={"bg-hotelsBanner"}
                                            label="Heritage Hotels"
                                            onclick={() => history.push("/hotels")}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </ParentContainer>
            </div>

            <div className="bg-white">
                <ParentContainer>
                    <div className="py-20">
                        <Heading>
                            LOOK WHAT OUT <span className="text-primaryColor">CUSTOMERS</span>{" "}
                            SAYS
                        </Heading>
                        <ReviewsCarousel />
                    </div>
                </ParentContainer>
            </div>
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default withRouter(Home);
