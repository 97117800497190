import React, { useState } from "react";
import NavBar from "../../Components/Navbar/NavBar";
import styles from "./styles.module.scss";
import logo2 from "../../Assets/Image/logo2.png";
import { Grid } from "@mui/material";
import call from "../../Assets/Svg/calls.svg";
import location from "../../Assets/Svg/location.svg";
import email from "../../Assets/Svg/email.svg";
import Footer from "../../Components/Footer/Footer";
import MobileFooter from "../../Components/Footer/MobileFooter";
import InputField from "../../utilitiesComponents/InputField/InputField";
import TextAreaInputField from "../../utilitiesComponents/TextAreaInputField/TextAreaInputField";
import Button from "../../utilitiesComponents/Button/Button";
import FloatingButton from "../../Components/FloatingButton/FloatingButton";
import messages from "./messages";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { sendEmail } from "../../utils/mail";

const IconComponent = ({ image, label, width }) => {
    return (
        <div className="flex mb-5 items-center">
            <img src={image} alt={label} width={width} className="mr-5" />
            <h4 className="text-white font-medium ">{label}</h4>
        </div>
    );
};

function ContactUs({ lang }) {

    const [values, setValues] = useState({
        mailType: "contact"
    });

    function handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        setValues({
            ...values,
            [name]: value
        });
    }

    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton/>
            <div className="relative w-full ">
                <div>
                    <Grid container spacing={0}>
                        <Grid item md={6} className="w-full">
                            <div
                                className={`bg-contactBanner w-full ${styles.Banner} p-20 md:px-10 sm:px-5 2xs:px-5`}
                                style={{
                                    height: "100vh",
                                }}
                            >
                                <div className="mt-20">
                                    <h2 className="text-white font-semibold text-3xl">
                                        {messages.title[lang]}:
                                    </h2>
                                    <h6 className="text-white font-medium mt-4 ">
                                        {messages.heading1[lang]}
                                    </h6>

                                    <div className={styles.infoBox}>
                                        <IconComponent
                                            label={"95, Shri Ram Nagar 'Extn', Jhotwara, Jaipur (Rajasthan)"}
                                            image={location}
                                            width={24}
                                        />
                                        <IconComponent
                                            label={"global_indiatour@yahoo.in"}
                                            image={email}
                                            width={30}
                                        />
                                        <IconComponent
                                            label={"+91-98291-36255"}
                                            image={call}
                                            width={30}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            style={{ height: "100vh" }}
                            className="w-full flex h-full justify-center items-center"
                        >
                            <div
                                className={`mt-10 w-full p-20 md:px-10 sm:px-5 sm:mt-0 2xs:px-5 2xs:mt-0`}
                            >
                                <h1 className="text-3xl text-secondaryColor  font-semibold text-center sm:text-2xl 2xs:text-xl">
                                    {messages.title[lang]}
                                </h1>
                                <form
                                    onSubmit={(event) => {
                                        event.preventDefault();
                                        sendEmail(values);
                                    }}
                                >
                                    <GoogleReCaptchaProvider reCaptchaKey="6LdqwQgkAAAAAOhlmQB31Hqo1iCWFHjaWJlSSKMf">
                                        <InputField name="Name" label={messages.input1[lang]} isBlur={false} onChange={handleInputChange} />
                                        <InputField name="Email" label={messages.input2[lang]} isBlur={false} onChange={handleInputChange} />
                                        <InputField name="Phone" label={messages.input3[lang]} isBlur={false} onChange={handleInputChange} />
                                        <TextAreaInputField
                                            label={messages.input4[lang]}
                                            name="Message"
                                            isBlur={false}
                                            row={5}
                                            onChange={handleInputChange}
                                        />{" "}
                                        <div className="flex justify-center items-center mt-10">
                                            <Button
                                                type="submit"
                                                title={messages.button1[lang]}
                                                style={{
                                                    background: "var(--primaryColor)",
                                                    color: "white",
                                                    borderRadius: "10px",
                                                    width: "400px",
                                                    height: "40px",
                                                    fontSize: "14px",
                                                }}
                                            />
                                        </div>
                                        <GoogleReCaptcha />
                                    </GoogleReCaptchaProvider>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default ContactUs;
