import React from "react";
import { Grid } from "@mui/material";
function GridComponent({ label, alt, img }) {
    return (
        <li>
            <Grid container>
                <Grid item sm={2}>
                    <img
                        src={img}
                        alt={alt}
                        width={"20"}
                        style={{ marginRight: "10px" }}
                    />
                </Grid>
                <Grid item sm={10}>
                    {label}
                </Grid>
            </Grid>
        </li>
    );
}

export default GridComponent;
