import React from "react";
import { Grid } from "@mui/material";
import styles from "./styles.module.scss";
import Subheader from "../SubHeader/Subheader";

function InnerContent({ img, details, isSvg, bg, spanText }) {
    return (
        <Grid container className="mb-12">
            {isSvg ? (
                <Grid
                    item 
                    md={1}
                    className="w-full md:flex md:justify-center sm:flex sm:justify-center 2xs:flex 2xs:justify-center"
                >
                    <div
                        className="flex justify-center items-center mt-10"
                        style={{
                            background: bg,
                            borderRadius: "5px",
                            width: "70px",
                            height: "70px",
                        }}
                    >
                        <img src={img} alt={img} width={40} />
                    </div>
                </Grid>
            ) : (
                <Grid
                    item
                    md={1}
                    className="w-full md:flex md:justify-center sm:flex sm:justify-center 2xs:flex 2xs:justify-center"
                >
                    <img src={img} alt={img} width={70} className="mt-10" />
                </Grid>
            )}

            <Grid item md={11} className="w-full">
                <div className="flex ml-5 sm:ml-0 2xs:ml-0">
                    <div className={`${styles.verticalLine} mt-10`}></div>
                    <div>
                        <Subheader>
                            <span className="font-semibold">{spanText}</span>{" "}
                            {details}
                        </Subheader>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}

export default InnerContent;
