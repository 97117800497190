export const searchData = [
    {
        "title": "North India With Taj Mahal",
        "Description": "Delhi - Mandawa - Bikaner - Pokran - Jaisalmer - Jodhpur - Ranakpur - Kumbhalgarh - Udaipur - Pushkar - Jaipur - Amber Fort - Fatehpur Sikari - Agra - Delhi",
        "path" : "/package-details/1"

    },
    {
        "title": "North India With Varanasi",
        "Description": "Delhi - Mandawa - Bikaner - Pokran - Jaisalmer - Jodhpur - Ranakpur - Kumbhalgarh - Udaipur - Pushkar - Jaipur - Amber Fort - Fatehpur Sikari - Agra - Gwalior - Orchha - Khajuraho (Flight) - Varanasi (Flight) - Delhi",
        "path" : "/package-details/2"

    },
    {
        "title": "North India With Goa",
        "Description": "Delhi – Mandawa – Bikaner – Pokran – Jaisalmer – Jodhpur – Ranakpur – Udaipur – Pushkar -  Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi –(Flight) -  Goa – (Flight) Delhi",
        "path" : "/package-details/3"

    },
    {
        "title": "North India With South India",
        "Description": "Delhi – Mandawa – Bikaner – Pokran – Jaisalmer – Jodhpur – Ranakpur – Udaipur – Pushkar -  Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi – (Flight) Cochin – Kumarakom – Allepey – Kovalam – Trivandrum – (Flight) Delhi / Mumbai",
        "path" : "/package-details/4"

    },
    {
        "title": "North India With Nepal",
        "Description": "Delhi – Mandawa – Bikaner – Pokran – Jaisalmer – Jodhpur – Ranakpur Kumbhalgarh – Udaipur – Pushkar -  Jaipur – Amber Fort – Fatehpur Sikari – Agra – Gwalior – Orchha – Khajuraho - (Flight) – Varanasi (Flight) Kathmandu – (Flight) – Delhi",
        "path" : "/package-details/5"

    },
    {
        "title": "Golden Triangle Tour",
        "Description": "Delhi – Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi",
        "path" : "/package-details/6"

    },
    {
        "title": "Golden Triangle With Golden Temple",
        "Description": "Delhi – Amritsar – Delhi – Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi",
        "path" : "/package-details/7"

    },
    {
        "title": "Golden Triangle With Varanasi",
        "Description": "Delhi – Jaipur – Fatehpur Sikri – Agra – Orchha – Khajuraho – Flight – Varanasi  Flight – Delhi",
        "path" : "/package-details/8"

    },
    {
        "title": "Golden Triangle With Nepal",
        "Description": "Delhi – Jaipur – Fatehpur Sikri – Agra – Orchha – Khajuraho  Flight – Varanasi  Flight – Kathmandu  Flight – Delhi",
        "path" : "/package-details/9"

    },
    {
        "title": "Golden Triangle With Wildlife Tour",
        "Description": "Delhi – Jaipur – Ranthambhore – Bharatpur - Fatehpur Sikri – Agra  – Bandhavgarh - Khajuraho – Flight – Varanasi – Flight – Delhi",
        "path" : "/package-details/10"

    },
    {
        "title": "Best of Rajasthan",
        "Description": "Delhi – Agra -  Jaipur – Bikaner – Jaisalmer – Jodhpur – Ranakpur – Udaipur – Flight -  Delhi",
        "path" : "/package-details/11"

    },
    {
        "title": "Kingdom of Rajasthan",
        "Description": "Delhi – Mandawa – Khimsar – Luni – Deogarh – Udaipur – Pushkar – Jaipur – Agra – Fatehpur Sikri – Agra - Delhi",
        "path" : "/package-details/12"

    },
    {
        "title": "Historical Rajasthan Tour",
        "Description": "Delhi – Mandawa – Bikaner – Pokran – Jaisalmer – Jodhpur – Ranakpur – Kumbhalgarh – Udaipur – Pushkar -  Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi",
        "path" : "/package-details/13"

    },
    {
        "title": "Royal Rajasthan",
        "Description": "Delhi – Mandawa – Bikaner – Jaisalmer – Jodhpur – Luni – Ranakpur – Udaipur – Chittaurgarh - Bundi -  Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi",
        "path" : "/package-details/14"

    },
    {
        "title": "Exotic Rajasthan",
        "Description": "Delhi – Mandawa – Bikaner – Jaisalmer – Jodhpur – Luni – Ranakpur – Udaipur – Chittaurgarh - Bundi – Pushkar -  Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi",
        "path" : "/package-details/15"

    },
    {
        "title": "Buddhist Pilgrimage Tour",
        "Description": "Delhi – Patna – Bodhgaya – Varanasi – Kushinagar – Lumbini – Sravasti – Lucknow -  – Agra – Delhi",
        "path" : "/package-details/16"

    },
    {
        "title": "Ajanta Ellora with Royal Rajasthan Tours",
        "Description": "Delhi – Agra -  Jaipur – Pushkar – Jodhpur - Ranakpur – Udaipur – Flight – Aurangabad – Flight - Mumbai",
        "path" : "/package-details/17"

    },
    {
        "title": "Pushkar Fair Tours",
        "Description": "Delhi – Mandawa – Bikaner – Jaisalmer – Jodhpur – Ranakpur – Udaipur – Pushkar -Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi",
        "path" : "/package-details/18"

    },
    {
        "title": "Royal Fort and Palaces Tours",
        "Description": "Delhi – Neemrana – Samode -  Mandawa – Khimsar – Jaisalmer - Jodhpur -  Udaipur – Deogarh  – Jaipur – Fatehpur Sikri – Agra - Delhi",
        "path" : "/package-details/19"

    },
    {
        "title": "Best of India Tours",
        "Description": "Delhi – Mandawa – Bikaner – Pokran – Jaisalmer – Jodhpur – Ranakpur – Kumbhalgarh – Udaipur – Pushkar -  Jaipur – Amber Fort – Fatehpur Sikari – Agra – Gwalior – Orchha – Khajuraho - (Flight) – Varanasi (Flight) – Mumbai – Flight – Cochin - Kumarakom – Allepey – Kovalam – Trivandrum – (Flight) Delhi / Mumbai",
        "path" : "/package-details/20"

    },
    {
        "title": "North India Tours",
        "Description": "",
        "path" : "/tour-package#north"

    },
    {
        "title": "Rajasthan Tours",
        "Description": "",
        "path" : "/tour-package#rajasthan"

    },
    {
        "title": "Exclusive Tours",
        "Description": "",
        "path" : "/tour-package#exclusive"

    },
    {
        "title": "Golden Triangle Tours",
        "Description": "",
        "path" : "/tour-package#golden"

    },
    {
        "title": "Private Day Tour",
        "Description": "",
        "path" : "/private-day-tour"

    },
    {
        "title": "Tips for Travelling in India",
        "Description": "",
        "path" : "/tips-and-travelling"

    },

    {
        "title": "Distance Calculator",
        "Description": "",
        "path" : "/distance-calculator"

    },

    {
        "title": "Currency Converter",
        "Description": "",
        "path" : "/currency-converter"

    },

    {
        "title": "Terms and Condition",
        "Description": "",
        "path" : "/terms-and-condition"

    },
    {
        "title": "About Us",
        "Description": "",
        "path" : "/about-us"

    },
    {
        "title": "Our Services",
        "Description": "",
        "path" : "/our-service"

    },
    {
        "title": "Tour Packages",
        "Description": "",
        "path" : "/tour-package"

    },
    {
        "title": "Reservation",
        "Description": "",
        "path" : "/reservation-form"

    },
    {
        "title": "Car Rental",
        "Description": "",
        "path" : "/car-rental"

    },
    {
        "title": "Contact Us",
        "Description": "",
        "path" : "/car-rental"

    },
    {
        "title": "Bundi Utsav",
        "Description": "Bundi (Rajasthan), 3 Days, November / December",
        "path" : "fair-and-festivals/details/1"

    },
    {
        "title": "Camel Festival",
        "Description": "Bikaner (Rajasthan), 2 Days, January",
        "path" : "fair-and-festivals/details/2"

    },
    {
        "title": "Desert Festival",
        "Description": "Jaisalmer (Rajasthan), 3 Days, January",
        "path" : "fair-and-festivals/details/3"

    },
    {
        "title": "Elephant Festival",
        "Description": "Pushkar (Rajasthan), 5 Days, February / march (on Holi)",
        "path" : "fair-and-festivals/details/4"

    },
    {
        "title": "Ganguar Festival",
        "Description": "Jaipur (Rajasthan), 16 Days, March/ April",
        "path" : "fair-and-festivals/details/5"

    },




]