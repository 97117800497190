import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

function IconComponent({ img, alt, style, width }) {
    return (
        <Grid item sm={4}>
            <Link to="/">
                <img
                    src={img}
                    alt={alt}
                    width={width ? width : "30"}
                    style={style}
                />
            </Link>
        </Grid>
    );
}

export default IconComponent;
