import React, { useState } from "react";
import "./SearchBar.css";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { Grid } from "@mui/material";
import styles from "./styles.module.scss";
import searchIcon from "../../Assets/Svg/searchIcon.svg";

import arrow from "../../Assets/Svg/arrowWhite.svg";

function SearchBar({ placeholder, data }) {
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");

    const handleFilter = (event) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        const newFilter = data.filter((value) => {
            console.log("search value", value);
            return value.title.toLowerCase().includes(searchWord.toLowerCase());
        });

        if (searchWord === "") {
            setFilteredData([]);
        } else {
            setFilteredData(newFilter);
        }
    };

    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    };

    return (
        <>
            <div className="relative w-full ">
                <div>
                    <Grid container spacing={0}>
                        <Grid item md={12} className="w-full">
                            <div
                                className={`bg-currencyBanner w-full ${styles.Banner} p-20 md:px-10 sm:px-5 2xs:px-5`}
                                style={{
                                    height: "100vh",
                                }}
                            >
                                <div className="flex justify-center w-full">
                                    <div className={`w-full ${styles.infoBox}`}>
                                        <div className="searchInputs w-full">
                                            <input
                                                className="bg-transparent text-white w-full"
                                                type="text"
                                                placeholder={placeholder}
                                                value={wordEntered}
                                                onChange={handleFilter}
                                            />
                                            <div className="searchIcon">
                                                {filteredData.length === 0 ? (
                                                    <img src={searchIcon} alt="loading" />
                                                ) : (
                                                    <CloseIcon style={{ color: "white" }} id="clearBtn" onClick={clearInput} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {filteredData.length != 0 && (
                                    <div className="mt-10">
                                        {filteredData.slice(0, 5).map((value, key) => {
                                            return (
                                                <div className={`w-full ${styles.infoBoxSearch}`}>
                                                    <Link to={value.path}>
                                                        <a className="dataItem" href={value.link} target="_blank">
                                                            <div className="flex justify-between">
                                                                <div><p>{value.title} </p> <br />
                                                                    <p>{value.Description}</p>
                                                                </div>
                                                                <div><img src={arrow} alt="loading" /></div>
                                                            </div>
                                                        </a>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>

                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default SearchBar;