import React from "react";
import styles from "./styles.module.scss";
import Subheader from "../SubHeader/Subheader";

function InnerContent2({ details }) {
    return (
        <div className="flex ml-5 sm:ml-0 2xs:ml-0">
            <div className={`${styles.verticalLine} mt-10`}></div>
            <div>
                <Subheader>{details}</Subheader>
            </div>
        </div>
    );
}

export default InnerContent2;
