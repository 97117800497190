import hands from "../Assets/Svg/hands.svg";
import bill from "../Assets/Svg/bill.svg";
import food from "../Assets/Svg/food.svg";

export const carRentalContent = [
    {
        code: "rgba(40, 228, 81, 0.3)",
        img: hands,
        spanText: "",
        details: {
            "en": "A nice, clean and comfortable car with having sufficient space for your luggage.",
            "es": "Un coche bonito, limpio y cómodo con suficiente espacio para tu equipaje.",
            "fr": "Une belle voiture propre et confortable avec suffisamment d'espace pour vos bagages.",
            "it": "Un'auto bella, pulita e confortevole con spazio sufficiente per i bagagli.",
            "de": "Ein schönes, sauberes und komfortables Auto mit ausreichend Platz für Ihr Gepäck."
        }
    },
    {
        code: "rgba(154, 220, 255, 0.3)",
        img: bill,
        spanText: "",
        details: {
            "en": "The car rental price will also included Fuel, Insurance, and Toll Taxes, State Taxes, Parkings and all transport related expenses.",
            "es": "El precio del alquiler del coche también incluirá Combustible, Seguros, Impuestos de peaje, Impuestos estatales, Estacionamientos y todos los gastos relacionados con el transporte.",
            "fr": "Le prix de la location de voiture comprendra également les taxes sur le carburant, les assurances et les péages, les taxes d'État, les parkings et toutes les dépenses liées au transport.",
            "it": "Il prezzo del noleggio auto includerà anche le tasse su carburante, assicurazione e pedaggio, le tasse statali, i parcheggi e tutte le spese relative al trasporto.",
            "de": "Der Mietwagenpreis beinhaltet auch Kraftstoff-, Versicherungs- und Mautsteuern, staatliche Steuern, Parkplätze und alle mit dem Transport verbundenen Kosten."
        }
    },
    {
        code: "rgba(255, 101, 101, 0.3)",
        img: food,
        spanText: "",
        details: {
            "en": "Driver Food and his lodging are also included in the car rental price. The car will be at your disposal.",
            "es": "La comida del conductor y su alojamiento también están incluidos en el precio del alquiler del coche. El coche estará a su disposición.",
            "fr": "La nourriture du chauffeur et son hébergement sont également inclus dans le prix de la location de voiture. La voiture sera à votre disposition.",
            "it": "Nel prezzo dell'autonoleggio sono inclusi anche il cibo dell'autista e il suo alloggio. L'auto sarà a tua disposizione.",
            "de": "Fahrerverpflegung und seine Unterkunft sind ebenfalls im Mietwagenpreis enthalten. Das Auto steht Ihnen zur Verfügung."
        }
    },
];

export const car = [
    {
        title: "Toyota Innova",
        seating_capacity: "6",
        img: "https://imgd.aeplcdn.com/0x0/n/cw/ec/51435/innova-crysta-exterior-right-front-three-quarter-3.jpeg",
    },
    {
        title: "Toyota Etios",
        seating_capacity: "4",
        img: "https://imgd.aeplcdn.com/664x374/n/s14t6ra_1421876.jpg",
    },
    {
        title: "Mercedes Benz",
        seating_capacity: "4",
        img: "https://imgd.aeplcdn.com/1200x900/n/cw/ec/47336/e-class-exterior-right-front-three-quarter.jpeg?q=75",
    },
    {
        title: "Tempo Traveller",
        seating_capacity: "10",
        img: "https://carhirepune.in/wp-content/uploads/2021/01/TEMPO-TRAVELLER-ON-RENT-IN-PUNE.jpg",
    },
    {
        title: "Luxury Mini Bus",
        seating_capacity: "18",
        img: "https://thumbs.dreamstime.com/b/white-luxury-mini-bus-isolated-white-mercedes-benz-white-luxury-mini-bus-isolated-white-146488954.jpg",
    },
    {
        title: "Luxury Bus",
        seating_capacity: "35",
        img: "https://www.freeiconspng.com/uploads/bus-png-25.jpg",
    },
    {
        title: "Luxury Volvo Bus",
        seating_capacity: "38",
        img: "https://heritagecabs.in/assets/uploads/product_images/hire-bus-in-jaipur.jpg",
    },
];
