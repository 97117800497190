import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "../../index.css";
import styles from "./styles.module.scss";
import NavBar from "../../Components/Navbar/NavBar";
import logo2 from "../../Assets/Image/logo2.png";
import TopBanner from "../../Components/TopBanner/TopBanner";
import ParentContainer from "../../Components/ParentContainer/ParentContainer";
import { Grid } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from "../../utilitiesComponents/Button/Button";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './style.css';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { privateDayTour } from "../../JsonData/PrivateDayTour";

import {
    adults,
    children,
    countries,
    travelMode,
    hotelCategory,
} from "../../JsonData/DropdownOptions";

import Footer from "../../Components/Footer/Footer";
import MobileFooter from "../../Components/Footer/MobileFooter";

import img1 from "../../Assets/Svg/packagedays.svg";
import circle from "../../Assets/Svg/circleAccordion.svg"
import info from "../../Assets/Svg/info.svg";
import FloatingButton from "../../Components/FloatingButton/FloatingButton";
import { sendEmail } from "../../utils/mail";

const IconComponent = ({ label }) => {
    return (
        <div className="flex mb-0 items-center">
            <h4 className="text-base text-white font-medium ">{label}</h4>
        </div>
    );
};

function PrivateDayTour() {

    const [packageInfo, setPackageInfo] = useState(privateDayTour[0]);

    console.log("PrivateDayTour packageInfo", packageInfo);

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let { id } = useParams();

    useEffect(() => {
        // const res = privateDayTour?.filter(
        //     (item) => item.id.toString() === id.toString()
        // );
        const res = privateDayTour;
        setPackageInfo(res[0]);
    }, [id]);

    const [values, setValues] = useState({
        mailType: "reservation",
        Package: privateDayTour[0].titleName
    });

    function handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        setValues({
            ...values,
            [name]: value
        });
    }

    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton />
            <div className="box">
                <div className="shadow-2xl">
                    <div className="pb-10 pt-14">
                        <div>
                            <ParentContainer>
                                <div className="flex">
                                    <div>
                                        <h className="text-4xl sm:text-3xl 2xs:text-3xl font-bold text-secondaryColor ">{packageInfo?.titleName}</h>
                                    </div>
                                </div>
                            </ParentContainer>
                        </div>
                    </div>

                    <Grid container className="pb-0">
                        <Grid item xs={12} md={1} lg={1}></Grid>
                        <Grid item xs={12} md={10} lg={10}>
                            <img src={packageInfo.Banner && require(`../../Assets/Image/${packageInfo.Banner}`).default} alt="banner" className="rounded-lg w-full mb-5" />
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="ITINERARY" />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} md={1} lg={1}></Grid>
                    </Grid>
                </div>
                <Grid container className="pb-10" style={{ marginTop: "30px" }}>
                    <Grid item xs={12} md={1} lg={1}></Grid>
                    <Grid item xs={12} md={10} lg={10}>
                        <div className="grid gap-4 grid-cols-3">
                            <div className="col-span-2 sm:col-span-3 2xs:col-span-3">
                                <div className="p-5 shadow-2xl" style={{ borderRadius: '12px' }}>
                                    <h className="text-primaryColor font-bold">Destination</h>

                                    <p className="pt-5">{packageInfo?.Destinations}</p>
                                </div>

                                <div className="p-5 shadow-2xl" style={{ marginTop: '35px', borderRadius: '12px' }}>
                                    <div className="p-5">
                                        <h className="text-secondaryColor font-bold text-4xl">Tour Itinerary</h>
                                    </div>
                                    {packageInfo?.Day?.map((item) => (
                                        <Accordion>
                                            <AccordionSummary
                                                className="drop-shadow-none"
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <div className="flex">
                                                    <div>
                                                        <img src={circle} alt="loading" />
                                                    </div>
                                                    <div>
                                                        <Typography className="pl-2 pt-2 text-primaryColor font-bold">{item.title}</Typography>
                                                    </div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography className="pl-11">
                                                    {item.Description}
                                                </Typography>
                                            </AccordionDetails>

                                        </Accordion>
                                    ))}
                                </div>

                                <div className="p-5 bg-ternaryBlue2 mt-10 flex" style={{ borderRadius: '12px' }}>
                                    <div><img className="pr-5" src={info} alt="loading" /></div>
                                    <div className="text-center"><h className="text-secondaryColor text-1xl sm:text-[3px] 2xl:text-[3px]">Request Note: - The proposed tour can be customized as per your need and requirements.</h></div>

                                </div>

                            </div>

                            <div className="col-span-1 sm:col-span-3 2xs:col-span-3">
                                <div className="shadow-2xl " style={{ borderRadius: '12px' }}>
                                    <div className="bg-blue p-5 text-center rounded-tl-lg rounded-tr-lg">
                                        <h className="text-white">Have an Enquiry</h>
                                    </div>
                                    <form 
                                        onSubmit={(event) => { 
                                            event.preventDefault();
                                            sendEmail(values);
                                        }}
                                    >
                                        <GoogleReCaptchaProvider reCaptchaKey="6LdqwQgkAAAAAOhlmQB31Hqo1iCWFHjaWJlSSKMf">
                                            <div className="p-3">
                                                <div>
                                                    <h className="mb-5 text-secondaryBackground">Name :</h>
                                                    <input className="p-2 w-full bg-grey border-none" placeholder="John Doe" type="text" id="Name" name="Name" onChange={handleInputChange} />
                                                </div>

                                                <div className="pt-5">
                                                    <h className="mb-5 text-secondaryBackground">Address :</h>
                                                    <input className="p-2 w-full bg-grey border-none h-20" placeholder="48764 Howard Forge Apt. London" type="text" id="Address" name="Address" onChange={handleInputChange} />
                                                </div>

                                                <div className="pt-5">
                                                    <h className="mb-5 text-secondaryBackground">Country :</h>
                                                    <select className="p-2 w-full bg-grey" id="Country" name="Country" onChange={handleInputChange}>
                                                        <option value="" selected>
                                                            Select
                                                        </option>
                                                        {countries.map((index) => (
                                                            <option value={index.name}>{index.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="pt-5">
                                                    <div className="grid gap-2 grid-cols-3">
                                                        <div className="col-span-1">
                                                            <h className="mb-5 text-secondaryBackground">Code :</h>
                                                            <input className="p-2 w-full bg-grey border-none" placeholder="+91" type="number" id="Code" name="Code" onChange={handleInputChange} />
                                                        </div>
                                                        <div className="col-span-2">
                                                            <h className="mb-5 text-secondaryBackground">Phone Number :</h>
                                                            <input className="p-2 w-full bg-grey border-none" placeholder="9999999999" type="text" id="Phone" name="Phone" onChange={handleInputChange} />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="pt-5">
                                                    <h className="mb-5 text-secondaryBackground">Email :</h>
                                                    <input className="p-2 w-full bg-grey border-none" placeholder="johndoe@gmail.com" type="text" id="Email" name="Email" onChange={handleInputChange} />
                                                </div>

                                                <div className="pt-5" style={{ fontSize: '22px' }}>
                                                    <h className="mb-5 text-secondaryBackground">Duration of Travel :</h>
                                                </div>

                                                <div className="pt-5">
                                                    <h className="mb-5 text-secondaryBackground">Duration of Travel :</h>
                                                    <input className="p-2 w-full bg-grey border-none" placeholder="2 Weeks" type="text" id="Duration" name="Duration" onChange={handleInputChange} />
                                                </div>

                                                <div className="pt-5">
                                                    <h className="mb-5 text-secondaryBackground">Arrival Date :</h>
                                                    <input className="p-2 w-full bg-grey border-none" placeholder="20/12/2022" type="date" id="Arrival" name="Arrival" onChange={handleInputChange} />
                                                </div>

                                                <div className="pt-5">
                                                    <h className="mb-5 text-secondaryBackground">Number of Adults :</h>
                                                    <select className="p-2 w-full bg-grey" name="Adults" id="Adults" onChange={handleInputChange}>
                                                        <option value="" selected>
                                                            Select
                                                        </option>
                                                        {adults.map((index) => (
                                                            <option value={index}>{index}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="pt-5">
                                                    <h className="mb-5 text-secondaryBackground">Number of Children :</h>
                                                    <select className="p-2 w-full bg-grey" name="Children" id="Children" onChange={handleInputChange}>
                                                        <option value="" selected>
                                                            Select
                                                        </option>
                                                        {children.map((index) => (
                                                            <option value={index}>{index}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="pt-5">
                                                    <h className="mb-5 text-secondaryBackground">Age of Children :</h>
                                                    <input className="p-2 w-full bg-grey border-none" placeholder="3, 10, 16" type="text" id="Age" name="Age" onChange={handleInputChange} />
                                                </div>
                                                {/* <div className="pt-5">
                                                    <h className="mb-5 text-secondaryBackground">Tour Package :</h>
                                                    <select className="p-2 w-full bg-grey" name="cars" id="cars">
                                                        {privateDayTour.map((item) =>(
                                                    <option value={item.titleName} selected={item.titleName}>{item.titleName}</option>
                                                ))}
                                                    </select>
                                                </div> */}
                                                <div className="pt-5">
                                                    <h className="mb-5 text-secondaryBackground">Travel Mode :</h>
                                                    <select className="p-2 w-full bg-grey" name="Mode" id="Mode" onChange={handleInputChange}>
                                                        <option value="" selected>
                                                            Select
                                                        </option>
                                                        {travelMode.map((index) => (
                                                            <option value={index}>{index}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="pt-5">
                                                    <h className="mb-5 text-secondaryBackground">Hotel Category :</h>
                                                    <select className="p-2 w-full bg-grey text-black border-none" name="Hotel" id="Hotel" onChange={handleInputChange}>
                                                        <option value="" selected>
                                                            Select
                                                        </option>
                                                        {hotelCategory.map((index) => (
                                                            <option value={index}>{index}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="pt-5">
                                                    <h className="mb-5 text-secondaryBackground">Preferences :</h>
                                                    <input className="p-2 w-full bg-grey border-none h-28" placeholder="5 Star Hotel" type="text" id="Preferences" name="Preferences" onChange={handleInputChange} />
                                                </div>
                                                <div className="pt-5">
                                                    <div className="font-normal bg-blue p-5 text-center rounded text-white">
                                                        <Button
                                                            type="submit"
                                                            title="Submit your enquiry" />
                                                    </div>
                                                </div>
                                            </div>
                                            <GoogleReCaptcha />
                                        </GoogleReCaptchaProvider>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}></Grid>
                </Grid>
            </div>
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default withRouter(PrivateDayTour);
