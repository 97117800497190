import React from "react";

function ParentContainer({ children }) {
    return (
        <div className="px-32 md:px-16 sm:px-8 2xs:px-4">
            <div className="mt-20 sm:mt-10 2xs:mt-10">{children}</div>
        </div>
    );
}

export default ParentContainer;
