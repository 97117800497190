export const basicView = [
    {
        title: "Brief View of India",
        content: [
            {
                label: "Country Full Name :",
                detail: "Republic of India",
            },
            {
                label: "Capital :",
                detail: "New Delhi",
            },
            {
                label: "Population :",
                detail: "1.06 Billion",
            },
            {
                label: "Area :",
                detail: "3,166,414 Sq Km",
            },
            {
                label: "Currency Name :",
                detail: "Indian Rupees",
            },
            {
                label: "Population Density :",
                detail: "310 persons per Sq km",
            },
            {
                label: "GNI (GNP) :",
                detail: "USD 470 per capita per year",
            },
            {
                label: "Languages :",
                detail: "22 Official + 1600 minor languages and dialects",
            },
            {
                label: "Religion :",
                detail: "80.5% Hindu, 13.4% Muslim, 2.3% Christian, 1.9% Sikh, 0.8%Buddhist, 0.4% Jain, 0.01%Zoroastrian, 1.3% others.",
            },
            {
                label: "Literacy Rate :",
                detail: "64.8%",
            },
            {
                label: "Gender Ratio :",
                detail: "933 Female to 1000 Male",
            },
        ],
    },
    {
        title: "Visa Information",
        content: [
            {
                label: "Passports :",
                detail: "Passport is travel evidence signed by the national government, which normally signifies the holder as a national of the issuing state and desires that the holder be allowed to enter and exit through other countries. Thus India government also made it mandatory; all the travelers to India barring the national of Nepal and Bhutan should present the valid passport for identification and authenticity.",
            },
            {
                label: "Visa :",
                detail: "Any foreigner who wants to enter India must have a valid visa affixed on his or her passport. This can be obtained from the Indian Consulate in your country. Foreign nationals of Indian origin, their spouses and children can obtain visas from the Consulate. Foreign nationals of Indian origin, their spouses and children can obtain Entry Visas valid up to 10 years. Tourist Visas can be obtained for six months, one year and 10 years. Visas of the appropriate type should be obtained by students, businessmen, journalists and others who want to visit India for professional purposes.",
            },
            {
                label: "Validity of visas :",
                detail: "All types of visas are valid for the indicated period from the date of issue (and not repeat not from the date of first entry into India). Post-dated visas are not issued.",
            },
            {
                label: "Application form and picture requirements :",
                detail: "Visas can also be issued to persons who normally do not live in the jurisdiction of this Consulate (this also includes people holding Tourist / Business visas) after obtaining clearance from the Indian Mission under whose jurisdiction the applicant normally resides. This however takes a few weeks. The applicant may, however, pay an additional charge for clearance by fax or telex.",
            },
            {
                label: "Visas to non-residents :",
                detail: "Visas can also be issued to persons who normally do not live in the jurisdiction of this Consulate (this also includes people holding Tourist / Business visas) after obtaining clearance from the Indian Mission under whose jurisdiction the applicant normally resides. This however takes a few weeks. The applicant may, however, pay an additional charge for clearance by fax or telex.",
            },
            {
                label: "Restricted area permits :",
                detail: "Persons desiring to go to a restricted area should fill in special forms and apply well in advance as clearances are required before a permit can be issued to travel to these places. It takes at least 6 to 8 weeks to receive the Government of India's clearances in such cases.",
            },
        ],
    },
    {
        title: "Foreign Currency and Exchange",
        content: [
            {
                label: "",
                detail: "Foreign exchange/currency brought into India must be declared at Customs should the value of foreign currency exceed US$ 2,500 or the aggregate value of the foreign exchange including currency notes exceeds US$ 10,000 or equivalent.",
            },
            {
                label: "",
                detail: "You can exchange money at international airports where 24-hour exchange facilities are available through banks and approved money changers. You can also change money at nationalized banks and other banks in the country.",
            },
            {
                label: "Indian Money :",
                detail: "The Indian currency is the Rupee and it is a fully convertible currency. The Rupee notes are available in denominations of 1000, 500, 100, 50, 20, 10, 5, 2 and 1. Coins are available in the denominations of 5, 2, 1 (rupee) and 50 paisa. When paying by an international credit card save your invoices. I prefer to write Indian Rupees also where the amount is filled on the invoice before signing.",
            },
            {
                label: "Tipping :",
                detail: "Baksheesh, a term which encompasses tipping and a lot more besides, is widespread in India. More often than not, you 'tip' in India not so much for good service but in order to get things done. Judicious baksheesh will often open closed doors, find missing items and perform other small miracles. In mid-range and upper-end restaurants or hotels a service charge (usually 10%) is often added to bills, so tipping is very optional. In smaller places, where a service charge has not been added to the bill, a tip is warmly appreciated - the amount you give depends on how happy you are with the service. Anything upwards of 20.00 is the norm, but exceptional service should be rewarded with at least 50.00.",
            },
        ],
    },
    {
        title: "Some Useful Tips while Travel to India:-",
        content: [
            {
                label: "",
                detail: "Drink only bottled water. Buy it only from respectable or known outlets. In restaurants insist that they bring a sealed bottle to your table.",
            },
            {
                label: "",
                detail: "Beef is not served in India. Pork is also not easily available.",
            },
            {
                label: "",
                detail: "Eat non-vegetarian food only in good restaurants. The meat in cheaper and smaller places is generally of dubious quality.",
            },
            {
                label: "",
                detail: "Vegetarian food is easily available, cheap, and of excellent quality.",
            },
            {
                label: "",
                detail: "Curd or yoghurt is served with most meals. It is a natural aid to digestion and helps temper the spicy food.",
            },
            {
                label: "",
                detail: "Avoid keeping valuables in a purse, which can be easily snatched off your shoulder.",
            },
            {
                label: "",
                detail: "For the first few days it might be advisable to clean your teeth in bottled water.",
            },
            {
                label: "",
                detail: "Always keep a tube of mosquito repellent with you.",
            },
            {
                label: "",
                detail: "Always carry a kit of the basic emergency medicines you might need for diarrhoea, fever, etc. Also, band aids and an antiseptic ointment.",
            },
            {
                label: "",
                detail: "Drink lassi - a yoghurt drink. It will help tone down the bacteria.",
            },
            {
                label: "",
                detail: "Everything in India takes time - longer than in most places. So always give yourself extra time for whatever you may have to do - even it is just a visit to the Post Office or changing money.",
            },
            {
                label: "",
                detail: "If you are fair-complexioned, blonde or red-haired - and especially if you are female - chances are that you will be stared at continuously, specially in the smaller towns. Don't be offended - they mean no harm, it is just curiosity.",
            },
            {
                label: "",
                detail: "In India, public toilet facilities are few and far between, and those that are there should not be ventured into. Take every opportunity you can to use a clean toilet in places such as hotels and restaurants. Make this a habit wherever you go.",
            },
            {
                label: "",
                detail: "Keep extra photocopies of the relevant pages of your passport. This will be required for Indian permits.",
            },
            {
                label: "",
                detail: "Taxi and auto-rickshaw fares keep changing, and therefore do not always confirm to readings on meters. Insist on seeing the latest rate card (available with the driver) and pay accordingly.",
            },
            {
                label: "",
                detail: "Dress codes for religious places can include covering your head, being barefoot etc. Ask, so that you don't unwillingly give offence.",
            },
            {
                label: "",
                detail: "Some temples do not permit any leather articles at all on their premises.",
            },
            {
                label: "",
                detail: "Certain areas of temples are not open to Non-Hindus.",
            },
            {
                label: "",
                detail: "Most museums in India are closed on Mondays and Site Museums, those near archaeological monuments, on Fridays.",
            },
            {
                label: "",
                detail: "The dry summer heat can drain you completely. Drink lots of water and fluids.",
            },
            {
                label: "",
                detail: "The sun is strong. Remember to use sunscreen on exposed parts of the body. Wear sunglasses to screen out harmful rays.",
            },
            {
                label: "",
                detail: "Photography is not always permissible, and at many places it is permitted only at a fee. There is usually a higher fee for using a video camera.",
            },
            {
                label: "",
                detail: "English is spoken at almost all tourist centers, but you can also request Government-trained and approved guides who also speak German, French, Spanish, Japanese, Italian or Russian.",
            },
            {
                label: "",
                detail: "Women should dress conservatively. Avoid tank tops or short skirts / shorts. The best outfit, especially during the hot summers, is a T-shirt worn with loose cotton trousers. These are comfortable, cool and easily washable. You can purchase them anywhere in India, at very reasonable rates, at any of the street shops. If you are adventurous, wear the Indian 'salwar-kameez'. It is comfortable and free sized, and you will be amazed at the change of attitude among the shopkeepers, pedestrians and taxicab drivers!",
            },
            {
                label: "",
                detail: "Tourists traveling to India with a visa permit for 180 days need to obtain a Registration certificate and residential permit and submit four passport size photographs for the same. The registration certificate has to be returned to the issuing authority while leaving the country.",
            },
            {
                label: "",
                detail: "Foreign tourists can make payments through traveler’s cheques, credit cards or foreign currency. Indian rupees can be used in case of proof of legal currency exchange.",
            },
            {
                label: "",
                detail: "Air / rail or travel tickets should only be bought from an authorized travel agent only",
            },
        ],
    },
];
