import React from "react";
import NavBar from "../../Components/Navbar/NavBar";
import ParentContainer from "../../Components/ParentContainer/ParentContainer";
import TopBanner from "../../Components/TopBanner/TopBanner";
import logo2 from "../../Assets/Image/logo2.png";
import FilledBackground from "../../Components/FilledBackground/FilledBackground";
import Heading2 from "../../Components/Heading2/Heading2";
import Subheader from "../../Components/SubHeader/Subheader";
import { basicView } from "../../JsonData/TipsAndTravelling";
import Footer from "../../Components/Footer/Footer";
import MobileFooter from "../../Components/Footer/MobileFooter";
import FloatingButton from "../../Components/FloatingButton/FloatingButton";

function TipsAndTravelling() {
    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton/>
            <ParentContainer>
                <TopBanner
                    showBanner={false}
                    label={"Tips and Travelling In India"}
                />
            </ParentContainer>
            {basicView.map((item, index) => (
                <FilledBackground key={index}>
                    <Heading2
                        isVerticalLine={true}
                        heading={item.title}
                        blueText={true}
                    />
                    <div className="ml-5">
                        <Subheader>
                            {item.content.map((item2, index2) =>
                                item2.label === "" &&
                                item === "Foreign Currency and Exchange" ? (
                                    <p className="ml-5 mt-5" key={index2}>
                                        <span className="font-semibold">
                                            {item2.label + " "}
                                        </span>
                                        {item2.detail}
                                    </p>
                                ) : (
                                    <li className="ml-5 mt-5" key={index2}>
                                        <span className="font-semibold">
                                            {item2.label + " "}
                                        </span>
                                        {item2.detail}
                                    </li>
                                )
                            )}
                        </Subheader>
                    </div>
                </FilledBackground>
            ))}

            <div className="mt-10"></div>
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default TipsAndTravelling;
