import goaBanner from "../Assets/Image/goa.png";
import goaChurch from "../Assets/Image/goaChurch.jpg";
import goaBeach from "../Assets/Image/goaBeach.jpg";
import vasco from "../Assets/Image/vasco.jpg";
import panji from "../Assets/Image/panji.jpg";
import margao from "../Assets/Image/margao.jpg";
import mapusa from "../Assets/Image/mapusa.jpg";
import goaCulture from "../Assets/Image/goaCulture.jpg";
import kerelaCulture from "../Assets/Image/kerelaCulture.jpg";
import kerelaDetails from "../Assets/Image/kerelaDetails.jpg";
import kerelaGeo from "../Assets/Image/kerelaGeo.jpg";
import kerelaHistory from "../Assets/Image/kerelaHistory.jpg";
import kerelaBeach from "../Assets/Image/kerelaBeach.jpg";
import varanasiDetails from "../Assets/Image/varanasiDetails.jpg";
import varanasiHistory from "../Assets/Image/varansiHistory.jpg";
import varanasiCulture from "../Assets/Image/varanasiCulture.jpg";
import assiGhat from "../Assets/Image/AssiGhatVaranasiINside.jpg";
import tulsiGhat from "../Assets/Image/TulsiGhatVaranasiInside.jpg";
import bhu from "../Assets/Image/BHUVaranasiInside.jpg";
import manikarnika from "../Assets/Image/Manikarnika_Ghat,_VaranasiInside.jpg";
import dasaswamedhGhat from "../Assets/Image/dasaswamedhGhat.jpg";
import harishchandraGhat from "../Assets/Image/harishchandraGhat.jpg";
import jaipurDetails from "../Assets/Image/AmberFortJaipurInside.jpg";
import jaipurHistory from "../Assets/Image/jaipurHistory.jpg";
import jaipurCulture from "../Assets/Image/jaipurCulture.jpg";
import jaipurGeo from "../Assets/Image/jaipurGeo.jpg";
import hawaMahal from "../Assets/Image/hawaMahal.jpg";
import jaigarh from "../Assets/Image/jaigarh.JPG";
import jalmahal from "../Assets/Image/jalmahal.jpg";
import cityPalace from "../Assets/Image/cityPalace.jpg";
import jantarMantar from "../Assets/Image/jantarMantar.jpg";
import nahargarh from "../Assets/Image/Nahargarh_fort_jaipur.jpeg";
import agraDetails from "../Assets/Image/agraDetails.jpg";
import lalKila from "../Assets/Image/lalKila.jpg";
import indiaGate from "../Assets/Image/indiaGate.jpg";
import delhiCulture from "../Assets/Image/delhiCulture.jpg";
import Rashtrapati from "../Assets/Image/Rashtrapati.jpg";
import Laxminarayan from "../Assets/Image/Laxminarayan.jpg";
import sahibGurudwara from "../Assets/Image/sahibGurudwara.jpg";
import Humayun from "../Assets/Image/Humayun.jpg";
import Qutub from "../Assets/Image/Qutub.jpeg";
import Chandni from "../Assets/Image/Chandni.jpg";
import jama from "../Assets/Image/jama.jpg";
import purana from "../Assets/Image/purana.jpg";
import shanti from "../Assets/Image/shanti.jpg";
import lotusTemple from "../Assets/Image/lotusTemple.jpg";
import rajGhat from "../Assets/Image/rajGhat.jpg";
import tajmahal from "../Assets/Image/tajmahal.png";
import agraHistory from "../Assets/Image/agraHistory.jpg";
import yamuna from "../Assets/Image/yamuna.jpg";
import agraFort from "../Assets/Image/agraFort.jpg";
import FathepurSikri from "../Assets/Image/FathepurSikri.jpg";
import itmad from "../Assets/Image/itmad.jpg";
import akbar from "../Assets/Image/akbar.jpg";
import buland from "../Assets/Image/buland.jpg";
import delhiDetails from "../Assets/Image/delhi.png";

export const topDestinations = [
    {
        id: 1,

        banner: agraDetails,

        title: "Agra",

        description: [
            "Agra is a city on the banks of the Yamuna River in the northern state of Uttar Pradesh, India. It finds mention in the epic Mahabharata when it was called Agrabana, or Paradise. Ptolemy, the famous 2nd century geographer, marked it on his map of the world as Agra. Tradition and legend ascribe the present city of Raja Badal Singh (around 1475) whose fort, Badalgarh, stood on or near the site of the present Fort. ",
            "However, the 12th century Persian poet Salman writes of a desperate assault on the fortress of Agra, then held by one King Jaipal, by Sultan Mahmud of Ghazni. It was ruled by Sultan Sikandar Lodi in the year 1506. It achieved fame as the capital of the Mughal emperors from 1526 to 1658 and remains a major tourist destination because of its many splendid Mughal-era buildings, most notably the Taj Mahal, Agra Fort and Fatehpur Sikri, all three of which are UNESCO World Heritage Sites.",
        ],

        additionalInfo: [
            {
                title: "History",
                img: agraHistory,
                mainContent:
                    "Agra is a medieval city situated on the banks of the river Yamuna. Sultan Sikandar Lodi of Lodi Dynesty the Ruler of Delhi Sultanate founded Agra in the year 1504. In the year 1556, the great Hindu warrior, Hem Chander Vikramaditya won Agra, the commander of Humayun / Akbar's forces in Agra was so scared of Hemu that he ran away from Agra without the fight. This was Hemu's 21st continuous win, who later on won Delhi and had his coronation at Purana Qila in Delhi and re-established Hindu Kingdom and the Vikramaditya Dynasty in North India. The golden age of the city began with the Mughals. It was known then as Akbarabad and remained the capital of the Mughal Empire Since Akbarabad was one of the most important cities in India under the Mughals, it witnessed a lot of building activity. Babar, the founder of the Mughal dynasty laid out the first formal Persian garden on the banks of river Yamuna. The garden is called the Aram Bagh or the Garden of Relaxation. His grandson Akbar raised the towering ramparts of the Great Red Fort besides making Agra, a center for learning arts, commerce and religion. Akbar also built a new city on the outskirts of Akbarabad called Fatehpur Sikri. This city was built in the form of a Mughal military camp in stone.",
                subContent: [
                    "His son Jahangir had a love of gardens, flora and fauna. He laid many gardens inside the Red Fort or Laal Kila. Shah Jahan known for his keen interest in architecture gave Akbarabad its most prized monument -- The Taj Mahal. Built in loving memory of his wife Mumtaz Mahal, the mausoleum was completed in 1653. Shah Jahan’s son Aurangzeb imprisoned his father in the Fort there. Akbarabad remained capital of India during the rule of Aurangzeb until he shifted it to Aurangabad in the Deccan in 1653. After the decline of the Mughal Empire, the city came under the influence of Marathas and Jats and was called Agra, before falling into the hands of the British Raj in 1803. In 1835 when the Presidency of Agra was established by the British, the city became the seat of government, and just two year later it was the witness to the Agra famine of 1837–38. Agra is the birth place of religion Din-i-Ilahi, which flourished during the reign of Akbar and also of the Radhaswami Faith, which has around two million followers worldwide",
                ],
            },
            {
                title: "Geography",
                img: yamuna,
                mainContent:
                    "Agra is situated on the banks of Yamuna river. It has an average elevation of 171 metres (561 ft). On the north it is bound by Mathura, on the south by Dhaulpur, on the east by Firozabad, on the south-east by Fatehabad and on the west by Bharatpur. Agra is the third biggest city in Uttar Pradesh. Total number of Broad Gauge lines is 196 K.M. and Meter Gauge is 35 K.M",
                subContent: [],
            },
        ],

        places: [
            {
                id: 1,
                title: "Agra Fort",
                image: agraFort,
                description: [
                    "Another world heritage site in Agra. Agra's dominant structure, the Agra Fort (sometimes called the Red Fort), was built by Akbar in 1565. Be Noted that a Stone Plate located at the Gate of Fort describes it to be built before 1000 and later renovated by Akbar. The red sandstone fort was renovated and converted into a palace during Shah Jahan's time, and reworked extensively with marble and pietra dura inlay. Notable buildings in the fort include the Pearl Mosque, the Diwan-e-Am and Diwan-e-Khas (halls of public and private audience), Jehangir's Palace, Khaas Mahal, Sheesh Mahal (mirrored palace), and Musamman Burj. The great Mughal Emperor Akbar commissioned the construction of the Agra Fort in 1565 CE., although additions were made till the time of his grandson Shah Jahan. The forbidding exteriors of this fort hide an inner paradise. The fort is crescent shaped, flattened on the east with a long, nearly straight wall facing the river. It has a total perimeter of 2.4 k.m., and is ringed by double castellated ramparts of red sandstone punctuated at regular intervals by bastions. A 9 mt. wide and 10 mt. deep moat surround the outer wall.Shivaji visited Agra fort as per the Purandar Treaty entered with Mirza Raja Jaisingh to met Aurangzeb in the Diwan-i-khas. In the audience he was deliberately placed behind men of lower rank. Insulted Shivaji stormed out of the imperial audience and was confined to Jai Sing's quarters on 12th May 1666. Fearing the dungeons and execution, in a famously sweet legend, he escaped on the 17th of August 1666. A heroic equestrian statue of Shivaji has been erected outside the fort. The fort standing as a typical example of the Mughal architecture.It shows how the North Indian style of fort construction differentiated from that of the South.In South majority of the beautiful forts were built on the sea beds like the one at Bekal in Kerala ",
                ],
            },
            {
                id: 2,
                title: "Taj Mahal",
                image: tajmahal,
                description: [
                    "Agra's Taj Mahal is one of the most famous buildings in the world, the mausoleum of Shah Jahan's favorite wife, Mumtaz Mahal. Completed in 1653 CE. It is one of the New 7 Wonders of the world, and one of three World Heritage Sites in Agra, the others being Agra Fort and Fatehpur Sikri. Taj Mahal, perfectly symmetrical monument took 22 years (1630-1652) of hard labour and 20,000 workers, masons and jewellers to build and is set amidst landscaped gardens. Built by the Persian architect, Ustad Isa, the Taj Mahal is on the bank of the Yamuna River. It can be observed like a mirage from the Agra Fort from where Emperor Shah Jahan stared at it, for the last eight years of his life as a prisoner of his son Aurangzeb. It is a masterpiece seeming to be floating in the air from a distance, and each revealed as an illusion experienced as one enters through the main gate. Verses of the Holy Koran are inscribed on it and at the top of gate 22 small domes, signifying the number of years the monument took to build. The Taj Mahal was built on a marble platform that stands above a sandstone one. The most elegant dome of the Taj, with a diameter of 60 feet (18 m), rises 80 feet (24 m) over the building and directly under the dome is the tomb of Mumtaz Mahal. Shah Jahan's tomb was erected next to hers by his son Aurangzeb. Fantastic inlay works using semi-precious stones decorate the interiors. Opening Times: 6 A.M. to 7.30 P.M. (closed Fridays)",
                ],
            },
            {
                id: 3,
                image: FathepurSikri,
                title: "Fatehpur Sikri",
                description: [
                    "The Mughal Emperor Akbar built Fatehpur Sikri about 35 km from Agra, and moved his capital there. Later abandoned, the site displays a number of buildings of significant historical importance. A World Heritage Site, it is often visited by tourists to Agra. The name of the place came after Mughal Emperor Babur defeated Rana Sanga in a battle at a place called Sikri (about 40 km from Agra). Then Mughal Emperor Akbar wanted to make Fatehpur Sikri his head quarters. So he built this majestic fort. But due to shortage of water he had to ultimately move his headquarters to Agra Fort.",
                ],
            },
            {
                id: 4,
                image: buland,
                title: "Buland Darwaja",
                description: [
                    "Buland Darwaza or the loft gateway was built by the great Mughal emperor, Akbar in 1601 CE. at Fatehpur Sikri. Akbar built the Buland Darwaza to commemorate his victory over Gujarat. The Buland Darwaza is approached by 42 steps. The Buland Darwaza is 53.63 m high and 35 meters wide. Buland Darwaza is the highest gateway in the world and an astounding example of the Mughal architecture. The Buland Darwaza or the magnificence gateway is made of red and buff sandstone, decorated by carving and inlaying of white and black marble. An inscription on the central face of the Buland Darwaza throws light on Akbar's religious broad mindedness, here is an inscription one on the monument which is a message from Jesus advising his followers not to consider this world as their permanent home.",
                ],
            },
            {
                id: 5,
                title: "Itmad-Ud-Daulah",
                image: itmad,
                description: [
                    "Itmad-Ud-Daulah Empress Nur Jehan built Itmad-Ud-Daulah's Tomb, sometimes called the Baby Taj, for her father, Ghias-ud-Din Beg, the Chief Minister of Emperor Jahangir. Located on the left bank of the Yamuna river, the mausoleum is set in a large cruciform garden criss-crossed by water courses and walkways. The mausoleum itself is set on a base about 50 meters square and about 1 meter high. The mausoleum is about 23 meters square. On each corner are hexagonal towers, about 13 meters tall. Small in comparison to many other Mughal-era tombs, it is sometimes described as a jewel box. Its garden layout and use of white marble, pietra dura, inlay designs and latticework presage many elements of the Taj Mahal. The walls of white marble from Rajasthan encrusted with semi-precious stone decorations - cornelian, jasper, lapis lazuli, onyx, and topaz in images of cypress trees and wine bottles, or more elaborate decorations like cut fruit or vases containing bouquets. Light to the interior passes through delicate jali screens of intricately carved white marble.Many of Nur Jahan's relatives are interred in the mausoleum. The only asymmetrical element of the entire complex is that the cenotaphs of her father and mother have been set side-by-side, a formation replicated in the Taj Mahal",
                ],
            },
            {
                id: 6,
                title: "Akbar Tomb, Sikandra",
                image: akbar,
                description: [
                    "Akbar's Tomb, Sikandra Sikandra, the last resting place of the Mughal Emperor Akbar the Great, is on Delhi-Agra Highway, is only 13 kilometres from the Agra Fort. Akbar's tomb reflects the completeness of his personality. The vast, beautifully carved, red-ochre sandstone tomb with deers, rabbits and Langoor is set amidst a lush garden. Akbar himself planned his own tomb and selected a suitable site for it. To construct a tomb in one's lifetime was a Tartary custom which the Mughals followed religiously. Akbar's son Jahangir completed the construction of this pyramidal tomb in 1613.",
                ],
            },
        ],
    },
    {
        id: 2,
        banner: delhiDetails,
        title: "Delhi",
        description: [
            "Delhi sometimes referred to as Dilli is the second-largest city of India and the eighth largest metropolis in the world by population. It is a federally administered union territory officially known as the National Capital Region (NCR). ",
            "Located on the banks of river Yamuna in northern India, Delhi has been continuously inhabited since at least the 6th century BC, after the rise of the Delhi Sultanate, Delhi emerged as a major political, cultural and commercial city along the trade routes between northwest India and the Indo-Gangetic plains. It is the site of many ancient and medieval monuments, archaeological sites and remains.",
            "After the British East India Company gained control of much of India during the 18th and 19th centuries. In 1911 a new capital city, New Delhi, was built to the south of the old city during the 1920s. When India gained independence from British rule in 1947, New Delhi was declared its capital and seat of government. As such, New Delhi houses important offices of the federal government, including the Parliament of India.",
            "Owing to the migration of people from across the country, Delhi has grown to be a cosmopolitan city. Today Delhi is a major cultural, political, and commercial center of India",
        ],

        additionalInfo: [
            {
                img: indiaGate,
                title: "History",
                mainContent:
                    "Human habitation was probably present in and around Delhi during the second millennium BC and before, as evidenced by archeological relics. The city is believed to be the site of Indraprastha, legendary capital of the Pandavas in the Indian epic Mahabharata. Settlements grew from the time of the Mauryan Empire (300 BC). Remains of seven major cities have been discovered in Delhi. The Tomara dynasty founded the city of Lal Kot in 736 AD. The Chauhan Rajputs of Ajmer conquered Lal Kot in 1180 AD and renamed it Qila Rai Pithora. The Chauhan king Prithviraj III was defeated in 1192 by the Afghan Muhammad Ghori. In 1206, Qutb-ud-din Aybak, the first ruler of the Slave Dynasty established the Delhi Sultanate. Qutb-ud-din started the construction the Qutub Minar and Quwwat-al-Islam (might of Islam), the earliest extant mosque in India. ",
                subContent: [
                    "After the fall of the Slave dynasty, a succession of Turkic and Central Asian dynasties, the Khilji dynasty, the Tughluq dynasty, the Sayyid dynasty and the Lodhi dynasty held power in the late medieval period, and built a sequence of forts and townships that are part of the seven cities of Delhi. In 1398, Timur Lenk invaded India on the pretext that the Muslim sultans of Delhi were too much tolerant to their Hindu subjects. Timur entered Delhi and the city was sacked, destroyed, and left in ruins. Delhi was a major center of Sufism during the Sultanate period.",
                    "The Mughal Empire ruled northern India for more than three centuries, with a five-year hiatus during Sher Shah Suri's reign in the mid-16th century. Mughal emperor Akbar shifted the capital from Agra to Delhi. Shah Jahan built the seventh city of Delhi that bears his name (Shahjahanabad), and is more commonly known as the Old City or Old Delhi. The old city served as the capital of the Mughal Empire from 1638. Nader Shah defeated the Mughal army at the huge Battle of Karnal in February, 1739. After this victory, Nader captured and sacked Delhi, carrying away many treasures, including the Peacock Throne. In 1761, Delhi was raided by Ahmed Shah Abdali after the Third battle of Panipat. At the Battle of Delhi on 11 September 1803, General Lake's British forces defeated the Marathas.",
                    "Delhi came under direct British control after the Indian Rebellion of 1857 and Delhi was made a district province of the Punjab. In 1911, Delhi was declared the capital of British India and was designed by a team of British architects led by Edwin Lutyens to house the government buildings. New Delhi, also known as Lutyens' Delhi, was officially declared as the seat of the Government of India and the capital of the republic after independence on 15 August 1947. During the partition of India thousands of Hindu and Sikh refugees from West Punjab and Sindh fled to Delhi while many Muslim residents of the city migrated to Pakistan.",
                    "Built in 1639 by Shah Jahan, the Red Fort is the site from which the Prime Minister of India addresses the nation on Independence Day.",
                ],
            },
            {
                img: lotusTemple,
                title: "Geography",
                mainContent:
                    "The National Capital Territory of Delhi is spread over an area of 1,484 km2 (573 sq mi). There are three local bodies (statutory towns) namely, Municipal Corporation of Delhi (area is 1,397.3 km2 or 540 sq mi), New Delhi Municipal Committee (42.7 km2 or 16 sq mi) and Delhi Cantonment Board (43 km2 or 17 sq mi). Delhi is located at 28°37N 77°14E/28.61N, 77.23E, and lies in northern India. It borders the Indian states of Uttar Pradesh on East and Haryana on West, North and South. Delhi lies almost entirely in the Gangetic plains. Two prominent features of the geography of Delhi are the Yamuna flood plain and the Delhi ridge. The low-lying Yamuna flood plains provide fertile alluvial soil suitable for agriculture. However, these plains are prone to recurrent floods.The ridge forms the most dominating feature in this region, originates from the Aravalli Range in the south and encircles the west, northeast and northwest parts of the city. Yamuna, a sacred river in Hinduism, is the only major river flowing through Delhi.",
                subContent: [
                    " Delhi is often regarded as the Book Capital of India because of high readership. Punjabi and Mughlai delicacies like kababs and biryanis are popular in several parts of Delhi. Due to Delhi's large cosmopolitan population, cuisines from every part of India, including Rajasthani, Maharashtrian, Bengali, Hyderabadi cuisines, and South Indian food items. There are several food outlets in Delhi serving international cuisine including Italian and Chinese. Historically, old city's tangle of snaking lanes and teeming bazaars. The dingy markets of the Old City has an eclectic product range from oil-swamped mango, lime and eggplant pickles, candy-colored herbal potions to silver jewelry, bridal attire, uncut material and linen, spices, sweets. Some of old regal havelis (palatial residences) are still there in the Old City.",
                ],
            },
            {
                img: delhiCulture,
                title: "Culture",
                mainContent:
                    "Delhi's culture has been influenced by its lengthy history and historic association as the capital of India. This is exemplified by the many monuments of significance found in the city; the Archaeological Survey of India recognises 175 monuments in Delhi as national heritage sites. Religious festivals include Diwali (the festival of lights), Mahavir Jayanti, Guru Nanak's Birthday, Durga Puja, Holi, Lohri, Maha Shivaratri, Eid and Buddha Jayanti. The Qutub Festival is a cultural event during which performances of musicians and dancers from all over India are showcased at night, with the Qutub Minar as the chosen backdrop of the event. Other events such as Kite Flying Festival, International Mango Festival and Vasant Panchami (the Spring Festival) are held every year in Delhi. The Auto Expo, Asia's largest auto show, is held in Delhi biennially. The World Book Fair, held annually at the Pragati Maidan, is the second largest exhibition of books in the world with as many as 23 nations participating in the event. ",
                subContent: [],
            },
        ],

        places: [
            {
                id: 1,
                title: "Rashtrapati Bhawan",
                image: Rashtrapati,
                description: [
                    "Built with a mix of Western and Indian styles, Rashtrapati Bhavan was originally built for the Governor General of India. Inaugurated in 1931 as the Viceregal Lodge, the name was changed in 1950 after India became a republic.",
                ],
            },
            {
                id: 2,
                title: "India Gate",
                image: indiaGate,
                description: [
                    "Built in the memory of more than 90,000 Indian soldiers who lost their lives during the Afghan Wars and World War I, the India Gate is one of the most famous monuments in Delhi. Situated along the ceremonial Rajpath avenue (meaning King's Way) in New Delhi, India Gate is a memorial raised in honour of the Indian soldiers who died during the Afghan wars and World War I. The names of the soldiers who died in these wars are inscribed on the walls. The cenotaph (or shrine) in the middle is constructed with black marble and depicts a rifle placed on its barrel, crested by a soldier's helmet. Each face of the cenotaph has inscribed in gold the words Amar Jawan (in Hindi, meaning Immortal Warrior). The green lawns at India Gate are a popular evening and holiday rendezvous for young and old alike.",
                ],
            },
            {
                id: 3,
                title: "Laxminarayan Temple",
                image: Laxminarayan,
                description: [
                    "Also called the Birla Mandir, the Laxminarayan Temple was built by the Birla family in 1938. Along with the temple itself, the name 'Laxminarayan Temple' encompasses a large garden with fountains behind it. The temple attracts thousands of devotees on Janmashtami day, the birthday of Lord Krishna.",
                ],
            },
            {
                id: 4,
                title: "Akshardham Temple",
                image: delhiCulture,
                description: [
                    "Inaugurated in November 2005, Akshardham Temple has become one of the most visited tourist places of Delhi. In the sprawling 100-acre (0.40 km2) land rests an intricately carved monument, high tech exhibitions, IMAX theatre, musical fountain, food court and gardens.",
                ],
            },
            {
                id: 5,
                title: "Gurdwara Bangla Sahib",
                image: sahibGurudwara,
                description: [
                    "One of the many Gurdwaras in Delhi, Gurdwara Bangla Sahib is the most visited one in the Delhi area. Millions visit this Gurdwara from all over the world and of all religions to offer their prayers at this elegant yet historical Gurdwara in Delhi. This is not just a sacred Sikh shrine, but also very important to many Hindus",
                ],
            },
            {
                id: 6,
                title: "Humayun's Tomb",
                image: Humayun,
                description: [
                    "Humayun's Tomb is one of Delhi's most famous landmarks. The monument has an architectural design similar to the Taj Mahal. Humayun's Tomb was built by Humayun's widow, Hamida Banu Begum. Designed by a Persian architect named Mirak Mirza Ghiyas, the structure was begun in 1562 and completed in 1565.The tomb established a standard for all later Mughal monuments, which followed its design, most notably the Taj Mahal.",
                ],
            },
            {
                id: 7,
                title: "Qutub Minar",
                image: Qutub,
                description: [
                    "At 72.5 meters, the 13th century Qutub Minar is the world's tallest brick minaret.The Qutub Minar is located in a small village called Mehrauli in South Delhi. It was built by Qutub-ud-din Aybak of the Slave Dynasty, who took possession of Delhi in 1206. It is a fluted red sandstone tower, which tapers up to a height of 72.5 metres and is covered with intricate carvings and verses from the Qur'an. Qutub-ud-din Aybak began constructing this victory tower as a sign of Muslim domination of Delhi and as a minaret for the Muslim priest, the muezzin, to call the faithful to prayer. However, only the first story was completed by Qutub-ud-din. The other stories were built by his successor Iltutmish. The two circular stories in white marble were built by Ferozshah Tughlaq in 1368, replacing the original fourth story. The balconies in the tower are supported by exquisite stalactite designs. The tapering tower has pointed and circular flutings on the first story and star-shaped ones on the second and third stories. The Qutub Minar is also significant for what it represents in the history of Indian culture. In many ways, the Qutub Minar, the first monument built by a Muslim ruler in India, heralded the beginning of a new style of art and architecture that came to be known as the Indo-Islamic style.",
                ],
            },
            {
                id: 8,
                title: "Red Fort",
                image: lalKila,
                description: [
                    "The Lahore gate is the main enterance of the Red Fort. The decision for constructing the Red Fort was made in 1639, when Shah Jahan,decided to shift his capital from Agra to Delhi. Within eight years, Shahjahanabad (name given to delhi by Shah Jahan) was completed with the Red Fort-Qila-i-Mubarak (fortunate citadel) — Delhi's seventh fort — ready in all its magnificence to receive the Emperor. Though much has changed with the large-scale demolitions during the British occupation of the fort, its important structures have survived.",
                ],
            },
            {
                id: 9,
                title: "Chandni Chowk",
                image: Chandni,
                description: [
                    "Chandni Chowk, a main marketplace in Delhi, keeps alive the city's living legacy of Shahjahanabad. Created by Shah Jahan the builder of Taj Mahal, the old city, with the Red Fort as its focal point and Jama Masjid as the praying centre, has a fascinating market called Chandni Chowk. Legend has it that Shah Jahan planned Chandni Chowk so that his daughter could shop for all that she wanted. The market was divided by canals. The canals are now closed, but Chandni Chowk remains Asia's largest wholesale market. Crafts once patronized by the Mughals continue to flourish there.",
                ],
            },
            {
                id: 10,
                title: "Jama Masjid",
                image: jama,
                description: [
                    "The Jama Masjid is one of the largest and most elegant mosques in South Asia. The Masjid-i-Jahan Numa, commonly known as Jama Masjid, is the principal mosque of Old Delhi. Commissioned by the Mughal Emperor Shah Jahan and completed in the year 1656, it is one of the largest and best known mosques in India.",
                ],
            },
            {
                id: 11,
                title: "Purana Quila",
                image: purana,
                description: [
                    "The Purana Quila (Old Fort) is a good example of medieval military architecture. Built by Humayun, with later-day modifications by Sher Shah Suri, the Purana Quila is a monument of bold design, which is strong, straightforward, and every inch a fortress. It is different from the well-planned, carefully decorated, and palatial forts of the later Mughal rulers. Purana Quila is also different from the later forts of the Mughals. The main purpose of this now-dilapidated fort was its utility with less emphasis on decoration. The Qal'a-I-Kunha Masjid and the Sher Mandal are two important monuments inside the fort. It was made by Aqeel in 1853.",
                ],
            },
            {
                id: 12,
                title: "Raj Ghat",
                image: rajGhat,
                description: [
                    "On the bank Yamuna River, which flows past Delhi, there is Raj Ghat, the final resting place of Mahatma Gandhi, the father of the nation. It has become an essential point of call for all visiting dignitaries. Two museums dedicated to Gandhi are situated nearby.",
                ],
            },
            {
                id: 13,
                title: "Shanti Van",
                image: shanti,
                description: [
                    "Lying close to the Raj Ghat, the Shanti Vana (literally, the forest of peace) is the place where India's first Prime Minister Jawaharlal Nehru was cremated. The area is now a beautiful park adorned by trees planted by visiting dignitaries and heads of state.",
                ],
            },
            {
                id: 14,
                title: "Lotus Temple",
                image: lotusTemple,
                description: [
                    "The Lotus Temple is a Bahá'í House of Worship, situated in South Delhi and shaped like a lotus. It was built by the Bahá'í community in 1986 and has won numerous architectural awards. The Lotus Temple is open to all regardless of religion, or any other distinction.",
                ],
            },
        ],
    },
    {
        id: 3,
        banner: goaBanner,
        title: "Goa",
        description: [
            "Goa is India's smallest state in terms of area and the fourth smallest in terms of population. Located on the west coast of India in the region known as the Konkan, it is bounded by the state of Maharashtra to the north and by Karnataka to the east and south, while the Arabian Sea forms its western coast. Panaji (also referred to as Panjim) is the state's capital. Vasco da Gama (sometimes shortened to Vasco) is the largest city. The historic city of Margao still exhibits the influence of Portuguese culture. Portuguese first landed in Goa as merchants, in the early 16th century, and conquered it soon after. The Portuguese overseas territory existed for about 450 years, until it was annexed by India in 1961.",
            "Renowned for its beaches, places of worship and world heritage architecture, Goa is visited by hundreds of thousands of international and domestic tourists each year. It also has rich flora and fauna, owing to its location on the Western Ghats range, which is classified as a biodiversity hotspot.",
        ],

        additionalInfo: [
            {
                img: goaChurch,
                title: "History",
                mainContent:
                    "Goa's known history stretches back to the 3rd century BC, when it formed part of the Mauryan Empire. It was later ruled by the Satavahanas of Kolhapur, around 2000 years ago it was passed on to the Chalukya Dynasty, who controlled it between 580 to 750. Over the next few centuries Goa was successively ruled by the Silharas, the Kadambas and the Chalukyas of Kalyani, rulers of Deccan India. In 1312, Goa came under the governance of the Delhi Sultanate. However, the kingdom's grip on the region was weak, and by 1370 they were forced to surrender it to Harihara I of the Vijayanagara empire. In 1469 it was monarched by the Bahmani sultans of Gulbarga. After that dynasty crumbled, the area fell to the hands of the Adil Shahis of Bijapur who established as their auxiliary capital the city known under the Portuguese as Velha Goa.",
                subContent: [
                    "In 1510, the Portuguese defeated the ruling Bijapur kings with the help of a local ally, Timmoji or Timyya, leading to permanent settlement in Velha Goa (or Old Goa).The Portuguese encouraged the spread of Christianity, often with repressive measures. The repeated wars of the Portuguese with the Marathas and the Deccan sultanate, along with their repressive religious policies led to large migrations of Goans to neighbouring areas.",
                    "In 1843 the capital was moved to Panjim from Velha Goa. By mid-18th century the area under occupation had expanded to most of Goa's present day state limits. Simultaneously the Portuguese lost other possesions in India until their borders stabilised and formed the Estado da India Portuguesa, of which Goa was the largest territory.",
                    "After India gained independence from the British in 1947, Portugal refused to negotiate with India on the transfer of sovereignity of their Indian enclaves. On 12 December 1961, the Indian army commenced with Operation Vijay resulting in the annexation of Goa, Damman and Diu into the Indian union. On 30 May 1987, the Union Territory was split, and Goa was elevated as India's twenty-fifth state, with Daman and Diu remaining Union Territories.",
                ],
            },
            {
                img: goaBanner,
                title: "Geography",
                mainContent:
                    "Goa encompasses an area of 3,702 km² (1,430 sq mile). It lies between the latitudes 14°53'54 N and 15°40'00 N and longitudes 73°40'33 E and 74°20'13 E. Most of Goa is a part of the coastal country known as the Konkan, which is an escarpment rising up to the Western Ghats range of mountains, which separate it from the Deccan Plateau. The highest point is the Sonsogor, with an altitude of 1,167 meters (3,827 feet). Goa has a coastline of 101 km (63 miles). Goa's main rivers are the Mandovi, the Zuari, the Terekhol, Chapora River and Sal. The Mormugao harbor on the mouth of the river Zuari is one of the best natural harbors in South Asia. The Zuari and the Mandovi are the lifelines of Goa, with their tributaries draining 69% of its geographic area. Goa has more than forty estuarine, eight marine and about ninety riverine islands. The total navigable length of Goa's rivers is 253 km (157 miles). Goa has more than three hundred ancient tanks built during the rule of the Kadamba dynasty and over a hundred medicinal springs.",
                subContent: [
                    "Most of Goa's soil cover is made up of laterites which are rich in ferric aluminium oxides and reddish in color. Further inland and along the river banks, the soil is mostly alluvial and loamy. The soil is rich in minerals and humus, thus conducive to plantation. Some of the oldest rocks in the Indian subcontinent are found in Goa between Molem and Anmod on Goa's border with Karnataka. The rocks are classified as Trondjemeitic Gneiss estimated to be 3,600 million years old. Goa has a hot and humid climate for most of the year. The month of May is the hottest, seeing day temperatures of over 35 °C (95 °F) coupled with high humidity. The monsoon rains arrive by early June and provide a much needed respite from the heat. Most of Goa's annual rainfall is received through the monsoons which last till late September.Goa has short winter season between mid-December and February are marked 20 °C (68 °F) with moderate amounts of humidity.",
                ],
            },
            {
                title: "Culture",
                img: goaCulture,
                mainContent:
                    "The most popular celebrations in Goa are Ganesh Chaturthi (Chavoth-Konkani), Diwali, Christmas, Easter, Samsar Padvo, Shigmo and the Carnival. Goa is also known for its New Year's celebrations. The Goan Carnival is known to attract a large number of tourists. Goan Hindus are very fond of Natak, Bhajan and Kirtan. Many famous Indian Classical singers hail from Goa, such as , Kishori Amonkar, Kesarbai Kerkar, Jitendra Abhisheki, Prabhakar Karekar. Goan Hindus are very fond of Natak, Bhajan and Kirtan. Many famous Indian Classical singers hail from Goa, such as , Kishori Amonkar, Kesarbai Kerkar, Jitendra Abhisheki, Prabhakar Karekar. Some traditional Goan dance forms are dekhnni, fugdi, and corridinho. Rice with fish curry (Xit kodi in Konkani) is the staple diet in Goa. Goan cuisine is renowned for its rich variety of fish dishes cooked with elaborate recipes. Coconut and coconut oil is widely used in Goan cooking along with chili peppers, spices and vinegar giving the food a unique flavour. Pork dishes such as Vindaloo, Xacuti and Sorpotel are cooked for major occasions among the Catholics. An exotic Goan vegetable stew, known as Khatkhate, is a very popular dish during the celebrations of festivals, Hindu and Christian alike. ",
                subContent: [
                    "A rich egg-based multi-layered sweet dish known as bebinca is a favourite at Christmas. The most popular alcoholic beverage in Goa is feni; Cashew feni is made from the fermentation of the fruit of the cashew tree, while coconut feni is made from the sap of toddy palms. Goa has two World Heritage Sites: the Bom Jesus Basilica and a few designated convents. The Basilica holds the mortal remains of St. Francis Xavier, regarded by many Catholics as the patron saint of Goa (the patron of the Archdiocese of Goa is actually the Blessed Joseph Vaz). Once every twelve years, the body is taken down for veneration and for public viewing. The last such event was conducted in 2004. The Velhas Conquistas regions are also known for its Goa-Portuguese style architecture.",
                    "In many parts of Goa, mansions constructed in the Indo-Portuguese style architecture still stand, though in some villages, most of them are in a dilapidated condition. Fontainhas in Panaji has been declared a cultural quarter, showcasing the life, architecture and culture of Goa. Some influences from the Portuguese era are visible in some of Goa's temples, notably the Mangueshi Temple, although after 1961, many of these were demolished and reconstructed in the indigenous Indian style.",
                ],
            },
        ],

        additionalPlaces: [
            {
                title: "Church",
                bannerImg: goaChurch,
                place: [
                    {
                        title: "Se Cathedral - Churches of Goa",
                        description:
                            "The cathedral built in purist Tuscan style and is stagerring in proportions. In one chapel is kept the Cross of Miracles, said to grow in size & have healing properties. Heavily gilded central altars depict the martyrdom of Saint Catherine, to whom the cathedral is dedicated.",
                    },
                    {
                        title: "Convent & Church of St. Francis of Assisi - Churches of Goa",
                        description:
                            "The beautiful church displays superb decorative paintwork & woodcarving & floor are inlaid with elaborately engraved tombstones depicting scenes from the life of St. Francis. The convent at the back of this church is now the Archaeological Museum.",
                    },
                    {
                        title: "Church & Convent of St. Monica - Churches of Goa",
                        description:
                            "This huge, three-storied laterite building was commenced in 1606 and completed in 1627. Once known as the Royal Monastery on account of the royal patronage, which it enjoyed, the building is now used by the Mater dei Institute as a nunnery, which was inaugurated in 1964. The Church of Mae de Deus -At Saligao Bardez, 13 kms. Built in 1873, is situated amidst picturesque surroundings. The shrine of the miraculous statue of Mae de Deus (Mother of God) was brought from the ruins of the convent of Mae de Deus at Old Goa. This beautiful church is the finest piece of gothic style.",
                    },
                    {
                        title: "The Church of St. Alex - Curtorim - Churches of Goa",
                        description:
                            "Nine kilometer from Margao, the Church of St. Alex is one of the oldest churches in Goa, built in 1597.",
                    },
                    {
                        title: "The Church of our Lady of the Rosary - Churches of Goa",
                        description:
                            "The Church of our Lady of the Rosary represents a fusion of European and Indian elements; while the wall frescos reveal Hindu designs, those on the alabaster tomb of Dona Catherina, wife of the first Portuguese woman to hazard the long & arduous voyage to the Indies, demonstrate the impact of the Muslim-Bijapur style. Over the years the Portuguese zeal for propagating their religion became rigid & intolerant. Consequently temples were demolished & churches built in their place. It was not until 18th century, when the conqueror’s religious zeal had diminished, that Hindu temples were built in sylvan surroundings. Driving through Goa’s villages one is struck by the deep imprint of four & a half centuries of Latin Catholicism. Presiding over every village, commanding the heights at hilltops, hugging the shores of rivers, beside fields and dusty roads, and visible for miles around, sparkling white churches, crosses & small shrines indicate Christian ubiquity; religion is never more than a bend away. The Churches of Goa are surely a must see on Goa visit.",
                    },
                ],
            },
            {
                title: "Beach",
                bannerImg: goaBeach,
                place: [
                    {
                        title: "Anjuna (Beach)",
                        description:
                            "Is a small village in north Goa and is popularly called the freak capital in the world. Its significant tourist spots are the magnificent Albuquerque Mansion built in 1920 (flanked by octagonal towers and an attractive Mangalore tile-roof), the Mascarenhas Mansion, and the Chapora Fort. Anjuna is the least likely place to find peace and quiet. The Wednesday Flea Market of Anjana os famous for its bric-a-brac and the eclectic items that are on sale. Any and everything under the sky can be found here, and lines of vehicles full of tourists start virtually raising clouds of dust. The flea market is a heaven for shopping addicts and good bargainers, to bid on wonderful blends of Tibetan, Kashmiri and Gujarati trinkets and handicrafts, European snacks, cassettes of a noisy brand of music called 'Goa Trance', artificial ornaments carvings and T-shirts. Elephant rides, photographs with fake sadhus or fake snake charmers can also be found here. Campfires burn bright all through the night and the music and merriment carries on till the wee hours of the morning. Facilities for bungee jumping are also available here due to the initiative of a private tour operator.It became popular because of its Trance Parties and the Hippies who tried to fuse the Eastern spiritual traditions with Western music, ideas and art.",
                    },
                    {
                        title: "Goa Palolem Beach",
                        description:
                            "Is just the kind of beach you are likely to come across in glossy paged brochures. White sands, coconut palms and a gentle ocean breeze make this beach one of exceptional beauty. However, there is hardly any development here The accommodation available is rustic but the atmosphere is undeniably relaxing and enchanting.",
                    },
                    {
                        title: "The Goa Palolem Beach",
                        description:
                            "Is located between two headlands, and is the beach that is the closest to Karnataka. It has interesting little wooded islands on the northern headland and you could try to persuade a fisherman to ferry you across. You could go with them and try spotting dolphins. A few shacks selling seafood snacks, souvenirs, and bright, informal clothes have been set up here ever since. Colva Beach is the most popular beach among the Goans with its white powder sands on the shores of the Arabian Sea. Colva is Goa’s longest beach with 20 km of virgin white sands that are fringed with palms and drenched with the sun. The small village of Colva is located in south Goa.It is broad, beautiful and has a stream coursing through it. The Nossa Senhora de Merces (Our Lady of Mercy) Church in Colva is famous for its annual religious event-Fama of Menino Jesuse (Child Jesus)-since the 17th century.",
                    },
                    {
                        title: "Benaulim Beach",
                        description:
                            "Benaulim beach is a must not miss! Frenzied visitors dance in merriment and other entertainment.Bullfights can be seen at Benaulim from early October to late May, usually held in an old rice field with no fences or barricades just outside a village.The Beach Bonanza fair that is held on successive Sundays from mid-April onwards.",
                    },
                    {
                        title: "The Church of our Lady of the Rosary - Churches of Goa",
                        description:
                            "The Church of our Lady of the Rosary represents a fusion of European and Indian elements; while the wall frescos reveal Hindu designs, those on the alabaster tomb of Dona Catherina, wife of the first Portuguese woman to hazard the long & arduous voyage to the Indies, demonstrate the impact of the Muslim-Bijapur style. Over the years the Portuguese zeal for propagating their religion became rigid & intolerant. Consequently temples were demolished & churches built in their place. It was not until 18th century, when the conqueror’s religious zeal had diminished, that Hindu temples were built in sylvan surroundings. Driving through Goa’s villages one is struck by the deep imprint of four & a half centuries of Latin Catholicism. Presiding over every village, commanding the heights at hilltops, hugging the shores of rivers, beside fields and dusty roads, and visible for miles around, sparkling white churches, crosses & small shrines indicate Christian ubiquity; religion is never more than a bend away. The Churches of Goa are surely a must see on Goa visit.",
                    },
                    {
                        title: "Baga Goa Beach",
                        description:
                            "Is the perfect getaway for those looking for a little bit of peace and quiet Baga is a fishing beach with brown sand that sees a lot of international tourists sun-bathing in its golden sun, as a grove of palms gently sways in the wind. The Baga River flows along one side of Baga beach Goa offering a good alternative for children and those who are not too confident about swimming in the sea. On one of the far ends of the beach, lies a group of black rocks against which the sea crashes in dramatic explosions of spray. Baga village is lined with several bars, tandoori terraces and handicraft shops. A temporary stage is constructed for the visiting local musician. Baga Goa's nightlife is the liveliest in Goa and is the perfect expression of the Goan and Western mix of culture. Backwater crocodile and dolphin spotting down the Mandovi River to the Zuari River is a favorite pastime for the travelers at the Baga beach. The thickly forested banks of these rivers are rife with mangrove trees and inhabited by crocodiles in the swampy regions. Dolphins can be spotted at the mouth of the Mandovi River.",
                    },
                ],
            },
        ],

        places: [
            {
                id: 1,
                title: "PANJI",
                image: panji,

                description: [
                    "Panji is the official capital of Goa. It was called Panjim during the times of the Portuguese and was one of the three principal Portuguese cities along with Velha Goa (Old Goa) and Margao. Panaji has carefully managed to preserve its Portuguese heritage and parts of this quaint town still consist of narrow winding streets, old houses with over hanging balconies and red-tiled roofs, and many small bars and cafe's. Signs written in Portuguese still hang over shops, cafes and administrative buildings. The people of Panaji are friendly with an easygoing attitude.",
                    "The Idalcao Palace was once the castle of the Adil Shahis of Bijapur. It served as the Viceregal Palace until 1759 after the Portuguese rebuilt it in 1615. In 1843 it housed the secretariat and now it is the Passport office. Our Lady of Immaculate Conception is Panaji's main church, which was built in 1541 and enlarged in 1619. it is modeled after the church at Reis Magos, and the second largest bell in Goa that was salvaged from the ruins of the Augustian Monastery in Velha Goa is now placed here. The main attraction of the Braganza Institute is a blue tiled frieze depicting the Portuguese colonization of India. The Jama Masjid located in Panaji is one of the few mosques in coastal Goa. It was built after the Inquisition. The Chapel of St Sebastian is a relatively new structure that was built in the 1880's. It has the crucifix that was originally in the Palace of Inquisition in Velha Goa. A Hindu temple, the Mahalaxmi Temple stands as a reminder of the Hindu population and its dominance in Goa. The carnival held in the month of February lasts 3 days and is begun on Sabado Gordo (Fat Saturday) when colorful floats parade and celebrate on the streets of Panaji. The nearest beach form Panaji is at Miramar, about 3 Km along the road to Dona Paula. The most charming attractions of Panaji are still its narrow winding streets, small cafes and bars, and occasional old stone buildings dating back to the 16th and 17th centuries.",
                ],
            },
            {
                id: 2,
                title: "VASCO-DA-GAMA",
                image: vasco,
                description: [
                    "The town of Vasco-da-Gama was originally called Sambhaji. This spick and span, and geometrically planned well laid out coastal town popularly known as Vasco is also the oldest railway terminus for passenger service. Vasco is a key shipping center, with container vessels and iron ore barges clogging the river mouth, but doesn’t hold anything of interest for visitors. The only usual reasons for tourists to come to Vasco is to catch a bus to Dabolim airport, or the Bogmalo beach that lies 8-km southeast. Its city center is dominated by imposing multi-storied buildings and a church. Goa's only airport, Dabolim lies at one end of Vasco, and the internationally famous natural port of Mormugao is also close by. The Mormugao harbour is certainly one of the finest natural anchorages on the West Coast of India. Passenger and Cargo ships dock here from all over the world.",
                ],
            },
            {
                id: 3,
                title: "MARGAO",
                image: margao,
                description: [
                    "Margao is Goa's second largest city and commercial metropolis of Salcete taluka in South Goa. It still retains semblance of Goa’s Portuguese colonial past and is connected to the rest of the Indian sub-continent by rail. Margao is the headquarters of South Goa District and is considered the main commercial city of Goa. It is famous for its ancient cultural heritage and traditional customs of the people of Goa. ",
                    "Places of interest in Margao are the Holy Spirit Church founded in 1565 with the distinctive cross built in front of the church a century earlier. The large rectangular 'Jorge Barreto' Park in front of the colonial-styled building with its arched corridors is a noticeable feature. Chandreshwar Bhutnath Temple and the Rachol Seminary are also worth visiting. Margao's famous market offers the agricultural produce of the entire South Goa. The most noteworthy of these is the 'Sat Burnzam Ghor', which originally had seven roofs. The 'Monte Church' situated on a little promontory also merits a visit to get a bird's eye-view of the city below and the Arabian Sea.",
                ],
            },
            {
                id: 4,
                title: "MAPUSA",
                image: mapusa,
                description: [
                    "Mapusa’s main claim to fame is its famous Friday market where people from all over Goa come to buy and sell their wares. It is otherwise a small town forming the hub of north Goa. It's location as the converging point of all overland routes in Bardez taluka as well as its proximity to the Rio de Mapusa, (the Mapusa River, which in ancient times was a much used waterway for transport of agricultural products and spices) has enabled it to develop a commercial reputation of sorts. Mapusa lies 13kms from Panaji, and has an even blend of residential and commercial establishments and gardens. The beaches nearest to this town are the Anjuna and Chapora beaches. The Kansarpal-Kalbadevi temple, believed to be about 800 years old lies 14 km from Mapusa. There's not much to see in Mapusa, though the Friday market is worth a visit.",
                ],
            },
        ],
    },
    {
        id: 4,
        banner: jaipurDetails,
        title: "Jaipur",
        description: [
            "Jaipur popularly known as the Pink City, is the capital of Rajasthan state, India. Historically rendered as Jeypore, founded in 1727 by Maharaja Sawai Jai Singh, the ruler of Amber. The city is remarkable among pre-modern Indian cities for the width and regularity of its streets which are laid out into six sectors separated by broad streets 111 ft (34 m) wide. The urban quarters are further divided by networks of gridded streets. Five quarters wrap around the east, south, and west sides of a central palace quarter, with a sixth quarter immediately to the east. The Palace quarter encloses a sprawling palace complex (the Hawa Mahal, or palace of winds), formal gardens, and a small lake. Nahargarh Fort crowns the hill in the northwest corner of the old city. Another noteworthy building is Sawai Jai Singh's observatory, Jantar Mantar.",
            "Renowned for its beaches, places of worship and world heritage architecture, Goa is visited by hundreds of thousands of international and domestic tourists each year. It also has rich flora and fauna, owing to its location on the Western Ghats range, which is classified as a biodiversity hotspot.",
        ],

        additionalInfo: [
            {
                img: jaipurHistory,
                title: "History",
                mainContent:
                    "Jaipur, the pink city was founded in 1727 by Maharaja Sawai Jai Singh II, a Kachwaha Rajput, who ruled from 1699-1744. Initially his capital was Amber (city), which was shifteddue to the increase in population and growing scarcity of water. After several battles with Marathas, Jai Singh was keen on the security aspect of the city, he focused on his scientific and cultural interests to make a brilliant city. Being, a lover of mathematics and science, Jai Singh sought advice from Vidyadhar Bhattacharya, a Brahmin scholar of Bengal, to aid him design the city architecture. With a strategic plan, the construction of the city started in 1727. It took around 4 years to complete the major palaces, roads and square. The city was built following the principles of Shilpa Shastra, the Indian Architecture. The city was divided into nine blocks, out of which two consist the state buildings and palaces, whereas the remaining seven blocks were allotted to the public.",
                subContent: [
                    "In order to ensure the security, huge fortification walls were made along with seven strong gates. According to that time, architecture of the town was very advanced and certainly the best in Indian subcontinent. In 1853, when Prince of Wales visited Jaipur, the whole city was painted in Pink color to welcome him. Still, the neat and broadly laid-out avenues, painted in pink provide a magical charm to the city.",
                ],
            },
            {
                img: jaipurGeo,
                title: "Geography",
                mainContent:
                    "Jaipur is located at 26°55′N 75°49′E 26.92,75.82. It has an average elevation of 432 metres (1417ft). The district is situated in the eastern part of Rajasthan, bounded in the north by Sikar and Alwar, in South by Tonk, Ajmer and Sawai Madhopur. Nagaur, Sikar and Ajmer in the west and in east by Bharatpur and Dausa districts. The major rivers passing through the Jaipur district are Banas and Banganga. Ground water resources to the extent of about 28.65 million cubic meter are available in the district.",
                subContent: [
                    "Jaipur has a semi-arid climate. Although it receives over 50 cm (20 inch) of rainfall annually the rainfall is concentrated in the monsoon months between June and September. Temperatures remain relatively high throughout the year, with the summer months of April to early July having average daily temperatures of around 30oC. During the monsoon months there are frequent, heavy rains and thunderstorms, but flooding is not common. The winter months of November to February are mild and pleasant, with average temperatures in the 15-18°C range and little or no humidity. There are however occasional cold waves that lead to temperatures near freezing.",
                ],
            },
            {
                title: "Culture",
                img: jaipurCulture,
                mainContent:
                    "Jaipur has a rich culture for which it has been well known.Jaipur Tamasha, a unique musical folk play, is a 19th century. The contemporary emperor of Jaipur, Maharaja Sawai Jaisingh brought a few artists to Jaipur from Aurangzeb’s Darbar and settled them in Brahmpuri. The foremost among them was the Bhatt Family of Jaipur. Here in under the proximity of the chief of the Bhatt family, Shri Banshidhar Bhatt, Tamasha style developed a specific form and is being performed from the last 250 years in the open theatre called ‘Akhara’.  In this way Tamasha is being improvised new Tamashas are being written upon the present circumstances and upon the contemporary problems. After Shri Banshidhar Bhatt, Mr Vsasudev Bhatt a man of his fourth generation wrote a couple of tamashas on many contemporary issues give a living heath to this skill. ",
                subContent: [],
            },
        ],

        places: [
            {
                id: 1,
                title: "AMBER FORT",
                image: jaipurDetails,
                description: [
                    "Amber Fort also known as Amer Fort is located in Amber, 11 km from Jaipur, Rajasthan state, India. It was the ancient citadel of the ruling Kachhawa clan of Amber, before the capital was shifted to present day Jaipur. Amber Fort is known for its unique artistic style, blending both Hindu and Muslim (Mughal) elements, and its ornate and breathtaking artistic mastery. The fort borders the Maota Lake, and is a major tourist attraction in Rajasthan. ",
                    "Amber was originally built by the Meenas in the town they consecrated to Amba, the Mother Goddess, whom they knew as `Gatta Rani' or `Queen of the Pass' . The initial structure of the fort was entirely completed by Jai Singh I. Amber was modified by successive rulers over the next 150 years, until the Kachwahas shifted their capital to Jaipur during the time of Sawai Jai Singh II.",
                    "The structure which is known today as Amber Fort was initially a palace complex within the original fort of Amber that is today known as Jaigarh Fort. Connected to Amber via fortified passages, Jaigarh Fort is located on a hill above the Amber complex, and is constructed of red sandstone and white marble. It overlooks Maotha Lake, and was reputed to be the treasure vault of the Kacchwaha rulers.",
                    "Like the entire fort complex, Amber Fort is also constructed of white and red sandstone. The Fort is unique in that its outside, an imposing and rugged defensive structure, is markedly different from its inside, an ornate, lavish interior influenced by both Hindu and Muslim (Mughal) styles of ornamentation. The walls of the interior of the fort are covered with murals, frescoes, and paintings depicting various scenes from daily life. Other walls are covered with intricate carvings, mosaic, and minute mirror work. ",
                    "Amber Fort is divided into four sections. Each is accessible via large staircases from a central location, or from a broad pathway leading to each of the sections. The pathways are currently used to transport tourists via an elephant ride. The main entrance of Amber Fort, Surajpol, leads to the Jaleb chowk, the main courtyard of the Fort where the staircase to the palace is located. In ancient times, Jaleb Chowk was the area where returning armies were paraded back home.",
                    "Just prior to the palace entrance is a narrow staircase leading to the Kali Temple, also known as the Shila Devi Temple, made popular for its enormous silver lions. The Kali Temple is known for its silver doors with raised reliefs. According to legends, Maharaja Man Singh I had worshiped Kali for a victory over the rulers of Bengal. The legend says that Kali appeared in the Maharaja's dream and ordered him to recover her statue from the Jessore seabed (now in Bangladesh) and place it in an appropriate temple. However, it is said that the Maharaja recovered the statue from the bed of the sea and created the temple. A tourist curiosity is an image of Ganesha at the temple entrance, carved entirely from a single piece of coral.",
                    "Today, tourists can ride up to the fort from the base of the hill on elephants. On the ride, one can see the skyline of Jaipur, Maotha lake, and the original city walls. The fort can be toured with a guide or on one's own. You also have audio guides available in various languages. The sound and light show in the evening is worth a view.",
                    "One of the most striking parts of the fort is the Hall of Mirrors ‘Sheesh Mahal’. When the palace was occupied by royalty, the hall could be lit at night by a single candle because of all the tiny, intricate mirrors.",
                ],
            },
            {
                id: 2,
                title: "HAWA MAHAL",
                image: hawaMahal,
                description: [
                    "Hawa Mahal, Palace of Winds, built in 1799 by Maharaja Sawai Pratap Singh, and designed by Lal Chand Usta in the form of the crown of Krishna, the Hindu god. It forms part of the City Palace and extends the Zenana or women's chambers, the chambers of the harem. Its original intention was to allow royal ladies to observe everyday life in the street below without being seen.",
                    "It has five stories and is constructed of red and pink sandstone, highlighted with white quakeee quick lime. The side facing the street outside the palace complex has 953 small windows, and the breeze (hawa) which circulates through these windows gives the palace its name, and keeps it cool even in hot months.",
                    "The entrance opens onto a courtyard with a double-storied building on three sides, and one on the eastern wing with three more stories, which is just one room wide. There are no stairs to reach the upper floors, only ramps.",
                ],
            },
            {
                id: 3,
                title: "JAIGARH FORT",
                image: jaigarh,
                description: [
                    "Jaigarh Fort, located around 15 km from Jaipur, is one of the most spectacular forts in India, with almost all its original facilities intact. While Jaigarh Fort is on top of the hill, Amber Fort is at the bottom. The forts are connected through well-guarded passages. Many consider the two together as one complex.",
                    "Jaigarh Fort was a center of artillery production for the Rajputs and it is home to the world's largest cannon on wheels, the Jaivana. The foundries provide fascinating information for the visitors. The manner in which they drew in blasts of air from the desert is most intriguing. A 5km long canal can be seen entering the fort complex to bring in water from the high hills and store in the fort for the armymen. A huge water tank is centrally located connecting the canal. It is assumed that the Kings of Amber/Jaipur used the compartments below the water tank to store the gold and jewellery of the royal family. It is said that this tank was opened during the Emergency declared by the Indian Prime Minister Indira Gandhi during 1975-1977. ",
                ],
            },
            {
                id: 4,
                title: "NAHARGARH FORT",
                image: nahargarh,
                description: [
                    "Nahagarh Fort stands on the edge of the Aravalli Hills, overlooking the pink city of Jaipur in the Indian state of Rajasthan. The view of the city from the fort is breath taking. Along with Amber Fort and Jaigarh Fort it formed a strong defence ring for the city. During the Sepoy revolt of 1857, Nahagarh served as a refuge for Europeans fleeing from the havoc created by mutineers in neighboring states. The word Nahargarh means the abode of tigers. ",
                    "Built mainly in 1734 by Maharaja Sawai Jai Singh II, it is partially in ruins. It was extended in 1868. The rooms are linked by corridors and still have some delicate frescos. There are nine apartments for the nine queens the Maharaja had and all are well planned and decorated. Nahargarh is also called as the Hunting residence of Maharajas.",
                ],
            },
            {
                id: 5,
                title: "JANTAR MANTAR",
                image: jantarMantar,
                description: [
                    "The Jantar Mantar is a collection of architectural astronomical instruments, built by Maharaja - meaning King - Jai Singh II at his then new capital of Jaipur between 1727 and 1734. It is modelled after the one that he had built for him at the then Mughal capital of Delhi. He had constructed a total of five such labs at different locations, including the ones at Delhi and Jaipur. The Jaipur observatory is the largest of these.",
                    "The name is derived from yantra, instrument, and mantra, for formula or in this context calculation. Therefore jantar mantar means literally 'calculation instrument'.",
                    "The observatory consists of fourteen major geometric devices for measuring time, predicting eclipses, tracking stars in their orbits, ascertaining the declinations of planets, and determining the celestial altitudes and related ephemerides. Each is a fixed and 'focused' tool, used for announcing eclipses and the arrival of monsoons.",
                    "Built of local stone and marble, each instrument carries an astronomical scale, generally marked on the marble inner lining; bronze tablets, all extraordinarily accurate, were also employed. Thoroughly restored in 1901, the Jantar Mantar was declared a national monument in 1948.",
                ],
            },
            {
                id: 6,
                title: "CITY PALACE",
                image: cityPalace,
                description: [
                    "Located in the heart of the walled city, The City Palace Complex gives you an idea about the far sightedness of the founder of Jaipur Sawai Jai Singh. Jai Singh built the outer walls but its many buildings were built later and some of them date in the twentieth century too.",
                    "The palace is blend of Mughal and Rajasthani architecture and the royal family still lives in a part of the palace. On entering the complex and before the palace proper lies the Mubarak Mahal, the palace of welcome or reception. Sawai Madho Singh built the palace in the nineteenth century.",
                    "The building now forms the Maharaja Sawai Man Singh II Museum and on display here are a wide array of royal costumes, some very exquisite and precious Pashmina (Kashmiri) Shawls, Benaras silk saris, Sanganeri prints and folk embroidery. An unusual display is that of voluminous clothes worn by Maharaja Sawai Madho Singh I (ruled 1750-68).",
                    "The Maharani's Palace, the palace of the Queen paradoxically puts a display of the Rajput weaponry. The inestimable collections of weapons date back to even 15th century and are in a remarkable state of preservation. Other exhibits include protective chain armours, pistols, jewelled and ivory handled swords, a belt sword, small and assorted cannons, guns, poison tipped blades and gun powder pouches. The frescos on the ceiling are amazing and well preserved.",
                    "This is a marble paved pavilion and puts on display the world largest sterling silver object two gigantic silver vessels. These vessels were made for Maharaja Sawai Madho Singh II, who took in along with him filled with water from River Ganga for drinking. As a devout Hindu the Maharaja did not wish to risk polluted English waters. The ceiling also has large chandeliers,the Guinness Book of Records accounts it has the biggest silver objects in the world.",
                    "The art gallery is located in the Diwan-I-Aam. The Chandra Palace is still occupied by the royal family but visitors can visit the ground floor where some exhibits are on display. However the visit here is worthwhile for the exquisite Peacock in the courtyard outside.",
                ],
            },
            {
                id: 7,
                title: "JAL MAHAL",
                image: jalmahal,
                description: [
                    "Jal Mahal, the Rajput style Water Palace sits in the center of the Mansagar lake. The lake is often dry in the summer but winter monsoons frequently turn it into a beautiful lake filled with water hyacinths. It is on the way to Sisodia garden.",
                ],
            },
        ],
    },
    {
        id: 5,
        banner: kerelaDetails,
        title: "Kerela",
        description: [
            "Kerala, situated on the lush and tropical Malabar Coast, is one of the most popular tourist destinations in India. Named as one of the ten paradises of the world and 50 places of a lifetime by the National Geographic Traveler magazine, Kerala is especially known for its ecotourism initiatives. Its unique culture and traditions, coupled with its varied demographics, has made Kerala one of the most popular tourist destinations in the world.",
            "Kerala is a union state located in the south western part of India. With an Arabian sea coastline on the west, it is bordered on the north by Karnataka and by Tamil Nadu on the south and east. Major cities are Thiruvananthapuram (the capital), Kochi and Kozhikode. The principal spoken language is Malayalam but many other languages are also spoken. Kerala is mentioned in the ancient epic Mahabharata (800 BC) at several instances as a tribe, as a region and as a kingdom. The first written mention of Kerala is seen in a 3rd-century-BC rock inscription by emperor Asoka the Great, where it is mentioned as Keralaputra. This region formed part of ancient Tamilakam and was ruled by the Cheras. They had extensive trade relations with the Greeks, Romans and Arabs. Early contact with Europeans later gave way to struggles between colonial and native interests.",
        ],

        additionalInfo: [
            {
                img: kerelaHistory,
                title: "History",
                mainContent:
                    "It is not certain if the region was inhabited during Neolithic times. However, there is evidence of the emergence of prehistoric pottery and granite burial monuments in the form of megalithic tombs in the 10th century BC; they resemble their counterparts in Western Europe and other parts of Asia. Kerala and Tamil Nadu once shared a common language, ethnicity and culture; this common area was known as Tamilakam. According to legend, Kerala was an Asura-ruled kingdom under Mahabali. Onam, the national festival of Kerala, is dedicated to Maveli's memory. Another legend has Parasurama, an avatar of Mahavishnu, throwing his battle axe into the sea; from those waters, Kerala arose. Parasurama, surrounded by settlers, commanding Varuna to part the seas and reveal Kerala. The ancient Cheras, whose mother tongue and court language was Tamil, ruled Kerala from their capital at Vanchi. They were constantly at war with the neighbouring Chola and Pandya kingdoms. Artist's rendition of Vasco da Gama's 1498 landing in Calicut, now Kozhikode.",
                subContent: [
                    "The Chera kings' dependence on trade meant that merchants from West Asia and Southern Europe could establish coastal posts and settlements in Kerala. The Jewish, Christian, and Muslim immigrants established Nasrani Mappila and Muslim Mappila communities. The Jews first arrived in Kerala in 573 BC. Thomas the Apostle visited Muziris in Kerala in 52 AD to proselytize amongst Kerala's Jewish settlements. However, the first verifiable migration of Jewish-Nasrani families to Kerala is of the arrival of Knai Thoma in 345 AD . Muslim merchants (Malik ibn Dinar) settled in Kerala by the 8th century AD and introduced Islam. After Vasco Da Gama's arrival in 1498, the Portuguese gained control of the lucrative pepper trade by subduing Keralite communities and commerce Conflicts between Kozhikode (Calicut) and Kochi (Cochin) provided an opportunity for the Dutch to oust the Portuguese. In turn, the Dutch were ousted by Marthanda Varma of the Travancore Royal Family who routed them at the Battle of Colachel in 1741. In 1766, Hyder Ali, the ruler of Mysore invaded northern Kerala, capturing Kozhikode in the process. In the late 18th century, Tipu Sultan, Ali’s son and successor, launched campaigns against the expanding British East India Company; these resulted in two of the four Anglo-Mysore Wars. He ultimately ceded Malabar District and South Kanara to the Company in the 1790s. The Company then forged tributary alliances with Kochi (1791) and Travancore (1795). Malabar and South Kanara became part of the Madras Presidency.",
                    "Pazhassi Raja, the Lion of Kerala, who waged a guerilla war against the British in the late 18th century. After India gained its independence in 1947, Travancore and Cochin were merged to form Travancore-Cochin on 1 July 1949. On 1 January 1950 (Republic Day), Travancore-Cochin was recognised as a state. A new legislative assembly was also created, for which elections were first held in 1957.",
                ],
            },
            {
                img: kerelaGeo,
                title: "Geography",
                mainContent:
                    "Kerala is wedged between the Arabian Sea and the Western Ghats. Lying between north latitudes 8°18' and 12°48' and east longitudes 74°52' and 72°22', Kerala is well within the humid equatorial tropics. Kerala’s coast runs for some 580 km (360 miles), while the state itself varies between 35 and 120 km (22–75 miles) in width. Geographically, Kerala can be divided into three climatically distinct regions: the eastern highlands (rugged and cool mountainous terrain), the central midlands (rolling hills), and the western lowlands (coastal plains). Located at the extreme southern tip of the Indian subcontinent, Kerala lies near the centre of the Indian tectonic plate. Pre-Cambrian and Pleistocene geological formations compose the bulk of Kerala’s terrain.",
                subContent: [
                    "Eastern Kerala consists of high mountains, gorges and deep-cut valleys immediately west of the Western Ghats' rain shadow. Forty one of Kerala’s west-flowing rivers, and three of its east-flowing ones originate in this region. The Western Ghats form a wall of mountains interrupted only near Palakkad, where the Palakkad Gap breaks through to provide access to the rest of India. The Western Ghats rises on average to 1,500 m (4920 ft) above sea level, while the highest peaks may reach to 2,500 m (8200 ft). Just west of the mountains lie the midland plains comprising central Kerala, dominated by rolling hills and valleys. Generally ranging between elevations of 250–1,000 m (820–3300 ft), the eastern portions of the Nilgiri and Palni Hills include such formations as Agastyamalai and Anamalai.",
                    "Kerala’s western coastal belt is relatively flat, and is criss-crossed by a network of interconnected brackish canals, lakes, estuaries, and rivers known as the Kerala Backwaters. As Kerala's rivers which lies below sea level result in the nearly year-round water logging of such western regions.  Lake Vembanad—Kerala’s largest body of water—dominates the Backwaters; it lies between Alappuzha and Kochi and is more than 200 km² in area. Around 8% of India's waterways (measured by length) are found in Kerala. The most important of Kerala’s forty four rivers include the Periyar (244 km), the Bharathapuzha (209 km), the Pamba (176 km), the Chaliyar (169 km), the Kadalundipuzha (130 km) and the Achankovil (128 km). The average length of the rivers of Kerala is 64 km.",
                ],
            },
            {
                title: "Culture",
                img: kerelaCulture,
                mainContent:
                    "Kerala's culture is derived from both a greater Tamil-heritage region known as Tamilakam and southern coastal Karnataka. Later, Kerala's culture was elaborated upon through centuries of contact with neighboring and overseas cultures. Native performing arts include koodiyattom (a 2000 year old Sanskrit theatre tradition, officially recognised by UNESCO as a Masterpiece of the Oral and Intangible Heritage of Humanity, kathakali—from katha (story) and kali (performance)—and its offshoot Kerala natanam, koothu (akin to stand-up comedy), mohiniaattam (dance of the enchantress), thullal, padayani, and theyyam. During Onam, Keralites create floral pookkalam designs in front of their houses. Other forms of art are more religious or tribal in nature. These include chavittu nadakom, oppana (originally from Malabar), which combines dance, rhythmic hand clapping, and ishal vocalisations.",
                subContent: [
                    "The elephants are an integral part of the daily life in Kerala. These Indian elephants are loved, revered, groomed and given a prestigious place in the state's culture. Elephants in Kerala are often referred to as the 'sons of the sahya.' Keralite elephants, caparisoned, during the Sree Poornathrayesa Temple festival. Kerala music also has ancient roots. Carnatic music dominates Keralite traditional music. Raga-based renditions known as sopanam accompany kathakali performances. Melam (including the paandi and panchari variants) is a more percussive style of music; it is performed at Kshetram centered festivals using the chenda.",
                    "A typical Kerala-style house with a thulasi thara, a platform for tulasi. Kerala's cuisine is typically served as a sadhya on green banana leaves. Such dishes as idli, payasam, pulisherry, puttucuddla, puzhukku, rasam, and sambar are typical. Keralites—both men and women alike—traditionally don flowing and unstitched garments. These include the mundu, a loose piece of cloth wrapped around men's waists. Women typically wear the sari, a long and elaborately wrapped banner of cloth, wearable in various styles.",
                ],
            },
        ],

        additionalPlaces: [
            {
                title: "",
                bannerImg: kerelaBeach,
                place: [
                    {
                        title: "",
                        description:
                            "Kerala's tourism industry was able to transform the state into one of the niche holiday destinations in India. The tagline Kerala- God's Own Country, originally coined by Vipin Gopal, has been widely used in Kerala's tourism promotions and soon became synonymous with the state. In 2006, Kerala attracted 8.5 million tourist arrivals,",
                    },
                    {
                        title: "Beaches",
                        description:
                            "Are the popular attractions in the state include the beaches at Kovalam, Cherai and Varkala.",
                    },
                    {
                        title: "The hill stations",
                        description:
                            "Of Munnar, Nelliampathi, Ponmudi and Wayanad.",
                    },
                    {
                        title: "",
                        description:
                            "National Parks and Wildlife Sanctuaries at Periyar and Eravikulam National Park.",
                    },
                    {
                        title: "The backwaters region",
                        description:
                            "Which comprises an extensive network of interlocking rivers, lakes, and canals that centre on Alleppey, Kollam, Kumarakom, and Punnamada (where the annual Nehru Trophy Boat Race is held in August), also see heavy tourist traffic.",
                    },
                    {
                        title: "Heritage sites",
                        description:
                            "Such as the Padmanabhapuram Palace and the Mattancherry Palace, are also visited. Cities such as Kochi and Thiruvananthapuram are popular centres for their shopping and traditional theatrical performances. During early summer, the Thrissur Pooram is conducted, attracting foreign tourists who are largely drawn by the festival's elephants and celebrants.",
                    },
                ],
            },
        ],
    },
    {
        id: 6,
        banner: varanasiDetails,
        title: "Varanasi",
        description: [
            "The name Varanasi, has its origin possibly from the names of the two rivers Varuna and Assi for it lies with the confluence of Varuna with the Ganges being to its north and that of Assi and the Ganges to its south. Another speculation about the origin of the name is that the river Varuna itself was called Varanasi in olden times, from where the city got its name. Through the ages, Varanasi was variously known as Avimuktaka, Anandakanana, Mahasmasana, Surandhana, Brahma Vardha, Sudarsana, Ramya,Benaras and Kasi, regarded as holy by Hindus, Buddhists and Jains. Varanasi is home to the Banaras Hindu University. Residents mainly speak Kashika Bhojpuri, which is closely related to the Hindi language. People often refer to Varanasi as the city of temples, the holy city of India, the religious capital of India, the city of lights, the city of learning and the culture capital of India",
            "American writer Mark Twain wrote: Benares is older than history, older than tradition, older even than legend, and looks twice as old as all of them put together.",
        ],

        additionalInfo: [
            {
                img: varanasiHistory,
                title: "History",
                mainContent:
                    "According to legend, the city was founded by the Hindu deity, Shiva, around 5,000 years ago, thus making it one of the most important pilgrimage destinations in the country. It is one of the seven sacred cities of Hindus. Many Hindu scriptures, including Rigveda, Skanda Purana, Ramayana, and Mahabharata, describe the city. Varanasi is generally believed to be about 3,000 years old. Varanasi was a commercial and industrial center famous for its muslin and silk fabrics, perfumes, ivory works, and sculpture. During the time of Gautama Buddha (born circa 567 BC, Varanasi was the capital of the Kingdom of Kashi. The celebrated Chinese traveler, Xuanzang, attested that the city was a center of religious, educational, and artistic activities, and that it extended for about 5 km along the western bank of the Ganges.",
                subContent: [
                    "During successive invasions starting with the hordes of Mahmud of Ghazni in 1033 CE followed by Mohammed Ghori in 1193 CE, Muslims pillaged and destroyed several times Hindu temples (which were being continually rebuilt) in Varanasi, and used the temple material to build mosques. At the start of the seventeenth century, Mughal Emperor Akbar brought some relief in the destruction of Hindu temples, but near the end of that century, Mughal Emperor Aurangzeb led another temple destruction and even renamed the city as Mohammadâbâd. In these years of Muslim rule, learned scholars in Varanasi fled to other parts of India.until Marathas came to rescue it. Marathas brought old pride of city back when it was under their control Varanasi under subsequent British rule, remained a commercial and religious center. In 1910, the British made Varanasi a new Indian state, with Ramanagar as its headquarters but with no jurisdiction over the city of Varanasi itself. Kashi Naresh (Maharaja of Kashi) still resides in the fort of Ramanagar.",
                ],
            },
            {
                img: varanasiDetails,
                title: "Geography",
                mainContent:
                    "The city of Varanasi is located in the middle Ganga valley of North India, in the Eastern part of the state of Uttar Pradesh, along the left crescent-shaped bank of the Ganga river. It has the headquarters of Varanasi district. The Varanasi Urban Agglomeration — an agglomeration of seven urban sub-units — covers an area of 112.26 km² (approximately 43 mi²). The urban agglomeration is stretched between 82° 56’E - 83° 03’E and 25° 14’N - 25° 23.5’N. Being located in the Gangetic plains of North India, the land is very fertile because low level floods in the Ganges continually replenish the soil. On a local level, Varanasi is located on a higher ground between rivers Ganga and Varuna, results in absence of tributaries and canals, the main land is continuous and relatively dry. In ancient times, this geographic situation must have been highly favorable for forming settlements. ",
                subContent: [
                    "Varanasi has a humid subtropical climate with large variations between summer and winter temperatures. Summers are long, from early April to October, with intervening monsoon seasons. Cold waves from the Himalayan region cause temperatures to dip across the city in the winter from December to February. The temperature ranges between 32°C – 46°C (90°F – 115°F) in the summers, and 5°C – 15°C (41°F – 59°F) in the winters. The average annual rainfall is 1110 mm (44 in). Fog is common in the winters, while hot dry winds, called loo, blow in the summers. The city is relatively free from air pollution. Through a combination of water pollution, new constructions of upstream dams, and increase in the local temperature, the water level of the Ganges has recently gone down significantly, and small islands have become visible in the middle of the river.",
                ],
            },
            {
                title: "Culture",
                img: varanasiCulture,
                mainContent:
                    "The culture of Varanasi is closely associated with the River Ganges and the river's religious importance. The city has been a cultural and religious centre in northern India for several thousand years. The Benares Gharana form of Indian classical music developed in Varanasi, and many prominent Indian philosophers, poets, writers, and musicians resided or reside in Varanasi, Tulsidas wrote his Ramacharitamanas there, and Gautama Buddha gave his first sermon at Sarnath near Kashi. Ayurveda is said to have originated at Varanasi. Regions of Varanasi near the banks of Ganga are extremely crowded and have narrow winding lanes that are flanked by road-side shops and several Hindu temples. The main residential areas of Varanasi (especially for the middle and upper classes) are situated in regions far from the ghats; they are more spacious and less polluted.",
                subContent: [
                    "Varanasi has nearly 100 ghats. Many of the ghats were built when the city was under Maratha control. Marathas, Shindes (Scindias), Holkars, Bhonsles, and Peshwes (Peshwas) stand out as patrons of present-day Varanasi. Many ghats are privately owned. The former Kashi Naresh (Maharaja of Kashi, Kasi) owns Shivala or Kali ghat. Most of the ghats are bathing ghats, while others are used as cremation sites. Many ghats are associated with legends or mythologies.",
                ],
            },
        ],

        places: [
            {
                id: 1,
                title: "Assi Ghat",
                image: assiGhat,
                description: [
                    "Situated at the confluence of Ganga and Asi rivers, Assi Ghat is the southernmost Ghat in Varanasi, where pilgrims bathe before paying their homage to Lord Shiva in the form of huge lingam situated under a peepal tree. Assi Ghat also constitutes the southern end of conventional city. Another lingam worshipped here is the Asisangameshwar lingam representing the lord of confluence of the Asi, enshrined in a small marble temple near the Assi Ghat. It was at the Assi Ghat where the famous Indian poet saint, Tulsi Das had written the much-celebrated Ramcharitmanas.",
                    "There are numerous references of Assi Ghat in early literature of the Hindus. We find the mention of Assi Ghat in matsya purana, Agni purana, kurma purana, padma purana and kashi khanda. According legends, Goddess Durga had thrown her sword after slaying the demon, Shumbha- Nishumbha. The place, where the sword had fallen resulted in a big stream, known as Assi River. Assi Ghat is located at the confluence of River Ganga and Assi River. In Kashi Khand, Assi Ghat is referred as Assi Saimbeda Tirtha and according to it one gets punya of all the Tirthas (religious places) by taking a dip here. Thousands of Hindu pilgrims take holy dip here in the months of Chaitya (March/ April) and Magh (Jan/Feb) and other important occasions like solar/ lunar eclipse, Ganga Dussehra, Probodhoni Ekadashi, Makar Shankranti etc.",
                ],
            },
            {
                id: 2,
                title: "Dasaswamedh Ghat",
                image: dasaswamedhGhat,
                description: [
                    "Dasaswamedh literally means the Ghat (river front) of ten sacrificed horses. According to legends ten horses were sacrificed by Lord Brahma to allow Lord Shiva to return from a period of banishment. In spite of the fact that Dasaswamedh is one of the oldest Ghats of Varanasi, dating back to many thousand years, the Ghat has remained unspoilt and clean",
                    "Dasaswamedh provides a beautiful and colorful riverfront view. A large number of Sadhus can be seen performing religious rites on this Ghat. Devotees must not miss the opportunity of visiting the Dasaswamedh Ghat in the evening when after Aarti, thousands of earthen lamps are immersed in the waters of the holy Ganges and the floating lamps give a divine look to the river at dusk.",
                ],
            },
            {
                id: 3,
                title: "Harish Chandra Ghat",
                image: harishchandraGhat,
                description: [
                    "Harish Chandra Ghat is one of the oldest Ghats of Varanasi, name after a mythological King Harish Chandra, who once worked at the cremation ground here for the perseverance of truth and charity. It is believed that the Gods rewarded him for his resolve, charity and truthfulness and restored his lost throne and his dead son to him. Harish Chandra Ghat is one of the two cremation Ghats (the other being Manikarnika Ghat) and is some times referred as Adi Manikarnika (the original creation ground). Hindus from distant places bring the dead bodies of their near and dear ones to the Harish Chandra Ghat for cremation. In Hindu mythology it is believed that if a person is cremated at the Harish Chandra Ghat, that person gets salvation or moksha. The Harish Chandra Ghat was somewhat modernized in late 1980's, when an electric crematorium was opened here.",
                ],
            },
            {
                id: 4,
                title: "Manikarnika Ghat",
                image: manikarnika,
                description: [
                    "Manikarnika Ghat is the main cremation Ghat of Varanasi. Manikarnika Ghat is one of the oldest and most sacred Ghats in Benaras. According to the Hindu mythology, being burned here provides an instant gateway to liberation from the cycle of births and rebirths. Lying at the center of the five tirthas, Manikarnika Ghat symbolizes both creation and destruction. At Manikarnika Ghat, the mortal remains are consigned to flames with the prayers that the souls rest in eternal peace. There is a sacred well at the Manikarnika Ghat, called the Manikarnika Kund. Manikarnika Kund is said to be dug by Lord Vishnu at the time of creation while the hot ashes of the burnt bodies makes one remember the inevitable destruction of everything in the world.",
                ],
            },
            {
                id: 5,
                title: "Tulsi Ghat",
                image: tulsiGhat,
                description: [
                    "Tulsi Ghat is named after the great Hindu poet of the 16th century, Tulsidas. Tulsi Ghat is an important window into the Hindu mythology. Tulsi Das composed the great Indian epic, Ramcharitmanas at Varanasi. According to mythology, when Tulsi's manuscript fell into the River Ganga it did not sink and kept floating instead. It is also believed that the Ramlila (story of Lord Rama's life) was staged here for the first time. Perhaps, to commemorate this a temple of Lord Ram was built on the Tulsi Ghat. The house in which Tulsidas died has been preserved and his samadhi, wooden clogs, pillow and the idol of Hanuman, which Tulsi worshipped, are all still intact here.",
                    "Earlier, Tulsi Ghat was known as Lolark Ghat, is associated with a number of important activities such as bath of Lolarkkunda (to be blessed with sons and their long life) and the sacred bath to get rid of leprosy. Tulsi Ghat is also a center of cultural activities. During Hindu lunar month of Kartika (Oct/Nov), Krishna Lila is staged here with great fanfare and devotion.",
                ],
            },
            {
                id: 6,
                title: "BANARAS HINDU UNIVERSITY",
                image: bhu,
                description: [
                    "Varanasi is the site of three public universities. Banaras Hindu University, which includes Institute of Technology and Institute of Medical Sciences, Mahatma Gandhi Kashi Vidyapeeth University and Sampurnanand Sanskrit University are the other two universities.",
                    "anaras Hindu University (1916) was founded by Pandit Madan Mohan Malaviya with the cooperation of Dr Annie Besant. Its 1350 acre (5.5 km²) campus was built on land donated by the Kashi Naresh.",
                    "Governor General Lord Cornwallis establish the Sanskrit College (1791), which was the first college in Varanasi. After independence this college turned to in Sampurnanand Sanskrit University.",
                    "The Central Institute for Higher Tibetan Studies at Sarnath is a deemed university with a preference for the traditional Tibetan method of teaching within a framework of modern universities. Varanasi is also noted to provide Hindu religious teaching. Since ancient times people have been coming to Varanasi to learn philosophy, Sanskrit, astrology, social science and religious teachings. In Indian tradition, Varanasi is often called Sarva Vidya Ki Rajdhani (capital of knowledge).The city also has the Jamiah Salafiah, a Salafi Islamic institution.",
                ],
            },
        ],
    },
];

export const topDestinationOptions = {
    loop: true,
    margin: 5,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    responsive: {
        0: {
            items: 1,
        },
        900: {
            items: 2,
        },
        1200: {
            items: 4,
        },
    },
};
