import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "../../index.css";
import styles from "./styles.module.scss";
import NavBar from "../../Components/Navbar/NavBar";
import logo2 from "../../Assets/Image/logo2.png";
import TopBanner from "../../Components/TopBanner/TopBanner";
import ParentContainer from "../../Components/ParentContainer/ParentContainer";
import { Grid } from "@mui/material";
import { distanceData } from "../../JsonData/DistanceData";
import Footer from "../../Components/Footer/Footer";
import MobileFooter from "../../Components/Footer/MobileFooter";
import Button from "../../utilitiesComponents/Button/Button";
import FloatingButton from "../../Components/FloatingButton/FloatingButton";
import messages from "./messages";



const IconComponent = ({ label }) => {
    return (
        <div className="flex mb-0 items-center">
            <h4 className="text-6xl text-white font-medium ">{label}</h4>
        </div>
    );
};
function DistanceCalculator({lang}) {
    const [value1, setValue1] = React.useState(distanceData[0].city);
    const [value2, setValue2] = React.useState(distanceData[0].city);
    const [calculatedDistance, setCalculatedDistance] = React.useState(0);

    useEffect(() => {
        var latitude1;
        var longitude1;
        var latitude2;
        var longitude2;

        distanceData.map((item) => {

            if (value1 == item.city) {
                latitude1 = item.lat;
                longitude1 = item.lng;
            }
            else if (value2 == item.city) {
                latitude2 = item.lat;
                longitude2 = item.lng;
            }
            const google = window.google;
            if (google) {
                var distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(latitude1, longitude1), new google.maps.LatLng(latitude2, longitude2));
                distance = Math.round(distance / 1000) || 0;
                setCalculatedDistance(distance);
                console.log("calculatedDistance", calculatedDistance);
            }
            else {
                setCalculatedDistance(0);
            }
        });
    }, []);

    const handleChange1 = (event) => {
        setValue1(event.target.value);
        var latitude1;
        var longitude1;
        var latitude2;
        var longitude2;
        distanceData.map((item) => {

            if (event.target.value == item.city) {
                latitude1 = item.lat;
                longitude1 = item.lng;
            }
            else if (value2 == item.city) {
                latitude2 = item.lat;
                longitude2 = item.lng;
            }
            const google = window.google;
            if (google) {
                var distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(latitude1, longitude1), new google.maps.LatLng(latitude2, longitude2));
                distance = Math.round(distance / 1000) || 0;
                setCalculatedDistance(distance);
                console.log("handleChange1 calculatedDistance", calculatedDistance);
            }
            else {
                setCalculatedDistance(0);
            }
        });
    };
    const handleChange2 = (event) => {
        setValue2(event.target.value);
        var latitude1;
        var longitude1;
        var latitude2;
        var longitude2;
        distanceData.map((item) => {

            if (value1 == item.city) {
                latitude1 = item.lat;
                longitude1 = item.lng;
            }
            else if (event.target.value == item.city) {
                latitude2 = item.lat;
                longitude2 = item.lng;
            }
            const google = window.google;
            if (google) {
                var distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(latitude1, longitude1), new google.maps.LatLng(latitude2, longitude2));
                distance = Math.round(distance / 1000) || 0;
                setCalculatedDistance(distance);
                console.log("handleChange2 calculatedDistance", calculatedDistance);
            }
            else {
                setCalculatedDistance(0);
            }
        });
    };

    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton/>
            <div className="pb-10">
                <ParentContainer>
                    <TopBanner showBanner={false} label={messages.title[lang]} />
                </ParentContainer>
            </div>
            <div
                className={`bg-currencyBanner w-full ${styles.Banner} p-20 md:px-10 sm:px-5 2xs:px-5`}
                style={{
                    height: "100vh",
                }}
            >
                <div className="flex justify-center">
                    <div className={styles.infoBox}>
                        <IconComponent label={calculatedDistance + " " + "KM"} />
                    </div>
                </div>
                <Grid style={{ marginTop: "90px" }} container className=" ">
                    <Grid item xs={12} md={2} lg={2}></Grid>
                    <Grid className="mt-10 md:mt-0" item xs={12} md={3} lg={3}>
                        <h className="text-white">{messages.From[lang]} :</h>
                        <div className={styles.infoBox2}>
                            <select className="w-full bg-transparent border-none text-white" value={value1} onChange={(e) => handleChange1(e)}>
                                {distanceData.map((option) => (
                                    <option className="text-black border-none" value={option.value1}>{option.city}</option>
                                ))}
                            </select>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}></Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <h className="text-white">{messages.To[lang]} :</h>
                        <div className={styles.infoBox2}>
                            <select className="w-full bg-transparent border-none text-white" value={value2} onChange={(e) => handleChange2(e)}>
                                {distanceData.map((option) => (
                                    <option className="text-black border-none" value={option.value2}>{option.city}</option>
                                ))}
                            </select>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}></Grid>
                </Grid>
            </div>
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default withRouter(DistanceCalculator);
