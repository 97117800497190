export const footerLinks = [
    { title: "North India Tours", path: "/tour-package#north" },
    { title: "Rajasthan Tours", path: "/tour-package#rajasthan" },
    { title: "Exclusive Tours", path: "/tour-package#exclusive" },
    { title: "Golden Triangle Tours", path: "/tour-package#golden" },
    { title: "Private Day Tour", path: "/private-day-tour" },
];

export const footerLinks2 = [
    { title: "Tips for Travelling in India", path: "/tips-and-travelling" },
    { title: "Distance Calculator", path: "/distance-calculator" },
    { title: "Currency Converter", path: "/currency-converter" },
    { title: "Terms and Condition", path: "/terms-and-condition" },
];
export const bottomLinks = [
    { title: "Home", path: "/" },
    { title: "About Us", path: "/about-us" },
    { title: "Our Services", path: "/our-service" },
    { title: "Tour Packages", path: "/tour-package" },
    // { title: "Reservation", path: "/reservation-form" },
    { title: "Car Rental", path: "/car-rental" },
    { title: "Contact Us", path: "/contact-us" },
];
