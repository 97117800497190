import task from "../Assets/Svg/task.svg";
import car from "../Assets/Svg/car.svg";
import airplane from "../Assets/Svg/airplane.svg";
import train from "../Assets/Svg/train.svg";
import hotel from "../Assets/Svg/hotel.svg";
import travel from "../Assets/Image/travel.png";
import island from "../Assets/Image/island.png";
import diary from "../Assets/Image/diary.png";
import earth1 from "../Assets/Image/earth1.png";

export const ourService = [
    {
        code: "rgba(139, 13, 160, 0.3)",
        img: task,
        spanText: {
            "en": "Itinerary Planning & Tailor Made Holiday: ",
            "es": "Planificación de itinerarios y vacaciones a medida: ",
            "fr": "Planification d'itinéraire et vacances sur mesure: ",
            "it": "Pianificazione dell'itinerario e vacanza su misura: ",
            "de": "Reiseroutenplanung & maßgeschneiderter Urlaub: "
        },
        details: {
            "en": "Visitors may either select the desired itinerary from our Tour Package or allow our brilliant and experienced vacation planners to create a unique tailor made private tour Just for You",
            "es": "Los visitantes pueden seleccionar el itinerario deseado de nuestro paquete turístico o permitir que nuestros planificadores de vacaciones brillantes y experimentados creen un recorrido privado único hecho a medida solo para usted.",
            "fr": "Les visiteurs peuvent soit sélectionner l'itinéraire souhaité dans notre forfait, soit permettre à nos planificateurs de vacances brillants et expérimentés de créer une visite privée sur mesure unique, juste pour vous.",
            "it": "I visitatori possono selezionare l'itinerario desiderato dal nostro Pacchetto Tour o consentire ai nostri brillanti ed esperti pianificatori di vacanze di creare un tour privato su misura unico per te",
            "de": "Besucher können entweder die gewünschte Reiseroute aus unserem Tourpaket auswählen oder unseren brillanten und erfahrenen Urlaubsplanern erlauben, eine einzigartige maßgeschneiderte private Tour nur für Sie zu erstellen"
        }
    },
    {
        code: "rgba(154, 220, 255, 0.3)",
        img: hotel,
        spanText: {
            "en": "Hotel Reservations: ",
            "es": "Reservaciones de hotel: ",
            "fr": "Réservations d'hôtel: ",
            "it": "Prenotazioni alberghiere: ",
            "de": "Hotelreservierungen: "
        },
        details: {
            "en": "Individual or group booking in any hotel of any category in any part of India can be provided instantly. Owing to our good connections with the hotels in almost every part of the country. Please enter our hotel query form and just let us know the name of the city and category of the hotel and wait for sometime, Our professional will get back to you in no time with a number of suitable options.",
            "es": "La reserva individual o de grupo en cualquier hotel de cualquier categoría en cualquier parte de la India se puede realizar al instante. Debido a nuestras buenas conexiones con los hoteles en casi todas las partes del país. Ingrese a nuestro formulario de consulta de hotel y háganos saber el nombre de la ciudad y la categoría del hotel y espere un momento. Nuestro profesional se comunicará con usted en poco tiempo con una serie de opciones adecuadas.",
            "fr": "La réservation individuelle ou de groupe dans n'importe quel hôtel de n'importe quelle catégorie dans n'importe quelle partie de l'Inde peut être fournie instantanément. Grâce à nos bonnes relations avec les hôtels dans presque toutes les régions du pays. Veuillez saisir notre formulaire de demande d'hôtel et indiquez-nous simplement le nom de la ville et la catégorie de l'hôtel et attendez un moment. Notre professionnel vous répondra en un rien de temps avec un certain nombre d'options appropriées.",
            "it": "La prenotazione individuale o di gruppo in qualsiasi hotel di qualsiasi categoria in qualsiasi parte dell'India può essere fornita immediatamente. Grazie ai nostri buoni collegamenti con gli hotel in quasi ogni parte del paese. Si prega di inserire il nostro modulo di richiesta dell'hotel e di farci sapere il nome della città e la categoria dell'hotel e attendere un po', il nostro professionista ti risponderà in pochissimo tempo con una serie di opzioni adeguate.",
            "de": "Einzel- oder Gruppenbuchungen in jedem Hotel jeder Kategorie in jedem Teil Indiens können sofort durchgeführt werden. Aufgrund unserer guten Verbindungen zu den Hotels in fast allen Landesteilen. Bitte füllen Sie unser Hotelanfrageformular aus und teilen Sie uns einfach den Namen der Stadt und Kategorie des Hotels mit und warten Sie einige Zeit. Unser Profi wird sich in kürzester Zeit mit einer Reihe geeigneter Optionen bei Ihnen melden."
        }
    },
    {
        code: "rgba(255, 211, 101, 0.3)",
        img: airplane,
        spanText: {
            "en": "Airline Ticketing: ",
            "es": "Venta de billetes de avión: ",
            "fr": "Billetterie aérienne: ",
            "it": "Biglietteria aerea: ",
            "de": "Flugtickets: "
        },
        details: {
            "en": "Our Exceptionaly good relations with the Domestic and International Airlines enable us to come up with very competitive and reasonable price for our client. The online reservation system helps us in confirming the bookings instantly even in very busy sectors.",
            "es": "Nuestras relaciones excepcionalmente buenas con las aerolíneas nacionales e internacionales nos permiten ofrecer precios muy competitivos y razonables para nuestros clientes. El sistema de reservas en línea nos ayuda a confirmar las reservas al instante, incluso en sectores muy concurridos.",
            "fr": "Nos relations exceptionnellement bonnes avec les compagnies aériennes nationales et internationales nous permettent de proposer des prix très compétitifs et raisonnables pour notre client. Le système de réservation en ligne nous aide à confirmer les réservations instantanément même dans les secteurs très fréquentés.",
            "it": "I nostri rapporti eccezionalmente buoni con le compagnie aeree nazionali e internazionali ci consentono di trovare un prezzo molto competitivo e ragionevole per il nostro cliente. Il sistema di prenotazione online ci aiuta a confermare le prenotazioni istantaneamente anche in settori molto trafficati.",
            "de": "Unsere außergewöhnlich guten Beziehungen zu den nationalen und internationalen Fluggesellschaften ermöglichen es uns, unseren Kunden einen sehr wettbewerbsfähigen und angemessenen Preis zu bieten. Das Online-Reservierungssystem hilft uns, die Buchungen auch in stark frequentierten Sektoren sofort zu bestätigen."
        }
    },
    {
        code: "rgba(255, 168, 168, 0.3)",
        img: train,
        spanText: {
            "en": "Train Ticketing: ",
            "es": "Billete de tren: ",
            "fr": "Billetterie ferroviaire: ",
            "it": "Biglietteria del treno: ",
            "de": "Zugtickets: "
        },
        details: {
            "en": "We provide the all class train reservation tickets for our clients.",
            "es": "Proporcionamos boletos de reserva de tren de todas las clases para nuestros clientes.",
            "fr": "Nous fournissons les billets de réservation de train toutes classes pour nos clients.",
            "it": "Forniamo ai nostri clienti i biglietti di prenotazione del treno per tutte le classi.",
            "de": "Wir stellen unseren Kunden Zugreservierungstickets für alle Klassen zur Verfügung."
        }
    },
    {
        code: "rgba(217, 96, 152, 0.3)",
        img: car,
        spanText: {
            "en": "Car Rental Services: ",
            "es": "Servicios de alquiler de coches: ",
            "fr": "Services de location de voitures: ",
            "it": "Servizi di noleggio auto: ",
            "de": "Autovermietung: "
        },
        details: {
            "en": "Amongst the multifaceted features of Indian Transport, for your comfort we offer you our fleet of Indian Ambassdor, Luxury Cars and Air conditioned luxury coaches fitted with panoramic windows, Seat belts, Luxury Push Back Seats, Flush Interiors, Audio systems etc. All to make your drive ever so enjoyable.",
            "es": "Entre las características multifacéticas de Indian Transport, para su comodidad, le ofrecemos nuestra flota de Indian Ambassador, automóviles de lujo y autocares de lujo con aire acondicionado equipados con ventanas panorámicas, cinturones de seguridad, asientos traseros de lujo, interiores empotrados, sistemas de audio, etc. Todo para hacer tu viaje siempre tan agradable.",
            "fr": "Parmi les caractéristiques multiformes d'Indian Transport, pour votre confort, nous vous proposons notre flotte d'ambassadeurs indiens, de voitures de luxe et d'autocars de luxe climatisés équipés de fenêtres panoramiques, de ceintures de sécurité, de sièges de luxe à dossier rabattable, d'intérieurs affleurants, de systèmes audio, etc. votre conduite est toujours aussi agréable.",
            "it": "Tra le molteplici caratteristiche di Indian Transport, per la tua comodità ti offriamo la nostra flotta di Indian Ambassdor, auto di lusso e autobus di lusso con aria condizionata dotati di finestrini panoramici, cinture di sicurezza, sedili push-back di lusso, interni a filo, sistemi audio ecc. Tutto da fare la tua guida è sempre così piacevole.",
            "de": "Unter den vielfältigen Funktionen von Indian Transport bieten wir Ihnen für Ihren Komfort unsere Flotte von indischen Botschaftern, Luxusautos und klimatisierten Luxusbussen, die mit Panoramafenstern, Sicherheitsgurten, luxuriösen Push-Back-Sitzen, bündigen Innenräumen, Audiosystemen usw. ausgestattet sind Ihre Fahrt ist so angenehm."
        }
    },
];

export const bottomService = [
    { img: earth1, code: "rgba(255, 211, 101, 0.3)" },
    { img: travel, code: "rgba(217, 96, 152, 0.3)" },
    { img: diary, code: "rgba(154, 220, 255, 0.3)" },
    { img: island, code: "rgba(139, 13, 160, 0.3)" },
];
