export default {
    "title": {
        "en": "Our Services",
        "es": "Nuestros servicios",
        "fr": "Nos services",
        "it": "I nostri servizi",
        "de": "Unsere Leistungen"
    },
    "heading1": {
        "en": "Our Services Include complete Travel arrangements:",
        "es": "Nuestros servicios incluyen arreglos de viaje completos:",
        "fr": "Nos services comprennent des arrangements de voyage complets:",
        "it": "I nostri servizi includono l'organizzazione del viaggio completa:",
        "de": "Unsere Dienstleistungen umfassen komplette Reisearrangements:"
    },
    "paragraph1": {
        "en": "Global India Tours formed with the mission to carve for itself a niche as specialist in the delivery of unique experience. We organize Luxury, Exclusive, Deluxe, Special interest tours, Cultural India tour, Heritage India Tour ,Rural India Tour, Trekking Tour, Royal India Tour, Safari and Wildlife Tour, Adventure Tour, Beaches Tour, Ethnic India Tour, Medical Tour, Luxury Nepal Travel package with emphasis on cultural, Historical, Rural Tribal India, Art and Craft, Rejuvenations Ayurveda, Yoga Meditation, Wildlife.",
        "es": "'Global India Tours' se formó con la misión de hacerse un hueco como especialista en la entrega de una experiencia única. Organizamos tours de lujo, exclusivos, de lujo, de interés especial, tour cultural de la India, tour del patrimonio de la India, tour de la India rural, tour de trekking, tour de la India real, tour de safari y vida salvaje, tour de aventura, tour de playas, tour de la India étnica, tour médico, lujo Paquete de viaje a Nepal con énfasis en cultura, historia, India tribal rural, arte y artesanía, rejuvenecimiento ayurvédico, meditación de yoga, vida silvestre.",
        "fr": "'Global India Tours' s'est formé avec la mission de se tailler une niche en tant que spécialiste dans la livraison d'une expérience unique. Nous organisons des circuits de luxe, exclusifs, de luxe, d'intérêt spécial, circuit culturel de l'Inde, circuit de l'Inde patrimoniale, circuit de l'Inde rurale, circuit de trekking, circuit de l'Inde royale, circuit safari et faune, circuit d'aventure, circuit des plages, circuit de l'Inde ethnique, circuit médical, luxe Forfait voyage au Népal mettant l'accent sur l'Inde tribale culturelle, historique, rurale, l'art et l'artisanat, le rajeunissement Ayurveda, la méditation yoga, la faune.",
        "it": "'Global India Tours' si è formata con la missione di ritagliarsi una nicchia come specialista nella fornitura di esperienze uniche. Organizziamo tour di lusso, esclusivi, deluxe, di interesse speciale, tour dell'India culturale, tour dell'India del patrimonio, tour dell'India rurale, tour di trekking, tour dell'India reale, tour di safari e fauna selvatica, tour di avventura, tour delle spiagge, tour dell'India etnica, tour medico, lusso Pacchetto viaggio Nepal con enfasi sull'India tribale culturale, storica, rurale, arte e artigianato, ringiovanimento, ayurveda, meditazione yoga, fauna selvatica.",
        "de": "'Global India Tours' wurde mit der Mission gegründet, sich eine Nische als Spezialist für die Bereitstellung einzigartiger Erfahrungen zu schaffen. Wir organisieren Luxus-, Exklusiv-, Deluxe-, Special-Interest-Touren, kulturelle Indien-Tour, Heritage India-Tour, ländliche Indien-Tour, Trekking-Tour, königliche Indien-Tour, Safari- und Wildtier-Tour, Abenteuer-Tour, Strand-Tour, ethnische Indien-Tour, medizinische Tour, Luxus Nepal-Reisepaket mit Schwerpunkt auf kulturellem, historischem, ländlichem Stammes-Indien, Kunst und Handwerk, Ayurveda-Verjüngung, Yoga-Meditation, Tierwelt."
    },
    "heading2": {
        "en": "Your next holiday can be an endless memory and a very unique journey of Discovery.",
        "es": "Sus próximas vacaciones pueden ser un recuerdo interminable y un viaje de descubrimiento único.",
        "fr": "Vos prochaines vacances peuvent être un souvenir sans fin et un voyage de découverte tout à fait unique.",
        "it": "La tua prossima vacanza può essere un ricordo infinito e un viaggio alla scoperta davvero unico.",
        "de": "Ihr nächster Urlaub kann eine endlose Erinnerung und eine einzigartige Entdeckungsreise sein."
    }
}