import React from "react";
import styles from "./style.module.scss";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import call from "../../Assets/Svg/calls.svg";
import location from "../../Assets/Svg/location.svg";
import email from "../../Assets/Svg/email.svg";
import facebook from "../../Assets/Svg/facebook.svg";
import instagram from "../../Assets/Svg/instagram.svg";
import IconComponent from "./IconComponent";
import {
    footerLinks,
    footerLinks2,
    bottomLinks,
} from "../../JsonData/FooterLinks";

const MobileGridComponent = ({ img, label, alt, width }) => {
    return (
        <div className="flex justify-start items-center mt-10">
            <img
                src={img}
                alt={alt}
                width={width}
                style={{ marginRight: "30px" }}
            />
            <h5 className=" text-iconColor font-semibold">{label}</h5>
        </div>
    );
};

function MobileFooter() {
    return (
        <div
            className={`bg-footerBackground p-16 pb-0 sm:p-14 2xs:p-10 ${styles.mobileFooter}`}
        >
            <h4 className=" text-3xl text-white font-semibold">Contact Us</h4>
            <div className={styles.mainFooter}>
                <div className="w-1/2 sm:w-full 2xs:w-full">
                    <ul className={styles.footerLinks}>
                        <MobileGridComponent
                            img={location}
                            alt="location"
                            label={
                                "95, Shri Ram Nagar 'Extn', Jhotwara, Jaipur (Rajasthan)"
                            }
                            width="20"
                        />
                        <MobileGridComponent
                            img={email}
                            alt="email"
                            label={"global_indiatours@yahoo.in"}
                            width="25"
                        />
                        <MobileGridComponent
                            img={call}
                            alt="call"
                            label={"+91-98291-36255"}
                            width="30"
                        />
                    </ul>
                </div>
                <div>
                    {" "}
                    <div className={styles.iconComponent}>
                        <IconComponent
                            img={facebook}
                            alt="facebook"
                            style={{}}
                        />

                        <IconComponent
                            img={instagram}
                            alt="instagram"
                            style={{ marginTop: "40px" }}
                        />

                        <IconComponent
                            img={email}
                            alt="email"
                            style={{
                                marginTop: "40px",
                            }}
                        />
                    </div>
                </div>
            </div>

            <Grid container spacing={4}>
                <Grid item sm={6}>
                    <h4 className="text-3xl text-white font-semibold mt-16 sm:mt-16 2xs:mt-16">
                        Package Tours
                    </h4>
                    <div>
                        <ul className={styles.footerLinks}>
                            {footerLinks.map((item, index) => (
                                <Link key={index} to={item.path}>
                                    <li>{item.title}</li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                </Grid>
                <Grid item sm={6}>
                    <h4 className="text-3xl text-white font-semibold mt-16 sm:mt-0 2xs:mt-5">
                        Tourist Information
                    </h4>
                    <div>
                        <ul className={styles.footerLinks}>
                            {footerLinks2.map((item, index) => (
                                <Link key={index} to={item.path}>
                                    <li>{item.title}</li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                </Grid>
            </Grid>

            <div className="mt-5">
                <div className={styles.line}></div>
                <div
                    className={`flex flex-1 flex-wrap justify-center items-center w-full text-subheader font-semibold mt-7 ${styles.mobileBottomLinks}`}
                >
                    {bottomLinks.map((item, index) => (
                        <Link key={index} to={item.path}>
                            <h4>{item.title}</h4>
                        </Link>
                    ))}
                </div>
                <div className="mt-16 pb-5 flex justify-center items-center sm:block sm:text-center 2xs:block 2xs:text-center">
                    <h3 className="text-primaryColor font-medium text-md ">
                        Copyright © 2022 Global India Tours
                    </h3>
                    <div
                        className="bg-primaryBackground  h-3 mx-5 sm:hidden 2xs:hidden"
                        style={{ width: "1px" }}
                    ></div>
                    <h3 className="text-primaryColor font-medium text-md sm:mt-2 2xs:mt-2">
                        <a
                            href="https://bytesalgo.com"
                            rel="noreferrer"
                            target="_blank"
                        >
                            Powered by BytesAlgo
                        </a>
                    </h3>
                    <div
                        className="bg-primaryBackground  h-3 mx-5 sm:hidden 2xs:hidden"
                        style={{ width: "1px" }}
                    ></div>{" "}
                    <h3 className="text-primaryColor font-medium text-md sm:mt-2 2xs:mt-2">
                        All Right Reserved
                    </h3>
                </div>
            </div>
        </div>
    );
}

export default MobileFooter;
