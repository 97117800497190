import { useEffect, useState } from 'react';
import Axios from 'axios';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { withRouter } from "react-router-dom";
import { Grid } from "@mui/material";
import "../../index.css";
import "./style.css";
import styles from "./styles.module.scss";
import NavBar from "../../Components/Navbar/NavBar";
import logo2 from "../../Assets/Image/logo2.png";
import TopBanner from "../../Components/TopBanner/TopBanner";
import ParentContainer from "../../Components/ParentContainer/ParentContainer";
import Button from "../../utilitiesComponents/Button/Button";

import Footer from "../../Components/Footer/Footer";
import MobileFooter from "../../Components/Footer/MobileFooter";
import converter from "../../Assets/Svg/converter.svg";
import FloatingButton from '../../Components/FloatingButton/FloatingButton';
import messages from './messages';

const IconComponent = ({ label }) => {
    return (
        <div className="flex mb-0 items-center">
            <h4 className="text-6xl text-white font-medium ">{label}</h4>
        </div>
    );
};

function CurrencyConverter({lang}) {

    // Initializing all the state variables 
    const [info, setInfo] = useState([]);
    const [input, setInput] = useState(0);
    const [from, setFrom] = useState("usd");
    const [to, setTo] = useState("inr");
    const [options, setOptions] = useState([]);
    const [output, setOutput] = useState(0);

    // Calling the api whenever the dependency changes
    useEffect(() => {
        Axios.get(
            `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${from}.json`)
            .then((res) => {
                setInfo(res.data[from]);
                console.log("currency res",res);
            })
    }, [from]);

    // Calling the convert function whenever
    // a user switches the currency
    useEffect(() => {
        setOptions(Object.keys(info));
        convert();
    }, [info])

    // Function to convert the currency
    function convert() {
        var rate = info[to];
        setOutput(input * rate);
    }

    // Function to switch between two currency
    function flip() {
        var temp = from;
        setFrom(to);
        setTo(temp);
    }

    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton/>
            <div className="pb-10">
                <ParentContainer>
                    <TopBanner showBanner={false} label={messages.title[lang]} />
                </ParentContainer>
            </div>
            <div
                className={`bg-currencyBanner w-full ${styles.Banner} p-20 md:px-10 sm:px-5 2xs:px-5`}
            >
                <Grid container>
                    <Grid item xs={12} md={8} lg={8}>
                        <div className={styles.infoBox}>
                            <IconComponent
                                label={output.toFixed(2) + " " + to.toUpperCase()}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}></Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={5} lg={5}>
                        <Grid container className="pt-10">
                            <Grid item xs={12} md={12} lg={12}>
                                <h className="text-white">{messages.Amount[lang]} :</h>
                                <div className={styles.infoBox2}>
                                    <input className="text-white bg-transparent outline-none w-full" type="text" id="amount" name="amount" onChange={(e) => setInput(e.target.value)} />
                                </div>
                            </Grid>
                            <Grid className="pt-5" item xs={12} md={12} lg={12}>
                                <h className="text-white">{messages.Currency[lang]} :</h>
                                <div className={styles.infoBox2}>
                                    <Dropdown options={options}
                                        onChange={(e) => { setFrom(e.value) }}
                                        value={from} placeholder="From" />
                                    {/* <input className="text-white bg-transparent outline-none w-full" type="text" id="currency" name="currency" value={from} placeholder="From" /> */}
                                </div>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        <div className={styles.Imgcontainer}>
                            <div className={styles.Imgcenter}>
                                <img onClick={() => { flip() }} src={converter} alt="loading" />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5} lg={5}>
                        <Grid container className="pt-10">
                            <Grid item xs={12} md={12} lg={12}>
                                <h className="text-white">{messages.Amount[lang]} :</h>
                                <div className={styles.infoBox2}>
                                    <p className="text-white bg-transparent">{output.toFixed(2)}</p>
                                </div>
                            </Grid>
                            <Grid className="pt-5" item xs={12} md={12} lg={12}>
                                <h className="text-white">{messages.Currency[lang]} :</h>
                                <div className={styles.infoBox2}>
                                    <Dropdown options={options}
                                        onChange={(e) => { setTo(e.value) }}
                                        value={to} placeholder="To" />
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <div className="flex justify-center">
                    <h className="pt-5 pb-5 md:pt-0 pb-10 text-white">{messages.or[lang]}</h>
                </div>
                <div className="flex justify-center">
                    <Button
                        onClick={() => { convert() }}
                        className='cursor-pointer p-5'
                        title={messages.button[lang]}
                        style={{
                            background: "var(--primaryColor)",
                            color: "white",
                            borderRadius: "5px",
                            width: "435px",
                            height: "40px",
                            fontSize: "22px",
                        }}
                    />
                </div>
            </div>
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default withRouter(CurrencyConverter);
