export default {
    "title": {
        "en": "Rent A Car",
        "es": "Alquilar un coche",
        "fr": "Louer une voiture",
        "it": "Affittare una macchina",
        "de": "Autovermietungen"
    },
    "heading1": {
        "en": "Best car rental services:",
        "es": "Los mejores servicios de alquiler de coches:",
        "fr": "Meilleurs services de location de voitures:",
        "it": "I migliori servizi di autonoleggio:",
        "de": "Beste Autovermietung:"
    },
    "paragraph1": {
        "en": "Car rental in India can be a hassle-free task with us, offering a wide range of cars and luxury coaches to our customers for giving them the best of comforts along with hotel reservations, to conclude an overall happy travel experience. We take care to give the much desired privacy to our client while traveling in India. Our efforts are focused toward providing 24 hours customer friendly services 365 days a year.",
        "es": "El alquiler de autos en India puede ser una tarea sin complicaciones con nosotros, ofreciendo una amplia gama de autos y autocares de lujo a nuestros clientes para brindarles las mejores comodidades junto con reservas de hotel, para concluir una experiencia de viaje feliz en general. Nos encargamos de dar la privacidad tan deseada a nuestro cliente mientras viaja en la India. Nuestros esfuerzos se enfocan en brindar servicios amigables al cliente las 24 horas, los 365 días del año.",
        "fr": "La location de voitures en Inde peut être une tâche sans tracas avec nous, offrant une large gamme de voitures et d'autocars de luxe à nos clients pour leur offrir le meilleur des conforts ainsi que des réservations d'hôtel, pour conclure une expérience de voyage globalement heureuse. Nous prenons soin de donner l'intimité tant souhaitée à notre client lors de son voyage en Inde. Nos efforts sont concentrés sur la fourniture de services conviviaux 24 heures sur 24, 365 jours par an.",
        "it": "Il noleggio auto in India può essere un compito senza problemi con noi, offrendo ai nostri clienti un'ampia gamma di auto e pullman di lusso per offrire loro il meglio dei comfort insieme alle prenotazioni alberghiere, per concludere un'esperienza di viaggio complessivamente felice. Ci prendiamo cura di dare la privacy tanto desiderata al nostro cliente durante il viaggio in India. I nostri sforzi sono focalizzati sulla fornitura di servizi di assistenza clienti 24 ore su 24, 365 giorni all'anno.",
        "de": "Die Autovermietung in Indien kann mit uns eine problemlose Aufgabe sein, da wir unseren Kunden eine große Auswahl an Autos und Luxusbussen anbieten, um ihnen den besten Komfort sowie Hotelreservierungen zu bieten, um ein rundum glückliches Reiseerlebnis abzuschließen. Wir achten darauf, unseren Kunden auf Reisen in Indien die begehrte Privatsphäre zu geben. Unsere Bemühungen konzentrieren sich darauf, 365 Tage im Jahr rund um die Uhr kundenfreundlichen Service zu bieten."
    },
    "heading2": {
        "en": "Type of Transportation:",
        "es": "Tipo de Transporte:",
        "fr": "Type de transport:",
        "it": "Tipo di trasporto:",
        "de": "Art des Transports:"
    },
    "subheading": {
        "en": "Visit and enjoy India, with our specialized travel packages, economical car rental, discounts for corporate clients and organizing meetings & conferences. Cars are available according the number of people or as required according your choice and budget you can select by yourself. We can provide you following transportation:-",
        "es": "Visite y disfrute India, con nuestros paquetes de viajes especializados, renta de autos económicos, descuentos para clientes corporativos y organización de reuniones y conferencias. Los coches están disponibles según el número de personas o según sea necesario según su elección y presupuesto que puede seleccionar por sí mismo. Podemos proporcionarle el siguiente transporte:-",
        "fr": "Visitez et profitez de l'Inde, avec nos forfaits de voyage spécialisés, la location de voiture économique, des réductions pour les entreprises clientes et l'organisation de réunions et de conférences. Les voitures sont disponibles selon le nombre de personnes ou selon vos besoins selon votre choix et votre budget que vous pouvez sélectionner vous-même. Nous pouvons vous fournir le transport suivant : -",
        "it": "Visita e goditi l'India, con i nostri pacchetti di viaggio specializzati, noleggio auto economico, sconti per clienti aziendali e organizzazione di riunioni e conferenze. Le auto sono disponibili in base al numero di persone o secondo necessità in base alla vostra scelta e al budget che potete selezionare da soli. Possiamo fornirti il seguente trasporto: -",
        "de": "Besuchen und genießen Sie Indien mit unseren spezialisierten Reisepaketen, günstiger Autovermietung, Rabatten für Firmenkunden und der Organisation von Tagungen und Konferenzen. Autos stehen je nach Anzahl der Personen oder nach Bedarf nach Ihrer Wahl und Ihrem Budget zur Verfügung, das Sie selbst auswählen können. Wir können Ihnen folgenden Transport anbieten: -",
    },
    "heading3": {
        "en": "The Car rental price will Include following things:-",
        "es": "El precio del alquiler del coche incluirá lo siguiente:-",
        "fr": "Le prix de la location de voiture comprendra les éléments suivants : -",
        "it": "Il prezzo del noleggio auto includerà le seguenti cose:-",
        "de": "Der Mietwagenpreis beinhaltet folgende Dinge:-"
    },
    "heading4": {
        "en": "Car Rental Form",
        "es": "Formulario de alquiler de coches",
        "fr": "Formulaire de location de voiture",
        "it": "Modulo di noleggio auto",
        "de": "Mietwagenformular"
    },
    "input1": {
        "en": "Name :",
        "es": "Nombre :",
        "fr": "Nom :",
        "it": "Nome :",
        "de": "Name :"
    },
    "input2": {
        "en": "Address :",
        "es": "Dirección :",
        "fr": "Adresse :",
        "it": "Indirizzo :",
        "de": "Adresse :"
    },
    "input3": {
        "en": "Code :",
        "es": "Código :",
        "fr": "Code :",
        "it": "Codice :",
        "de": "Code :"
    },
    "input4": {
        "en": "Phone Number :",
        "es": "Número de teléfono :",
        "fr": "Numéro de téléphone :",
        "it": "Numero di telefono :",
        "de": "Telefonnummer :"
    },
    "input5": {
        "en": "Email :",
        "es": "Correo electrónico :",
        "fr": "E-mail :",
        "it": "E-mail :",
        "de": "Email :"
    },
    "input6": {
        "en": "Type of Car :",
        "es": "Tipo de coche :",
        "fr": "Type de voiture :",
        "it": "Tipo di auto :",
        "de": "Art von Auto :"
    },
    "input7": {
        "en": "Number Of Days :",
        "es": "Número de días :",
        "fr": "Nombre de jours :",
        "it": "Numero di giorni :",
        "de": "Anzahl der Tage :"
    },
    "input8": {
        "en": "Date Of Journey :",
        "es": "Fecha de viaje :",
        "fr": "Date du voyage :",
        "it": "Data del viaggio :",
        "de": "Datum der Reise :"
    },
    "button1": {
        "en": "Submit",
        "es": "Enviar",
        "fr": "Soumettre",
        "it": "Invia",
        "de": "Einreichen"
    },
    "button2": {
        "en": "Reset",
        "es": "Reiniciar",
        "fr": "Réinitialiser",
        "it": "Ripristina",
        "de": "Zurücksetzen"
    }
}