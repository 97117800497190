import React, { useLayoutEffect, useContext } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import { Context } from '../Store';

import {
    Home,
    ContactUs,
    AboutUs,
    OurService,
    TermsAndCondition,
    TipsAndTravelling,
    ReservationForm,
    RentACar,
    TopDestinations,
    TopDestinationDetails,
    PopularPackages,
    FairAndFestivals,
    FairAndFestivalsDetails,
    NationalLife,
    NationalLifeDetails,
    Hotels,
    HotelsDetails,
    CurrencyConverter,
    DistanceCalculator,
    PackageDetails,
    Search,
    PrivateDayTour,
} from "../containers";

function Routes() {
    const [state] = useContext(Context);

    const { pathname, hash, key } = useLocation();

    useLayoutEffect(() => {
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]);

    return (
        <Switch>
            <Route exact path="/">
                <Home lang={state.language} />
            </Route>

            <Route exact path="/contact-us">
                <ContactUs lang={state.language} />
            </Route>
            <Route exact path="/about-us">
                <AboutUs lang={state.language} />
            </Route>
            <Route exact path="/our-service">
                <OurService lang={state.language} />
            </Route>
            <Route exact path="/terms-and-condition">
                <TermsAndCondition />
            </Route>
            <Route exact path="/tips-and-travelling">
                <TipsAndTravelling />
            </Route>
            <Route exact path="/reservation-form">
                <ReservationForm />
            </Route>
            <Route exact path="/car-rental">
                <RentACar lang={state.language} />
            </Route>
            <Route exact path="/top-destination/:id">
                <TopDestinations />
            </Route>
            <Route exact path="/top-destination/details/:name/:id">
                <TopDestinationDetails />
            </Route>
            <Route exact path="/tour-package">
                <PopularPackages />
            </Route>
            <Route exact path="/fair-and-festivals">
                <FairAndFestivals lang={state.language} />
            </Route>
            <Route exact path="/fair-and-festivals/details/:id">
                <FairAndFestivalsDetails lang={state.language} />
            </Route>
            <Route exact path="/national-life">
                <NationalLife />
            </Route>
            <Route exact path="/national-life/details/:id">
                <NationalLifeDetails />
            </Route>
            <Route exact path="/hotels">
                <Hotels />
            </Route>
            <Route exact path="/hotels/details/:id">
                <HotelsDetails />
            </Route>
            <Route exact path="/currency-converter">
                <CurrencyConverter lang={state.language} />
            </Route>
            <Route exact path="/distance-calculator">
                <DistanceCalculator lang={state.language} />
            </Route>
            <Route exact path="/package-details/:id">
                <PackageDetails />
            </Route>
            <Route exact path="/search">
                <Search />
            </Route>
            <Route exact path="/private-day-tour">
                <PrivateDayTour />
            </Route>
        </Switch>
    );
}

export default Routes;
