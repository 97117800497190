import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./styles.module.scss";
import { reviews } from "../../JsonData/Reviews";
import starFull from "../../Assets/Svg/fullStar.svg";
// import halfStar from "../../Assets/Svg/halfStar.svg";
import quotes from "../../Assets/Svg/quotes.svg";
import "./index.scss";

function ReviewsCarousel() {
    const options = {
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        nav: false,
        responsive: {
            0: {
                items: 1,
            },
            900: {
                items: 2,
            },
            1200: {
                items: 3,
            },
        },
    };

    return (
        <div className="mt-16">
            <OwlCarousel className="owl-theme relative MySlider" {...options}>
                {" "}
                {reviews.map((item, index) => (
                    <a href={item.url} target="_blank">
                        <div
                            className={`item ${styles.reviewsContainer} `}
                            key={index}
                            style={{height: '13vh'}}
                        >
                            {" "}
                            <img
                                src={quotes}
                                alt="quotes"
                                className={`absolute top-5 left-5 ${styles.quote}`}
                                style={{ width: "50px !important" }}
                            />
                            <div className="pl-16 pr-4  pt-10 pb-5">
                                <p
                                    className={`font-regular text-secondaryColor  ${styles.header}`}
                                >
                                    {item.review}
                                </p>
                                <div className="flex justify-start items-center mt-4">
                                    {Array.from(Array(item.rating)).map((item, i) =>
                                        <img
                                            key={index+i}
                                            src={starFull}
                                            alt="full star"
                                            className={`${styles.star}`}
                                        />
                                    )}
                                </div>
                                <div className="flex justify-start items-center mt-5 mb-5">
                                    <div>
                                        <img src={item.profilePicture} alt="" className={styles.avatar} />
                                    </div>
                                    <div className={styles.details}>
                                        <h5 className="text-secondaryColor font-medium ">
                                            {item.user}
                                        </h5>
                                        <p className="text-subheader text-base">
                                            {item.location}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                ))}
            </OwlCarousel>
        </div>
    );
}

export default ReviewsCarousel;
