import team from "../Assets/Image/team.png";
import airplane from "../Assets/Image/airplane.png";
import traveller from "../Assets/Image/traveller.png";
export const aboutUsText = [
    {
        img: traveller,
        details:
            {
                "en": "Being a Global India Tours Operator, we understand that our customers just want one thing, the promised tour entertainment... without any kind of hassles. And that's exactly what we deliver. At Global India Tours you will find our well researched tour schedules and programs include every bit of comfort that a traveler might look for.",
                "es": "Al ser un operador de 'Global India Tours', entendemos que nuestros clientes solo quieren una cosa, el entretenimiento prometido en el tour... sin ningún tipo de molestia. Y eso es exactamente lo que entregamos. En 'Global India Tours' encontrará que nuestros horarios y programas bien investigados incluyen todas las comodidades que un viajero podría buscar.",
                "fr": "En tant qu'opérateur 'Global India Tours', nous comprenons que nos clients ne veulent qu'une chose, le divertissement promis... sans aucune sorte de tracas. Et c'est exactement ce que nous livrons. Chez 'Global India Tours', vous trouverez nos horaires et programmes de visites bien documentés qui incluent tout le confort qu'un voyageur pourrait rechercher.",
                "it": "Essendo un operatore di 'Global India Tours', capiamo che i nostri clienti vogliono solo una cosa, l'intrattenimento del tour promesso... senza alcun tipo di seccatura. Ed è esattamente ciò che offriamo. A 'Global India Tours' troverai i nostri programmi e programmi di tour ben studiati che includono ogni comfort che un viaggiatore potrebbe cercare.",
                "de": "Als „Global India Tours“-Veranstalter verstehen wir, dass unsere Kunden nur eines wollen, nämlich die versprochene Tour-Unterhaltung … ohne jede Art von Ärger. Und genau das liefern wir. Bei 'Global India Tours' finden Sie unsere gut recherchierten Tourpläne und Programme, die jeden Komfort beinhalten, den ein Reisender sucht."
            }
    },
    {
        img: team,
        details:
            {
                "en": "Our extensive network of field staff, spread across all corners of the subcontinent ensure that our clients' trips are absolutely hassle-free which help them to have a hearty experience of the rich cultural heritage and geographical diversity of this magical land called India.",
                "es": "Nuestra extensa red de personal de campo, distribuida en todos los rincones del subcontinente, asegura que los viajes de nuestros clientes sean absolutamente libres de problemas, lo que les ayuda a tener una experiencia abundante del rico patrimonio cultural y la diversidad geográfica de esta tierra mágica llamada India.",
                "fr": "Notre vaste réseau de personnel de terrain, réparti dans tous les coins du sous-continent, garantit que les voyages de nos clients sont absolument sans tracas, ce qui les aide à vivre une expérience chaleureuse du riche patrimoine culturel et de la diversité géographique de cette terre magique appelée Inde.",
                "it": "La nostra vasta rete di personale sul campo, sparsa in tutti gli angoli del subcontinente, assicura che i viaggi dei nostri clienti siano assolutamente senza problemi, il che li aiuta a vivere un'esperienza sincera del ricco patrimonio culturale e della diversità geografica di questa terra magica chiamata India.",
                "de": "Unser umfangreiches Netzwerk von Außendienstmitarbeitern, die über alle Ecken des Subkontinents verteilt sind, stellt sicher, dass die Reisen unserer Kunden absolut problemlos sind, was ihnen hilft, das reiche kulturelle Erbe und die geografische Vielfalt dieses magischen Landes namens Indien zu erleben."
            }        
    },
    {
        img: airplane,
        details:
            {
                "en": "The only things you would find missing from our packages are unscheduled delays, mismanaged services, unplanned halts, and unpaid for hindrances. Our unending clientele and an ever-growing list of satisfied tourists are ready facts to support our stand.",
                "es": "Lo único que le faltaría a nuestros paquetes son retrasos no programados, servicios mal administrados, paradas no planificadas y obstáculos no pagados. Nuestra clientela interminable y una lista cada vez mayor de turistas satisfechos son hechos listos para respaldar nuestro stand.",
                "fr": "Les seules choses qui manquent à nos forfaits sont les retards imprévus, les services mal gérés, les arrêts imprévus et les entraves non payées. Notre clientèle sans fin et une liste toujours croissante de touristes satisfaits sont des faits prêts à soutenir notre stand.",
                "it": "Le uniche cose che ti mancano nei nostri pacchetti sono ritardi non programmati, servizi mal gestiti, interruzioni non pianificate e ostacoli non pagati. La nostra clientela infinita e un elenco sempre più ampio di turisti soddisfatti sono fatti pronti a supportare il nostro stand.",
                "de": "Die einzigen Dinge, die Sie in unseren Paketen vermissen würden, sind ungeplante Verzögerungen, schlecht verwaltete Dienste, ungeplante Unterbrechungen und unbezahlte Hindernisse. Unsere endlose Kundschaft und eine ständig wachsende Liste zufriedener Touristen sind bereite Fakten, um unseren Stand zu unterstützen."
            }
    },
];
