export default {
    "title": {
        "en": "Fair and Festivals",
        "es": "Feria y Festivales",
        "fr": "Foire et Fêtes",
        "it": "Fiera e Festival",
        "de": "Messe und Festivals"
    },
    "Amount": {
        "en": "Amount",
        "es": "Monto",
        "fr": "Montante",
        "it": "Quantità",
        "de": "Menge"
    },
    "Currency": {
        "en": "Currency",
        "es": "Divisa",
        "fr": "Devise",
        "it": "Moneta",
        "de": "Währung"
    },
    "button": {
        "en": "Currency Converter",
        "es": "Convertidor de moneda",
        "fr": "Convertisseur de devises",
        "it": "Convertitore di valuta",
        "de": "Währungsrechner"
    },
    "or": {
        "en": "OR",
        "es": "O",
        "fr": "OU",
        "it": "O",
        "de": "ODER"
    },
}