import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ParentContainer from "../../../Components/ParentContainer/ParentContainer";
import NavBar from "../../../Components/Navbar/NavBar";
import logo2 from "../../../Assets/Image/logo2.png";
import Subheader from "../../../Components/SubHeader/Subheader";
import { hotels } from "../../../JsonData/Hotels";
import styles from "./styles.module.scss";
import Heading2 from "../../../Components/Heading2/Heading2";
import { Grid } from "@mui/material";
import Footer from "../../../Components/Footer/Footer";
import MobileFooter from "../../../Components/Footer/MobileFooter";
import FloatingButton from "../../../Components/FloatingButton/FloatingButton";

const GridComponent = ({ item, size, history }) => {
    return (
        <Grid item md={size} className="w-full">
            <div className={styles.banner2}>
                <div
                    className={`${styles.innerBanner} relative flex justify-center items-center`}
                    style={{
                        backgroundImage: `url(${item})`,
                    }}
                >
                    <p className="text-white text-2xl font-bold hidden">
                        {item?.title}
                    </p>
                </div>
            </div>
        </Grid>
    );
};

function HotelsDetails() {
    const [fairFestival, setFairFestival] = useState({});

    let { id } = useParams();

    useEffect(() => {
        const res = hotels?.filter(
            (item) => item.id.toString() === id.toString()
        );
        setFairFestival(res[0]);
    }, [id]);

    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton />
            <ParentContainer>
                <div className={styles.banner}>
                    <div
                        className={`${styles.innerBanner} mt-32 flex justify-center items-center`}
                        style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${fairFestival?.banner})`,
                        }}
                    >
                        <h1 className="font-bold text-white text-5xl sm:text-3xl 2xs:text-3xl">
                            {fairFestival?.title}
                        </h1>
                    </div>
                </div>
            </ParentContainer>
            {fairFestival?.places?.map((item, index) =>
                index % 2 !== 0 ? (
                    <div className="bg-greyBackgorund" key={index}>
                        <ParentContainer key={index}>
                            <div className="py-20 sm:py-10 2xs:py-10">
                                <Heading2
                                    isVerticalLine={true}
                                    heading={item?.title}
                                    blueText={true}
                                />
                                <Subheader>{item?.description}</Subheader>
                                {item?.content.map((item2, index2) => (
                                    <>
                                        <Subheader key={index2}>
                                            <span className="font-medium">
                                                {item2?.title + " :"}{" "}
                                            </span>
                                            {item2?.desc}
                                        </Subheader>
                                    </>
                                ))}
                                <div className="mt-10"></div>
                                <Grid container spacing={2}>
                                <Grid item md={4} className="w-full">
                                <div className={styles.banner2}>
                                    <div
                                        className={`${styles.innerBanner} relative flex justify-center items-center`}
                                        style={{
                                            backgroundImage: `url(${item?.img1})`,
                                        }}
                                    >
                                        <p className="text-white text-2xl font-bold ">
                                            {item?.title}
                                        </p>
                                    </div>
                                </div>
                            </Grid>
                                {item?.img.map((item3, index3) => (
                                    <GridComponent
                                        item={item3.img}
                                        size={4}
                                    />
                                ))}

                                </Grid>
                            </div>
                        </ParentContainer>
                    </div>
                ) : (
                    <ParentContainer key={index}>
                        <Heading2
                            isVerticalLine={true}
                            heading={item?.title}
                            blueText={true}
                        />
                        <Subheader>{item?.description}</Subheader>
                        {item?.content.map((item2, index2) => (
                            <>
                                <Subheader key={index2}>
                                    <span className="font-medium">
                                        {item2?.title + " :"}{" "}
                                    </span>
                                    {item2?.desc}
                                </Subheader>
                            </>
                        ))}
                        <div className="mt-10"></div>
                        <Grid container spacing={2}>
                            <Grid item md={4} className="w-full">
                                <div className={styles.banner2}>
                                    <div
                                        className={`${styles.innerBanner} relative flex justify-center items-center`}
                                        style={{
                                            backgroundImage: `url(${item?.img1})`,
                                        }}
                                    >
                                        <p className="text-white text-2xl font-bold ">
                                            {item?.title}
                                        </p>
                                    </div>
                                </div>
                            </Grid>
                            {item?.img.map((item3, index3) => (
                                    <GridComponent
                                        item={item3.img}
                                        size={4}
                                    />
                                ))}
                        </Grid>
                    </ParentContainer>
                )
            )}

            <div className="mb-20"></div>
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default HotelsDetails;
