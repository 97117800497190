import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ParentContainer from "../../../Components/ParentContainer/ParentContainer";
import styles from "./styles.module.scss";
import NavBar from "../../../Components/Navbar/NavBar";
import logo2 from "../../../Assets/Image/logo2.png";
import FilledBackground from "../../../Components/FilledBackground/FilledBackground";
import Subheader from "../../../Components/SubHeader/Subheader";
import { fairAndFestivalsDetailsList } from "../../../JsonData/FairAndFestival";
import Heading2 from "../../../Components/Heading2/Heading2";
import { Grid } from "@mui/material";
import Footer from "../../../Components/Footer/Footer";
import MobileFooter from "../../../Components/Footer/MobileFooter";
import FloatingButton from "../../../Components/FloatingButton/FloatingButton";
const HeaderBox = ({ label, title }) => {
    return (
        <div className="bg-ternaryBlue rounded-md p-5 w-full m-2 sm:mx-0 sm:mb-5 2xs:mx-0 2xs:mb-5">
            <p className="text-white font-medium text-xl">{label}</p>
            <p className="text-white font-medium text-2xl">{title}</p>
        </div>
    );
};

function FairAndFestivalsDetails({lang}) {
    const [fairFestival, setFairFestival] = useState({});

    let { id } = useParams();

    useEffect(() => {
        const res = fairAndFestivalsDetailsList?.filter(
            (item) => item.id.toString() === id.toString()
        );
        setFairFestival(res[0]);
    }, [id]);

    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton/>
            <ParentContainer>
                <div className={styles.banner}>
                    <div
                        className={`${styles.innerBanner} mt-32 flex justify-center items-center`}
                        style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${fairFestival?.banner})`,
                        }}
                    >
                        <h1 className="font-bold text-white text-5xl sm:text-3xl 2xs:text-3xl">
                            {fairFestival && fairFestival.title && fairFestival.title[lang]}
                        </h1>
                    </div>
                </div>
            </ParentContainer>

            <FilledBackground>
                <div className="flex w-full sm:block 2xs:block">
                    <HeaderBox
                        label={fairFestival && fairFestival.label1 && fairFestival.label1[lang]}
                        title={fairFestival?.time?.time}
                    />
                    <HeaderBox
                        label={fairFestival && fairFestival.label2 && fairFestival.label2[lang]}
                        title={fairFestival?.time?.place}
                    />
                    <HeaderBox
                        label={fairFestival && fairFestival.label3 && fairFestival.label3[lang]}
                        title={fairFestival?.time?.duration[lang]}
                    />
                </div>
            </FilledBackground>
            <ParentContainer>
                {fairFestival &&  fairFestival.description && fairFestival.description[lang].map((item, index) => (
                    <Subheader key={index}>{item}</Subheader>
                ))}
            </ParentContainer>
            {fairFestival?.about && (
                <div className="bg-greyBackgorund">
                    <ParentContainer>
                        <div className="py-16">
                            <Heading2
                                isVerticalLine={true}
                                heading={fairFestival && fairFestival.about && fairFestival.about.labelAbout && fairFestival.about.labelAbout[lang]}
                                blueText={true}
                            />
                            <Grid container spacing={4}>
                                <Grid item md={7}>
                                    {fairFestival && fairFestival.about && fairFestival.about.desctiption && fairFestival.about.desctiption[lang].map(
                                        (item, index) => (
                                            <Subheader key={index}>
                                                {item}
                                            </Subheader>
                                        )
                                    )}
                                </Grid>
                                <Grid item md={5}>
                                    <Subheader>
                                        <img
                                            src={fairFestival?.about?.img}
                                            alt="goa"
                                            height={"auto"}
                                            width="100%"
                                        />
                                    </Subheader>
                                </Grid>
                            </Grid>
                        </div>
                    </ParentContainer>
                </div>
            )}

            {fairFestival?.attractions && (
                <ParentContainer>
                    <div>
                        <Heading2
                            isVerticalLine={true}
                            heading={fairFestival && fairFestival.attractions && fairFestival.attractions.labelAttractions && fairFestival.attractions.labelAttractions[lang] }
                            blueText={true}
                        />
                        <div className={styles.banner2}>
                            <div
                                className={styles.innerBanner}
                                style={{
                                    height: "100%",
                                    marginTop: "40px",
                                    backgroundImage: `url("${fairFestival?.attractions?.banner}")`,
                                }}
                            ></div>
                        </div>
                        <div>
                            {fairFestival?.attractions?.places?.map(
                                (item, index) => (
                                    <Subheader key={index}>
                                        <h1 className="text-2xl font-medium text-primaryColor mb-2">
                                            {item && item.title && item.title[lang]}
                                        </h1>
                                        {item && item.description && item.description[lang]}
                                    </Subheader>
                                )
                            )}
                        </div>
                    </div>
                </ParentContainer>
            )}

            {fairFestival?.attractions && (
                <div className="bg-greyBackgorund">
                    <ParentContainer>
                        <div className="py-20">
                            <Heading2
                                isVerticalLine={true}
                                heading={"Places to Visit Around Bundi"}
                                blueText={true}
                            />

                            {fairFestival?.attractions?.placesToVisit?.map(
                                (item, index) => (
                                    <Subheader key={index}>
                                        <h1 className="text-2xl font-medium text-primaryColor mb-2">
                                            {item && item.titlePTV1 && item.titlePTV1[lang]}
                                        </h1>
                                        {item && item.descriptionPTV1 && item.descriptionPTV1[lang]}
                                    </Subheader>
                                )
                            )}
                        </div>
                    </ParentContainer>
                </div>
            )}
            <div className="my-10"></div>
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default FairAndFestivalsDetails;
