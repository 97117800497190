import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ParentContainer from "../../../Components/ParentContainer/ParentContainer";
import NavBar from "../../../Components/Navbar/NavBar";
import logo2 from "../../../Assets/Image/logo2.png";
import Subheader from "../../../Components/SubHeader/Subheader";
import { nationalLifeDetails } from "../../../JsonData/NationalLife";
import styles from "./styles.module.scss";
import Heading2 from "../../../Components/Heading2/Heading2";
import { Grid } from "@mui/material";
import Footer from "../../../Components/Footer/Footer";
import MobileFooter from "../../../Components/Footer/MobileFooter";
import FloatingButton from "../../../Components/FloatingButton/FloatingButton";
function NationalLifeDetails() {
    const [fairFestival, setFairFestival] = useState({});

    let { id } = useParams();

    useEffect(() => {
        const res = nationalLifeDetails?.filter(
            (item) => item.id.toString() === id.toString()
        );
        setFairFestival(res[0]);
    }, [id]);
    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton/>
            <ParentContainer>
                <div className={styles.banner}>
                    <div
                        className={`${styles.innerBanner} mt-32 flex justify-center items-center`}
                        style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${fairFestival?.banner})`,
                        }}
                    >
                        <h1 className="font-bold text-white text-5xl sm:text-3xl 2xs:text-3xl">
                            {fairFestival?.title}
                        </h1>
                    </div>
                </div>
            </ParentContainer>
            <ParentContainer>
                {fairFestival?.description?.map((item, index) => (
                    <Subheader key={index}>{item}</Subheader>
                ))}
            </ParentContainer>
            <div className="bg-greyBackgorund">
                <ParentContainer>
                    <div className="py-20">
                        <Heading2
                            isVerticalLine={true}
                            heading={fairFestival?.geoGraphy?.title}
                            blueText={true}
                        />

                        {fairFestival?.geoGraphy?.content?.map((item2, index2) => (
                            <Subheader key={index2}>{item2}</Subheader>
                        ))}
                    </div>
                </ParentContainer>
            </div>

            {fairFestival?.additionalInfo?.map((item, index) =>
                index % 2 === 0 ? (
                    <ParentContainer>
                        <Heading2
                            isVerticalLine={true}
                            heading={item?.title}
                            blueText={true}
                        />
                        <Grid container spacing={4}>
                            <Grid item md={7}>
                                <Subheader>{item?.mainContent}</Subheader>
                            </Grid>
                            <Grid item md={5}>
                                <Subheader>
                                    <img
                                        src={item?.img}
                                        alt="goa"
                                        height={"auto"}
                                        width="100%"
                                    />
                                </Subheader>
                            </Grid>
                        </Grid>
                        {item?.description.map((item2, index2) => (
                            <Subheader key={index2}>{item2}</Subheader>
                        ))}
                    </ParentContainer>
                ) : (
                    <div className="bg-greyBackgorund" key={index}>
                        <ParentContainer key={index}>
                            <div className="py-20 sm:py-10 2xs:py-10">
                                <Heading2
                                    isVerticalLine={true}
                                    heading={item?.title}
                                    blueText={true}
                                />
                                <Grid container spacing={4}>
                                    <Grid item md={5}>
                                        <Subheader>
                                            <img
                                                src={item?.img}
                                                alt="goa"
                                                height={"auto"}
                                                width="100%"
                                            />
                                        </Subheader>
                                    </Grid>
                                    <Grid item md={7}>
                                        <Subheader>
                                            {item?.mainContent}
                                        </Subheader>
                                    </Grid>
                                </Grid>
                                {item?.description.map((item2, index2) => (
                                    <Subheader key={index2}>{item2}</Subheader>
                                ))}
                            </div>
                        </ParentContainer>
                    </div>
                )
            )}
            <div className="mb-20"></div>
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default NationalLifeDetails;
