export const privateDayTour = [
    {
        "id": 1,
        "titleName": "Private Day Tour",
        "Banner": "jaipur_tour.jpeg",
        "Destinations": "We are offers private day tour in the Jaipur City with car & driver, guide.  We suggest you try this tour of all the must-see places. This tour covers – Amber Fort with Elephant ride, Nahargarh Fort, City Palace, Hawa Mahal, Jantar Mantar, Albert Hall, Birla Temple, Local Market",
        "Day": [
            {
                "id": 1,
                "title": "08:00",
                "Description": "Our driver and guide will meet you at hotel and procced for the day tour of Jaipur",

            },
            {
                "id": 2,
                "title": "09:00",
                "Description": "Visit Amber Fort with Elephant ride. The fort situated on hill 12Kms from the city",

            },
            {
                "id": 3,
                "title": "10:30",
                "Description": "Visit Nahargarh Fort- The smallest of the three forts, notable primarily for excellent views over Man Sagar lake and the vast sprawl of Jaipur.",

            },
            {
                "id": 4,
                "title": "11:00",
                "Description": "Visit City Palace - The City Palace is an imposing blend of traditional Rajput and Mughal architecture",

            },
            {
                "id": 5,
                "title": "12:00",
                "Description": "Jantar Mantar- The Jantar Mantar is the largest of five astronomical observatory build by Maharaja Jai Singh during the period 1727-1734 in north India. It is located very close to the City Palace.",

            },
            {
                "id": 6,
                "title": "13:00",
                "Description": "Hawa Mahal",

            },
            {
                "id": 7,
                "title": "14:00",
                "Description": "Lunch at Indina cusion",

            },
            {
                "id": 8,
                "title": "15:00",
                "Description": "Visit Albert Hall Museam",

            },
            {
                "id": 9,
                "title": "16:00",
                "Description": "Visit Birla Temple",

            },
            {
                "id": 10,
                "title": "17:00",
                "Description": "Local Market of Jaipur",

            },
            {
                "id": 11,
                "title": "19:00",
                "Description": "Drop at hotel",

            },
        ]
    }
]