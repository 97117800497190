import React from "react";
import styles from "./style.module.scss";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import call from "../../Assets/Svg/calls.svg";
import location from "../../Assets/Svg/location.svg";
import email from "../../Assets/Svg/email.svg";
import facebook from "../../Assets/Svg/facebook.svg";
import instagram from "../../Assets/Svg/instagram.svg";
import IconComponent from "./IconComponent";
import GridComponent from "./GridComponent";
import {
    footerLinks,
    footerLinks2,
    bottomLinks,
} from "../../JsonData/FooterLinks";

function Footer() {
    return (
        <div
            className={`bg-footerBackground pt-28 pl-28 pb-0 pr-28 lg:block md:hide sm:hide 2xs:hide ${styles.desktopFooter}`}
        >
            <Grid container spacing={6}>
                <Grid item md={4}>
                    <div>
                        <h4 className=" text-3xl text-white font-semibold">
                            Contact Us
                        </h4>
                        <ul className={styles.footerLinks}>
                            <a href="https://www.google.com/maps/place/95,+Shri+Ram+Nagar+Extension,+Brij+Mandal+Colony,+Jhotwara,+Jaipur,+Rajasthan+302012/@26.9386095,75.7358543,17z/data=!3m1!4b1!4m5!3m4!1s0x396db33e352fc3fb:0xce58653d6cfc6a6c!8m2!3d26.9386095!4d75.7358543" target="_blank">
                                <GridComponent
                                    img={location}
                                    alt="location"
                                    label={
                                        "95, Shri Ram Nagar 'Extn', Jhotwara, Jaipur (Rajasthan)"
                                    }
                                />
                            </a>
                            <a href="mailto:global_indiatours@yahoo.in">
                                <GridComponent
                                    img={email}
                                    alt="email"
                                    label={"global_indiatours@yahoo.in"}
                                />
                            </a>
                            <a href="tel:+919829136255">
                                <GridComponent
                                    img={call}
                                    alt="call"
                                    label={"+91-98291-36255"}
                                />
                            </a>
                        </ul>

                        <Grid container spacing={3}>
                            <IconComponent img={facebook} alt="facebook" />
                            <IconComponent img={instagram} alt="instagram" />
                            <IconComponent img={email} alt="email" />
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <h4 className="text-3xl text-white font-semibold">
                        Tour Packages
                    </h4>
                    <div>
                        <ul className={styles.footerLinks}>
                            {footerLinks.map((item, index) => (
                                <Link key={index} to={item.path}>
                                    <li>{item.title}</li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                </Grid>
                <Grid item md={4}>
                    <h4 className="text-3xl text-white font-semibold ">
                        Tourist Information
                    </h4>
                    <div>
                        <ul className={styles.footerLinks}>
                            {footerLinks2.map((item, index) => (
                                <Link key={index} to={item.path}>
                                    <li>{item.title}</li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                </Grid>
            </Grid>

            <div className="mt-16">
                <div className={styles.line}></div>
                <div className="flex flex-wrap justify-between items-center w-full text-subheader font-semibold mt-7">
                    {bottomLinks.map((item, index) => (
                        <Link key={index} to={item.path}>
                            <h4>{item.title}</h4>
                        </Link>
                    ))}
                </div>
            </div>
            <div className="mt-16 pb-5 flex justify-center items-center">
                <h3 className="text-primaryColor font-medium text-md">
                    Copyright © {new Date().getUTCFullYear()} Global India Tours
                </h3>
                <div
                    className="bg-primaryBackground  h-3 mx-5"
                    style={{ width: "1px" }}
                ></div>
                <h3 className="text-primaryColor font-medium text-md">
                    <a
                        href="https://bytesalgo.com"
                        rel="noreferrer"
                        target="_blank"
                    >
                        Powered by BytesAlgo
                    </a>
                </h3>
                <div
                    className="bg-primaryBackground  h-3 mx-5"
                    style={{ width: "1px" }}
                ></div>{" "}
                <h3 className="text-primaryColor font-medium text-md">
                    All Right Reserved
                </h3>
            </div>
        </div>
    );
}

export default Footer;
