import bandhavBanner from "../Assets/Image/NationalLife/bandhavBanner.jpg";
import jimBanner from "../Assets/Image/NationalLife/jimBanner.jpg";
import kanhaBanner from "../Assets/Image/NationalLife/kanhaBanner.jpg";
import keoladeoBanner from "../Assets/Image/NationalLife/Keoladeo_National_Park.jpeg";
import periyarBanner from "../Assets/Image/NationalLife/periyarBanner.jpg";
import rantBanner from "../Assets/Image/NationalLife/rantBanner.jpg";
import bandhav1 from "../Assets/Image/NationalLife/bandhav1.jpg";
import bandhav4 from "../Assets/Image/NationalLife/bandhav4.jpg";
import bandhav from "../Assets/Image/NationalLife/bandhav.jpg";
import bandhav7 from "../Assets/Image/NationalLife/bandhav7.jpg";
import jim1 from "../Assets/Image/NationalLife/jim1.jpg";
import jim2 from "../Assets/Image/NationalLife/jim2.jpg";
import jim3 from "../Assets/Image/NationalLife/jim3.jpg";
import jim4 from "../Assets/Image/NationalLife/jim4.jpg";
import k1 from "../Assets/Image/NationalLife/k1.jpg";
import k2 from "../Assets/Image/NationalLife/k2.jpg";
import k3 from "../Assets/Image/NationalLife/k3.jpg";
import ke1 from "../Assets/Image/NationalLife/ke1.jpg";
import ke2 from "../Assets/Image/NationalLife/ke2.jpg";
import ke3 from "../Assets/Image/NationalLife/ke3.jpg";
import p1 from "../Assets/Image/NationalLife/p1.jpg";
import p2 from "../Assets/Image/NationalLife/p2.jpg";
import p3 from "../Assets/Image/NationalLife/p3.jpg";
import r1 from "../Assets/Image/NationalLife/r1.jpg";
import r2 from "../Assets/Image/NationalLife/r2.jpg";
import r3 from "../Assets/Image/NationalLife/r3.jpg";

export const nationalLifeList = [
    {
        topPlaces: [
            {
                id: 1,
                title: "Bandhavgarh National Park",
                img: bandhavBanner,
            },
            {
                id: 2,
                title: "Jim Corbett National Park",
                img: jimBanner,
            },
            {
                id: 3,
                title: "Kanha National Park",
                img: kanhaBanner,
            },
        ],
        bottomPlaces: [
            {
                id: 4,
                title: "Keoladeo National Park",
                img: keoladeoBanner,
            },
            {
                id: 5,
                title: "Periyar National Park",
                img: periyarBanner,
            },
            {
                id: 6,
                title: "Ranthambhore National Park",
                img: rantBanner,
            },
        ],
    },
];

export const nationalLifeDetails = [
    {
        id: 1,
        title: "Bandhavgarh National Park",
        banner: bandhavBanner,
        description: [
            "Bandhavgarh National Park is one of the popular national parks in India located in the Umaria district of Madhya Pradesh. Bandhavgarh was declared a national park in 1968 with an area of 105 km². The buffer is spread over the forest divisions of Umaria and Katni and totals 437 km². The park derives its name from the most prominent hillock of the area, which is said to be given by Hindu Lord Rama to his brother Laxman to keep a watch on Lanka (Ceylon). Hence the name Bandhavgarh (Sanskrit: Brother's Fort). This park has a large biodiversity. The density of the tiger population at Bandhavgarh is one of the highest known in India.",
            "The park has a large breeding population of panthers, and various species of deer. Maharaja Martand Singh of Rewa captured a white tiger in this region in 1951. This white tiger, Mohan, is now stuffed and on display in the palace of the Maharajas of Rewa",
        ],
        geoGraphy: {
            title: "Geography",
            table: [
                {
                    title: "Area",
                    value: "437 km²",
                },
                {
                    title: "Elevation",
                    value: "410 m to 810m",
                },
                {
                    title: "Climate -Precipitation",
                    value: "2,800 mm (110.2 in)",
                },
                {
                    title: "Summer",
                    value: "44 °C",
                },
                {
                    title: "Winter",
                    value: "22 °C",
                },
                {
                    title: "Established",
                    value: "1968",
                },
            ],
            content: [
                "Bandhavgarh National Park lies on the extreme north- eastern border of the present state of Madhya Pradesh in India and the northern flanks of the eastern Satpuda Mountain range. The geology is soft feldspathic sandstone with quartzite. The soil is generally sandy to sandy-loam. At least twenty streams rise or flow through the park, amongst the streams Umrar (forming the western boundary) is the largest. The other important streams are Johilla (eastern boundary), Janadh, Charnganga, Damnar, Banbei, Ambanala and Andhyari Jhiria. All these streams eventually flow into the river Son, which is an important southern tributary to the Ganges. At the centre of the Park is the Bandhavgarh hill, rising 811 m above sea level – surrounding it are a large number of smaller hills separated by gently sloping valleys. These valleys end in small, swampy meadows, locally known as 'bohera'. The lowest point in the park is at Tala, 440 m above sea level. The terrain is of great rocky hills rising sharply from the swampy and densely forested valley bottoms. The finest of these hills is Bandhavgarh, sided with great cliffs and eroded rocks.",
                "The park lies within the tropical monsoon climatic zone, characterized by well-defined winters, summers and rains. Average rainfall is 1,173 millimetres (46 in), most of which falls during the monsoons. Some rains result from the cyclonic depressions as well, between the months of November and February",
                "Tourist are restricted to an area of 105 km² of the park, known as the Tala range. However this area is richest in terms of biodiversity, mainly tigers. There are four more ranges in the reserve namely –Magdhi, Kallwah, Khitauli and Panpatha. Together, these five ranges comprise the 'Core' of the Bandhavgarh Tiger Reserve constituting a total area of 694 km². The buffer zone is spread over the forest divisions of Umaria and Katni and totals another 437 km",
            ],
        },
        additionalInfo: [
            {
                img: bandhav,
                title: "Flora",
                mainContent:
                    "The wide valleys along the streams carry long linear grasslands flanked by Sal forests. Rich mixed forests consisting of Sal (shorea rubusta), Saja, Salai, and Dhobin etc. with dense bamboo thickets occur in many places. These together provide Bandhavgarh its rich biodiversity. According to bio-geographic classification, the area under Northern India Moist Deciduous Forests. The vegetation is chiefly of Sal forest in the valleys and on the lower slopes, gradually changing to mixed deciduous forest on the hills and in the hotter drier areas of the park in the south and west.",
                description: [],
            },
            {
                img: bandhav1,
                title: "Fauna",
                mainContent:
                    "With the tiger at the apex of the food chain, it contains 37 species of mammals, more than 250 species of birds, about 70 species of butterflies, a number of reptiles. The richness and tranquility of grasslands invites pairs of Sarus Cranes to breed in the rainy season. One of the biggest attractions of this national park is the tiger(panthera tigris tigris) and its sightings. Bandhavgarh has a very high density of tigers within the folds of its jungles. The 105 km² of park area open for tourist was reported to have 22 Tigers, a density of one tiger for every 4.77 km². (Population estimation exercise 2001). There is a saying about the Park that goes: In any other Park, You are lucky if you see a tiger. In Bandhavgarh, you are unlucky if you don't see (at least) one.",
                description: [
                    "Bandhagarh tiger reserve is densely populated with other species: the gaur, or Indian bison are now extinct or migrated elsewhere; sambar and barking deer are a common sight, and nilgai are to be seen in the open areas of the park. There has been reporting of Indian Wolf (canis lupus indica), hyeana and the caracal the latter being an open country dweller. The tiger reserve abounds with cheetal or the spotted deer (Axis axis) which is the main prey animal of the tiger and the leopard (Panthera pardus).",
                ],
            },
            {
                img: bandhav4,
                title: "Avifauna",
                mainContent:
                    "Plum-headed Parakeet 2. Orange-headed Thrush 3. Brown-headed Barbe 4. Coppersmith Barbet 5. Common Myna 6. Alexandrine Parakeet 7. Indian Grey Hornbill 8. Rock Pigeon 9. House Crow 10. Carrion Crow 11. Little Egret 12. Cattle Egret 13. Great Egret 14. Black Drongo 15. Pond Heron 16. Common Snipe 17. Black-winged Stilt 18. Red-wattled Lapwing 19. Indian Peafowl 20. Greater Coucal 21. Oriental Magpie Robin 22. Indian Roller 23. Indian Robin 24. Eurasian Collared Dove 25. Hoopoe 26. Sirkeer Malkoha 27. Large-billed Crow 28. White-browed Fantail Flycatcher 29. Yellow-crowned Woodpecker 30. Rufous Treepie (Normal And Pallida) 31. Lesser Adjutant Stork 32. Oriental White Eye 33. Olive-backed Pipit 34. Spotted Dove 35. White-throated Kingfisher 36. Red-rumped Swallow 37. Lesser Whistling Teal 38. Common Kingfisher 39. Black Stork",
                description: [
                    "40. Green Bee-Eater 41. Greater Racket-tailed Drongo 42. Red-vented Bulbul 43. Long-billed Vulture 44. Grey Capped Pigmy Woodpecker 45. Chestnut-shouldered Petronia 46. Crested Serpent Eagle 47. Black Redstart 48. Brahminy Starling 49. Brown Fish Owl 50. Yellow-footed Green Pigeon 51. Malabar Pied Hornbill 52. Common Kestrel 53. White-throated Fantail Flycatcher 54. Rufous Woodpecker 55. Sapphire Flycatcher 56. Changeable Hawk Eagle (Cirrhatus) 57. Oriental Turtle Dove 58. White-rumped Vulture 59. Lesser Kestrel 60. Large Cuckooshrike 61. Pied Bushchat 62. Black-winged Cuckooshrike 63. Black-rumped Flameback Woodpecker 64. House Sparrow 65. Golden Oriole 66. Rose-ringed Parakeet 67. Paddyfield Pipit 68. Dusky Crag Martin 69. Long-tailed Shrike 70. Black Ibis 71. White-necked Stork",
                    "72. Purple Sunbird 73. Giant Leafbird 74. Tickell's Flowerpecker 75. Little Cormorant 76. Little Brown Dove 77. White-tailed Swallow 78. Jungle Babbler 79. Shikra 80. Jungle Myna 81. Common Tailorbird 82. Red Collared Dove 83. Red-necked Vulture 84. Painted Francolin 85. Eurasian Thick-knee 86. Common Sandpiper 87. Lesser Spotted Eagle 88. Greater Whistling Teal 89. Great Cormorant 90. Pied Kingfisher 91. Laughing dove 92. Bonelli's Eagle 93. Dark Black Crow",
                ],
            },

            {
                img: bandhav7,
                title: "History",
                mainContent:
                    "The history of the region can be traced back to 1st century. There are 39 caves in the Bandhavgarh fort and in the surrounding hillocks up to a radius of about 5 km. The oldest cave is of 1st century. Several caves carry inscriptions in Brahmi script. Some caves have embossed figure such as those of tiger, pig, elephant and horseman. Badi gufa, the largest cave, has broad entrance, nine small rooms and several pillars. It has been dated back to the 10th century. The cave appears to be primitive lacking the elaborate statues and carvings seen in the caves of Buddhist period. Its purpose remains mystery.",
                description: [
                    "No records are available to show when Bandhavgarh fort was constructed. However it is thought, to be some 2000 years old, and there are references to it in the ancient books, the “Narad-Panch Ratra” and the ‘Shiva Purana”. Various dynasties have ruled the fort; including the The Mauryans from 3rd century BC, Vakataka rulers from 3rd to 5th century the Sengars from 5th century and the Kalachuris from 10th century. In the 13th century, the Baghels took over, ruling from Bandhavgarh until 1617, when Maharaja Vikramaditya Singh moved his capital to Rewa. The last inhabitants deserted the fort in 1935. Bandhavgarh had long been maintained as a Shikargah, or game preserve, of the Maharajas and their guests. No special conservation measures were taken until 1968.",
                    "Project Tiger was constituted in 1972 and then the Wildlife Protection Act 1972 came into force. It was realized that protection of just the 105 km² of prime Bandhavgarh habitat was not enough, so in 1982, three more ranges namely, Khitauli, Magdhi and Kallawah were added to Tala range (the original Bandhavgarh National Park) to extend the area of Bandhavgarh to 448 km². As Project tiger extended its activities and area of influence, Bandhavgarh was taken in its folds in 1993, and a core of 694 km². Including the previously named ranges and the Panpatha Sanctuary along with a buffer area of 437 km² was declared as the Bandhavgarh Tiger Reserve.",
                ],
            },
        ],
    },
    {
        id: 2,
        title: "Jim Corbett National Park",
        banner: jimBanner,
        description: [
            "Jim Corbett National Park—named after the hunter turned conservationist Jim Corbett who played a key role in its establishment—is the oldest national park in India. The park was established in 1936 as Hailey National Park. Situated in Nainital district of Uttarakhand, the park acts as a protected area for the critically endangered Bengal tiger of India, the secure survival of which is the main objective of Project Tiger, an Indian wildlife protection initiative. ",
            "The park has sub-Himalayan belt geographical and ecological characteristics. An ecotourism destination, it contains 488 different species of plants and a diverse variety of fauna. The increase in tourist activities, among other problems, continues to present a serious challenge to the park's ecological balance.",
        ],
        geoGraphy: {
            title: "Geography",
            table: [
                {
                    title: "Area",
                    value: "521 sqkm (201sq mi)",
                },
                {
                    title: "Elevation",
                    value: "1,210 m (3,970 ft)",
                },
                {
                    title: "Climate -Precipitation",
                    value: "2,800 mm (110.2 in)",
                },
                {
                    title: "Summer",
                    value: "32.5 °C (91 °F)",
                },
                {
                    title: "Winter",
                    value: "14.5 °C (58 °F)",
                },
                {
                    title: "Established",
                    value: "1938",
                },
            ],
            content: [
                "The average altitude of the region ranges between 360 m (1,181 ft) and 1,040 m (3,412 ft). It has numerous ravines, ridges, minor streams and small plateaus with varying aspects and degrees of slopes. The park encompasses the Patli Dun valley formed by the Ramganga river.",
                "The reserve, located partly along a valley between the Lesser Himalaya in the north and the Siwaliks in the south, has a sub-Himalayan belt structure.[3] The upper tertiary rocks are exposed towards the base of the Siwalik range and hard sandstone units form broad ridges. Characteristic longitudinal valleys, geographically termed Doons, or Duns can be seen formed along the narrow tectonic zones between lineaments. The weather in the park is temperate compared to most other protected areas of India. The temperature may vary from 5 °C (41 °F) to 30 °C (86 °F) during the winter and some mornings are foggy. Summer temperatures normally do not rise above 40 °C (104 °F). Rainfall ranges from light during the dry season to heavy during the monsoons.",
            ],
        },
        additionalInfo: [
            {
                img: jim1,
                title: "Flora",
                mainContent:
                    "A total of 488 different species of plants have been recorded in the park. Tree density inside the reserve is higher in the areas of Sal forests and lowest in the Anogeissus-Acacia catechu forests. Total tree basal cover is greater in Sal dominated areas of woody vegetation. Healthy regeneration in sapling and seedling layers is occurring in the Mallotus philippensis, Jamun and Diospyros tomentosa communities, but in the Sal forests the regeneration of sapling and seedling is poor.",
                description: [],
            },
            {
                img: jim2,
                title: "Fauna",
                mainContent:
                    "Over 585 species of resident and migratory birds have been categorized, including crested serpent eagles, blossom headed parakeet and the red jungle fowl — ancestor of all domestic fowl. 33 species of reptiles, 7 species of amphibians, 7 species of fish and 37 species of dragonflies have also been recorded. Bengal tigers, although plentiful, are not easily spotted due to the abundance of camouflage in the reserve. Thick jungle, the Ramganga river, and plentiful prey make this reserve an ideal habitat for tigers who are opportunistic feeders and prey upon a range of animals. The tigers in the park have been known to kill much larger animals such as buffalo and even elephant for food. The tigers prey upon the larger animals in rare cases of food shortage, often in packs using the advantage of numerical superiority. The reserve has enormous boars, weighing up to 200 pounds, who provide a match for the tigers as a large male boar is capable of killing a tiger. There have been incidents of tigers attacking domestic animals in times when there is a shortage of prey.",
                description: [
                    "Leopards are found in hilly areas but may also venture into the low land jungles. Smaller felines in the park include the Jungle Cat, Fishing Cat and Leopard Cat. Other mammals include four kinds of deer (barking, sambar, hog, Black buck and chital), Sloth and Himalayan Black bears, Indian Grey Mongoose, otters, yellow-throated martens, ghoral (goat-antelopes), Indian pangolins, and langur and rhesus monkeys. Owls and Nightjars can be heard during the night.",
                    "In the summer, Elephants are seen in large herds of several hundred. The Indian python found in the reserve is a dangerous species, capable of killing a chital deer. Local crocodiles were saved from extinction by captive breeding programs that subsequently released crocodiles into the Ramganga river.",
                ],
            },
            {
                img: jim3,
                title: "Ecotourism",
                mainContent:
                    "Though the main focus is protection of wildlife, the reserve management has also encouraged ecotourism. In 1993, a training course covering natural history, visitor management and park interpretation was introduced to train nature guides. Tiwari & Joshi (1997) consider summer (April-June) to be the best season for Indian tourists to visit the park while recommending the winter months (November-January) for foreign tourists.[18] According to Riley & Riley (2005): Best chances of seeing a tiger to come late in the dry season- April to mid June-and go out with mahouts and elephants for several days.",
                description: [],
            },

            {
                img: jim4,
                title: "History",
                mainContent:
                    "Some areas of the park were formerly part of the princely state of Tehri Garhwal. The Boksas—a tribe from the Terai—settled on the land and began growing crops, but in the early 1860s they were evicted with the advent of British rule. The British forest department established control over the land and prohibited cultivation and the operation of cattle stations. The British administration considered the possibility of creating a game reserve there in 1907 and established a reserve area known as Hailey National Park covering 323.75 km2 (125.00 sq mi) in 1936. The preserve was renamed in 1954–55 as Ramganga National Park and was again renamed in 1955–56 as Corbett National Park.",
                description: [
                    "No records are available to show when Bandhavgarh fort was constructed. However it is thought, to be some 2000 years old, and there are references to it in the ancient books, the “Narad-Panch Ratra” and the ‘Shiva Purana”. Various dynasties have ruled the fort; including the The Mauryans from 3rd century BC, Vakataka rulers from 3rd to 5th century the Sengars from 5th century and the Kalachuris from 10th century. In the 13th century, the Baghels took over, ruling from Bandhavgarh until 1617, when Maharaja Vikramaditya Singh moved his capital to Rewa. The last inhabitants deserted the fort in 1935. Bandhavgarh had long been maintained as a Shikargah, or game preserve, of the Maharajas and their guests. No special conservation measures were taken until 1968.",
                    "The new name honours the well-known author and wildlife conservationist Jim Corbett, who played a key role in creating the reserve by using his influence to persuade the provincial government to establish it. Over time the area in the reserve was increased—797.72 km2 (308.00 sq mi) were added in 1991 as a buffer for the Corbett Tiger Reserve. The 1991 additions included the entire Kalagarh forest division, assimilating the 301.18 km2 (116.29 sq mi) area of Sonanadi Wildlife Sanctuary as a part of the Kalagarh division. It was chosen in 1974 as the location for launching Project Tiger, an ambitious and well known wildlife conservation project. The reserve is administered from its headquarters in the district of Nainital.",
                    "Corbett National Park is one of the thirteen protected areas covered by World Wildlife Fund under their Terai Arc Landscape Programme. The programme aims to protect three of the five terrestrial flagship species, the tiger, the Asian elephant and the Great One-horned Rhinoceros, by restoring corridors of forest to link 13 protected areas of Nepal and India to enable wildlife migration.",
                ],
            },
        ],
    },
    {
        id: 3,
        title: "Kanha National Park",
        banner: kanhaBanner,
        description: [
            "Kanha National Park - the richest tiger reserve of India, in the state of Madhya Pradesh is popular for its tiger population. If you are in India to see tigers in abundance, then this is the place for you to visit. The park is located between the Banjar and Halon valleys in the Mandla / Balaghat districts of the state of Madhya Pradesh. The Kanha National Park was established after a lot of flurry within the concerned circles regarding rampant killing of animals in the area at the time. The central Kanha valley was declared a sanctuary way back in the year 1933 but was been given the status of a National Park in the year 1955.   ",
        ],
        geoGraphy: {
            title: "Geography",
            table: [
                {
                    title: "Area",
                    value: "1940 sq km",
                },
                {
                    title: "Elevation",
                    value: "1,210 m (3,970 ft)",
                },
                {
                    title: "Summer",
                    value: "40.6 °C",
                },
                {
                    title: "Winter",
                    value: "11.1 °C",
                },
                {
                    title: "Established",
                    value: "1938",
                },
                {
                    title: "Park Closed",
                    value: "July to mid November (Due toMonsoon)",
                },
            ],
            content: [
                "The entire topography of Kanha consists of huge plateaus with vast grasslands, three rivers like -Sulkum, Banjar and Nila and numerous perennial springs, that waters the higher areas during the extremely hot summer months.",
                "While you are venturing the forest area, you will pass by the thick forests of Bija, Haldu, Dhaora trees. The forest is also covered by Sal, Ban-rahar, Bamboo and Sindhur. But, try to visit the park during the cooler and greener months, as the lush surroundings of the park makes it amazingly beautiful. The Kanha area was divided into two sanctuaries: Hallon and Banjar, of 250 sq km and 300 sq km each. ",
            ],
        },
        additionalInfo: [
            {
                img: k1,
                title: "Fauna",
                mainContent:
                    "When you are at Kanha National Park, you must be looking for the tigers, for which it is famous round the world. Tiger sightings are very common here. Other than the tiger, some of the more frequently seen animals in the park consist of Leopards, Gaur, Sambar, Chausinghas, Barasingha, Nilgais, Sloth Bears, Barking Deer, Swamp Deer (Barasingha), Blackbuck, Langurs, Wild Boars, Porcupines, Mouse Deer, Hyenas, Jackals, Wild Dogs (Dholes), Gray Langurs, Mongoose, Jungle Cat, Chittal and Wild Pig.",
                description: [],
            },
            {
                img: k2,
                title: "Avifauna",
                mainContent:
                    "Kanha has over 300 different species of birds within its complex. Some of the more interesting ones for bird lovers to look out for are Pied or Marsh Harriers, Red Jungle Fowls, Painted Spur Fowls, Lesser Whistling Teals, Common Teals, Pintails, Cotton Teals, Shovelers, Peafowls, Indian Rollers, Racket Tailed Drongos, Red Wattled Lapwings, Brown Fish Owls, Nightjars, Laggers, Shaheen Falcons, Shikras, Crested Serpent Eagles, Crested Honey Buzzards, Yellow Wattled Lapwings, Green Bee-eaters, Scavenger Vultures, Long Billed Vultures, White backed Vultures, gray Hornbills, Tree Pies, Kestrels, Barn Owls, White Eyed Buzzards, Black Winged Kites, Mynahs, Munias, Bushchats, Black Headed Orioles, Golden Orioles, Paradise Flycatchers, Pied Malabar Hornbills, Indian Pittas, Indian Stone Curlews, Common Gray Partridges, Warblers, Flycatchers, Babblers, Woodpeckers, Painted Partridges, Green Pigeons, Black Ibis, White Necked Storks, Lesser Adjutant Storks, White breasted Kingfishers, Pied Kingfishers, Egrets and Cormorants. ",
                description: [],
            },

            {
                img: k3,
                title: "History",
                mainContent:
                    "disbanded, the area remained a protected one until 1947. Depletion of the tiger population in the years that followed led to the area being made an absolute sanctuary in 1952. By a special statute in 1955, Kanha National Park came into being. Since then, a series of stringent conservation programmes for the protection of the park's flora and fauna has given Kanha its deserved reputation for being one of the finest and best administered National Parks in Asia, an irresistible attraction for all wildlife lovers and a true haven for its animal and avian population.",
                description: [],
            },
        ],
    },
    {
        id: 4,
        title: "Keoladeo National Park",
        banner: keoladeoBanner,
        description: [
            "Keoladeo National Park (KNP) - one of the most enchanting waterfowl refuges in the world. At the confluence of the Gambhir and Banganga rivers in Bharatpur district of Rajasthan. Formerly known as the Bharatpur Ghana Bird Sanctuary, the park, which has been extensively modified and managed by man, supports enormous congregations of migratory waterfowl in winter, and massive colonies of breeding, fish-eating birds in the monsoon and autumn. Besides the enormity in number and variety of these birds, the possibility of watching them from close quarters is the major attraction of this park. The rare and most endangered of the cranes, the Siberian crane, which is reported to winter regularly only in Bharatpur in India, is the star visitor to KNP. Since the wetland is surrounded by a variety of terrestrial habitats, chances of sighting semi - aquatic and land birds are also high, making it all the more impressive to any bird lover and giving it the name 'bird paradise'.",
            "KNP became a Ramsar Site in 1981 and a World Heritage site in 1985 giving it the international recognition it deserves.",
        ],
        geoGraphy: {
            title: "Geography",
            table: [
                {
                    title: "Area",
                    value: "28.7 sq. km  ",
                },
                {
                    title: "Best time to visit",
                    value: "Throughout the year",
                },
                {
                    title: "Breeding season",
                    value: "Aug-Oct",
                },
                {
                    title: "Migrants",
                    value: "Oct-Feb",
                },
            ],
            content: [
                "The entire topography of Kanha consists of huge plateaus with vast grasslands, three rivers like -Sulkum, Banjar and Nila and numerous perennial springs, that waters the higher areas during the extremely hot summer months.",
                "While you are venturing the forest area, you will pass by the thick forests of Bija, Haldu, Dhaora trees. The forest is also covered by Sal, Ban-rahar, Bamboo and Sindhur. But, try to visit the park during the cooler and greener months, as the lush surroundings of the park makes it amazingly beautiful. The Kanha area was divided into two sanctuaries: Hallon and Banjar, of 250 sq km and 300 sq km each. ",
            ],
        },
        additionalInfo: [
            {
                img: ke1,
                title: "Flaura",
                mainContent:
                    "The subtropical climate of Keoladeo and the variety of habitats ranging from upland terrestrial to submerged aquatic, the dynamics of the wetland system and its strategic geographic location on the flyway of migratory birds, all contribute to the diversity of life-forms it supports. One-third of the area is wetland with varying types of micro-habitats having trees, mounds, dykes and open water with or without submerged or emergent plants. The uplands have grasslands (savanna) of tall species of grass as also scattered trees and shrubs in varying densities. ",
                description: [
                    "The richness and diversity of plant life inside this small park is remarkable. Flowering plants were surveyed during a ten-year ecological study of the park in 1991. A total of 379 species were recorded in 1980s.Acacia nilotica, A.leucophloea, Prosopis juliflora, P .cinerarea, Mitragyna parvifolia and Zyzyphus spp. are the common trees found in the park. Salvadora spp. and Capparis spp. occur both as trees and shrubs. Herbs are numerous, constituting more than 55% of the floristic species present. The most widespread grasses in the terrestrial areas are Vetiveria zizanioides (Khus grass), Desmostachya bipinnata and Cynodon dactylon. ",
                ],
            },
            {
                img: ke2,
                title: "Fauna",
                mainContent:
                    "Ornithologically, the park assumes significance in two respects: One, because of its strategic location, it is a staging ground for migratory waterfowl arriving in the Indian subcontinent before dispersing to various regions. It is also a site where waterfowl converge before departing to breeding grounds in the western Palaearctic region. In addition, the wetland is a wintering area for massive congregations of waterfowl. Two, it has been the only regular wintering area in India for the central population of the Siberian crane, which is one of the most endangered of all the crane species in the world. KNP annually supports more than 40,000 birds of 370 species. The park has been observed to have over 130 nesting species. More than 25 species of ducks and goose like coots, brahminy duck, mallard, gadwal, wigeon, shoveller, pintail duck, barheaded goose, greylag goose and others are known to winter here annually. ",
                description: [
                    "The park is the only wintering ground for the highly endangered central population of the Siberian crane, migrating almost 6,400 km. KNP supports 43 species of fish and in a good rainy season it receives more than 65 million fishfry and fingerlings along with water from the Ajan bund. KNP has 27 species of herbivores, Bluebull, feral cattle and spotted deer are common while sambhar, mongoose and civets are few. Wild boar, jackal, porcupine, hyaena and bats are also seen.Herpetofauna comprisesof 7 species of turtles, 5 species of lizards, 13 species of snakes and 7 species of amphibians. It supports a number of other endangered species especially reptiles such as the Indian flap shell turtle and the Indian rock python.",
                ],
            },

            {
                img: ke3,
                title: "Conservative Measure",
                mainContent:
                    "Keoladeo National Park is a renowned Ramsar Site and World Heritage site. As an artificial wetland surrounded by villages and crop fields, Keoladeo National Park needs a holistic management plan that involves local people and benefits them. An Integrated Development Programme has been suggested in which overall development of the area is envisaged. Alongwith a proposal for meeting water needs from Chambal river, the Programme envisages social and economic upliftment of the rural communities surrounding the park. WWF-India in partnership with Rajsathan Forest Department is involved in setting up a much needed Visitor Interpretation Centre and Programme.",
                description: [],
            },
        ],
    },
    {
        id: 5,
        title: "Periyar National Park",
        banner: periyarBanner,
        description: [
            "Periyar is a protected area, and a Project Tiger Nature Reserve in the Suth Indian State of Kerala, set high in the mountains of the Western Ghats at the border to Tamil Naidu. It lies in the districts of Idukki and Pathanamthitta.",
        ],
        geoGraphy: {
            title: "Geography",
            table: [
                {
                    title: "Area",
                    value: "305 sq km",
                },
                {
                    title: "Elevation",
                    value: "100m to 1,200 m",
                },
                {
                    title: "Climate — Precipitation",
                    value: "2000 and 3000 mm",
                },
                {
                    title: "Summer",
                    value: "31 °C",
                },
                {
                    title: "Winter",
                    value: "15 °C",
                },
                {
                    title: "Established",
                    value: "1982",
                }
            ],
            content: [
                "The protected area covers an area of 777 km², out of which a 350 km² part of the core zone was made into the Periyar National Park and Tiger Reserve, sometimes dubbed the Periyar Wildlife Sanctuary. The park is often called by the name thekkady also. The Periyar protected area lies in the middle of a mountainous area of the Cardamom hills. In the north and the east it is bounded by mountain ridges of over 1700 metres altitude and toward the west it expands into a 1200 Meter high plateau. the 100 Meter valley of the Pamba River. The highest peak is the 2019 Meter high. The sanctuary surrounds picturesque 26 km² Periyar lake."
            ],
        },
        additionalInfo: [
            {
                img: p1,
                title: "Flaura",
                mainContent:
                    "Approximately 75% of the entire protected area are covered of unaffected evergreen or semi-evergreen rain forest. Scarcely 13% consists of damp leaves forest, 7% of Eucalyptus plantation and 1.5% of grassland. The remainder (around 3.5%) of the protected area is covered by the Periyar artificial lake as well as the Periyar River and Pamba river.",
                description: [
                    "Altogether the reservation counts nearly 2000 kinds of flowering plants (Angiosperm), three kinds of seed plants (gymnosperms) and 170 different species of ferns. Among the Angiosperms, there are 169 families of sweet grass and 155 kinds of Fabaceaes. Orchids, with 145 representative types, are the most frequent flower.",
                    "About 350 of the occurring plant types can be used for medical purposes",
                ],
            },
            {
                img: p2,
                title: "Fauna",
                mainContent:
                    "Altogether 62 different kinds of mammals have been recorded in Periyar, including many threatened ones. There are an estimated 24 tigers in the reserve. Tourists also come here to view the Indian Elephants in the act of ablution and playfulness by the Periyar lake. The elephant number around 900 to 1000 individuals. Other mammals found here include gaur, sambhar (horse deer), barking deer, mouse deer, dholes (Indian wild dogs), mongoose, foxes and leopards. Also inhabiting the park, though rarely seen, are the elusive Ningiri tahar. Four species of primates are found at Periyar - the rare lion-tailed macaque, the Nilgiri langoor, the common longoor, and the Bonnet Macaque",
                description: [
                    "So far 320 different kinds have been counted in Periyar. The bird life includes darters, cormorants, kingfisher and Racket-tailed Drongos.",
                    "There are 45 different kinds of reptile in the protected area out of which there are 30 snakes, two turtles, and 13 lizards species. Among those are Monitor lizards that can be spotted basking in the sun on the rocks along the lake shore. Visitors who trek into the Periyar national park often see a Python and sometimes even a King Cobra.",
                    "Twenty seven different kinds have been recorded, of which ten are endemic to the Western Ghats.",
                    "In the waters of the protected area 38 different fish types live, of which four are endemic to the Western Ghats. In artificial lake there are two introduced species : the tilapia originally from Africa and the Carp introduced from Europe",
                    "There is a remarkable variety of butterflies and there are about 160 different kinds in total. Some are dangerous enough to make a human seriously ill.",
                    "Periyar Tiger Reserve is one of the 27 Tiger Reserve in India. Its a must to go for Jungle Track during your visit to the Periyar National Park. There are different kind of options available for the Jungle Track. One can undertrack the night patrol with the forest guard through the jungle and if you are not really that courageous there are options available for the short track of 4 hours starting in morning and afternoon. During the track the flora and fauna can be experienced at close quarters and if one is lucky enough there are chances to sight the bigger animals of the cat family."
                ],
            },
            {
                img: p3,
                title: "History",
                mainContent:
                    `1895 - Construction of the Mullaperiyar Dam
                    1899 - Formation of the Periyar Lake Reser
                    1933 - S.C.H. Robinson made the first game warden
                    1934 - Formation of Nellikkampatty Game Sanctuary
                    1950 - Consolidation of Periyar as a Wildlife Sanctuary
                    1978 - Declaration of Periyar as a Tiger Reserve
                    1982 - Preliminary notification of the core area as a National Park National Park
                    1991 - Brought under Project Elephant Project Elephant
                    1996 - India Ecodevalopment Project launched
                    2001 - Reorganised as two Divisions: Periyar East and Periyar West`,
                description: [],
            },
        ],
    },
    {
        id: 6,
        title: "Ranthambhore National Park",
        banner: rantBanner,
        description: [
            "Ranthambhore National Park is one of the largest and most famous national parks in northern India. It is situated in Sawai Madhopur district of southeastern Rajasthan. The forests around the Ranthambore Fort were once, the private hunting grounds of the Maharajas of Jaipur. The entry point to the Park, goes straight to the foot of the fort and the forest rest house, Jogi Mahal. The latter boasts of the second-largest banyan tree in India. The Padam Talab, the Raj Bagh Talab and the Malik Talab are some of the lakes in the area, that attract the tiger population. They have been spotted at the edges of these lakes, and Jogi Mahal itself. Old crumbling walls, ruined pavilions, wells, and other ancient structures stand witness to the region's glorious past. The entire forest is peppered with the battlements and spillovers of the Ranthambore Fort - tigers are said to frequent these ruins, too. As a result of stringent efforts in conservation, tigers, the prime assets of the Park, have become more and more active during the day. More than in any other park or sanctuary in India, tigers are easily spotted here in daylight. They can be seen lolling around lazily in the sun, or feverishly hunting down Sambar around the lakes.",
        ],
        geoGraphy: {
            title: "Geography",
            table: [
                {
                    title: "Area",
                    value: "392 sq km",
                },
                {
                    title: "Summer",
                    value: "45 °C",
                },
                {
                    title: "Winter",
                    value: "10 °C",
                },
                {
                    title: "Established",
                    value: "1980",
                }
            ],
            content: [
                "The park lies at the edge of a plateau, and is bounded to the north by the Banas River and to the south by the Chambal River. There are several lakes in the park. It is named for the historic Ranthambore fortress, which lies within the national park. The park covers an area of 392 km², and is famous for its tiger population, and is one of India's Project Tiger reserves."
            ],
        },
        additionalInfo: [
            {
                img: r1,
                title: "Flaura",
                mainContent:
                    "The terrain fluctuates between impregnable forests and open bushland. The forest is the typically dry deciduous type, with dhok, being the most prominent tree. It is also home to wide variety of trees, plants, Ranthambore is also the site for one of the largest Banyan trees in India.",
                description: [
                    "Around 280 species of plants are found in the forest, mainly the forest area is covered by Aam (Magnifera iIndica), Imli (Tamarindicus indica), Babul (Accasia nilotica) , Banyan (Ficus benghalensis),Ber (Zizyphus mauritania), Dhak or Chila(flame of the forest/Butea monosperma), Dhok (Anogeossis pendula), Jamun (Syzygium cumini),Kadam (Authocephalus cadamba), Khajur (Phoenix sylvestris), Khair (Accacia catechu), Karel (Capparis decidua),Khejda (Prosopis specigera), Kakera (Flacourtia indica), Mohua (Madhuca indica), Neem (Azadirachta indica)."
                ],
            },
            {
                img: r2,
                title: "Fauna",
                mainContent:
                    "Beside Tiger, the major wild animals that can be spotted in Ranthambore National Park are Leopard, Nilgai, dhole, Sambar, Chital, Striped Hyenas, Sambar deer, Common or Hanuman langurs, Macaques, Jackals, Jungle cats, Caracals, Sloth bears, Black bucks, Rufoustailed Hare, Indian Wild Boar, Chinkara, Common Palm Civets or Toddy cat, Coomon Yellow Bats, Desert Cats, Fivestriped Palm Squirels, Indian False Vampires, Indian Flying Foxes, Indian Foxes, Indian Gerbilles, Indian Mole Rats, Indian Porcupines, Longeared Hedgehogs, Ratels, Small Indian Mongoose, Small Indian Civets and Common mongoose.",
                description: [
                    "the reserve has thriving bird population with more than 270 different species of birds here are few of them: Graylag Goose, Woodpeckers, Indian Gray Hornbills, Common Kingfishers, Bee Eaters, Cuckoos, Parakeets, Asian Palm Swift, Owl, Nightjars, Pigeon, Dove, Crakes, Snipes, Sandpipers, Gulls, Terns, Great Crested Grebe, Eagles, Darters, Cormorants, Egrets, Herons, Bitterns, Flamingos, Ibis, Pelicans, Storks, Pittas, Shrikes, Treepies, Crows, Orioles, Cuckoo-Shrikes, Minivets, Drongos, Flycatchers, Ioras, Wood Shrikes, Pipits, Bayas, Sparrows, Finches, Wagtails, Munias, Bulbul, Mynas, Falcons etc.",
                    "The amphibian species only consist of the Common India Toad and the Common Frog,also North Indian Flap Shelled Turtles, Tortoise are seen",
                    "The park also has a large number of marsh crocs Reptiles: Snub Nosed Marsh Crocodiles, Desert Monitor Lizards, Banded Kraits, Cobras, Common Kraits, Ganga Soft Shelled Turtles, Indian Pythons, Rat Snakes, Russel's Vipers, Saw-scaled Vipers and the Indian Chamaeleon."
                ],
            },

            {
                img: r3,
                title: "History",
                mainContent:
                    "Ranthambhore was established as the Sawai Madhopur Game Sanctuary in 1955 by the Government of India, The Indian Government started Project Tiger in 1972 with an allotted area of 60 sq m. It was later expanded to become what is now called, the Ranthambore National Park. Ranthambore became a national park in 1980. In 1984, the adjacent forests were declared the Sawai Man Singh Sanctuary and Keladevi Sanctuary, and in 1991 the tiger reserve was enlarged to include Sawai Man Singh and Keladevi sanctuaries.",
                description: [
                    "In 2005, there were 26 tigers living in Ranthambhore. This was significantly lower than the recorded tiger population of the reserve in 1982, which then stood at 44. In 2008, more than 14 tiger cubs were recorded. This was largely attributed to sustained efforts by forest officials to curb poaching. Villagers in the region were being given incentives to stay out of the park and surveillance cameras were also fitted across the reserve."
                ],
            },
        ],
    },
];
