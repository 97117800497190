import React from "react";

function Subheader({ children }) {
    return (
        <p className="mt-8 text-secondaryColor text-lg leading-10 sm:text-base sm:leading-8 2xs:leading-8 2xs:text-base">
            {children}
        </p>
    );
}

export default Subheader;
