export default {
    "title": {
        "en": "Contact Us",
        "es": "Contacta con nosotras",
        "fr": "Nous contacter",
        "it": "Contattaci",
        "de": "Kontaktiere uns"
    },
    "heading1": {
        "en": "Fill up the form and our team will contact you in 24 Hrs",
        "es": "Llena el formulario y nuestro equipo te contactará en 24 Hrs",
        "fr": "Remplissez le formulaire et notre équipe vous contactera dans les 24 heures",
        "it": "Compila il modulo e il nostro team ti contatterà in 24 ore",
        "de": "Füllen Sie das Formular aus und unser Team wird Sie innerhalb von 24 Stunden kontaktieren"
    },
    "input1": {
        "en": "Name :",
        "es": "Nombre :",
        "fr": "Nom :",
        "it": "Nome :",
        "de": "Name :"
    },
    "input2": {
        "en": "Email :",
        "es": "Correo electrónico :",
        "fr": "E-mail :",
        "it": "E-mail :",
        "de": "Email :"
    },
    "input3": {
        "en": "Phone :",
        "es": "Teléfono :",
        "fr": "Téléphone :",
        "it": "Telefono :",
        "de": "Telefon :"
    },
    "input4": {
        "en": "Message :",
        "es": "Mensaje :",
        "fr": "Message :",
        "it": "Messaggio :",
        "de": "Nachricht :"
    },
    "button1": {
        "en": "Submit",
        "es": "Enviar",
        "fr": "Soumettre",
        "it": "Invia",
        "de": "Einreichen"
    },
}