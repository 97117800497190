import React from "react";

function CardsArrangement2({ styles, banner, onclick, label, arrow }) {
    return (
        <div
            className={`${styles.innerBanner} ${banner}`}
            onClick={() => onclick()}
        >
            <div className={`${styles.banner} relative`}>
                <div className="absolute top-5 right-5">
                    <img src={arrow} alt="arrow" width="40" />
                </div>

                <div
                    className={`absolute bottom-7 w-full text-center text-white text-3xl md:text-2xl sm:text-3xl 2xs:text-2xl`}
                >
                    <h1>{label}</h1>
                </div>
            </div>
        </div>
    );
}

export default CardsArrangement2;
