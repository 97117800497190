import React, { useState } from "react";
import NavBar from "../../Components/Navbar/NavBar";
import logo2 from "../../Assets/Image/logo2.png";
import TopBanner from "../../Components/TopBanner/TopBanner";
import ParentContainer from "../../Components/ParentContainer/ParentContainer";
import Heading2 from "../../Components/Heading2/Heading2";
import Subheader from "../../Components/SubHeader/Subheader";
import { car, carRentalContent } from "../../JsonData/CarRent";
import InnerContent from "../../Components/InnerContent/InnerContent";
import CarCardComponent from "../../Components/CarCardComponent/CarCardComponent";
import styles from "./styles.module.scss";
import { Grid } from "@mui/material";
import InputField from "../../utilitiesComponents/InputField/InputField";
import InputSelectField from "../../utilitiesComponents/SelectInputField/InputSelectField";
import Button from "../../utilitiesComponents/Button/Button";
import Footer from "../../Components/Footer/Footer";
import MobileFooter from "../../Components/Footer/MobileFooter";
import FloatingButton from "../../Components/FloatingButton/FloatingButton";
import messages from "./messages";
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { sendEmail } from "../../utils/mail";

function RentACar({ lang }) {
    
    const [values, setValues] = useState({
        mailType: "rental"
    });

    function handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        setValues({
            ...values,
            [name]: value
        });
    }

    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton/>
            <ParentContainer>
                <TopBanner
                    showBanner={false}
                    label={messages.title[lang]}
                    imgAlt="banner"
                />
            </ParentContainer>

            <ParentContainer>
                <div>
                    <div className=" pb-0">
                        <Heading2
                            isVerticalLine={false}
                            heading={messages.heading1[lang]}
                        />
                    </div>
                    <Subheader>
                        {messages.paragraph1[lang]}
                    </Subheader>
                </div>
            </ParentContainer>
            <div className="md:bg-greyBackgorund pb-10">
                <ParentContainer className="">
                    <div className="pt-10 pb-0">
                        <Heading2
                            heading={messages.heading2[lang]}
                            isVerticalLine={false}
                        />
                    </div>
                    <div className={`${styles.cards}`}>
                        {car.map((item, index) => (
                            <CarCardComponent
                                lang={lang}
                                key={index}
                                title={item.title}
                                seating_capacity={item.seating_capacity}
                                img={item.img}
                            />
                        ))}
                    </div>
                </ParentContainer>
            </div>

            <ParentContainer>
                <Subheader>
                    {messages.subheading[lang]}
                </Subheader>
                <div className="pt-10 pb-0">
                    <h1 className="text-2xl text-secondaryColor font-bold text-left md:text-2xl sm:text:3xl ">
                        {messages.heading3[lang]}
                    </h1>{" "}
                </div>
                <div className="px-20 mt-5 sm:px-10 2xs:px-10">
                    {carRentalContent.map((item, index) => (
                        <InnerContent
                            isSvg={true}
                            key={index}
                            img={item.img}
                            details={item.details[lang]}
                            bg={item.code}
                            spanText={item.spanText}
                        />
                    ))}
                </div>
            </ParentContainer>
            <div className="bg-greyBackgorund py-14 mt-10">
                {" "}
                <div
                    className={`rounded-md mx-32 md:mx-16 sm:mx-8 
                    2xs:mx-4 bg-white  py-14 ${styles.formBox}`}
                >
                    <h1 className="text-2xl text-secondaryColor font-bold text-center md:text-2xl sm:text:3xl ">
                        {messages.heading4[lang]}
                    </h1>{" "}
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            sendEmail(values);
                        }}
                    >
                        <GoogleReCaptchaProvider reCaptchaKey="6LdqwQgkAAAAAOhlmQB31Hqo1iCWFHjaWJlSSKMf">
                            <Grid container>
                                <Grid item sm={12} className="w-full">
                                    <InputField label={messages.input1[lang]} isBlur={false} name="Name" onChange={handleInputChange} />
                                </Grid>
                                <Grid item sm={12} className="w-full">
                                    <InputField label={messages.input2[lang]} isBlur={false} name="Address" onChange={handleInputChange} />
                                </Grid>
                                <Grid item sm={12} className="w-full flex">
                                    <div style={{ width: "20%", marginRight: "20px" }}>
                                        <InputField label={messages.input3[lang]} isBlur={false} name="Code" onChange={handleInputChange} />
                                    </div>
                                    <div style={{ width: "80%" }}>
                                        <InputField
                                            label={messages.input4[lang]}
                                            isBlur={false}
                                            name="Phone"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Grid>
                                <Grid item sm={12} className="w-full">
                                    <InputField label={messages.input5[lang]} isBlur={false} name="Email" onChange={handleInputChange} />
                                </Grid>
                                <Grid item sm={12} className="w-full flex">
                                    <InputSelectField
                                        label={messages.input6[lang]}
                                        option={car.map((item) => (item.title))}
                                        name="Car"
                                        isBlur={false}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item sm={12} className="w-full">
                                    <InputField
                                        label={messages.input7[lang]}
                                        isBlur={false}
                                        name="Days"
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item sm={12} className="w-full">
                                    <InputField
                                        type="date"
                                        label={messages.input8[lang]}
                                        isBlur={false}
                                        name="Date"
                                        onChange={handleInputChange}
                                    />
                                </Grid>

                                <div className="flex justify-center items-center  pt-20 w-full">
                                    <Button
                                        type="submit"
                                        title={messages.button1[lang]}
                                        style={{
                                            background: "var(--primaryColor)",
                                            color: "white",
                                            borderRadius: "10px",
                                            width: "400px",
                                            height: "40px",
                                            fontSize: "14px",
                                        }}
                                    />
                                    <Button
                                        type="button"
                                        title={messages.button2[lang]}
                                        style={{
                                            border: "1px solid var(--primaryColor)",
                                            color: "var(--primaryColor)",
                                            borderRadius: "10px",
                                            marginLeft: "20px",
                                            width: "130px",
                                            height: "40px",
                                            fontSize: "14px",
                                        }}
                                        onClick={() => window.location.reload()}
                                    />
                                </div>
                            </Grid>
                            <GoogleReCaptcha />
                        </GoogleReCaptchaProvider>
                    </form>
                </div>
            </div>
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default RentACar;
