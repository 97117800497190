import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./styles.module.scss";
import Button from "../../utilitiesComponents/Button/Button";
import "./index2.scss";
import { useHistory } from "react-router-dom";

function CardsCarousel2({ options, cardsList, path, isHome, parentName }) {
    let history = useHistory();
    return (
        <div className="mt-16 ">
            <OwlCarousel className="owl-theme MySlider2" {...options}>
                {cardsList.map((item, index) => (
                    <div className={`item ${styles.owlSlider} `} key={index}>
                        <div className="h-full">
                            <div
                                className={`${styles.tourBanner} ${
                                    isHome && item.image
                                } flex justify-end items-center flex-col`}
                                style={{
                                    backgroundImage:
                                        !isHome &&
                                        `linear-gradient(180deg, rgba(0, 0, 0, 0) 50.25%, rgba(0, 0, 0, 0.8) 80.24%),url(${item.image})`,
                                }}
                            >
                                <p className="text-center text-2xl font-semibold text-white mb-6">
                                    {item.title}
                                </p>
                                <Button
                                    title="View Details"
                                    style={{
                                        background: "var(--primaryColor)",
                                        color: "white",
                                        borderRadius: "10px",
                                        width: "160px",
                                        height: "40px",
                                        fontSize: "14px",
                                    }}
                                    onClick={() =>
                                        history.push({
                                            pathname: isHome
                                                ? `${path}/${item.id}`
                                                : `${path}/${parentName}/${item.id}`,
                                            state: { id: item.id },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
        </div>
    );
}

export default CardsCarousel2;
