import React from "react";
import { Grid } from "@mui/material";

function GridComponents({ children }) {
    return (
        <Grid item md={4} className="w-full ">
            {children}
        </Grid>
    );
}

export default GridComponents;
