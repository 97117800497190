export const reviews = [
    {
        review: "Excellent service and experience. Would definitely recommend Nauri .",
        rating: 5,
        user: "tropparoba",
        location: "China",
        url: "https://www.tripadvisor.in/ShowUserReviews-g304555-d6132563-r346828391-Global_India_Tours_Private_Day_Tours-Jaipur_Jaipur_District_Rajasthan.html?m=19905",
        profilePicture: "https://media-cdn.tripadvisor.com/media/photo-l/0a/08/07/7f/tropparoba.jpg"
    },
    {
        review: "All our expectations were met",
        rating: 5,
        user: "68reniv",
        location: "Milan, Italy",
        url: "https://www.tripadvisor.in/ShowUserReviews-g304555-d6132563-r338930307-Global_India_Tours_Private_Day_Tours-Jaipur_Jaipur_District_Rajasthan.html?m=19905",
        profilePicture: "https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/e4/ca/default-avatar-2020-51.jpg"
    },
    {
        review: "The best way to see India",
        rating: 5,
        user: "Alan P",
        location: "Ilfracombe, United Kingdom",
        url: "https://www.tripadvisor.in/ShowUserReviews-g304555-d6132563-r206189872-Global_India_Tours_Private_Day_Tours-Jaipur_Jaipur_District_Rajasthan.html?m=19905",
        profilePicture: "https://media-cdn.tripadvisor.com/media/photo-t/05/e3/49/42/alan-p.jpg"
    },
    {
        review: "Our trip Heritage global tour",
        rating: 5,
        user: "Michel B ",
        location: "Draguignan, France",
        url: "https://www.tripadvisor.in/ShowUserReviews-g304555-d6132563-r196699273-Global_India_Tours_Private_Day_Tours-Jaipur_Jaipur_District_Rajasthan.html?m=19905",
        profilePicture: "https://media-cdn.tripadvisor.com/media/photo-t/1a/f6/eb/43/default-avatar-2020-11.jpg"
    },
    {
        review: "Very interesting trip to the north of India and Nepal (Katmandu)",
        rating: 4,
        user: "Lito F",
        location: "London",
        url: "https://www.tripadvisor.in/ShowUserReviews-g304555-d6132563-r587921308-Global_India_Tours_Private_Day_Tours-Jaipur_Jaipur_District_Rajasthan.html?m=19905",
        profilePicture: "https://media-cdn.tripadvisor.com/media/photo-o/07/26/3a/53/lito-f.jpg"
    },
    {
        review: "Fantastic Golden Triangle tour organised by Nauri",
        rating: 4,
        user: "Uri_traveler1",
        location: "Barcelona, Spain",
        url : "https://www.tripadvisor.in/ShowUserReviews-g304555-d6132563-r456925825-Global_India_Tours_Private_Day_Tours-Jaipur_Jaipur_District_Rajasthan.html?m=19905",
        profilePicture: "https://media-cdn.tripadvisor.com/media/photo-t/1a/f6/df/2b/default-avatar-2020-39.jpg"
    },
];
