import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../../Components/Navbar/NavBar";
import {
    topDestinations,
    topDestinationOptions,
} from "../../JsonData/TopDestinatons";
import logo2 from "../../Assets/Image/logo2.png";
import styles from "./styles.module.scss";
import ParentContainer from "../../Components/ParentContainer/ParentContainer";
import Subheader from "../../Components/SubHeader/Subheader";
import Heading2 from "../../Components/Heading2/Heading2";
import { Grid } from "@mui/material";
import CardsCarousel2 from "../../Components/CardsCarousel2/CardsCarousel2";
import Footer from "../../Components/Footer/Footer";
import MobileFooter from "../../Components/Footer/MobileFooter";
import FloatingButton from "../../Components/FloatingButton/FloatingButton";

function TopDestinations() {
    const [destination, setDestination] = useState({});
    let { id } = useParams();

    useEffect(() => {
        const data = topDestinations.filter((item) => item.id === parseInt(id));
        setDestination(data[0]);
    }, [id]);

    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton/>
            <ParentContainer>
                <div className={styles.banner}>
                    <div
                        className={`${styles.innerBanner} mt-32 flex justify-center items-center`}
                        style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${destination?.banner})`,
                        }}
                    >
                        <h1 className="font-bold text-white text-5xl sm:text-3xl 2xs:text-3xl">
                            {destination?.title}
                        </h1>
                    </div>
                </div>
            </ParentContainer>
            <ParentContainer>
                {destination?.description?.map((item, index) => (
                    <Subheader key={index}>{item}</Subheader>
                ))}
            </ParentContainer>
            {destination?.additionalInfo?.map((item, index) =>
                index % 2 === 0 ? (
                    <div className="bg-greyBackgorund" key={index}>
                        <ParentContainer>
                            <div className="py-20 sm:py-10 2xs:py-10">
                                <Heading2
                                    isVerticalLine={true}
                                    heading={item?.title}
                                    blueText={true}
                                />
                                <Grid container spacing={4}>
                                    <Grid item md={7}>
                                        <Subheader>
                                            {item?.mainContent}
                                        </Subheader>
                                    </Grid>
                                    <Grid item md={5}>
                                        <Subheader>
                                            <img
                                                src={item?.img}
                                                alt="goa"
                                                height={"auto"}
                                                width="100%"
                                            />
                                        </Subheader>
                                    </Grid>
                                </Grid>
                                {item?.subContent.map((item2, index2) => (
                                    <Subheader key={index2}>{item2}</Subheader>
                                ))}
                            </div>
                        </ParentContainer>
                    </div>
                ) : (
                    <ParentContainer key={index}>
                        <Heading2
                            isVerticalLine={true}
                            heading={item?.title}
                            blueText={true}
                        />
                        <Grid container spacing={4}>
                            <Grid item md={5}>
                                <Subheader>
                                    <img
                                        src={item?.img}
                                        alt="goa"
                                        height={"auto"}
                                        width="100%"
                                    />
                                </Subheader>
                            </Grid>
                            <Grid item md={7}>
                                <Subheader>{item?.mainContent}</Subheader>
                            </Grid>
                        </Grid>
                        {item?.subContent.map((item2, index2) => (
                            <Subheader key={index2}>{item2}</Subheader>
                        ))}
                    </ParentContainer>
                )
            )}
            <ParentContainer>
                {destination?.additionalPlaces?.map((item, index) => (
                    <div key={index} className="mb-10">
                        <Heading2
                            isVerticalLine={true}
                            heading={item?.title}
                            blueText={true}
                        />
                        <div className={styles.banner2}>
                            <div
                                className={styles.innerBanner}
                                style={{
                                    height: "100%",
                                    marginTop: "40px",
                                    backgroundImage: `url("${item?.bannerImg}")`,
                                }}
                            ></div>
                        </div>
                        {item?.place?.map((item2, index2) => (
                            <Subheader key={index2}>
                                <span className="font-semibold ">
                                    {item2.title + " : "}
                                </span>
                                {item2.description}
                            </Subheader>
                        ))}
                    </div>
                ))}
            </ParentContainer>
            {destination?.places?.length > 0 && (
                <div className="bg-white">
                    <ParentContainer>
                        <Heading2
                            isVerticalLine={true}
                            heading={"Places to Visit"}
                            blueText={true}
                        />
                        <CardsCarousel2
                            cardsList={destination?.places}
                            options={topDestinationOptions}
                            path={"details"}
                            isHome={false}
                            parentName={destination?.title}
                        />
                    </ParentContainer>
                </div>
            )}
            <div className="mb-16"></div>
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default TopDestinations;
