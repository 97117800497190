import bik from "../Assets/Image/Hotels/bik.jpg";
import jai from "../Assets/Image/Hotels/jai.jpg";
import jaipur from "../Assets/Image/Hotels/jaipur.jpg";
import jod from "../Assets/Image/Hotels/jod.jpg";
import other from "../Assets/Image/Hotels/other.jpg";
import udai from "../Assets/Image/Hotels/udai.jpg";
import Rambagh1 from "../Assets/Image/Hotels/Rambagh1.jpg";
import Rambagh2 from "../Assets/Image/Hotels/Rambagh2.jpg";
import RajMahalPalace1 from "../Assets/Image/Hotels/RajmahalPalace1.jpg";
import RajMahalPalace2 from "../Assets/Image/Hotels/RajmahalPalace2.jpg";
import JaimahalPalace1 from "../Assets/Image/Hotels/JaimahalPalace1.jpg";
import JaimahalPalace2 from "../Assets/Image/Hotels/JaimahalPalace2.jpg";
import NarainNIwas1 from "../Assets/Image/Hotels/NarainNIwas1.jpg";
import NarainNIwas2 from "../Assets/Image/Hotels/NarainNIwas2.jpg";
import SamodeHaveli2 from "../Assets/Image/Hotels/SamodeHaveli2.jpg";
import SamodeHaveli1 from "../Assets/Image/Hotels/SamodeHaveli1.jpg";
import LallgarhPalace1 from "../Assets/Image/Hotels/LallgarhPalace1.jpg";
import LallgarhPalace2 from "../Assets/Image/Hotels/LallgarhPalace2.jpg";
import LallgarhPalace3 from "../Assets/Image/Hotels/LallgarhPalace3.jpg";
import BhanwarNiwas1 from "../Assets/Image/Hotels/BhanwarNiwas1.jpg";
import BhanwarNiwas2 from "../Assets/Image/Hotels/BhanwarNiwas2.jpg";
import LakshmiNiwasPalace1 from "../Assets/Image/Hotels/LakshmiNiwasPalace1.jpg";
import LakshmiNiwasPalace2 from "../Assets/Image/Hotels/LakshmiNiwasPalace2.jpg";
import GajnerPalace1 from "../Assets/Image/Hotels/GajnerPalace1.jpg";
import GajnerPalace2 from "../Assets/Image/Hotels/GajnerPalace2.jpg";
import GajnerPalace3 from "../Assets/Image/Hotels/GajnerPalace3.jpg";
import JawaharNiwas1 from "../Assets/Image/Hotels/JawaharNiwas1.jpg";
import JawaharNiwas2 from "../Assets/Image/Hotels/JawaharNiwas2.jpg";
import FortRajwada1 from "../Assets/Image/Hotels/FortRajwada1.jpg";
import FortRajwada2 from "../Assets/Image/Hotels/FortRajwada2.jpg";
import Rawalkot1 from "../Assets/Image/Hotels/Rawalkot1.jpg";
import Rawalkot2 from "../Assets/Image/Hotels/Rawalkot2.jpg";
import NachnaHaveli1 from "../Assets/Image/Hotels/NachnaHaveli1.jpg";
import NachnaHaveli2 from "../Assets/Image/Hotels/NachnaHaveli2.jpg";
import NarayanNiwasJaisalmer1 from "../Assets/Image/Hotels/NarayanNiwasJaisalmer1.jpg";
import NarayanNiwasJaisalmer2 from "../Assets/Image/Hotels/NarayanNiwasJaisalmer2.jpg";
import AjitBhawanPalace1 from "../Assets/Image/Hotels/AjitBhawan1.jpg";
import AjitBhawanPalace2 from "../Assets/Image/Hotels/AjitBhawan2.jpg";
import Ranbanka1 from "../Assets/Image/Hotels/Ranbanka1.jpg";
import Ranbanka2 from "../Assets/Image/Hotels/Ranbanka2.jpg";
import PalHaveli1 from "../Assets/Image/Hotels/PalHaveli1.jpg";
import PalHaveli2 from "../Assets/Image/Hotels/PalHaveli2.jpg";
import UmaidBhawan1 from "../Assets/Image/Hotels/UmaidBhawan1.jpg";
import UmaidBhawan2 from "../Assets/Image/Hotels/UmaidBhawan2.jpg";
import LakePalace1 from "../Assets/Image/Hotels/LakePalace1.jpg";
import LakePalace2 from "../Assets/Image/Hotels/LakePalace2.jpg";
import FatehPrakash1 from "../Assets/Image/Hotels/FatehPrakash1.jpg";
import FatehPrakash2 from "../Assets/Image/Hotels/FatehPrakash2.jpg";
import shivniwas1 from "../Assets/Image/Hotels/shivniwas1.jpg";
import shivniwas2 from "../Assets/Image/Hotels/shivniwas2.jpg";
import Devigarh1 from "../Assets/Image/Hotels/Devigarh1.jpg";
import Devigarh2 from "../Assets/Image/Hotels/Devigarh2.jpg";
import LakshmiVilasUdaipur1 from "../Assets/Image/Hotels/LakshmiVilasUdaipur1.jpg";
import LakshmiVilasUdaipur2 from "../Assets/Image/Hotels/LakshmiVilasUdaipur2.jpg";
import KhimsarFort1 from "../Assets/Image/Hotels/KhimsarFort1.jpg";
import KhimsarFort2 from "../Assets/Image/Hotels/KhimsarFort2.jpg";
import SamodePalace from "../Assets/Image/Hotels/SamodePalace.jpg";
import CastleMandawa from "../Assets/Image/Hotels/CastleMandawa.jpg";
import FortChanwa1 from "../Assets/Image/Hotels/FortChanwa1.jpg";
import FortChanwa2 from "../Assets/Image/Hotels/FortChanwa2.jpg";
import RohetGarh1 from "../Assets/Image/Hotels/RohetGarh1.jpg";
import RohetGarh2 from "../Assets/Image/Hotels/Rohetgarh2.jpg";

export const hotelslList = [
    {
        topPlaces: [
            {
                id: 1,
                title: "Bikaner Heritage Hotels",
                img: bik,
            },
            {
                id: 2,
                title: "Jaipur Heritage Hotels",
                img: jaipur,
            },
            {
                id: 3,
                title: "Jaisalmer Heritage Hotels",
                img: jai,
            },
        ],
        bottomPlaces: [
            {
                id: 4,
                title: "Jodhpur Heritage Hotels",
                img: jod,
            },
            {
                id: 5,
                title: "Udaipur Heritage Hotels",
                img: udai,
            },
            {
                id: 6,
                title: "Others Heritage Hotels",
                img: other,
            },
        ],
    },
];

export const hotels = [
    {
        id: 1,
        title: "Bikaner Heritage Hotel",
        banner: bik,
        places: [
            {
                title: "Hotel Lalgarh Palace ",
                img: [
                    { img: LallgarhPalace2 },
                    { img: LallgarhPalace3 },

                ],
                img1: LallgarhPalace1,
                description:
                    "This sprawling palace was constructed by Maharaja Ganga Singh Ji in 1902. The imposing structure in sandstone is a perfect example of Indo- Sarasenic architecture. It was created and planned by Sir Swinton Jacod. It is a perfect blend of Mughal, Rajput and European architecture. Beautiful latticework and filigree work has been incorporated to add to the splendor of the palace. Now it is maintained by the Welcome Heritage Group.",
                content: [
                    {
                        title: "Accomodation",
                        desc: "The rooms of the hotel have retained much of its heritage value. Exquisite period furnitures, chandeliers, and latticework have been incorporated with modern fittings and equipments but at no time do they look jarring or out of place. Rajasthani paintings and hunting trophies are there in some of the rooms to lend that erstwhile charm to the rooms.",
                    },
                    {
                        title: "Dining",
                        desc: "The in house restaurant of the hotel provides you an array of delectable dishes ranging from traditional Rajasthani cuisines to Continental and Chinese dishes. There is a well stocked bar at the hotel premises to enliven the atmosphere.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: " There is a huge conference room at the hotel premises which is fitted with the latest automation technology for the smooth proceedings of Business. The Banquet hall is also used for occasions other than business purposes.",
                    },
                ],
            },
            {
                title: "Bhanwar Niwas",
                img: [
                    { img: BhanwarNiwas2 }
                ],
                img1: BhanwarNiwas1,
                description:
                    "Bhanwar Niwas, belonging to the Rampuria Family, was constructed in 1927 by Bhanwarlal ji Rampuria. This imposing Haveli is a living testimony to the splendid architecture of Rajasthan. This heritage hotel is a perfect blend of Indian and European style.",
                content: [
                    {
                        title: "Accomodation",
                        desc: "Each of the rooms in the hotel has been decorated differently and each exudes a different character. Period furniture, chandeliers, and Rajasthani paintings accentuate the heritage value of the rooms. The Blue room in the ground floor is well worth visiting. The rooms are provided with all modern facilities. Some of the rooms have an attached minibar",
                    },
                    {
                        title: "Dining",
                        desc: "The in house restaurant of Hotel Bhanwar Niwas, Bikaner offers you the best of Indian, Continental, and Rajasthani fare. There is also a coffee shop in the hotel premises.",
                    },
                    {
                        title: "Business and Banquets",
                        desc: "There is a separate banquet room and a business center in Hotel Bhanwar Niwas, Bikaner. They are fitted with the latest automation technology.",
                    },
                ],
            },
            {
                title: "Laxmi Niwas Palace",
                img: [
                    { img: LakshmiNiwasPalace2 }
                ],
                img1: LakshmiNiwasPalace1,
                description:
                    "One of the finest heritage hotels in the Bikaner city, Laxmi Niwas Palace is beautifully located within the Lallgarh Palace Complex and is well known for providing luxurious stay and regale hospitality to its guests. Spread over the lush green gardens, Laxmi Niwas Palace provides a tranquil feel and is preferred by tourists visiting Bikaner.",
                content: [
                    {
                        title: "History",
                        desc: "A majestic palace, The Laxmi Niwas Palace has now been converted into a heritage hotel full fledged with modern comforts.",
                    },
                    {
                        title: "Accomodation",
                        desc: "Accommodation facilities provided at Laxmi Niwas Palace are marvelous. The hotel offers 42 well furnished and elegantly appointed palatial rooms to its guests. Categorized as Suite and deluxe rooms, the rooms in Laxmi Niwas are air conditioned and well equipped with modern amenities.",
                    },
                    {
                        title: "Dining",
                        desc: "Providing home cooked like delicious food to its guests, The Laxmi Niwas Palace promises its guests with a fine and a memorable dining experience. You can savor wide range of delicacies served here and pamper your hungry taste buds.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "You will get excellent Conference and banquet facilities at hotel Balsamand Lake Palace. The meeting rooms and boardrooms at Ajit Bhawan Palace are set with all necessary equipments.",
                    },
                ],
            },
            {
                title: "Gajner Palace",
                img: [
                    { img: GajnerPalace2 },
                    { img: GajnerPalace3 }
                ],
                img1: GajnerPalace1,
                description:
                    "Gajner Palace, Bikaner which is constructed of red sandstone is very famous amongst the roll of Heritage Hotels in Bikaner. It stretches over a sprawling terrain of 6000 acres. It is remarkable for its architectural grandeur in addition to its plush suites and rooms. The greenery in the vicinity of Gajner Palace, Bikaner along with its splendid lawns, the charming twittering of birds, the leisurely walk of peacocks – all play their roles in making it a cherished destination for tourists.",
                content: [
                    {
                        title: "Accomodation",
                        desc: "Hotel Gajner Palace has 44 rooms to offer that include 13 Historic Suites along with 31 Deluxe Rooms. All these rooms are adorned with attractive period furniture, which would remind you of an erstwhile royal era. The big bathrooms, separate dressing rooms, separate sitting cum dining rooms are a few of the facilities that you will get at the Historic Suites. State-of-the-art facilities are also available at the Deluxe rooms of Gajner Palace, Bikaner.",
                    },
                    {
                        title: "Dining",
                        desc: "Your taste buds can relish traditional Rajasthani fare along with a wide range of Continental and Indian cuisine at Mirage, the restaurant of Hotel Gajner Palace. At the Mrigtrishna Bar of Gajner Palace Hotel, you can enjoy a varied selection of foreign as well as Indian liquor. Imperial Terrace facing the magnificent Gajner Lake, which can accommodate about 200 guests offers dining facility in open-air in the company of chirping birds.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "Hotel Gajner Palace gives the facility of a Boardroom along with a Conference room that can seat between 12 and 120 people. This hotel also has a Banquet.",
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        title: "Jaipur Heritage Hotel",
        banner: jaipur,
        places: [
            {
                title: "Rambagh Palace ",
                img: [
                    { img: Rambagh2 }
                ],
                img1: Rambagh1,
                description:
                    "One of the finest heritage hotels in Jaipur, Rambagh Palace in Jaipur is fondly called the Jewel of Jaipur. Situated on Bhawani Singh Road, the hotel allows easy approach to all major tourist attractions in Jaipur.",
                content: [
                    {
                        title: "History",
                        desc: "Built in 1835 AD for the queen's favorite handmaiden Kesar Badaran, The Rambagh Palace, Jaipur was transformed into a palace in 1925 and became the residence of the Maharaja of Jaipur. In 1957 it was converted into a plush hotel by Maharaja Sawai Man Singh II, and taken over by the Taj Hotels Resorts and Palaces in the year 1972.",
                    },
                    {
                        title: "Accomodation",
                        desc: "There are 85 well-appointed rooms and suites including Luxury Rooms, Historical Suites, Royal Suites, Grand Royal Suites, and Grand Presidential Suite at the hotel. All rooms are beautifully decked with marbled corridors.",
                    },
                    {
                        title: "Dining",
                        desc: "The dining facilities available at Rambagh Palace in Jaipur:-",
                    },
                    {
                        title: "Restaurants",
                        desc: "The gracefully adorned dining hall Suvarna Mahal offers an array of exotic delicacies to pamper your taste buds. The all-day dining restaurant Rajput Room is perfect for breakfast and light meals. The alfresco dining room Verandah provides excellent champagne and pre-dinner cocktails along with delicious eatables.",
                    },
                    {
                        title: "Pubs",
                        desc: "Unwind with some delightful wines and liquors at the well-stocked Polo Bar.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "There are two conference rooms for meetings at the hotel that can accommodate up to 80 participants, while the Conference SMS Function Centre can hold conventions of up to 350 delegates. Both these spacious rooms are aided with latest amenities like fax, internet, etc. For theme parties, there are terrace, garden and lawn areas for up to 3000 guests.",
                    },
                ],
            },
            {
                title: "Raj Mahal Palace",
                img: [
                    { img: RajMahalPalace2 }
                ],
                img1: RajMahalPalace1,
                description:
                    "One of the finest heritage hotels in Jaipur, Raj Mahal Palace in Jaipur is set in the backdrop of 15 acres of vibrant green lawns. Situated 11 km from the nearest airport, 2 km from the closest railway station, and 3 km from the bus stand.",
                content: [
                    {
                        title: "History",
                        desc: "Raj Mahal Palace, Jaipur was originally built in 1729 AD by Maharaja Sawai Jai Singh II for his beloved wife, Maharani Chandra Kumar Ranawatiji, daughter of the Maharana of Udaipur. The palace was later converted into a luxurious hotel in the year 1979.",
                    },
                    {
                        title: "Accomodation",
                        desc: "Each of the 23 air-conditioned rooms at Raj Mahal Palace in Jaipur, including 10 standard rooms, 8 superior rooms, 3 junior suites, and 2 plush Royal suites (Maharaja and Maharani Suites), is a cocoon of comfort. These tastefully appointed rooms are beautifully decorated with rich fabrics, antiques and precious paintings.",
                    },
                    {
                        title: "Dining",
                        desc: "The fully air-conditioned multi-cuisine restaurant at Raj Mahal Palace in Jaipur caters lip smacking preparations from Continental and Indian cuisine to pamper your taste buds.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "The spacious banquet hall at Raj Mahal Palace in Jaipur, decked with wooden dance floor, is well-suited for big receptions as also for small get-togethers.",
                    },
                ],
            },
            {
                title: "Jai Mahal Palace ",
                img: [
                    { img: JaimahalPalace2 }
                ],
                img1: JaimahalPalace1,
                description:
                    "One of the finest heritage hotels in Jaipur, Jai Mahal Palace in Jaipur is positioned downtown on Jacob Road in Civil Lines, near the main shopping center and historical monuments.",
                content: [
                    {
                        title: "History",
                        desc: "Nestled amidst 18 acres of sprawling Moghul gardens, the beautiful JaiMahal Palace in Jaipur was built in 1745 A.D.",
                    },
                    {
                        title: "Accomodation",
                        desc: "There are 100 well-appointed rooms including 6 suites at the hotel. Each room is decorated brilliantly with Indo-Saracenic style of architecture and miniature paintings.",
                    },
                    {
                        title: "Dining",
                        desc: "The dining facilities available at Jai Mahal Palace in Jaipur :-",
                    },
                    {
                        title: "Restaurants",
                        desc: "Enjoy pan-Indian dishes at Cinnamon restaurant, or Italian delicacies at Giardino, or contemporary eatables at Marble Arch.",
                    },
                    {
                        title: "Pubs",
                        desc: "Unwind with some delightful wines and liquors at the well-stocked Marigold Bar, or the Pool Lounge, or the open-air lounge The Terrace.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "There is a state-of-the-art conference hall and two board rooms at the hotel with a capacity for 10 to 150 persons in auditorium style and 200 guests for reception parties. For large parties and spectacular theme parties, gardens are also available.",
                    },
                ],
            },
            {
                title: "Narain Niwas Palace Hotel ",
                img: [{
                    img: NarainNIwas2
                }],
                img1: NarainNIwas1,
                description:
                    "A perfect accommodation option for leisure travelers, Narain Niwas Palace Hotel, Jaipur is one of the finest heritage hotels in Jaipur. Ideally suited for royal weddings, the hotel is located 11 km from the nearest airport, 5 km from the closest railway station, and 4 km from the bus stand.",
                content: [
                    {
                        title: "History",
                        desc: "Narain Niwas Palace Hotel, Jaipur is presently run by Kanota Resorts Pvt Ltd. Originally it was a magnificent palace built in the year 1928 century by General Amar Singh Ji, the then Thakur of Kanota.",
                    },
                    {
                        title: "Accomodation",
                        desc: "38 air-conditioned and air-cooled rooms await you at Narain Niwas Palace Hotel in Jaipur. Each room is tastefully appointed and decked with exquisite Rajasthani furnishing and beautiful Jaipur painted wall décor.",
                    },
                    {
                        title: "Dining",
                        desc: "Narain Niwas Palace Hotel in Jaipur treats you to special royal thalis from authentic Rajasthani cuisine to pamper your taste buds.",
                    },
                ],
            },
            {
                title: "Samode Haveli",
                img: [{
                    img: SamodeHaveli2
                }],
                img1: SamodeHaveli1,
                description:
                    "One of the finest heritage hotels in Jaipur, Samode Haveli in Jaipur is a cozy hotel located in the heart of the city. All the prime tourist attractions of Jaipur, including the imposing Amber Fort, Hawa Mahal, Jal Mahal, the City Palace Museum, and the shopping center in Jaipur are in close proximity to the hotel.",
                content: [
                    {
                        title: "History",
                        desc: "Built over 150 years ago by Rawal Sheo Singhji, a Prime Minister in the Jaipur court, who belonged to the family of Samode, the Samode Haveli in Jaipur was transformed into a heritage hotel in the year 1988.",
                    },
                    {
                        title: "Accomodation",
                        desc: "There are 29 well-appointed rooms and suites, including the luxurious Maharaja and Maharani suites at Samode Haveli, Jaipur. Each room is air-conditioned and decorated brilliantly.",
                    },
                    {
                        title: "Dining",
                        desc: "The dining facilities available at Samode Haveli in Jaipur: -",
                    },
                    {
                        title: "Restaurants",
                        desc: "Satiate your gastronomical desires at the hand-painted dining hall with some delicious preparations. Private meals and candle-lit dinners can also be arranged in the lush green back garden.",
                    },
                    {
                        title: "Pubs",
                        desc: "Unwind with some delightful wines and liquors at the well-stocked bar by the pool side.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "The well-equipped conference room at Samode Haveli, Jaipur can accommodate up to 50 persons. The reception area has room for 150 guests. Besides, special theme lunches and dinners can be organized at the hotel.",
                    },
                ],
            },
        ],
    },
    {
        id: 3,
        title: "Jaisalmer Heritage Hotel",
        banner: jai,
        places: [
            {
                title: "Jawahar Niwas Palace ",
                img: [{
                    img: JawaharNiwas2
                }],
                img1: JawaharNiwas1,
                description:
                    "Jawahar Niwas Palace was constructed in 1899 under the reign of Maharaja Shalivahan Singh Ji. The present Maharaja converted it into a heritage hotel. The hotel exudes elegance and charm. This is one of the best places to stay in Jaisalmer. Experience a slice of life from the royal quarters. This sprawling golden sandstone palace bears testimony of the royal grandeur and class.",
                content: [
                    {
                        title: "Accomodation",
                        desc: "Accommodation in Jawahar Niwas Palace speaks of class and elegance. A perfect blend of traditional marble work and modern amenities has been incorporated to bring out the best of both worlds. They give you a fair idea about the lavish lifestyle of the Rajputs. A free flow of colors and wooden period furniture have been used to give that special edge to the rooms. Most of the rooms overlook the Jaisalmer fort.",
                    },
                    {
                        title: "Dining",
                        desc: "Check out the multi cuisine restaurant of Jawahar Niwas Palace. Rang Mahal truly has a colorful ambience with a wide variety of Indian, Chinese, and Continental dishes doing the rounds. The restaurant is decorated with stone motifs and elegant murals.",
                    },

                    {
                        title: "Business and Banquets:",
                        desc: "A well equipped conference room in Jawahar Niwas Palace takes care of the business proceedings. It is fitted with the latest automation technology.",
                    },
                ],
            },
            {
                title: "Fort Rajwada",
                img: [{
                    img: FortRajwada2
                }],
                img1: FortRajwada1,
                description:
                    "Fort Rajwada, Jaisalmer was built with the patronage of Shri Jitendra Singh Rathore and Shri Dileep Singh Rathore. Spread on a land of 6 acres, Fort Rajwada is a magnificent structure standing evidence to its royal past. The interior of this princely house was designed by the gifted and celebrated opera designer Ms. Stephanie Engeln.",
                content: [
                    {
                        title: "Accomodation",
                        desc: "Fort Rajwada, Jaisalmer offers 65 gracefully furnished rooms with satellite television, telephone, attached bath with 24 hours H/C water. The superbly crafted Jharokhas of these rooms add a touch of grandeur to the interiors. The big and tastefully designed suites, four in number, bring alive the lively disposition of the desert-warriors. At each of these suites of Hotel Fort Rajwada, you will get a living room, a bedroom, large bathroom, H/C running water round the clock, satellite television and telephone.",
                    },
                    {
                        title: "Dining",
                        desc: "A variety of dining options are available at Hotel Fort Rajwada, Jaisalmer ",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "At Fort Rajwada, Jaisalmer, two entirely equipped conference rooms are offered. Facilities like Internet and e-mail are also admired by the guests, particularly the business travelers staying at Fort Rajwada Hotel.",
                    },
                ],
            },
            {
                title: "Hotel Taj Rawalkot (Jaisalmer) ",
                img: [{
                    img: Rawalkot2
                }],
                img1: Rawalkot1,
                description:
                    "Taj Rawalkot, Jaisalmer seems to rise out of the rocks. Inspired by the grand havelis of yesteryear, Hotel Taj Rawalkot presents postcard-perfect sights of the Jaisalmer Fort built in 12th century apart from the setting sun. The design of Taj Rawalkot, Jaisalmer makes use of a wide range of local colors, materials as well as fabrics.",
                content: [
                    {
                        title: "Accomodation",
                        desc: "Taj Rawalkot, Jaisalmer offers 31 stylishly furnished AC rooms with all sorts of modern facilities. So, you can stay assured of a lavish stay amidst royal ambience at Hotel Taj Rawalkot.",
                    },
                    {
                        title: "Dining",
                        desc: "Taj Rawalkot, Jaisalmer offers delectable Indian, Chinese, Continental, Tandoori and Rajasthani cuisine to enthrall the taste buds of its guests. So, you would be spoilt for a choice when it comes to dining at Hotel ",
                    },
                ],
            },
            {
                title: "Nachna Haveli Hotel (Jaisalmer)",
                img: [{
                    img: NachnaHaveli2
                }],
                img1: NachnaHaveli1,
                description:
                    "One of the most sought after heritage hotels in the city, Nachna Haveli boasts of providing luxurious comforts and excellent services at reasonable prices to its guests. The hotel being easily accessible is preferred equally by both holiday makers and business travelers.",
                content: [
                    {
                        title: "History",
                        desc: "Also referred to as the Nachna Thikana,, Nachna Haveli has now been converted into a hotel full fledged with modern comforts.",
                    },
                    {
                        title: "Accomodation",
                        desc: "Nachna Haveli, Jaisalmer provides excellent accommodation facilities to its guests. The hotel offers 14 well furnished and tastefully decorated rooms to its guests. The rooms are spacious and well equipped with modern facilities making the stay of its guests comfortable.",
                    },
                    {
                        title: "Dining",
                        desc: "ffering delicious delights, Nachna Haveli promises to provide its guests with a memorable and a fine dining experience. The hotel serves wide variety of cuisine from across the regions for its guests to savor and tickle their hungry taste buds. Be it Indian, Continental, Chinese or local Rajasthani delights you will get everything at Nachna Haveli to satisfy your hungry palate",
                    },
                ],
            },
            {
                title: "Narayan Niwas Palace (Jaisalmer)",
                img: [{img: NarayanNiwasJaisalmer2}],
                img1: NarayanNiwasJaisalmer1,
                description:
                    "Narayan Niwas Palace is a 19th century ancestral property which has been converted to a Heritage Hotel. The hotel is elegantly furnished with a touch of royalty in every nook and corner. It is one of the best places to put up in Jaisalmer if you have a luxurious bent of mind and want to enjoy your money's worth. Narayan Niwas Palace provides you with international standard of services and luxurious accommodations which are well worth mentioning. The palace reflects the splendor and magnificence of the Rajput era.",
                content: [
                    {
                        title: "Accomodation",
                        desc: "The 43 rooms are refurnished with period furnitures, chandeliers, murals and motifs which have a royal ring to it. The rooms overlook the main center of Jaisalmer. All the modern amenities that are called for in life are present here. Each of the rooms are decorated with a separate theme. The modern trimmings and the ethnic trimmings blend perfectly and in no way do they seem jarring or forced.",
                    },
                    {
                        title: "Dining",
                        desc: "Have a pick of your favorite cuisine in Chinese, Indian or Continental from Gokhra which is the in house restaurant of Narayan Niwas Palace. Nibble on the authentic Rajasthani cuisines in the restaurant. Dinner is followed by folk music in the hotel premises. Folk musicians play the score while you have a hearty dinner. The whole décor of the restaurant speaks of class and elegance. You can spend a leisurely evening with your friends in the Pagda bar.",
                    },
                ],
            },
        ],
    },
    {
        id: 4,
        title: "Jodhpur Heritage Hotel",
        banner: jod,
        places: [
            {
                title: "Ajit Bhawan Palace",
                img: [{
                    img: AjitBhawanPalace2
                }],
                img1: AjitBhawanPalace1,
                description:
                    "Ajit Bhawan Palace in Jodhpur is a magnificent mansion built by Maharaja Ajit Singhji of Jodhpur. The palace had been the home to the Maharaja at one point of time. Like all other mansions built by Maharaja Ajit Singhji of Jodhpur, this huge mansion also allures you with its royal appeal and unmatched sophistication. Following the latest trends, this royal palace has been transformed into heritage hotel at present, but still now, a part of this palace is occupied by the royal family. This one is believed to be India's first heritage hotel.",
                content: [
                    {
                        title: "Accomodation",
                        desc: "Ajit Bhawan offers you the promise of a wonderful holiday where you will be greeted with a heart warming welcome and experience the very best of class, style and comfort. There are 81 rooms at Hotel Ajit Bhawan. All the rooms have telephone, TV, Mini bars, Air conditioning, Wi-Fi, Electronic Safe, Tea/Coffee Makers, and Hair Dryers.",
                    },
                    {
                        title: "Dining",
                        desc: "The well laid mutlicuisine restaurant at the royal chamber offers delectable dishes from Indian, Marwari and Rajasthani cuisines.",
                    },

                    {
                        title: "Business and Banquets:",
                        desc: "Superb Conference and banquet facilities are available at hotel Ajit Bhawan. The meeting rooms and boardrooms at Ajit Bhawan Palace are finely appointed with all the necessary modern electronic equipments. Arrangements are made to handle large-scale business seminars and meetings.",
                    },
                ],
            },
            {
                title: "Ranbanka Palace",
                img: [{
                    img: Ranbanka2
                }],
                img1: Ranbanka1,
                description:
                    "Overlooking the magnificent Mehrangarh Fort on the one side and gigantic Umaid Bhawan on the other is the majestic Ranbanka Palace of Jodhpur. A perfect blend of the royal style and modern luxurious comforts, Ranbanka Palace boasts of providing a memorable stay and warm hospitality to its guests. One of the finest heritage hotels in the Jodhpur city, Ranbanka Palace is preferred equally by both holiday makers and business travelers.",
                content: [
                    {
                        title: "History",
                        desc: "Built for Maharaja Ajit Singhji, the younger brother of Maharaja Umaid Singh ji, Ranbanka Palace is a beautiful sandstone building that has now been converted into a heritage hotel.",
                    },
                    {
                        title: "Accomodation",
                        desc: "Offering 58 well furnished and tastefully appointed rooms/suites, Ranbanka Palace provide you with a fabulous staying experience. The rooms in Ranbanka Palace are elegantly decorated with antique colonial furniture and have a warm and a cozy ambience. The rooms are categorized as deluxe and suites and are replete with modern comforts making the stay of its guests luxurious.",
                    },
                    {
                        title: "Dining",
                        desc: "With two fine restaurants to choose from, dining can be quite a memorable experience at Ranbanka Palace. ‘Panchranga’ is a multicuisine buffet restaurant at Ranbanka that is beautifully decorated in the royal style and takes you to the bygone era. The restaurant is air conditioned and serves wide variety of cuisine from across the continents for its guests to relish and tickle their taste buds. Besides, ‘Roof Top Restaurant’ at Ranbanka is ideal for Theme and Mashaal dinners. ‘Trophy Bar’ is a well stocked bar at Ranbanka Palace.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "Providing state of the art business facilities, Ranbanka Palace is ideal for business travelers as well. The hotel also provides well facilitated business center to business travelers.",
                    },
                ],
            },
            {
                title: "Pal Haveli",
                img: [{
                    img: PalHaveli2
                }],
                img1: PalHaveli1,
                description:
                    "One of the finest heritage hotels in the city, Pal Haveli is well known for providing modern luxurious stay and warm hospitality to its guests. Being located near the invincible Mehrangarh Fort, it is also one of the most sought after heritage hotels in the city.",
                content: [
                    {
                        title: "History",
                        desc: "Pal Haveli is a classic 18th century Noble Home that has now been converted into a heritage hotel replete with modern amenities.",
                    },
                    {
                        title: "Accomodation",
                        desc: "Offering well furnished and well decorated rooms, accommodation in Pal Haveli is something to look forward to while on your stay here. The rooms in Pal Haveli are spacious and elegantly decorated in royal style giving you a feel of the bygone era. Besides, the rooms are well facilitated with modern amenities making the stay of its guests comfortable.",
                    },
                    {
                        title: "Dining",
                        desc: "Pamper your taste buds at the multicuisine restaurant in Pal Haveli. Or enjoy candle light dinner on the higher outer terrace of the Haveli. The hotel serves its guests with variety of delectable delights from across the regions to savor and pamper their hungry palate.",
                    },
                ],
            },
            {
                title: "Umaid Bhawan Palace",
                img: [{
                    img: UmaidBhawan2
                }],
                img1: UmaidBhawan1,
                description:
                    "Beautifully set amidst 26 acres of lush greenery is the majestic Umaid Bhawan Palace, one of the great palaces of India. Named after Maharaja Umaid Singh, Umaid Bhawan is a golden- yellow sandstone structure built in fashionable Art Deco style of the time. Converted into a hotel today and owned by the famous Taj Group of Hotels, Taj Umaid Bhawan Palace boasts of providing royal luxurious comforts and state of the art services coupled with traditional warm hospitality to its guests.",
                content: [
                    {
                        title: "History",
                        desc: "Named after Maharaja Umaid Singh, Umaid Bhawan Palace was built in the fashionable Art Deco style of the time. It is a golden yellow sandstone monument which took 15 years to complete. This 347 room palace was finally completed in 1943 and has served as the principle residence of the Jodhpur royal family since. Blend of the eastern and western architectural influences, Taj Umaid Bhawan Palace was designed by the renowned Edwardian architect Henry Lanchester.",
                    },
                    {
                        title: "Accomodation",
                        desc: "With 64 well furnished and elegantly appointed rooms/suites to choose from, staying could indeed be a memorable experience for guests at the Taj Umaid. There are 25 rooms and 39 suites in all at the Taj Umaid Bhawan Palace The rooms/ suites are categorized as Maharani Suite, Maharaja Suite, Royal Suite, Regal Suite and Deluxe Rooms. All the rooms in Taj Umaid Palace are beautifully designed in royal style and show case the Art Deco style with a distinct ambience.",
                    },
                    {
                        title: "Dining",
                        desc: "Serving delectable delicacies from across the regions Taj Umaid Bhawan promises to provide its guests with an exquisite and a memorable dining experience. The hotel invites its guests to ‘Risala’ a la carte restaurant serving wide range of cuisine from across the regions. ‘The Pillars’ is a coffee shop at Taj Umaid where you can relish a steaming cup of hot coffee while enjoying a spectacular view of the Jodhpur city. Besides, ‘The Trophy Bar’ is a well stocked bar serving wide range of exotic drinks to choose from.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "With four options to choose from, you can easily conduct a business meeting or a conference at the Taj Umaid Bhawan Palace. The hotel provides state of the art business facilities and is therefore ideal for business travelers.",
                    },
                ],
            },
        ],
    },
    {
        id: 5,
        title: "Udaipur Heritage Hotel",
        banner: udai,
        places: [
            {
                title: "Lake Palace Hotel",
                img: [{
                    img: LakePalace2
                }],
                img1: LakePalace1,
                description:
                    "Sprawled beautifully over 4-acres, against the backdrop of majestic Aravalli Mountains on one side of the lake, and towering palaces on the other, the Lake Palace in Udaipur floats ethereally on the glistening waters of the lake. Now functioning as a luxury hotel, the palace features fairytale gardens, offering extravagant comfort and regal décor, making it one of the most sought-after hotels in Udaipur.",
                content: [
                    {
                        title: "History",
                        desc: "Located on an island in the middle of Lake Pichola in Udaipur, Lake Palace Hotel Udaipur is a premier Heritage Hotel owned by the esteemed Taj Group of Hotels. The exquisite white marble Lake Palace was conceived in romance, and built as a palace in 1746 by Maharana Jagat Singh II, a successor to the royal dynasty of Mewar.",
                    },
                    {
                        title: "Accomodation",
                        desc: "Enjoy regal accommodation at any of the 66 Rooms and 17 Suites elegantly furnished with richly colored murals and ornately carved wooden furnishings. Sporting opulent grandeur and royal décor, the rooms provide splendid views of the lake and are fitted with all luxury guest amenities. The grand suites are embodiments of royal grandeur, and they create a regal world, where guests can surely enjoy the complete experience of staying in a palace.",
                    },
                    {
                        title: "Dining",
                        desc: "The hotel offers a wide array of dining options ranging from casual, all-day dining to formal, fine dining.",
                    },

                    {
                        title: "Business and Banquets:",
                        desc: "The hotel houses plush Meeting Rooms that are perfectly equipped to seat 35-50 pax. The Lily Pond Courtyard is the ideal venue for hosting outdoor dinners, and theme events can be held at the Mewar Terrace. Business equipments offered include Broadband Internet access, Color copier, Laptop computers, Portable printers, Secretarial services, Wireless Internet access..",
                    },
                ],
            },
            {
                title: "Fateh Prakash Palace",
                img: [{
                    img: FatehPrakash2
                }],
                img1 : FatehPrakash1,
                description:
                    "A Grand Heritage Palace standing majestically along the shores of Lake Pichola in Udaipur, Fateh Prakash Palace was named after Maharana Fateh Singh - one of the greatest rulers of Mewar dynasty. Converted into a Heritage Hotel par excellence, this magnificent palace features distinctive turrets and majestic domes, and is a treasure-trove of exquisite chandeliers and priceless paintings, unique crystal and crockery collections.",
                content: [
                    {
                        title: "History",
                        desc: "Fateh Prakash Palace Hotel was certified as the best Heritage Hotel in 'Heritage Grand' category for 2001-2002 by the Department of Tourism, Government of India. Traditional Marwari hospitality coupled with opulent facilities and efficient services makes Fateh Prakash a highly favored holiday retreat for tourists visiting Udaipur.",
                    },
                    {
                        title: "Accomodation",
                        desc: "The elegantly designed rooms and regal suites at The Fateh Prakash Palace are decorated with original portraits and period furnishings, and are designed to give maximum comfort and luxury. The newly renovated Dovecote Rooms offer excellent views of the Lake Pichola. Basic room facilities offered include centrally air-conditioned, with international direct dial telephone, satellite television, mini-bar, tea/coffee making facilities.",
                    },
                    {
                        title: "Dining",
                        desc: "The Gallery Restaurant is a specialty restaurant, which serves the best of Continental cuisines. The Sunset View Terrace overlooking the Lake Pichola is the perfect place for light refreshments and buffet dining.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "The palace offers several historic venues for hosting exclusive Banquets, Conferences, and Weddings at the Durbar Hall, Top Terrace, Sunset View Terrace and other venues within the premises. Resplendent with chandeliers and crystal, the Durbar Hall is the perfect venue for Conferences and other corporate events. The Top Terrace can seat up to 300 guests in buffet style dining and up to 190 guests for formal banquets.",
                    },
                ],
            },
            {
                title: "Shiv Niwas Palace",
                img: [{
                    img: shivniwas2
                }],
                img1: shivniwas1,
                description:
                    "A magnificent palace lying at the southern end of the Palace Complex of Udaipur city - Shiv Niwas Palace originally served as the royal residence of Maharana Fateh Singhji. It was much later that the late Maharana Bhagwat Singhji envisaged the palace as a venue for entertaining visitors to the city, which led to its conversion into a fine Heritage Hotel. This lavish Hotel is equipped with all modern-day comforts that will ensure that your stay in Udaipur turns out an absolutely royal experience.",
                content: [
                    {
                        title: "Accomodation",
                        desc: "The hotel offers royal accommodation in elegantly appointed suites and rooms, fitted with private terraces and adorned with period furniture, chandeliers, portraits and original paintings. The rooms and suites offer spectacular lake or mountain views, and feature modern in-room facilities like centrally air-conditioned, with international direct dial telephone, satellite television, mini-bar, tea/coffee making facilities.",
                    },
                    {
                        title: "Dining",
                        desc: "The Paantya multi-cuisine restaurant serves a gourmet's delight of Indian and Continental breakfast and sumptuous meals. The Poolside restaurant serves light snacks all day, apart from wholesome breakfast, lunch and dinner. Paneera Bar stores an exotic collection of Indian and imported liquor.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "The palace offers excellent venues for conducting Banquets, Conferences, and Wedding Receptions. The Lawns in front of the bandstand are perfectly suited for sit-down banquets, buffets and wedding ceremonies. Resplendent with chandeliers and crystal, the Durbar Hall is the perfect venue for Conferences and other corporate events. The hall can accommodate up to 120 guests for formal banquets and 300 guests for buffet style dining.",
                    },
                ],
            },
            {
                title: "Grand Laxmi Vilas Palace",
                img: [{
                    img: LakshmiVilasUdaipur2
                }],
                img1: LakshmiVilasUdaipur1,
                description:
                    "Located on the banks of Fateh Sagar Lake in Udaipur - Grand Laxmi Vilas Palace Hotel is an acclaimed Heritage Hotel which provides picturesque views of the lake, Aravalli Hills, and Sajjangarh Palace.",
                content: [
                    {
                        title: "History",
                        desc: "The Palace building was originally built by Maharana Bhupal Singh in 1911, to serve as the residence of Mr. Winn Gate - an eminent political agent of that time. Later, in 1945, Mr. Gate was shifted to another place and Grand Laxmi Vilas Palace was revamped to serve as a royal guest house for dignitaries, visiting princes and British officers. Managed by the Grand Group of Hotels since 2002, Laxmi Vilas Palace Hotel has been meticulously restored, with special emphasis on accommodation options - which finely blends all contemporary luxuries with unique royal splendors.",
                    },
                    {
                        title: "Accomodation",
                        desc: "The hotel provides lavish accommodation to guests in a total of 11 Royal Suites, 3 Executive Suites and 41 Deluxe Palace Rooms. Furnished with elegant décor, the rooms and suits are appointed with all amenities of modern comfort.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "Laxmi Vilas Palace houses a multi-functional Conference Hall which can accommodate 120 guests. The manicured lawns and pool side venues are ideally suited for hosting outdoor parties and receptions. The business facilities available at the hotel include Conference System, Slide / OHP Projector, White Board, TV / VCR, LCD Projector, Fixed and Cordless Mikes, etc.",
                    },
                ],
            },
            {
                title: "Devi Garh Fort Palace",
                img: [{
                    img: Devigarh2
                }],
                img1: Devigarh1,
                description:
                    "Nestled in the Aravali hills of Rajasthan, the 18th century Devi Garh Fort Palace, in the village of Delwara marks one of the three main entrances into the valley of Udaipur. This palace was awarded to Sajja Singh, who hailed from the neighboring state of Gujarat, for his bravery and loyalty to Maharana Pratap at the battle of Haldighati (1576) against the Mughal emperor Akbar. However, the construction of the fort palace started only in 1760s, under Raghudev Singh II, with later additions being made by the rulers who followed. The palace was absorbed into the state of Rajasthan in the 1960s, and was subsequently abandoned.",
                content: [
                    {
                        title: "Accomodation",
                        desc: "The all-suite Devigarh Resort offers luxury at its best. The Devigarh suite, the best suite at the hotel has a private Jacuzzi, a swimming pool and a bedroom and sitting room unit.",
                    },
                    {
                        title: "Dining",
                        desc: "Dining assumes unforeseen heights at the Devigarh Resort. From Asian to continental to local cuisine-everything is available for the health conscious, there is the spa menu. The bar serves an interesting assortment of liquor and cocktails.",
                    },
                    {
                        title: "Banquets and Conference:",
                        desc: "Seminars, meetings and workshops cam be easily arranged at Devigarh. The Conference hall accommodates 50 people and is adequately equipped. For formal banquets, the elegant Durbar Hall, outdoor gardens and courtyards can also be used.",
                    },
                ],
            },
        ],
    },
    {
        id: 6,
        title: "Others Heritage Hotel",
        banner: other,
        places: [
            {
                title: "Khimsar Fort (Khimsar)",
                img: [{
                    img: KhimsarFort2
                }],
                img1: KhimsarFort1,
                description:
                    "The Khimsar Fort, now a premium heritage hotel, was constructed by Rao Karamsiji, the 8th son of Rao Jodha, the founder of Jodhpur. The construction on the fort began in 1523. Lter adages to the fort included a zenana (ladies) wing, and a private regal wing built by Thakur Onkar Singh for himself. A part of the fort still forms the residence of the royal family of Khimsar.",
                content: [
                    {
                        title: "Accomodation",
                        desc: "The 50 rooms at the hotel offer luxury at its best in a grand and elegant ambience. The carved hanging casements attached to the rooms, the elegant period furniture and the latest amenities available makes Khimsar Fort a popular option for leisure tourists eho want to experience traditionalism with a difference.",
                    },
                    {
                        title: "Dining",
                        desc: "The multi cuisine restaurant serves lip smacking Indian, Chinese and continental cuisine. But the spicy Rajasthani cuisine is the icing on the cake.",
                    },

                    {
                        title: "Business and Banquets:",
                        desc: "The conference facilities at the hotel are state of the art. Necessary facilities like audio visual equipments, internet, podium, etc. are available. Banquets and formal receptions at Khimsar Fort can be arranged at their grandest.",
                    },
                ],
            },
            {
                title: "Samode Palace (Samode)",
                img: [],
                img1: SamodePalace,
                description:
                    "The Samode Palace was built 400 years ago, and the palace was converted into Heritage Hotel by the Samode family. The palace is a wonderful example of Rajput-Mughal architecture.",
                content: [
                    {
                        title: "Accomodation",
                        desc: "Hotel Samode Palace of Samode in Rajasthan has a provision for 43 well appointed guestrooms which include 3 Royal Suites 15 Deluxe suites and 25 Deluxe Rooms and all the rooms are air-conditioned. The hotel provides attached modern bathrooms and running hot and cold water in all the rooms.",
                    },
                    {
                        title: "Dining",
                        desc: "The tourists, staying in Hotel Samode Palace, Samode can enjoy delicious Indian and Rajasthani preparations as well as local food items at the in-house multi-cuisine restaurant. The chief dining room and the terrace of the hotel serve traditional Rajasthani food as well as a blend of Asian and European food. Light refreshments are served by the pool, and at the Central Courtyard of the hotel.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "The Hotel Samode Palace has a provision for an air-conditioned and well equipped conference hall where the business tourers can carry out their important business meetings, seminars and conferences. The Durbar Hall can have room for up to 80 people for meeting and conferences. Private parties can be held in the well arranged banquet hall of the hotel.",
                    },
                ],
            },
            {
                title: "Castle Mandawa (Mandawa)",
                img: [],
                img1:CastleMandawa,
                description:
                    "Castle Mandawa, Rajasthan is a fortress that is 240 years old. In the recent years, this castle has been renovated by the royal family into a heritage hotel. At Castle Mandawa, Rajasthan, you can get an excellent example of what traditional hospitality means in the actual sense.",
                content: [
                    {
                        title: "Accomodation",
                        desc: "Castle Mandawa, Rajasthan offers 51 rooms, each of which is adorned with magnificent period furniture. Each of these rooms depicts a particular theme of decoration and no two rooms resemble each other. The rooms come in various categories called luxury suites, royal suites, deluxe and standard rooms. You can have the most remarkable and spectacular sight of the enormous expanse of Mandawa region from Hotel Castle Mandawa's terrace.",
                    },
                    {
                        title: "Dining",
                        desc: "Dinner at Castle Mandawa, Rajasthan is a romantic affair. The rear courtyard of Castle Mandawa Hotel gets transformed into an open air dinning room that is bright and bejeweled. The candle lit dinner of delicious cuisine from around the world would surely be an event to remember.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "Two big meeting rooms with adjacent terrace, syndicate rooms, separate boardroom in addition to an entirely equipped business centre provides all kinds of facility for arranging corporate meetings.",
                    },
                ],
            },
            {
                title: "Fort Chanwa (Luni)",
                img: [{
                    img: FortChanwa2
                }],
                img1: FortChanwa1,
                description:
                    "Maharaja Jaswant Singh II, the ruler of Jodhpur Marwar from 1876-1895 A.D is thought of as the benevolent king who guided the administration and economy of Marwar away from disorder and flux. There he built an exquisite little fortress, now known as Fort Chanwa. In 1941, the village of Chanwa became Khalsa (belonging to the State). In the present day Fort Chanwa is reconstructed to its immaculate grandeur and charm by its proud owners Maharaj Dalip Singh & Rani Madhu Devi.",
                content: [
                    {
                        title: "Dining",
                        desc: "The tourists, staying in Hotel Fort Chanwa in Rajasthan, can enjoy delicious traditional Continental and Rajasthani preparations as well as local food items at the in-house restaurant. The restaurant in the hotel fresh vegetables and one can choose from the wide range of lip smacking vegetarian and continental cuisine.",
                    },
                    {
                        title: "Accomodation",
                        desc: "Hotel Fort Chanwa of Rajasthan has a provision for 31 well appointed and centrally air-conditioned guestrooms which are well maintained and equipped with modern facilities and amenities. The hotel rooms are furnished with wood-carved furniture and decorated with royal artifacts. The hotel provides attached modern bathrooms and running hot and cold water in all the rooms.",
                    },
                    {
                        title: "Business and Banquets:",
                        desc: "The Hotel Fort Chanwa in Rajasthan has a provision for an air-conditioned and well equipped conference hall where the business tourers can perform their important business meetings, seminars and conferences. Private parties can be held in the well arranged banquet hall of the hotel.",
                    },
                ],
            },
            {
                title: "Rohet Garh Heritage Hotel (Rohetgarh)",
                img: [{
                    img: RohetGarh2
                }],
                img1: RohetGarh1,
                description:
                    "Sprawling over acres of manicured gardens, Rohet Garh Heritage Hotel is sure to seduce you at first sight. Situated far from the madding crowd on the banks of a lake and set in the midst of rural surroundings, it is a royal retreat. A palatial mansion, it has now been converted into a plush heritage hotel by the owners.",
                content: [
                    {
                        title: "Accomodation",
                        desc: "Choose from 34 traditionally decorated and air-conditioned rooms with immaculate attached bathrooms. Each room has a personality of its own and speaks of royal splendor. The frescoes and opulent ambience add to the regal charm of these rooms.",
                    },
                    {
                        title: "History",
                        desc: "Rohet Garh Heritage Hotel was the residence of the erstwhile rulers of Rohet. It was bestowed upon Thakur Dalpat Singh I for his courage and valor in military campaigns under the Rathores. Soon Rohet became one of the most important feudal estates of the state of Marwar.",
                    },
                    {
                        title: "Dining",
                        desc: "Sample the best of traditional Indian cuisine at Rohet Garh Heritage Hotel. Apart from providing heavenly and aromatic fare, the hotel also takes pride in its Culinary Workshops organized for guests every now and then.",
                    },
                ],
            },
        ],
    },
];
