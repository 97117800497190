import axios from "axios";

export const sendEmail = (data) => {
    axios.post(`${process.env.NODE_ENV === 'production' ? 'https://email-bytesalgo.vercel.app' : 'http://localhost:10000'}/api/email/sg/git`, data)
        .then(function (response) {
            // console.log(response);
            if(!alert("Thank You. You’ll be hearing from us soon!")){
                window.location.reload(); 
            }
        })
        .catch(function (error) {
            // console.log(error);
            if(!alert("Sorry, Try Again. Your request didn’t reach us.")){
                window.location.reload();
            }
        });
}