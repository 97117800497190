export const packageTour = [
    {
        "id": 1,
        "titleName": "North India with Taj Mahal",
        "Days": "15",
        "Nights": "14",
        "Banner": "Taj_Mahal in Tours.jpg",
        "Destinations": "Delhi - Mandawa - Bikaner - Pokran - Jaisalmer - Jodhpur - Ranakpur - Kumbhalgarh - Udaipur - Pushkar - Jaipur - Amber Fort - Fatehpur Sikari - Agra - Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. Overnight stay in Hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi",
                "Description": "After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid - one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638-1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India Gate - war memorial, The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 3,
                "title": "Day 03 - Delhi / Mandawa (260kms)",
                "Description": "After breakfast at the hotel drive to Mandawa upon arrival check-in hotel and later we leave to see Mandawa -- often called the “open air art gallery of Rajasthan’ the region is famous for the high quality frescoes. See the beautifully painted havelis on the ancient and the famous silk route built in the 18th and 19th centuary by the rich merchants. Later take a heritage walk in this beautiful village. You can also see the traditional lifestyle of the local people. Overnight in Hotel. ",

            },
            {
                "id": 4,
                "title": "Day 04 - Mandawa / Bikaner (180 Km)",
                "Description": "After breakfast, drive towards to Bikaner. On the way visit Fatehpur Village which is also famous for the splendid havelis of the Shekhawati region. Reach Bikaner by afternoon later set off for the sight seen tour of Bikaner includes visiting the impregnable 15th century Junagarh Fort reputed to have remained unconquered till date,the fort has a number of gates- Karan Pol (gate) provides access to the fort, next to it is Suraj Pol (sun gate).  Also visit the Camel Breeding Farm which carries out breeding and training of camels (closed on Sundays). Evening time is to visit Deshnoke Rat Temple. Overnight stay at your hotel in Bikaner",

            },
            {
                "id": 5,
                "title": "Day 05 - Bikaner / Jaisalmer (330kms)",
                "Description": "After early breakfast, leave through the Thar Desert for Jaisalmer, en-route visit Pokharan -- the birth place of Akbar the Great (3rd Mughal Empror). Visiting the local market in the heart of the village and after taking lunch we proceed towards Jaisalmer. Arrive there by early evening. Let the Sun set view of this desert city weaves magic on you, leaving you speechless and mesmerized. Overnight stay at hotel.",

            },
            {
                "id": 6,
                "title": "Day 06 - Jaisalmer",
                "Description": "Sightseeing tour of the Golden City -Jaisalmer includes Sonar Fort or the Golden fort, rises from the sand and merges with the golden hues of the desert ambience. Badal Niwas, Manak Chowk, Patwon Ki Haveli -- one of the largest and most extensively carved Haveli with remnants of mirror work and paintings on the walls, Nathmal Ji Ki Haveli known for its unique miniature style paintings and mighty tuskers carved out of yellow sandstone stand guard to the haveli & Deewan Salim Singh Ki Haveli -- Prime Minister’s residence of 18th century.Visit the Gadisar lake then in evening camel carts safari for sunset onto Sam or Khuri Sand Dunes where you can enjoy dinner in mud huts listen to the folk tunes  by the local artists and musicians. Overnight stay at hotel in Jaisalmer.",

            },
            {
                "id": 7,
                "title": "Day 07 - Jaisalmer / Jodhpur (280 kms)",
                "Description": "Early Morning depart to Jodhpur, en-route visit Mandore Gardens. Check into hotel. Post lunch seightseeing tour includes Mehrangarh fort -- pride of Jodhpur, still stand high witnessing the battles fought. Museum inside the fort displays a rich collection of old royal palanquins, arms, paintings, costumes, etc. Fort has number of palaces - Moti Mahal, Phool Mahal, Sheesh Mahal, Takht Vilas and Jhanki Mahal. On the way down from the fort, towards left is Jaswant Thada -- intricately carved marble stone temple shaped cenotaph of Maharaja Jaswant Singh II. Umaid Bhawan Palace or Chittar Palace - palace with no mortar bindings, only chiselled  sandstone blocks are interlocked together. Evening is leisure or you can walk along the famous spice market and feel the flavours of indian spices. Overnight stay at hotel.",

            },
            {
                "id": 8,
                "title": "Day 08 - Jodhpur / Bishnoi Villages / Ranakpur (185 km)",
                "Description": "After breakfast, drive to Ranakpur via Guda Bishnoi Village. Bishnoi tribe lead a vegetarian lifestyle, they respect and conserve nature in all aspect.Blackbucks, antelopes, chinkaras, peacocks and even cranes can be seen wandering freely and are worshipped by the Bishnoi tribes. On arrival at Ranakpur, the city named after Rana Kumbha, known for its Jain temple The four images of Adinath are enshrined in the main temple called 'Chamukh', having 29 halls and 1444 columns all with differently carved sculpturs.The complex also includes Sun Temple, Amba Mata Temple, Parsvanath and Neminath temples. Overnight stay at hotel.",

            },
            {
                "id": 9,
                "title": "Day 09 - Ranakpur / Kumbhalgarh / Udaipur (140 km)",
                "Description": "After breakfast drive to the City of Lakes - Udaipur, enroute visits Kumbhalgarh Fort - One of the few impregnated forts in Rajasthan, with 2nd longest wall after china around Kumbhalgarh, the Muchchal Mahavir temple is wisely popular. This fort stands on a rocky hill commanding an excellent view of the wild and rugged scenery of the Aravallis and the sandy desert of Marwar. Reach Udaipur in afternoon and check in hotel. Later, see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter. After that move around the narrow streets of Udaipur Rest of the time free for your leisure.",

            },
            {
                "id": 10,
                "title": "Day 10 - Udaipur",
                "Description": "After breakfast move out for the city tour of Udaipur visit the City Palace -- a grand palace with beautiful architecture, the Jagdish Temple -- dedicated to Lord Vishnu, and the folk art museum with a colorful collection of puppets, musical instruments and paintings.In the evening you can wander through the city or take a boat ride on Lake Pichola and see the fabulous lake palaces of Udaipur, the Jag Mandir Palace and the Jag Niwas Palace now known as the Taj Lake Palace, where you can enjoy your dinner in a regal ambience. Return to your hotel to stay overnight.",

            },
            {
                "id": 11,
                "title": "Day 11 - Udaipur / Pushkar (275 km)",
                "Description": "Leave Udaipur and head towards Pushkar on the way visit the Eklingji and Nagda Jain Temple. Also visit the Saas and Bahu Temple dedicated to Lord Shiva and Vishnu. Afternoon reach the Holy City - Pushkar, the place where Lord Brahma( Creator of the Hindu Trinity) and Savitri (His Wife) perform 'Yagnas'.Here we visit Brahama Temple and Lake Pushkar, where devotees take holy bath in lake water.The legand says that the lake sprout on the place where the lotus petals fell on earth from the hands of Lord Brahma. Pushkar is popular for the Pushkar Fair or Camel Fair that held every year in the month of November. In evening enjoy the walk along the colourful and bustling local market.",

            },
            {
                "id": 12,
                "title": "Day 12 - Pushkar / Jaipur (150 kms)",
                "Description": "On the way to Jaipur in Ajmer Visit Nasiya Golden Jain Temple, 'Dargah Sharif' and Adhai Din Ka Jhopra. After that welcome to The Capital city of Rajasthan which is also known as 'Pink City' This afternoon, set off to see The City Palace -- still the home of the former royalty H.H Bhawani Singh Ji of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory -- situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory, on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 13,
                "title": "Day 13 - Jaipur",
                "Description": "A full day sightseeing stopping to view the dramatic hilltop fortress at Amber -- ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’.Within the palace are Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the “Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy a Bollywood movie at “Rajmandir” the Second best Cinema in Asia. In the evening then enjoy dinner with folk dances and music by typical Rajasthani women in an Indian Restaurant.",

            },
            {
                "id": 14,
                "title": "Day 14 - Jaipur / Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur - Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is 54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti, Jodha Bai and Birbal's house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 15,
                "title": "Day 15 - Agra / Delhi (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Delhi, visit Sikandara - the last resting place of the Mughal emperor Akbar. Upon arrival in Delhi, before taking your International Flight, you can walk around Cannaught Place or visit Sikh Temple.Take a dinner in the restaurant, later you will transferred to the International airport to board the International flight 3 hours before the scheduled flight time for your journey back to your country.",

            },

        ],
        "popular_tour": true,
        "image": "bg-tajMahal",
        "related_tours": [2, 3, 4, 5],
    },
    {
        "id": 2,
        "titleName": "North India with Varanasi",
        "Days": "18",
        "Nights": "17",
        "Banner": "Varanasi forTours.jpg",
        "Destinations": " Delhi - Mandawa - Bikaner - Pokran - Jaisalmer - Jodhpur - Ranakpur - Kumbhalgarh - Udaipur - Pushkar - Jaipur - Amber Fort - Fatehpur Sikari - Agra - Gwalior - Orchha - Khajuraho (Flight) - Varanasi (Flight) - Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple -Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India Gate - war memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel.",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi / Mandawa (260kms)",
                "Description": "After breakfast at the hotel drive to Mandawa upon arrival check-in hotel and later we leave to see Mandawa - often called the “open air art gallery of Rajasthan’ the region is famous for the high quality frescoes. See the beautifully painted havelis on the ancient and the famous silk route built in the 18th and 19th centuary by the rich merchants. Later take a heritage walk in this beautiful village. You can also see the traditional lifestyle of the local people. Overnight in Hotel.",

            },
            {
                "id": 3,
                "title": "Day 03 - Mandawa / Bikaner (180 Km)",
                "Description": "After breakfast, drive towards to Bikaner. On the way visit Fatehpur Village which is also famous for the splendid havelis of the Shekhawati region. Reach Bikaner by afternoon later set of for the sight seen tour of Bikaner includes visiting the impregnable 15th century Junagarh Fort reputed to have remained unconquered till date,the fort has a number of gates- Karan Pol (gate) provides access to the fort, next to it is Suraj Pol (sun gate Also visit the Camel Breeding Farm which carries out breeding and training of camels (closed on Sundays). Evening time is to visit Deshnoke Rat Temple. Overnight stay at your hotel in Bikaner.",

            },
            {
                "id": 4,
                "title": "Day 04 - Bikaner / Jaisalmer (330kms)",
                "Description": "After early breakfast, leave through the Thar Desert for Jaisalmer, en-route visit Pokharan -- the birth place of Akbar the Great (3rd Mughal Empror). Visiting the local market in the heart of the village and after taking lunch we proceed towards Jaisalmer. Arrive there by early evening. Let the Sun set view of this desert city weaves magic on you, leaving you speechless and mesmerized. Overnight stay at hotel.",

            },
            {
                "id": 5,
                "title": "Day 05 - Jaisalmer",
                "Description": "Sightseeing tour of the Golden City –Jaisalmer includes Sonar Fort or the Golden fort, rises from the sand and merges with the golden hues of the desert ambience. Badal Niwas, Manak Chowk, Patwon Ki Haveli -- one of the largest and most extensively carved  Haveli with remnants of mirror work and paintings on the walls, Nathmal Ji Ki Haveli known for its unique miniature style paintings and mighty tuskers carved out of yellow sandstone stand guard to the haveli & Deewan Salim Singh Ki Haveli -- Prime Minister’s residence of 18th  century. Visit the Gadisar lake then in evening camel carts safari for sunset onto Sam or Khuri Sand Dunes where you can enjoy dinner in mud huts listen to the folk tunes  by the local artists and musicians. Overnight stay at hotel in Jaisalmer.",

            },
            {
                "id": 6,
                "title": "Day 06 - Jaisalmer / Jodhpur (280 kms)",
                "Description": "Early Morning depart to Jodhpur, en-route visit Mandore Gardens. Check into hotel. Post lunch seightseeing tour includes Mehrangarh Fort -- pride of Jodhpur, still stand high witnessing the battles fought.  Museum inside the fort displays a rich collection of old royal palanquins, arms, paintings, costumes, etc. Fort has number of palaces - Moti Mahal, Phool Mahal, Sheesh Mahal, Takht Vilas and Jhanki Mahal. On the way down from the fort, towards left is Jaswant Thada -- intricately carved marble stone temple shaped cenotaph of Maharaja Jaswant Singh II. Umaid Bhawan Palace or Chittar Palace - palace with no mortar bindings, only chiselled sandstone blocks are interlocked together. Evening is leisure or you can walk along the famous spice market and feel the flavours of indian spices. Overnight stay at hotel.",

            },
            {
                "id": 7,
                "title": "Day 07 - Jodhpur / Bishnoi Villages / Ranakpur (185 km)",
                "Description": "After breakfast, drive to Ranakpur via Guda Bishnoi Village. Bishnoi tribe lead a vegetarian lifestyle, they respect and conserve nature in all aspect. Blackbucks, antelopes, chinkaras, peacocks and even cranes can be seen wandering  freely and are worshipped by the Bishnoi tribes. On arrival at Ranakpur, the city named after Rana Kumbha, known for its Jain temple. The four images of Adinath are enshrined in the main temple called 'Chamukh', having 29 halls and 1444 columns all with differently carved sculpturs. The complex also includes Sun Temple, Amba Mata Temple,  Parsvanath and Neminath temples. Overnight stay at hotel.",

            },
            {
                "id": 8,
                "title": "Day 08 - Ranakpur - Kumbhalgarh - Udaipur (140 km)",
                "Description": "After breakfast drive to the City of Lakes - Udaipur, enroute visits Kumbhalgarh Fort – One of the few impregnated forts in Rajasthan, with 2nd longest wall after china around Kumbhalgarh, the Muchchal Mahavir temple is wisely popular. This fort stands on a rocky hill commanding an excellent view of the wild and rugged scenery of the Aravallis and the sandy desert of Marwar. Reach Udaipur in afternoon and check in hotel. Later, see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter. After that move around the narrow streets of Udaipur. Rest of the time free for your leisure.",

            },
            {
                "id": 9,
                "title": "Day 09 - Udaipur",
                "Description": "After breakfast move out for the city tour of Udaipur visit  the City Palace -- a grand palace with beautiful architecture, the Jagdish Temple -- dedicated to Lord Vishnu, and the folk art museum with a colorful collection of puppets, musical instruments and paintings.In the evening you can wander through the city or take an  boat ride on Lake Pichola and see the fabulous lake palaces of Udaipur, the Jag Mandir Palace and the Jag Niwas Palace now known as the Taj Lake Palace, where you can enjoy your dinner in a regal ambience. Return to your hotel to stay overnight.",

            },
            {
                "id": 10,
                "title": "Day 10 - Udaipur / Pushkar (275 km)",
                "Description": "Leave Udaipur and head towards Pushkar on the way visit the Eklingji and Nagda Jain Temple. Also visit the Saas and Bahu Temple dedicated to Lord Shiva and Vishnu. Afternoon reach the Holy City --  Pushkar, the place where Lord Brahma (Creator of the Hindu Trinity) and Savitri (His Wife) perform “Yagnas”. Here we visit Brahama Temple and Lake Pushkar,where devotees take holy bath in lake water. The legand says that the lake sprout on the place where the lotus petals fell on barth from the hands of Lord Brahma. Pushkar is popular for the Pushkar Fair or Camel Fair that held every year in the month of November. In evening enjoy the walk along the colourful and bustling local market.",

            },
            {
                "id": 11,
                "title": "Day 11 - Pushkar / Jaipur (150 kms)",
                "Description": "On the way to Jaipur in Ajmer visit Nasiya Golden Jain Temple, “Dargah Sharif” and Adhai Din Ka Jhopra. After that welcome to The Capital city of Rajasthan which is also known as “Pink City” This afternoon, set off to see The City Palace -- still the home of the former royalty H.H Bhawani Singh Ji of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory -- situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory,on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 12,
                "title": "Day 12 - Jaipur",
                "Description": "A full day sightseeing stopping to view the dramatic hilltop fortress at Amber -- ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors Sheesh Mahal. Within the palace are Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the “Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy a Bollywood movie at “Rajmandir” the Second best Cinema in Asia. In the evening then enjoy dinner with folk dances and music by typical Rajasthani women in an Indian Restaurant",

            },
            {
                "id": 13,
                "title": "Day 12 - Jaipur /  Fatehpur Sikri / Agra (232 Kms)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is 54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti, Jodha Bai and  Birbal's house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 14,
                "title": "Day 14 - Agra / Gwalior / Orchha (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Tajmahal,erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete  a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Orchha, precede on a Gwalior city tour visiting the 9th century Teli ka Mandir, the 11th century Saas Bahu ka Mandir and the Jiwaji Rao Scindia museum. Continue the tour visiting the Tomb of Tansen - Akbar's foremost court musician followed by the memorials of the earliest freedom fighters Tatya Tope, Datia Palace. Datia and Orchha are two twin cities from the medieval times. In these villages time has stopped.  Orchha is a medieval city on the  bank of river Betwa founded by Bundela rulers known for its palaces and temples of the 16th and 17th century. In Orchha,  see the beautiful Palace Complex –  Jahangiri Mahal, Raj Mahal, Raj Parveen Mahal known for  the intricacies of the mural paintings.  In evening arrive to Orchha check into hotel.",

            },
            {
                "id": 15,
                "title": "Day 15 - Orchha / Khajuraho (182 Km)",
                "Description": "After breakfast, visit  the Laxminarayana Temple, Chaturbhuj Temple, Phool Bagh and finally the newly constructed Sun Temple. Post lunch continue towards Khajuraho. Khajuraho temple has achieved fame for the sensual appeal of its erotic sculptures built during the 9th to the 11th centuries by the powerful dynasty of Chandela rajputs. Visit the largest and grandest temple of Khajuraho is the immortal Kandariya Mahadeva, other Eastern and Western groups of temples are Visvanatha, Parsvanatha, Vaidyanatha, Jagadambi, Chitragupta. Return to your hotel to stay overnight. In the evening, you can take a dinner in a restaurant having typical Indian dance shows in Khajuraho.",

            },
            {
                "id": 16,
                "title": "Day 16 - Khajuraho / Varanasi (By Flight)",
                "Description": "After the leisure  breakfast in the morning, you will be transferred to the airport to take the flight to Varanasi. On Arrival received by representative and transfer to the hotel. The rest of the day is at leisure.",

            },
            {
                "id": 17,
                "title": "Day 17 - Varanasi",
                "Description": "In the early morning we take you for a Boat cruise at the river Ganges to observe the way of life of pilgrims by the Ghats.The boat ride starts from Dasashwamedh and ends at Manikarnika Ghat  also you visit Assi Ghat, Harish Chandra Ghat and Tulsi Ghat. After that visit the world famous Banaras Hindu University. Later visit Sarnath Temple, where Lord Buddha preached his first sermon. Sarnath's temples and Archaeological Museum are full of Buddhist art treasures. The 20 meter high Ashoka Pillar, set up by the Emperor Ashoka in 258 B.C. Overnight stay at the hotel.",

            },
            {
                "id": 18,
                "title": "Day 17 - Varanasi / Delhi (By Flight)",
                "Description": "In the Morning after breakfast you can move to visit the Ghats. Later you will be transferd to airport to take your flight to Delhi. On arrival the driver will receive you and before taking your international flight you can move around the connaught place market for shopping or to take a dinner in a good restaurant and than you will transferred to International airport 3 hours before your international flight time to your home country.",

            },
        ],
        "popular_tour": true,
        "image": "bg-varanasi",
        "related_tours": [1, 3, 4, 5],
    },
    {
        "id": 3,
        "titleName": "North India with Goa",
        "Days": "17",
        "Nights": "16",
        "Banner": "Goa for Tours.jpg",
        "Destinations": "Delhi – Mandawa – Bikaner – Pokran – Jaisalmer – Jodhpur – Ranakpur – Udaipur – Pushkar -  Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi –(Flight) -  Goa – (Flight) Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrival Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid - one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 -1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple -Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India Gate - war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel.",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi / Mandawa (260kms)",
                "Description": "After breakfast at the hotel drive to Mandawa upon arrival check-in hotel and later we leave to see Mandawa - often called the “open air art gallery of Rajasthan’ the region is famous for the high quality frescoes. See the beautifully painted havelis on the ancient and the famous silk route built in the 18th and 19th centuary by the rich merchants. Later take a heritage walk in this beautiful village. You can also see the traditional lifestyle of the local people. Overnight in hotel.",

            },
            {
                "id": 3,
                "title": "Day 03 - Mandawa / Bikaner (180 Km)",
                "Description": "After breakfast, drive towards to Bikaner. On the way visit Fatehpur Village which is also famous for the splendid havelis of the Shekhawati region. Reach Bikaner by afternoon later set of for the sight seen tour of Bikaner includes visiting the impregnable 15th century Junagarh Fort reputed to have remained unconquered till date,the fort has a number of gates- Karan Pol (gate) provides access to the fort, next to it is Suraj Pol (sun gate Also visit the Camel Breeding Farm which carries out breeding and training of camels (closed on Sundays). Evening time is to visit Deshnoke Rat Temple. Overnight stay at your hotel in Bikaner.",

            },
            {
                "id": 4,
                "title": "Day 04 - Bikaner / Jaisalmer (330kms)",
                "Description": "After early breakfast, leave through the Thar Desert for Jaisalmer, en-route visit Pokharan -- the birth place of Akbar the Great (3rd Mughal Empror). Visiting the local market in the heart of the village and after taking lunch we proceed towards Jaisalmer. Arrive there by early evening. Let the Sun set view of this desert city weaves magic on you, leaving you speechless and mesmerized. Overnight stay at hotel.",

            },
            {
                "id": 5,
                "title": "Day 05 - Jaisalmer",
                "Description": "Sightseeing tour of the Golden City –Jaisalmer includes Sonar Fort or the Golden fort, rises from the sand and merges with the golden hues of the desert ambience. Badal Niwas, Manak Chowk, Patwon Ki Haveli -- one of the largest and most extensively carved  Haveli with remnants of mirror work and paintings on the walls, Nathmal Ji Ki Haveli known for its unique miniature style paintings and mighty tuskers carved out of yellow sandstone stand guard to the haveli & Deewan Salim Singh Ki Haveli -- Prime Minister’s residence of 18th  century. Visit the Gadisar lake then in evening camel carts safari for sunset onto Sam or Khuri Sand Dunes where you can enjoy dinner in mud huts listen to the folk tunes  by the local artists and musicians. Overnight stay at hotel in Jaisalmer.",

            },
            {
                "id": 6,
                "title": "Day 06 - Jaisalmer / Jodhpur (280 kms)",
                "Description": "Early Morning depart to Jodhpur, en-route visit Mandore Gardens. Check into hotel. Post lunch seightseeing tour includes Mehrangarh Fort -- pride of Jodhpur, still stand high witnessing the battles fought.  Museum inside the fort displays a rich collection of old royal palanquins, arms, paintings, costumes, etc. Fort has number of palaces - Moti Mahal, Phool Mahal, Sheesh Mahal, Takht Vilas and Jhanki Mahal. On the way down from the fort, towards left is Jaswant Thada -- intricately carved marble stone temple shaped cenotaph of Maharaja Jaswant Singh II. Umaid Bhawan Palace or Chittar Palace - palace with no mortar bindings, only chiselled sandstone blocks are interlocked together. Evening is leisure or you can walk along the famous spice market and feel the flavours of indian spices. Overnight stay at hotel.",

            },
            {
                "id": 7,
                "title": "Day 07 - Jodhpur / Udaipur (330 km)",
                "Description": "After breakfast, drive to Udaipur enroute visit  Ranakpur, the city named after Rana Kumbha, known for its Jain temple. The four images of Adinath are enshrined in the main temple called 'Chamukh', having 29 halls and 1444 columns all with differently carved sculpturs. The complex also includes Sun Temple, Amba Mata Temple,  Parsvanath and Neminath temples. Overnight stay at hotel. Later Kumbhalgarh Fort – One of the few impregnated forts in Rajasthan, with 2nd longest wall after china around Kumbhalgarh, the Muchchal Mahavir temple is wisely popular. This fort stands on a rocky hill commanding an excellent view of the wild and rugged scenery of the Aravallis and the sandy desert of Marwar. Reach Udaipur in afternoon and check in hotel. Later, see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter. After that move around the narrow streets of Udaipur. Rest of the time free for your leisure.",

            },
            {
                "id": 8,
                "title": "Day 08 - Udaipur",
                "Description": "After breakfast move out for the city tour of Udaipur visit  the City Palace -- a grand palace with beautiful architecture, the Jagdish Temple -- dedicated to Lord Vishnu, and the folk art museum with a colorful collection of puppets, musical instruments and paintings.In the evening you can wander through the city or  take an  boat ride on Lake Pichola and see the fabulous lake palaces of Udaipur, the Jag Mandir Palace and the Jag Niwas Palace now known as the Taj Lake Palace, where you can enjoy your dinner in a regal ambience. Return to your hotel to stay overnight.",

            },
            {
                "id": 9,
                "title": "Day 09 - Udaipur / Pushkar (275 km)",
                "Description": "Leave Udaipur and head towards Pushkar on the way visit the Eklingji and Nagda Jain Temple. Also visit the Saas and Bahu Temple dedicated to Lord Shiva and Vishnu. Afternoon reach the Holy City -  Pushkar, the place where Lord Brahma (Creator of the Hindu Trinity) and Savitri (His Wife) perform “Yagnas”. Here we visit Brahama Temple and Lake Pushkar, where devotees take holy bath in lake water. The legand says that the lake sprout on the place where the lotus petals fell on earth from the hands of Lord Brahma. Pushkar is popular for the Pushkar Fair or Camel Fair that held every year in the month of November. In evening enjoy the walk along the colourful and bustling local market.",

            },
            {
                "id": 10,
                "title": "Day 10 - Pushkar / Jaipur (150 kms)",
                "Description": "On the way to Jaipur in Ajmer visit Nasiya Golden Jain Temple, “Dargah Sharif” and Adhai Din Ka Jhopra. After that welcome to The Capital city of Rajasthan which is also known as “Pink City” This afternoon, set off to see the City Palace -- still the home of the former royalty H.H Bhawani Singh Ji of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory -- situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory,on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 11,
                "title": "Day 11 - Jaipur",
                "Description": "A full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace are Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the“Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy a Bollywood movie at “Rajmandir” the Second best Cinema in Asia. In the evening then enjoy dinner with folk dances and music by typical Rajasthani women in an Indian Restaurant.",

            },
            {
                "id": 12,
                "title": "Day 12 - Jaipur / Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is 54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti,  Jodha Bai and  Birbal's house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 13,
                "title": "Day 12 - Agra / Delhi (205 Km)",
                "Description": "Early morning visit Incomparable Taj Mahal which is among the seven wonders in the world. Tajmahal, Erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Delhi, visit Sikandara -- the last resting place of the Mughal emperor Akbar. Upon arrival in Delhi, Check in hotel and overnight will be in hotel.",

            },
            {
                "id": 14,
                "title": "Day 14 - Delhi / Goa (By Flight)",
                "Description": "Today you will be transferred to airport to catch flight to Goa, on arrival transfer to hotel. Rest of the day free to relax to beach. In the evening enjoy Goan Carnival Theme -- Join a traditional carnival, which is a part of Goa’s rich Portuguese heritage. Where revelry, song and dance, great food and good wine come together beautifully. Discover the joy of Goan cuisine at a delightful buffet dinner. Overnight to be spent at hotel.",

            },
            {
                "id": 15,
                "title": "Day 15 - Goa",
                "Description": "Today you will explore the North Goa & South Goa. In the evening we have for you Fishing village -- Fishing villages are part of Goa’s rich cultural heritage. Local Goan fishing village is created on the grounds of the hotel, forming a perfect backdrop for a charming evening in a very rustic atmosphere. Artisans display their skills whilst the village dancers whirl to the music of a local band. Overnight at hotel.",

            },
            {
                "id": 16,
                "title": "Day 16 - Goa",
                "Description": "This day you are free to relax on Beach. In the evening, enjoy Sea food Bar-be-cue on the beach – Dine under a starlit sky on succulent lobsters, jumbo prawns and other local seafood delicacies which form part of a delightful bar-be-cue set up on a lovely stretch of the Goan beach. Guests may also cook their own food on skewers over a camp fire. A local musician captures the magic of the moment on his guitar strings. Overnight at hotel.",

            },
            {
                "id": 17,
                "title": "Day 17 - Goa / Delhi / Mumbai (By Flight)",
                "Description": " Morning after breakfast drive to airport to board the flight to Delhi or Mumbai. Upon arrival assistance and transferred to International airport 3hrs prior to the scheduled flight time to board your International flight to your home country.",

            },
        ],
        "popular_tour": true,
        "image": "bg-goa",
        "related_tours": [1, 2, 4, 5],
    },
    {
        "id": 4,
        "titleName": "North India with South India",
        "Days": "20",
        "Nights": "19",
        "Banner": "South India for Tours.webp",
        "Destinations": "Delhi – Mandawa – Bikaner – Pokran – Jaisalmer – Jodhpur – Ranakpur – Udaipur – Pushkar -  Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi – (Flight) Cochin – Kumarakom – Allepey – Kovalam – Trivandrum – (Flight) Delhi / Mumbai",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. Overnight stay in Hotel.",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi",
                "Description": "After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel.",

            },
            {
                "id": 3,
                "title": "Day 03 - Delhi / Mandawa (260kms)",
                "Description": "After breakfast at the hotel drive to Mandawa upon arrival check-in hotel and later we leave to see Mandawa -- often called the “open air art gallery of Rajasthan’ the region is famous for the high quality frescoes. See the beautifully painted havelis on the ancient and the famous silk route built in the 18th and 19th centuary by the rich merchants. Later take a heritage walk in this beautiful village. You can also see the traditional lifestyle of the local people. Overnight in Hotel.",

            },
            {
                "id": 4,
                "title": "Day 04 - Mandawa / Bikaner (180 Km)",
                "Description": "After breakfast, drive towards to Bikaner. On the way visit Fatehpur Village which is also famous for the splendid havelis of the Shekhawati region. Reach Bikaner by afternoon later set of for the sight seen tour of Bikaner includes visiting the impregnable 15th century Junagarh Fort reputed to have remained unconquered till date,the fort has a number of gates- Karan Pol (gate) provides access to the fort, next to it is Suraj Pol (sun gate Also visit the Camel Breeding Farm which carries out breeding and training of camels (closed on Sundays). Evening time is to visit Deshnoke Rat Temple. Overnight stay at your hotel in Bikaner",

            },
            {
                "id": 5,
                "title": "Day 05 - Bikaner / Jaisalmer (330kms)",
                "Description": "After early breakfast, leave through the Thar Desert for Jaisalmer, en-route visit Pokharan -- the birth place of Akbar the Great (3rd Mughal Empror). Visiting the local market in the heart of the village and after taking lunch we proceed towards Jaisalmer. Arrive there by early evening. Let the Sun set view of this desert city weaves magic on you, leaving you speechless and mesmerized. Overnight stay at hotel.",

            },
            {
                "id": 6,
                "title": "Day 06 - Jaisalmer",
                "Description": "Sightseeing tour of the Golden City –Jaisalmer includes Sonar Fort or the Golden fort, rises from the sand and merges with the golden hues of the desert ambience. Badal Niwas, Manak Chowk, Patwon Ki Haveli -- one of the largest and most extensively carved  Haveli with remnants of mirror work and paintings on the walls, Nathmal Ji Ki Haveli known for its unique miniature style paintings and mighty tuskers carved out of yellow sandstone stand guard to the haveli. & Deewan Salim Singh Ki Haveli -- Prime Minister’s residence of 18th  century.Visit the Gadisar lake then in evening camel carts safari for sunset onto Sam or Khuri Sand Dunes where you can enjoy dinner in mud huts listen to the folk tunes  by the local artists and musicians. Overnight stay at hotel in Jaisalmer.",

            },
            {
                "id": 7,
                "title": "Day 07 - Jaisalmer / Jodhpur (280 kms)",
                "Description": "Early Morning depart to Jodhpur, en-route visit Mandore Gardens. Check into hotel. Post lunch seightseeing tour includes Mehrangarh Fort -- pride of Jodhpur, still stand high witnessing the battles fought.  Museum inside the fort displays a rich collection of old royal palanquins, arms, paintings, costumes, etc. Fort has number of palaces - Moti Mahal, Phool Mahal, Sheesh Mahal, Takht Vilas and Jhanki Mahal. On the way down from the fort, towards left is Jaswant Thada -- intricately carved marble stone temple shaped cenotaph of Maharaja Jaswant Singh II. Umaid Bhawan Palace or Chittar Palace - palace with no mortar bindings, only chiselled sandstone blocks are interlocked together. Evening is leisure or you can walk along the famous spice market and feel the flavours of indian spices. Overnight stay at hotel.",

            },
            {
                "id": 8,
                "title": "Day 08 - Jodhpur / Udaipur (330 km)",
                "Description": "After breakfast, drive to Udaipur enroute visit  Ranakpur, the city named after Rana Kumbha, known for its Jain temple. The four images of Adinath are enshrined in the main temple called 'Chamukh', having 29 halls and 1444 columns all with differently carved sculpturs. The complex also includes Sun Temple, Amba Mata Temple,  Parsvanath and Neminath temples. Overnight stay at hotel. Later Kumbhalgarh Fort – One of the few impregnated forts in Rajasthan, with 2nd longest wall after china around Kumbhalgarh, the Muchchal Mahavir temple is wisely popular. This fort stands on a rocky hill commanding an excellent view of the wild and rugged scenery of the Aravallis and the sandy desert of Marwar. Reach Udaipur in afternoon and check in hotel. Later, see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter. After that move around the narrow streets of Udaipur, rest of the time free for your leisure.",

            },
            {
                "id": 9,
                "title": "Day 09 - Udaipur",
                "Description": "After breakfast move out for the city tour of Udaipur visit  the City Palace -- a grand palace with beautiful architecture, the Jagdish Temple -- dedicated to Lord Vishnu, and the folk art museum with a colorful collection of puppets, musical instruments and paintings.In the evening you can wander through the city or take an  boat ride on Lake Pichola and see the fabulous lake palaces of Udaipur, the Jag Mandir Palace and the Jag Niwas Palace now known as the Taj Lake Palace, where you can enjoy your dinner in a regal ambience. Return to your hotel to stay overnight.",

            },
            {
                "id": 10,
                "title": "Day 10 - Udaipur / Pushkar (275 km)",
                "Description": "Leave Udaipur and head towards Pushkar on the way visit the Eklingji and Nagda Jain Temple. Also visit the Saas and Bahu Temple dedicated to Lord Shiva and Vishnu. Afternoon reach the Holy City --  Pushkar, the place where Lord Brahma (Creator of the Hindu Trinity) and Savitri (His Wife) perform “Yagnas”. Here we visit Brahama Temple and Lake Pushkar, where devotees take holy bath in lake water. The legand says that the lake sprout on the place where the lotus petals fell on earth from the hands of Lord Brahma. Pushkar is popular for the Pushkar Fair or Camel Fair that held every year in the month of November. In evening enjoy the walk along the colourful and bustling local market.",

            },
            {
                "id": 11,
                "title": "Day 11 - Pushkar / Jaipur (150 kms)",
                "Description": "On the way to Jaipur in Ajmer visit Nasiya Golden Jain Temple, “Dargah Sharif” and Adhai Din Ka Jhopra. After that welcome to The Capital city of Rajasthan which is also known as “Pink City” This afternoon, set off to see The City Palace -- still the home of the former royalty H.H Bhawani Singh Ji of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory -- situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory,on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 12,
                "title": "Day 12 - Jaipur",
                "Description": "A full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace are Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the “Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy a Bollywood movie at “Rajmandir” the Second best Cinema in Asia. In the evening then enjoy dinner with folk dances and music by typical Rajasthani women in an Indian Restaurant.",

            },
            {
                "id": 13,
                "title": "Day 12 - Jaipur / Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is  54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti, Jodha Bai and  Birbal's house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 14,
                "title": "Day 14 - Agra / Delhi (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Tajmahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Delhi, visit Sikandara - the last resting place of the Mughal emperor Akbar. Upon arrival in Delhi check- in and overnight in hotel.",

            },
            {
                "id": 15,
                "title": "Day 15 - Delhi / Cochin (By Flight)",
                "Description": "Early morning you will be transferred to airport to board the flight for Cochin. On arrival at the airport in Cochin, you will be met and transferred to the Hotel at Cochin. Later we visit Cochin, Kerala's commercial centre, it has a fine natural harbour. Cochin (Kochi) gateway for Kerala is famous for Mattanchery Palace, St. Francis Church, Wellington Island, Bolghatty Island, Chinese Fishing nets and Jewish Synagogue. Cruising on the back-waters provides glimpses of the rural life, fine view of the harbour and three islands. Evening witness Kathakali Dance Show in a theatre. Overnight at the hotel.",

            },
            {
                "id": 16,
                "title": "Day 16 - Cochin / Kumarakom (90 Km)",
                "Description": "Morning after breakfast sightseeing of Cochin which includes Pareekshith Thampuran Museum and Museum of Kerala history. Afternoon proceed to Kumarakom, on arrival check-in to the resort on the bank of Backwater. Overnight stay at the resort.",

            },
            {
                "id": 17,
                "title": "Day 17 - Kumarakom / Allepey (Houseboat)",
                "Description": "Morning after breakfasts proceed to Alleppey. Check-in to the waiting houseboat. Enroute you can see many romantic beauty of the nature which will remind you that you are in the Gods Own Country. You can view coconut palm fringed canals and local people going for a fresh catch of the day & tapping of toddy (Local beverages) from palm trees. Rest of the day at leisure in Kumarakom, Kerala. Overnight stay at the houseboat.",

            },
            {
                "id": 18,
                "title": "Day 18 - Allepey / Kovalam (180 Km)",
                "Description": "Morning after breakfast proceed to Kovalam. Arrive Kovalam on arrival check-in to the hotel. Rest of the day at leisure at Kovalam Beach. Overnight stay at Kovalam.",

            },
            {
                "id": 19,
                "title": "Day 19 - Kovalam",
                "Description": "Morning after breakfast sight seeing of Thiruvananthapuram covering Museum Complex, Padmanabha Swamy Temple, Science & Technology Museum, Veli Tourist village, Aakulam boat club, Shangmugham Beach. Overnight stay at Kovalam.",

            },
            {
                "id": 20,
                "title": "Day 20 - Kovalam / Trivandrum (16 km) Delhi / Mumbai (By Flight)",
                "Description": "Morning after breakfast drive to Trivandrum airport to board the flight to Delhi or Mumbai. Upon arrival assistance and transferred to International airport to board your International flight to your home country.",

            },
        ],
        "popular_tour": true,
        "image": "bg-southIndia",
        "related_tours": [1, 2, 3, 5],
    },
    {
        "id": 5,
        "titleName": "North India with Nepal",
        "Days": "21",
        "Nights": "20",
        "Banner": "Nepal For Tours.jpg",
        "Destinations": "Delhi – Mandawa – Bikaner – Pokran – Jaisalmer – Jodhpur – Ranakpur Kumbhalgarh – Udaipur – Pushkar -  Jaipur – Amber Fort – Fatehpur Sikari – Agra – Gwalior – Orchha – Khajuraho - (Flight) – Varanasi (Flight) Kathmandu – (Flight) – Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel.",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi / Mandawa (260kms)",
                "Description": "After breakfast at the hotel drive to Mandawa upon arrival check-in hotel and later we leave to see Mandawa -- often called the “open air art gallery of Rajasthan’ the region is famous for the high quality frescoes. See the beautifully painted havelis on the ancient and the famous silk route built in the 18th and 19th centuary by the rich merchants. Later take a heritage walk in this beautiful village. You can also see the traditional lifestyle of the local people. Overnight in Hotel.",

            },
            {
                "id": 3,
                "title": "Day 03 - Mandawa / Bikaner (180 Km)",
                "Description": "After breakfast, drive towards to Bikaner. On the way visit Fatehpur Village which is also famous for the splendid havelis of the Shekhawati region. Reach Bikaner by afternoon later set off for the sight seen tour of Bikaner includes visiting the impregnable 15th century Junagarh Fort reputed to have remained unconquered till date,the fort has a number of gates- Karan Pol (gate) provides access to the fort, next to it is Suraj Pol (sun gate Also visit the Camel Breeding Farm which carries out breeding and training of camels (closed on Sundays). Evening time is to visit Deshnoke Rat Temple. Overnight stay at your hotel in Bikaner.",

            },
            {
                "id": 4,
                "title": "Day 04 - Bikaner / Jaisalmer (330kms)",
                "Description": "After early breakfast, leave through the Thar Desert for Jaisalmer, en-route visit Pokharan -- the birth place of Akbar the Great (3rd Mughal Empror). Visiting the local market in the heart of the village and after taking lunch we proceed towards Jaisalmer. Arrive there by early evening. Let the Sun set view of this desert city weaves magic on you, leaving you speechless and mesmerized. Overnight stay at hotel.",

            },
            {
                "id": 5,
                "title": "Day 05 - Jaisalmer",
                "Description": "Sightseeing tour of the Golden City – Jaisalmer includes Sonar Fort or the Golden fort, rises from the sand and merges with the golden hues of the desert ambience. Badal Niwas, Manak Chowk, Patwon Ki Haveli -- one of the largest and most extensively carved Haveli with remnants of mirror work and paintings on the walls, Nathmal Ji Ki Haveli known for its unique miniature style paintings and mighty tuskers carved out of yellow sandstone stand guard to the haveli. & Deewan Salim Singh Ki Haveli -- Prime Minister’s residence of 18th  century.Visit the Gadisar lake then in evening camel carts safari for sunset onto Sam or Khuri Sand Dunes where you can enjoy dinner in mud huts listen to the folk tunes  by the local artists and musicians. Overnight stay at hotel in Jaisalmer.",

            },
            {
                "id": 6,
                "title": "Day 06 - Jaisalmer / Jodhpur (280 kms)",
                "Description": "Early Morning depart to Jodhpur, en-route visit Mandore Gardens. Check into hotel. Post lunch seightseeing tour includes Mehrangarh Fort -- pride of Jodhpur, still stand high witnessing the battles fought.  Museum inside the fort displays a rich collection of old royal palanquins, arms, paintings, costumes, etc. Fort has number of palaces - Moti Mahal, Phool Mahal, Sheesh Mahal, Takht Vilas and Jhanki Mahal. On the way down from the fort, towards left is Jaswant Thada -- intricately carved marble stone temple shaped cenotaph of Maharaja Jaswant Singh II. Umaid Bhawan Palace or Chittar Palace -- palace with no mortar bindings, only chiselled sandstone blocks are interlocked together. Evening is leisure or you can walk along the famous spice market and feel the flavours of indian spices. Overnight stay at hotel.",

            },
            {
                "id": 7,
                "title": "Day 07 - Jodhpur / Bishnoi Villages / Ranakpur (185 km)",
                "Description": "After breakfast, drive to Ranakpur via Guda Bishnoi Village. Bishnoi tribe lead a vegetarian lifestyle, they respect and conserve nature in all aspect. Blackbucks, antelopes, chinkaras, peacocks and even cranes can be seen wandering  freely and are worshipped by the Bishnoi tribes. On arrival at Ranakpur, the city named after Rana Kumbha, known for its Jain temple. The four images of Adinath are enshrined in the main temple called 'Chamukh', having 29 halls and 1444 columns all with differently carved sculpturs. The complex also includes Sun Temple, Amba Mata Temple,  Parsvanath and Neminath temples. Overnight stay at hotel.",

            },
            {
                "id": 8,
                "title": "Day 08 - Ranakpur / Kumbhalgarh / Udaipur (140 km)",
                "Description": "After breakfast drive to the City of Lakes - Udaipur, enroute visits Kumbhalgarh Fort – One of the few impregnated forts in Rajasthan, with 2nd longest wall after china around Kumbhalgarh, the Muchchal Mahavir temple is wisely popular. This fort stands on a rocky hill commanding an excellent view of the wild and rugged scenery of the Aravallis and the sandy desert of Marwar. Reach Udaipur in afternoon and check in hotel. Later, see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter. After that move around the narrow streets of Udaipur. Rest of the time free for your leisure.",

            },
            {
                "id": 9,
                "title": "Day 09 - Udaipur",
                "Description": "After breakfast move out for the city tour of Udaipur visit  the City Palace -- a grand palace with beautiful architecture, the Jagdish Temple -- dedicated to Lord Vishnu, and the folk art museum with a colorful collection of puppets, musical instruments and paintings.In the evening you can wander through the city or take an  boat ride on Lake Pichola and see the fabulous lake palaces of Udaipur, the Jag Mandir Palace and the Jag Niwas Palace now known as the Taj Lake Palace, where you can enjoy your dinner in a regal ambience. Return to your hotel to stay overnight.",

            },
            {
                "id": 10,
                "title": "Day 10 - Udaipur / Pushkar (275 km)",
                "Description": "Leave Udaipur and head towards Pushkar on the way visit the Eklingji and Nagda Jain Temple. Also visit the Saas and Bahu Temple dedicated to Lord Shiva and Vishnu. Afternoon reach the Holy City --  Pushkar, the place where Lord Brahma(Creator of the Hindu Trinity) and Savitri (His Wife) perform “Yagnas”. Here we visit Brahama Temple and Lake Pushkar, where devotees take holy bath in lake water. The legand says that the lake sprout on the place where the lotus petals fell on earth from the hands of Lord Brahma. Pushkar is popular for the Pushkar Fair or Camel Fair that held every year in the month of November. In evening enjoy the walk along the colourful and bustling local market.",

            },
            {
                "id": 11,
                "title": "Day 11 - Pushkar / Jaipur (150 kms)",
                "Description": "On the way to Jaipur in Ajmer visit Nasiya Golden Jain Temple, “Dargah Sharif” and Adhai Din Ka Jhopra. After that welcome to The Capital city of Rajasthan which is also known as “Pink City” This afternoon, set off to see the City Palace --still the home of the former royalty H.H Bhawani Singh Ji of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory -- situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory, on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 12,
                "title": "Day 12 - Jaipur",
                "Description": "A full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace are Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the “Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy a Bollywood movie at “Rajmandir” the Second best Cinema in Asia. In the evening then enjoy dinner with folk dances and music by typical Rajasthani women in an Indian Restaurant.",

            },
            {
                "id": 13,
                "title": "Day 13 - Jaipur / Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is  54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti, Jodha Bai and  Birbal's house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 14,
                "title": "Day 14 - Agra / Gwalior / Orchha (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Tajmahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete  a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Orchha, precede on a Gwalior city tour visiting the 9th century Teli ka Mandir, the 11th century Sas Bahu ka Mandir and the Jiwaji Rao Scindia museum. Continue the tour visiting the Tomb of Tansen - Akbar's foremost court musician followed by the memorials of the earliest freedom fighters Tatya Tope, Datia Palace. Datia and Orchha are two twin cities from the medieval times. In these villages time has stopped.  Orchha is a medieval city on the  bank of river Betwa founded by Bundela rulers known for its palaces and temples of the 16th and 17th century. In Orchha,  see the beautiful Palace Complex –  Jahangiri Mahal, Raj Mahal, Raj Parveen Mahal known for  the intricacies of the mural paintings.  In evening arrive to Orchha check into hotel.",

            },
            {
                "id": 15,
                "title": "Day 15 - Orchha / Khajuraho (182 Km)",
                "Description": "After breakfast, visit  the Laxminarayana Temple, Chaturbhuj Temple, Phool Bagh and finally the newly constructed Sun Temple. Post lunch continue towards Khajuraho. Khajuraho temple has achieved fame for the sensual appeal of its erotic sculptures built during the 9th to the 11th centuries by the powerful dynasty of Chandela rajputs. Visit the largest and grandest temple of Khajuraho is the immortal Kandariya Mahadeva, other Eastern and Western groups of temples are Visvanatha, Parsvanatha, Vaidyanatha, Jagadambi, Chitragupta. Return to your hotel to stay overnight. In the evening, you can take a dinner in a restaurant having typical Indian Dance shows in Khajuraho.",

            },
            {
                "id": 16,
                "title": "Day 16 - Khajuraho / Varanasi (By Flight)",
                "Description": "After the leisure  breakfastin the morning, you will be transferred to the airport to take the flight to Varanasi. On Arrival received by representative and transfer to the hotel. The rest of the day is at leisure.",

            },
            {
                "id": 17,
                "title": "Day 17 - Varanasi",
                "Description": "In the early morning we take you for a Boat cruise at the river Ganges to observe the way of life of pilgrims by the Ghats. The boat ride starts from Dasashwamedh and ends at Manikarnika Ghat  also you visit  Assi Ghat, Harish Chandra Ghat and Tulsi Ghat. After that visit the world famous Banaras Hindu University. Later visit Sarnath Temple, where Lord Buddha preached his first sermon. Sarnath's temples and Archaeological Museum are full of Buddhist art treasures. The 20 meter high Ashoka Pillar, set up by the Emperor Ashoka in 258 B.C. Overnight stay at the hotel.",

            },
            {
                "id": 18,
                "title": "Day 18 - Varanasi / Kathmandu:",
                "Description": "After leisurely breakfast in hotel, transfer to the airport to board flight for Kathmandu, on arrival transfer to hotel. Evening free at leisure. You can move around in the Thamel markets. Overnight at Kathmandu.",

            },
            {
                "id": 19,
                "title": "Day 19 - Kathmandu",
                "Description": "Early morning at 06:30 take flight to Everest to fly above the Mountain King of the world. Once in a lifetime opportunity to witness a panoramic view of this natural wonders. After breakfast proceeds sightseeing tour of the Kathmandu which includes Pashupatinath Temple -- Nepal's most scared Hindu shrines and one of the subcontinent's greatest Shiva sites. Later visit Boudhanath Stupa -- center of Tibetan culture in Nepal. Later Move out to visit Kathmandu Durbar Square, Patan Durbar Square and Swayambhunath Temple. Overnight in Hotel.",

            },
            {
                "id": 20,
                "title": "Day 20 - Kathmandu / Bakhtapur / Nagarkot / Kathmandu",
                "Description": "In the morning after breakfast drive to Bakhtapur which is 35 km from Kathmandu and a relaxing place and has three interesting squares Taumadhi Tole, Durbar and Potter's. Then move to Nagarkot village, at an elevation of 2,195 meters, it is considered one of the most scenic spots in Bhaktapur District. It is renowned for its sunrise view of the Himalaya including Mount Everest as well as other snow-topped peaks of the Himalayan range of Eastern Nepal. Evening back to Kathmandu and overnight in Hotel.",

            },
            {
                "id": 21,
                "title": "Day 21 - Kathmandu / Delhi (By Flight)",
                "Description": "In the Morning after breakfast you will be transferd to airport to take your flight to Delhi. On arrival the driver will receive you and before taking your international flight you can move around the Connaught place market for shopping or to take a dinner in a good restaurant and than you will transferd to International airport 3 hours before your international flight time to your home country.",

            },
        ],
        "popular_tour": true,
        "image": "bg-nepal",
        "related_tours": [1, 2, 3, 4],
    },
    {
        "id": 6,
        "titleName": "Golden Triangle Tour",
        "Days": "05",
        "Nights": "04",
        "Banner": "TajMahal.jpg",
        "Destinations": "Delhi – Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi / Jaipur (260 Kms)",
                "Description": "After breakfast proceed to the Pink City – Jaipur and reach by afternoon and check into hotel. This afternoon, set off to see The City Palace -- still the home of the former royal family of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory - situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory, on the way back visit Birla Temple. Evening at leisure",

            },
            {
                "id": 3,
                "title": "Day 03 - Jaipur",
                "Description": "In Jaipur a full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace is Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the Water Palace built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy cinema at the Asia´s Second Best Cinema Hall. In the evening then enjoy dinner with folk dances by Gypsy women in an Indian Restaurant.",

            },
            {
                "id": 4,
                "title": "Day 04 - Jaipur / Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is 54mtr high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti, Jodha Bai and Birbal's house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 5,
                "title": "Day 05 - Agra / Delhi (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Delhi, visit Sikandara - the last resting place of the Mughal emperor Akbar. Upon arrival in Delhi, before taking your International Flight, you can walk around Cannaught Place or visit Sikh Temple. Take a dinner in the restaurant, later you will transferred to the International airport to board the International flight 3 hours before the scheduled flight time for your journey back to your country.",

            },
        ],
        "popular_tour": false,
        "image": "bg-goldenTajMahal",
        "related_tours": [7, 8, 9, 10],
    },
    {
        "id": 7,
        "titleName": "Golden Triangle with Golden Temple",
        "Days": "07",
        "Nights": "06",
        "Banner": "Golden_Temple.webp",
        "Destinations": "Delhi – Amritsar – Delhi – Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi / Amritsar",
                "Description": "Early morning transfer to train station to board the train for Amirtsar. Afternoon arrives to Amirtsar and transfer to hotel. Later set off for sightseeing to the 'land of Golden Sparrow' - Amritsar. We visit Durgiana Temple which also draws its share of pilgrims. Evening we visit Wagha Border (India / Pakistan Border) for change of guards ceremony and the flag hoisting and lowering activities which are done with great skill and precision by the border security forces of both the countries. Overnight in Hotel.",

            },
            {
                "id": 3,
                "title": "Day 03 - Amirtsar / Delhi",
                "Description": "In the morning after breakfast visit Amritsar's resplendent Golden Temple - Spiritual Center of Sikh Faith, built by Guru Arjun Singh in the late 16th Century. In its architecture are included, symbols associated with other places of worship. This is an example of the spirit of tolerance and acceptance that the Sikh philosophy propounds. Later visit Jallianwala Bagh Memorial - an important monument dedicated to hundreds of martyrs of a massacre in 1919. Later in afternoon transfer to train station to board the train to Delhi. On arrival transfer to hotel for overnight stay.",

            },
            {
                "id": 4,
                "title": "Day 04 - Delhi / Jaipur (260 Kms)",
                "Description": "After breakfast proceed to the Pink City – Jaipur and reach by afternoon and check into hotel. This afternoon, set off to see the City Palace -- still the home of the former royal family of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory - situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory, on the way back visit Birla Temple. Evening at leisure",

            },
            {
                "id": 5,
                "title": "Day 05 - Jaipur",
                "Description": "In Jaipur a full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace is Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the “Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy cinema at the Asia´s Second Best Cinema Hall. In the evening then enjoy dinner with folk dances by Gypsy women in an Indian Restaurant.",

            },
            {
                "id": 6,
                "title": "Day 06 - Jaipur / Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is 54m high main entrance. Jami Mosque, Buland Darwaza, palace of Jodha Bai, Birbal's house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 7,
                "title": "Day 07 - Agra / Delhi (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Delhi, visit Sikandara - the last resting place of the Mughal emperor. Upon arrival in Delhi, before taking your International Flight, you can walk around Cannaught Place or visit Sikh Temple. Take a dinner in the restaurant, later you will transferred to the International airport to board the International flight 3 hours before the scheduled flight time for your journey back to your country.",

            },
        ],
        "popular_tour": false,
        "image": "bg-golden",
        "related_tours": [6, 8, 9, 10],
    },
    {
        "id": 8,
        "titleName": "Golden Triangle with Varanasi",
        "Days": "10",
        "Nights": "09",
        "Banner": "varanasi.png",
        "Destinations": "Delhi – Jaipur – Fatehpur Sikri – Agra – Orchha – Khajuraho – Flight – Varanasi  Flight – Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. Overnight stay in Hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi",
                "Description": "After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 3,
                "title": "Day 03 - Delhi / Jaipur (260 Kms)",
                "Description": "After breakfast proceed to the Pink City – Jaipur and reach by afternoon and check into hotel. This afternoon, set off to see The City Palace --still the home of the former royal family of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory - situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory, on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 4,
                "title": "Day 04 - Jaipur",
                "Description": "In Jaipur a full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace is Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the“Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy cinema at the Asia´s Second Best Cinema Hall in the evening then enjoy dinner with folk dances by Gypsy women in an Indian Restaurant.",

            },
            {
                "id": 5,
                "title": "Day 05 - Jaipur /  Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is 54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti, Jodha Bai and Birbal’s house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 6,
                "title": "Day 06 - Agra / Gwalior / Orchha (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete  a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Orchha, precede on a Gwalior city tour visiting the 9th century Teli ka Mandir, the 11th century Sas Bahu ka Mandir and the Jiwaji Rao Scindia museum. Continue the tour visiting the Tomb of Tansen - Akbar's foremost court musician followed by the memorials of the earliest freedom fighters Tatya Tope, Datia Palace. Datia and Orchha are two twin cities from the medieval times. In these villages time has stopped.  Orchha is a medieval city on the  bank of river Betwa founded by Bundela rulers known for its palaces and temples of the 16th and 17th century. In Orchha,  see the beautiful Palace Complex –  Jahangiri Mahal, Raj Mahal, Raj Parveen Mahal known for  the intricacies of the mural paintings.  In evening arrive to Orchha check into hotel.",

            },
            {
                "id": 7,
                "title": "Day 07 - Orchha / Khajuraho (182 Km)",
                "Description": "After breakfast, visit  the Laxminarayana Temple, Chaturbhuj Temple, Phool Bagh and finally the newly constructed Sun Temple. Post lunch continue towards Khajuraho. Khajuraho temple has achieved fame for the sensual appeal of its erotic sculptures built during the 9th to the 11th centuries by the powerful dynasty of Chandela rajputs. Visit the largest and grandest temple of Khajuraho is the immortal Kandariya Mahadeva, other Eastern and Western groups of temples are Visvanatha, Parsvanatha, Vaidyanatha, Jagadambi, Chitragupta. Return to your hotel to stay overnight. In the evening, you can take a dinner in a restaurant having typical Indian Dance shows in Khajuraho.",

            },
            {
                "id": 8,
                "title": "Day 08 - Khajuraho / Varanasi (By Flight)",
                "Description": "After the leisure  breakfastin the morning, you will be transferred to the airport to take the flight to Varanasi. On Arrival received by representative and transfer to the hotel. The rest of the day is at leisure.",

            },
            {
                "id": 9,
                "title": "Day 09 - Varanasi",
                "Description": "In the early morning we take you for a Boat cruise at the river Ganges to observe the way of life of pilgrims by the Ghats. The boat ride starts from Dasashwamedh and ends at Manikarnika Ghat  also you visit Assi Ghat, Harish Chandra Ghat and Tulsi Ghat. After that, visit the world famous Banaras Hindu University. Later visit Sarnath Temple, where Lord Buddha preached his first sermon. Sarnath's temples and Archaeological Museum are full of Buddhist art treasures. The 20 meter high Ashoka Pillar, set up by the Emperor Ashoka in 258 B.C. Overnight stay at the hotel.",

            },
            {
                "id": 10,
                "title": "Day 10 - Delhi (By Flight)",
                "Description": "In the Morning after breakfast you can move to visit the Ghats. Later you will be transferd to airport to take your flight to Delhi. On arrival the driver will receive you and before taking your international flight you can move around the connaught place market for shopping or to take a dinner in a good restaurant and than you will transferred to International airport 3 hours before your international flight time to your home country.",

            },
        ],
        "popular_tour": false,
        "image": "bg-varanasi",
        "related_tours": [6, 7, 9, 10],
    },
    {
        "id": 9,
        "titleName": "Golden Triangle with Nepal",
        "Days": "12",
        "Nights": "11",
        "Banner": "NepalHome.webp",
        "Destinations": "Delhi – Jaipur – Fatehpur Sikri – Agra – Orchha – Khajuraho  Flight – Varanasi  Flight – Kathmandu  Flight – Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi / Jaipur (260 Kms)",
                "Description": "After breakfast proceed to the Pink City – Jaipur and reach by afternoon and check into hotel. This afternoon, set off to see the City Palace -- still the home of the former royal family of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory - situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory, on the way back visit Birla Temple. Evening at leisure",

            },
            {
                "id": 3,
                "title": "Day 03 - Jaipur",
                "Description": "In Jaipur a full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace is Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the“Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy cinema at the Asia´s Second Best Cinema Hall in the evening then enjoy dinner with folk dances by Gypsy women in an Indian Restaurant.",

            },
            {
                "id": 4,
                "title": "Day 04 - Jaipur / Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is 54mtr high main entrance. Jami Mosque, Buland Darwaza, palace of Jodha Bai, Birbal's house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 5,
                "title": "Day 05 - Agra / Gwalior / Orchha (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Orchha, precede on a Gwalior city tour visiting the 9th century Teli ka Mandir, the 11th century Sas Bahu ka Mandir and the Jiwaji Rao Scindia museum. Continue the tour visiting the Tomb of Tansen - Akbar's foremost court musician followed by the memorials of the earliest freedom fighters Tatya Tope, Datia Palace. Datia and Orchha are two twin cities from the medieval times. In these villages time has stopped.  Orchha is a medieval city on the  bank of river Betwa founded by Bundela rulers known for its palaces and temples of the 16th and 17th century. In Orchha,  see the beautiful Palace Complex –  Jahangiri Mahal, Raj Mahal, Raj Parveen Mahal known for  the intricacies of the mural paintings.  In evening arrive to Orchha check into hotel.",

            },
            {
                "id": 6,
                "title": "Day 06 - Orchha / Khajuraho (182 Km)",
                "Description": "After breakfast, visit  the Laxminarayana Temple, Chaturbhuj Temple, Phool Bagh and finally the newly constructed Sun Temple. Post lunch continue towards Khajuraho. Khajuraho temple has achieved fame for the sensual appeal of its erotic sculptures built during the 9th to the 11th centuries by the powerful dynasty of Chandela rajputs. Visit the largest and grandest temple of Khajuraho is the immortal Kandariya Mahadeva, other Eastern and Western groups of temples are Visvanatha, Parsvanatha, Vaidyanatha, Jagadambi, Chitragupta. Return to your hotel to stay overnight. In the evening, you can take a dinner in a restaurant having typical Indian Dance shows in Khajuraho.",

            },
            {
                "id": 7,
                "title": "Day 07 - Khajuraho / Varanasi (By Flight)",
                "Description": "After the leisure  breakfastin the morning, you will be transferred to the airport to take the flight to Varanasi. On Arrival received by representative and transfer to the hotel. The rest of the day is at leisure.",

            },
            {
                "id": 8,
                "title": "Day 08 - Varanasi",
                "Description": "In the early morning we take you for a Boat Cruise at the river Ganges to observe the way of life of pilgrims by the Ghats. The boat ride starts from Dasashwamedh and ends at Manikarnika Ghat  also you visit  Assi Ghat, Harish Chandra Ghat and Tulsi Ghat. After that, visit the world famous Banaras Hindu University. Later visit Sarnath Temple, where Lord Buddha preached his first sermon. Sarnath's temples and Archaeological Museum -- full of Buddhist art treasures. The 20 meter high Ashoka Pillar, set up by the Emperor Ashoka in 258 B.C.  Overnight stay at the hotel.",

            },
            {
                "id": 9,
                "title": "Day 09 - Varanasi (By Flight) / Kathmandu",
                "Description": "After leisurely breakfast in hotel, transfer to the airport to board flight for Kathmandu, on arrival transfer to hotel. Evening free at leisure. You can move around in the Thamel markets. Overnight at Kathmandu.",

            },
            {
                "id": 10,
                "title": "Day 10 - Kathmandu",
                "Description": "Early morning at 06:30 take flight to Everest to fly above the Mountain King of the world. Once in a lifetime opportunity to witness a panoramic view of this natural wonders. After breakfast proceed for sightseeing tour of Kathmandu City which includes Pashupatinath Temple -- Nepal's most scared Hindu shrines and one of the subcontinent's greatest Shiva sites. Later visit Boudhanath Stupa -- center of Tibetan culture in Nepal. Later move out to visit Kathmandu Durbar Square, Patan Durbar Square and Swayambhunath Temple. Overnight in Hotel.",

            },
            {
                "id": 11,
                "title": "Day 11 - Kathmandu / Bakhtapur /  Nagarkot / Kathmandu",
                "Description": "In the morning after breakfast drive to Bakhtapur which is 35 km from Kathmandu and a relaxing place and has three interesting squares Taumadhi Tole, Durbar and Potter's. Than move to Nagarkot village, at an elevation of 2,195 meters, it is considered one of the most scenic spots in Bhaktapur District. It is renowned for its sunrise view of the Himalaya including Mount Everest as well as other snow-topped peaks of the Himalayan range of eastern Nepal. Evening back to Kathmandu and overnight in Hotel.",

            },
            {
                "id": 12,
                "title": "Day 12 - Kathmandu / Delhi (By Flight)",
                "Description": "In the Morning after breakfast you will be transferd to airport to take your flight to Delhi. On arrival the driver will receive you and before taking your international flight you can move around the Connaught place market for shopping or to take a dinner in a good restaurant and than you will transferd to International airport 3 hours before your international flight time to your home country.",
            },
        ],
        "popular_tour": false,
        "image": "bg-nepal",
        "related_tours": [6, 7, 8, 10],
    },
    {
        "id": 10,
        "titleName": "Golden Triangle with Wildlife",
        "Days": "14",
        "Nights": "13",
        "Banner": "tiger.png",
        "Destinations": "Delhi – Jaipur – Ranthambhore – Bharatpur - Fatehpur Sikri – Agra  – Bandhavgarh - Khajuraho – Flight – Varanasi – Flight – Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. Overnight stay in Hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi",
                "Description": "After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 3,
                "title": "Day 03 - Delhi / Jaipur (260 Kms)",
                "Description": "After breakfast proceed to the Pink City – Jaipur and reach by afternoon and check into hotel. This afternoon, set off to see the City Palace --still the home of the former royal family of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory - situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory, on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 4,
                "title": "Day 04 - Jaipur",
                "Description": "In Jaipur a full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace is Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the “Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy cinema at the Asia´s Second Best Cinema Hall in the evening then enjoy dinner with folk dances by Gypsy women in an Indian Restaurant.",

            },
            {
                "id": 5,
                "title": "Day 05 - Jaipur / Ranthambore (180kms)",
                "Description": "After a leisure breakfast, leave for Ranthambore. On arrival check into hotel. Afternoon you will be escorted on game drives in the park. The park, which is one of the finest tiger reserves in the country, is the main attraction of Ranthambhor. Apart from tigers, Ranthambore National Park is home to a bewildering variety of animal species like Leopards, Striped Hyenas, Sambar, Chinkara, Black Bucks, Macaques, Langurs, Squirrels, Rufoustailed Hare, Small Indian Civets, Wild Cats to name just a few of the resident species that have made Ranthambore their home. The reptile species is no less varied with Indian Chameleon, Indian Python, King Cobra, Desert Monitor Lizard, Vipers. Overnight stay at hotel.",

            },
            {
                "id": 6,
                "title": "Day 06 - Ranthambore / Bharatpur (225 Kms)",
                "Description": "After breakfast we drive to Bharatpur - lying between the cities of Agra and Jaipur in the north-west is famous for Keoladeo Ghana bird sanctuary which is a home to an astonishing range of flora and fauna. An abundance of birdlife is found here, including the open-bill and painted stork, egret, darter, white ibis, spoonbill, grey heron, Sarus crane, cormorant, migratory geese, duck, rosy pelican and the rare Siberian crane. Arrive Bharatpur by evening and transfer to the hotel. Rest of the day at leisure. Overnight stay at the hotel.",

            },
            {
                "id": 7,
                "title": "Day 07 - Bharatpur / fatehpur sikri / Agra (56 Kms)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is the 54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti, Jodha Bai and Birbal’s house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 8,
                "title": "Day 08 - Agra / Umaria (Overnight train)",
                "Description": "Early morning visit Incomparable Taj Mahal which is among the seven wonders in the world. Taj mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. After that visit “Itmadullah's Tomb” built by Empress NOOR JEHAN in memory of her father (The interiors of which are considered better than the Taj). Later transfer to train Station to board the train for Umaria. Overnight in Train.",

            },
            {
                "id": 9,
                "title": "Day 09 - Bandhavgarh",
                "Description": "Reach Umaria and transfer to Bandhavgarh. Enjoy the breakfast and move to the national park The Bandhavgarh Tiger Reserve is known to host the famous and endangered white tigers. It is also well known for the beautiful flora and fauna. Enjoy a Morning and evening Safari Spend a quiet and refreshing evening at the Wildlife Resort in the evening.",

            },
            {
                "id": 10,
                "title": "Day 10 - Bandhavgarh",
                "Description": "After breakfast at the hotel you will be taken on you second day tour to the Bandhavgarh Tiger Reserve. You can try to spot the Indian Muntjac, Striped Hyena, Blackbuck, Dhole, Bengal Fox, Swamp Deer, Gaur and most importantly White Tigers in the tiger reserve.Spend a relaxed evening at the hotel.",

            },
            {
                "id": 11,
                "title": "Day 11 - Bandhavgarh /  Khajuraho",
                "Description": "In the morning after breakfast drive to Khajuraho. Reach Khajuraho by afternoon and check in hotel. After some relax move to visit the world famous “Kamasutra” temples. Khajuraho temples has achieved fame for the sensual appeal of  its erotic sculptures built during the 9th to the 11th centuries by the powerful dynasty of Chandela rajputs. Visit the largest and grandest temple of Khajuraho is the immortal Kandariya Mahadeva, other Eastern and Western groups of temples are Visvanatha, Parsvanatha, Vaidyanatha, Jagadambi, Chitragupta. Overnight in hotel.",

            },
            {
                "id": 12,
                "title": "Day 12 - Khajuraho / Varanasi (By Flight)",
                "Description": "After the leisure  breakfastin the morning, you will be transferred to the airport to take the flight to Varanasi. On Arrival received by representative and transfer to the hotel. The rest of the day is at leisure.",
            },
            {
                "id": 13,
                "title": "Day 13 - Varanasi",
                "Description": "In the early morning we take you for a Boat cruise at the river Ganges to observe the way of life of pilgrims by the Ghats.The boat ride starts from Dasashwamedh and ends at Manikarnika Ghat  also you visit Assi Ghat, Harish Chandra Ghat and Tulsi Ghat. After that visit the world famous Banaras Hindu University. Later visit Sarnath Temple, where Lord Buddha preached his first sermon.Sarnath's temples and Archaeological Museum are full of Buddhist art treasures. The 20 meter high Ashoka Pillar, set up by the Emperor Ashoka in 258 B.C. Overnight stay at the hotel.",
            },
            {
                "id": 14,
                "title": "Day 14 - Varanasi / Delhi (By Flight)",
                "Description": "In the Morning after breakfast you can move to visit the Ghats. Later you will be transferd to airport to take your flight to Delhi. On arrival the driver will receive you and before taking your international flight you can move around the connaught place market for shopping or to take a dinner in a good restaurant and than you will transferd to International airport 3 hours before your international flight time to your home country.",
            },
        ],
        "popular_tour": false,
        "image": "bg-goldenTiger",
        "related_tours": [6, 7, 8, 9],
    },
    {
        "id": 11,
        "titleName": "Best of Rajasthan",
        "Days": "12",
        "Nights": "11",
        "Banner": "Ranakpur-Jain-Temple-best_of_rajasthan.webp",
        "Destinations": "Delhi – Agra -  Jaipur – Bikaner – Jaisalmer – Jodhpur – Ranakpur – Udaipur – Flight -  Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. Overnight stay in Hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi",
                "Description": "After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 3,
                "title": "Day 03 - Delhi / Agra (205 Km)",
                "Description": "After breakfast at the hotel drive to Agra. On the way visit Sikandara - the last resting place of the Mughal emperor Akbar. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 4,
                "title": "Day 04 - Agra / Fatehpur Sikri / Jaipur (232 Km)",
                "Description": " Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is the 54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti, Jodha Bai and Birbal’s house are the other attractions. welcome to The Capital city of Rajasthan which is also known as “Pink City” This afternoon, set off to see The City Palace --still the home of the former royalty H.H Bhawani Singh Ji of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory - situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory, on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 5,
                "title": "Day 05 - Jaipur",
                "Description": "A full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace are Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the“Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy a Bollywood movie at “Rajmandir” the Second best Cinema in Asia. In the evening then enjoy Dinner with Folk Dances and Music by typical Rajasthani women in an Indian Restaurant.",

            },
            {
                "id": 6,
                "title": "Day 06 - Jaipur / Bikaner",
                "Description": "After breakfast, drive towards to Bikaner. On the way visit Fatehpur Village which is also famous for the splendid havelis of the Shekhawati region. Reach Bikaner by afternoon later set of for the sight seen tour of Bikaner includes visiting the impregnable 15th century Junagarh Fort reputed to have remained unconquered till date,the fort has a number of gates- Karan Pol (gate) provides access to the fort, next to it is Suraj Pol (sun gate Also visit the Camel Breeding Farm which carries out breeding and training of camels (closed on Sundays). Evening time is to visit Deshnoke Rat Temple. Overnight stay at your hotel in Bikaner",

            },
            {
                "id": 7,
                "title": "Day 07 - Bikaner / Jaisalmer (330kms)",
                "Description": "After early breakfast, leave through the Thar Desert for Jaisalmer, en-route visit Pokharan- the birth place of Akbar the Great (3rd Mughal Empror). Visiting the local market in the heart of the village and after taking lunch we proceed towards Jaisalmer. Arrive there by early evening. Let the Sun set view of this desert city weaves magic on you, leaving you speechless and mesmerized. Overnight stay at hotel.",

            },
            {
                "id": 8,
                "title": "Day 08 - Jaisalmer",
                "Description": "Sightseeing tour of the Golden City –Jaisalmer includes Sonar Fort or the Golden fort, rises from the sand and merges with the golden hues of the desert ambience. Badal Niwas, Manak Chowk, Patwon Ki Haveli - one of the largest and most extensively carved  Haveli with remnants of mirror work and paintings on the walls, Nathmal Ji Ki Haveli known for its unique miniature style paintings and mighty tuskers carved out of yellow sandstone stand guard to the haveli. & Deewan Salim Singh Ki Haveli- Prime Minister’s residence of 18th  century.Visit the Gadisar lake then in evening camel carts safari for sunset onto Sam or Khuri Sand Dunes where you can enjoy dinner in mud huts listen to the folk tunes  by the local artists and musicians. Overnight stay at hotel in Jaisalmer.",

            },
            {
                "id": 9,
                "title": "Day 09 - Jaisalmer / Jodhpur (280 kms)",
                "Description": "Early Morning depart to Jodhpur, en-route visit Mandore Gardens. Check into hotel.Post lunch seightseeing tour includes Mehrangarh fort - pride of Jodhpur, still stand high witnessing the battles fought.  Museum inside the fort displays a rich collection of old royal palanquins, arms, paintings, costumes, etc. Fort has number of palaces - Moti Mahal, Phool Mahal, Sheesh Mahal, Takht Vilas and Jhanki Mahal. On the way down from the fort, towards left is Jaswant Thada - intricately carved marble stone temple shaped cenotaph of Maharaja Jaswant Singh II. Umaid Bhawan Palace or Chittar Palace - palace with no mortar bindings, only chiselled  sandstone blocks are interlocked together. Evening is leisure or you can walk along the famous spice market and feel the flavours of indian spices. Overnight stay at hotel.",

            },
            {
                "id": 10,
                "title": "Day 10 - Jodhpur / Ranakpur / Udaipur (330 km)",
                "Description": "After breakfast, drive to Udaipur. On the way visit Ranakpur, the city named after Rana Kumbha, known for its Jain temple The four images of Adinath are enshrined in the main temple called 'Chamukh', having 29 halls and 1444 columns all with differently carved sculpturs.The complex also includes Sun Temple, Amba Mata Temple,  Parsvanath and Neminath temples. Reach Udaipur in afternoon and check in hotel. Later, see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter. After that move around the narrow streets of Udaipur, rest of the time free for your leisure.",

            },
            {
                "id": 11,
                "title": "Day 11 - Udaipur",
                "Description": " After breakfast move out for the city tour of Udaipur visit  the City Palace, a grand palace with beautiful architecture, the Jagdish Temple, dedicated to Lord Vishnu, and the folk art museum with a colorful collection of puppets, musical instruments and paintings.In the evening you can wander through the city or take an  boat ride on Lake Pichola and see the fabulous lake palaces of Udaipur, the Jag Mandir Palace and the Jag Niwas Palace now known as the Taj Lake Palace, where you can enjoy your dinner in a regal ambience. Return to your hotel to stay overnight.",

            },
            {
                "id": 12,
                "title": "Day 12 - Udaipur / Delhi (By Flight)",
                "Description": "In the Morning after breakfast you  will be transferd to airport to take your flight to Delhi. On arrival the driver will receive you and before taking your international flight you can move around the connaught place market for shopping or to take a dinner in a good restaurant and than you will transferred to International airport 3 hours before your international flight time to your home country.",
            },
        ],
        "popular_tour": false,
        "image": "bg-bestOfRajasthan",
        "related_tours": [12, 13, 14, 15],
    },
    {
        "id": 12,
        "titleName": "Kingdom of Rajasthan",
        "Days": "13",
        "Nights": "12",
        "Banner": "deogarh-mahal-kingdom_of_rajasthan.jpeg",
        "Destinations": "Delhi – Mandawa – Khimsar – Luni – Deogarh – Udaipur – Pushkar – Jaipur – Agra – Fatehpur Sikri – Agra - Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. Overnight stay in Hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi",
                "Description": "After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 3,
                "title": "Day 03 - Delhi / Mandawa (260kms)",
                "Description": "After breakfast at the hotel drive to Mandawa upon arrival check-in hotel and later we leave to see Mandawa - often called the “open air art gallery of Rajasthan the region is famous for the high quality frescoes. See the beautifully painted havelis on the ancient and the famous silk route built in the 18th and 19th centuary by the rich merchants. Later take a heritage walk in this beautiful village. You can also see the traditional lifestyle of the local people. Overnight in Hotel.",

            },
            {
                "id": 4,
                "title": "Day 04 - Mandawa / Khimsar (180kms)",
                "Description": "After Breakfst drive to Khimsar. Perched on the edge on the great Thar Desert in the heart of Rural India, the unique fort at Khimsar offers a wide range of experiences. The Battle scarred walls and turrets remained one of the Glorious past. The initial construction began in 1523 AD, the family moved in during the 18th Century when a new 'Zenana' (Ladies Wing) was constructed. Thakur Onkar Singh built a regal wing for himself in 1940s.Today the khimsar fort ranks high amongest the premier classified heritage hotels of India. Overnight in Hotel",

            },
            {
                "id": 5,
                "title": "Day 05 - Khimsar / Luni",
                "Description": "An hour drive brings you to a charming village Luni that reflects the royal past of Rajasthan, located on the bed of river Luni. The artisans inhabiting the village are still pursuing their ancestral profession of fashioning metal, clay or wood into intricate forms, Fort Chanwa of Luni is an exceptional example of the master craftsmanship. The entire fortress is carved out of the famous red sandstone of Jodhpur and with its ornately carved lattice work friezes and intricate Jharokas, it exquisitely captures the romance and grace of a bygone age. The fort is composed of a lyrical complex of courtyards towers, water, wheels, stables, passages and unexpected stairways to secret pavilions and panoramic roof tops spanning the village below and the Thar horizon beyond. The traditional paintings on the walls harmoniously reflect the skill of the master craftsmen and the bygone days.  Overnighy stay at hotel",

            },
            {
                "id": 6,
                "title": "Day 06 - Luni / Ranakpur / Deogarh (290Kms)",
                "Description": "After breakfast drive to Udaipur. On the way visit Ranakpur, the city named after Rana Kumbha, known for its Jain temple The four images of Adinath are enshrined in the main temple called 'Chamukh', having 29 halls and 1444 columns all with differently carved sculpturs.The complex also includes Sun Temple, Amba Mata Temple,  Parsvanath and Neminath temples. Arrive to Deogarh and check in Deogarh Mahal. Deogarh Mahal of Rajasthan is an impressive palace built in the 17th century. Surrounded by the Aravallis and speckled with lakes, the palace presents a magnificent sight. The main attractions of the Deogarh Palace are its graying battlements, domes, turrets, jharokhas, massive gateways and a rich collection of miniature paintings. Standing at a height of about 2100 ft above sea level,",

            },
            {
                "id": 7,
                "title": "Day 07 - Deogarh  / Udaipur (144Kms)",
                "Description": "In Morning you are free to explore the palace and later proceed towards Udaipur. Upon arrival check in hotel later see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter. After that move around the narrow streets of Udaipur Rest of the time free for your leisure.",

            },
            {
                "id": 8,
                "title": "Day 08 - Udaipur",
                "Description": "After breakfast move out for the city tour of Udaipur visit  the City Palace, a grand palace with beautiful architecture, the Jagdish Temple, dedicated to Lord Vishnu, and the folk art museum with a colorful collection of puppets, musical instruments and paintings. In the evening you can wander through the city or take an  boat ride on Lake Pichola and see the fabulous lake palaces of Udaipur, the Jag Mandir Palace and the Jag Niwas Palace now known as the Taj Lake Palace, where you can enjoy your dinner in a regal ambience. Return to your hotel to stay overnight.",

            },
            {
                "id": 9,
                "title": "Day 09 - Udaipur / Pushkar (275 km)",
                "Description": "Leave Udaipur and head towards Pushkar on the way visit the Eklingji and Nagda Jain Temple. Also visit the Saas and Bahu Temple dedicated to Lord Shiva and Vishnu. Afternoon reach the Holy City -  Pushkar, the place where Lord Brahma (Creator of the Hindu Trinity) and Savitri (His Wife) perform “Yagnas”. Here we visit Brahama Temple and Lake Pushkar,  where devotees take holy bath in lake water. The legand says that the lake sprout on the place where the lotus petals fell on earth from the hands of Lord Brahma. Pushkar is popular for the Pushkar Fair or Camel Fair that held every year in the month of November. In evening enjoy the walk along the colourful and bustling local market.",

            },
            {
                "id": 10,
                "title": "Day 10 - Pushkar / Jaipur (150 kms)",
                "Description": "On the way to Jaipur in Ajmer Visit Nasiya Golden Jain Temple, “Dargah Sharif” and Adhai Din Ka Jhopra. After that welcome to the Capital city of Rajasthan which is also known as “Pink City” This afternoon, set off to see the City Palace --still the home of the former royalty H.H Bhawani Singh Ji of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory - situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory,on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 11,
                "title": "Day 11 - Udaipur",
                "Description": "A full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace are Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the“Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy a Bollywood movie at “Rajmandir” the Second best Cinema in Asia. In the evening then enjoy dinner with folk dances and music by typical Rajasthani women in an Indian Restaurant.",

            },
            {
                "id": 12,
                "title": "Day 12 - Jaipur / Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is 54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti,  Jodha Bai and  Birbal's house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",
            },
            {
                "id": 13,
                "title": "Day 13 - Agra / Delhi (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Delhi, visit Sikandara - the last resting place of the Mughal emperor Akbar. Upon arrival in Delhi, before taking your International Flight, you can walk around Cannaught Place or visit Sikh Temple. Take a dinner in the restaurant, later you will transferred to the International airport to board the International flight 3 hours before the scheduled flight time for your journey back to your country.",
            },
        ],
        "popular_tour": false,
        "image": "bg-kingdomRajasthan",
        "related_tours": [11, 13, 14, 15],
    },
    {
        "id": 13,
        "titleName": "Historical Rajasthan",
        "Days": "14",
        "Nights": "13",
        "Banner": "mehrangarh-fort_historical_rajasthan.jpeg",
        "Destinations": "Delhi – Mandawa – Bikaner – Pokran – Jaisalmer – Jodhpur – Ranakpur – Kumbhalgarh – Udaipur – Pushkar -  Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. Overnight stay in Hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi",
                "Description": "After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 3,
                "title": "Day 03 - Delhi / Mandawa (260kms)",
                "Description": "After breakfast at the hotel drive to Mandawa upon arrival check-in hotel and later we leave to see Mandawa - often called the “open air art gallery of Rajasthan the region is famous for the high quality frescoes. See the beautifully painted havelis on the ancient and the famous silk route built in the 18th and 19th centuary by the rich merchants. Later take a heritage walk in this beautiful village. You can also see the traditional lifestyle of the local people. Overnight in Hotel.",

            },
            {
                "id": 4,
                "title": "Day 04 - Mandawa / Bikaner (180 Km)",
                "Description": "After breakfast, drive towards to Bikaner. On the way visit Fatehpur Village which is also famous for the splendid havelis of the Shekhawati region. Reach Bikaner by afternoon later set of for the sight seen tour of Bikaner includes visiting the impregnable 15th century Junagarh Fort reputed to have remained unconquered till date,the fort has a number of gates- Karan Pol (gate) provides access to the fort, next to it is Suraj Pol (sun gate Also visit the Camel Breeding Farm which carries out breeding and training of camels (closed on Sundays). Evening time is to visit Deshnoke Rat Temple. Overnight stay at your hotel in Bikaner.",

            },
            {
                "id": 5,
                "title": "Day 05 - Bikaner / Jaisalmer (330kms)",
                "Description": "After early breakfast, leave through the Thar Desert for Jaisalmer, en-route visit Pokharan- the birth place of Akbar the Great (3rd Mughal Empror). Visiting the local market in the heart of the village and after taking lunch we proceed towards Jaisalmer. Arrive there by early evening. Let the Sun set view of this desert city weaves magic on you, leaving you speechless and mesmerized. Overnight stay at hotel.",

            },
            {
                "id": 6,
                "title": "Day 06 - Jaisalmer",
                "Description": "Sightseeing tour of the Golden City –Jaisalmer includes Sonar Fort or the Golden fort, rises from the sand and merges with the golden hues of the desert ambience. Badal Niwas, Manak Chowk, Patwon Ki Haveli - one of the largest and most extensively carved  Haveli with remnants of mirror work and paintings on the walls, Nathmal Ji Ki Haveli known for its unique miniature style paintings and mighty tuskers carved out of yellow sandstone stand guard to the haveli & Deewan Salim Singh Ki Haveli- Prime Minister’s residence of 18th  century.Visit the Gadisar lake then in evening camel carts safari for sunset onto Sam or Khuri Sand Dunes where you can enjoy dinner in mud huts listen to the folk tunes  by the local artists and musicians. Overnight stay at hotel in Jaisalmer.",

            },
            {
                "id": 7,
                "title": "Day 07 - Jaisalmer / Jodhpur (280 kms)",
                "Description": "Early Morning depart to Jodhpur, en-route visit Mandore Gardens. Check into hotel. Post lunch seightseeing tour includes Mehrangarh fort - pride of Jodhpur, still stand high witnessing the battles fought. Museum inside the fort displays a rich collection of old royal palanquins, arms, paintings, costumes, etc. Fort has number of palaces - Moti Mahal, Phool Mahal, Sheesh Mahal, Takht Vilas and Jhanki Mahal. On the way down from the fort, towards left is Jaswant Thada - intricately carved marble stone temple shaped cenotaph of Maharaja Jaswant Singh II. Umaid Bhawan Palace or Chittar Palace - palace with no mortar bindings, only chiselled  sandstone blocks are interlocked together. Evening is leisure or you can walk along the famous spice market and feel the flavours of indian spices. Overnight stay at hotel.",

            },
            {
                "id": 8,
                "title": "Day 08 - Jodhpur / Ranakpur / Udaipur (330 km)",
                "Description": "After breakfast, drive to Udaipur. On the way visit Ranakpur, the city named after Rana Kumbha, known for its Jain temple The four images of Adinath are enshrined in the main temple called 'Chamukh', having 29 halls and 1444 columns all with differently carved sculpturs. The complex also includes Sun Temple, Amba Mata Temple,  Parsvanath and Neminath temples. Reach Udaipur in afternoon and check in hotel. Later, see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter. After that move around the narrow streets of Udaipur, rest of the time free for your leisure.",

            },
            {
                "id": 9,
                "title": "Day 09 - Udaipur",
                "Description": "After breakfast move out for the city tour of Udaipur visit  the City Palace, a grand palace with beautiful architecture, the Jagdish Temple, dedicated to Lord Vishnu, and the folk art museum with a colorful collection of puppets, musical instruments and paintings.In the evening you can wander through the city or take an  boat ride on Lake Pichola and see the fabulous lake palaces of Udaipur, the Jag Mandir Palace and the Jag Niwas Palace now known as the Taj Lake Palace, where you can enjoy your dinner in a regal ambience. Return to your hotel to stay overnight.",

            },
            {
                "id": 10,
                "title": "Day 10 - Udaipur / Pushkar (275 km)",
                "Description": "Leave Udaipur and head towards Pushkar on the way visit the Eklingji and Nagda Jain Temple. Also visit the Saas and Bahu Temple dedicated to Lord Shiva and Vishnu. Afternoon reach the Holy City -  Pushkar, the place where Lord Brahma (Creator of the Hindu Trinity) and Savitri (His Wife) perform “Yagnas”. Here we visit Brahama Temple and Lake Pushkar, where devotees take holy bath in lake water. The legand says that the lake sprout on the place where the lotus petals fell on earth from the hands of Lord Brahma. Pushkar is popular for the Pushkar Fair or Camel Fair that held every year in the month of November. In evening enjoy the walk along the colourful and bustling local market.",

            },
            {
                "id": 11,
                "title": "Day 11 - Pushkar / Jaipur (150 kms)",
                "Description": "On the way to Jaipur in Ajmer Visit Nasiya Golden Jain Temple, “Dargah Sharif” and Adhai Din Ka Jhopra. After that welcome to the capital city of Rajasthan which is also known as “Pink City”, this afternoon, set off to see the City Palace --still the home of the former royalty H.H Bhawani Singh Ji of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory - situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory,on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 12,
                "title": "Day 12 - Jaipur",
                "Description": "A full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace are Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the“Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy a Bollywood movie at “Rajmandir” the Second best Cinema in Asia .In the evening then enjoy Dinner with Folk Dances and Music by typical Rajasthani women in an Indian Restaurant.",
            },
            {
                "id": 13,
                "title": "Day 13 - Jaipur / Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is 54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti,  Jodha Bai and  Birbal's house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",
            },
            {
                "id": 14,
                "title": "Day 14 - Agra / Delhi (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Delhi, visit Sikandara - the last resting place of the Mughal emperor Akbar. Upon arrival in Delhi, before taking your International Flight, you can walk around Cannaught Place or visit Sikh Temple. Take a dinner in the restaurant, later you will transferred to the International airport to board the International flight 3 hours before the scheduled flight time for your journey back to your country.",
            },
        ],
        "popular_tour": false,
        "image": "bg-historicalRajasthan",
        "related_tours": [11, 12, 14, 15],
    },
    {
        "id": 14,
        "titleName": "Royal Rajasthan",
        "Days": "14",
        "Nights": "13",
        "Banner": "Neemrana-Fort_royal_rajasthan.jpeg",
        "Destinations": "Delhi – Mandawa – Bikaner – Jaisalmer – Jodhpur – Luni – Ranakpur – Udaipur – Chittaurgarh - Bundi -  Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi / Mandawa (260kms)",
                "Description": "After breakfast at the hotel drive to Mandawa upon arrival check-in hotel and later we leave to see Mandawa - often called the open air art gallery of Rajasthan the region is famous for the high quality frescoes. See the beautifully painted havelis on the ancient and the famous silk route built in the 18th and 19th centuary by the rich merchants. Later take a heritage walk in this beautiful village. You can also see the traditional lifestyle of the local people. Overnight in Hotel.",

            },
            {
                "id": 3,
                "title": "Day 03 - Mandawa / Bikaner (180 Km)",
                "Description": "After breakfast, drive towards to Bikaner. On the way visit Fatehpur Village which is also famous for the splendid havelis of the Shekhawati region. Reach Bikaner by afternoon later set of for the sight seen tour of Bikaner includes visiting the impregnable 15th century Junagarh Fort reputed to have remained unconquered till date,the fort has a number of gates- Karan Pol (gate) provides access to the fort, next to it is Suraj Pol (sun gate Also visit the Camel Breeding Farm which carries out breeding and training of camels (closed on Sundays). Evening time is to visit Deshnoke Rat Temple. Overnight stay at your hotel in Bikaner.",

            },
            {
                "id": 4,
                "title": "Day 04 - Bikaner / Jaisalmer (330kms)",
                "Description": "After early breakfast, leave through the Thar Desert for Jaisalmer, en-route visit Pokharan- the birth place of Akbar the Great (3rd Mughal Empror). Visiting the local market in the heart of the village and after taking lunch we proceed towards Jaisalmer. Arrive there by early evening. Let the Sun set view of this desert city weaves magic on you, leaving you speechless and mesmerized. Overnight stay at hotel.",

            },
            {
                "id": 5,
                "title": "Day 05 - Jaisalmer",
                "Description": "Sightseeing tour of the Golden City –Jaisalmer includes Sonar Fort or the Golden fort, rises from the sand and merges with the golden hues of the desert ambience. Badal Niwas, Manak Chowk, Patwon Ki Haveli - one of the largest and most extensively carved  Haveli with remnants of mirror work and paintings on the walls, Nathmal Ji Ki Haveli known for its unique miniature style paintings and mighty tuskers carved out of yellow sandstone stand guard to the haveli & Deewan Salim Singh Ki Haveli- Prime Minister’s residence of 18th  century. Visit the Gadisar lake then in evening camel carts safari for sunset onto Sam or Khuri Sand Dunes where you can enjoy dinner in mud huts listen to the folk tunes  by the local artists and musicians. Overnight stay at hotel in Jaisalmer.",

            },
            {
                "id": 6,
                "title": "Day 06 - Jaisalmer / Jodhpur (280 kms)",
                "Description": "Early Morning depart to Jodhpur, en-route visit Mandore Gardens. Check into hotel. Post lunch seightseeing tour includes Mehrangarh fort - pride of Jodhpur, still stand high witnessing the battles fought.  Museum inside the fort displays a rich collection of old royal palanquins, arms, paintings, costumes, etc. Fort has number of palaces - Moti Mahal, Phool Mahal, Sheesh Mahal, Takht Vilas and Jhanki Mahal. On the way down from the fort, towards left is Jaswant Thada - intricately carved marble stone temple shaped cenotaph of Maharaja Jaswant Singh II. Umaid Bhawan Palace or Chittar Palace - palace with no mortar bindings, only chiselled  sandstone blocks are interlocked together. Evening is leisure or you can walk along the famous spice market and feel the flavours of indian spices. Overnight stay at hotel.",

            },
            {
                "id": 7,
                "title": "Day 07 - Jodhpur / Luni (35 kms)",
                "Description": "An hour drive brings you to a charming village Luni that reflects the royal past of Rajasthan, located on the bed of river Luni. The artisans inhabiting the village are still pursuing their ancestral profession of fashioning metal, clay or wood into intricate forms, Fort Chanwa of Luni is an exceptional example of the master craftsmanship. The entire fortress is carved out of the famous red sandstone of Jodhpur and with its ornately carved lattice work friezes and intricate Jharokas, it exquisitely captures the romance and grace of a bygone age. The fort is composed of a lyrical complex of courtyards towers, water, wheels, stables, passages and unexpected stairways to secret pavilions and panoramic roof tops spanning the village below and the Thar horizon beyond. The traditional paintings on the walls harmoniously reflect the skill of the master craftsmen and the bygone days.  Overnighy stay at hotel.",

            },
            {
                "id": 8,
                "title": "Day 08 - Luni / Udaipur (250km)",
                "Description": "After breakfast drive to Udaipur. On the way visit Ranakpur, the city named after Rana Kumbha, known for its Jain temple. The four images of Adinath are enshrined in the main temple called 'Chamukh', having 29 halls and 1444 columns all with differently carved sculpturs. The complex also includes Sun Temple, Amba Mata Temple,  Parsvanath and Neminath temples. Reach Udaipur in afternoon and check in hotel. Later, see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter. After that move around the narrow streets of Udaipur, rest of the time free for your leisure.",

            },
            {
                "id": 9,
                "title": "Day 09 - Udaipur",
                "Description": "After breakfast move out for the city tour of Udaipur visit  the City Palace, a grand palace with beautiful architecture, the Jagdish Temple, dedicated to Lord Vishnu, and the folk art museum with a colorful collection of puppets, musical instruments and paintings. In the evening you can wander through the city or take an  boat ride on Lake Pichola and see the fabulous lake palaces of Udaipur, the Jag Mandir Palace and the Jag Niwas Palace now known as the Taj Lake Palace, where you can enjoy your dinner in a regal ambience. Return to your hotel to stay overnight."

            },
            {
                "id": 10,
                "title": "Day 10 - Udaipur / Chittaurgarh / Bundi (233Kms)",
                "Description": " After Breakfast drive to Bundi. On the way visit Chittaurgarh Built on a 180 metre high hill, the Chittaurgarh fort occupies 280 hectares and soars up to a height of 150 m. Later see the Rani Padmini Palace. Also see the Vijay Stambh – “Victory Tower” and Kirti Stambh. Then proceed to Bundi. On arrival check in hotel and rest of the day free for leisure. Overnight in hotel.",

            },
            {
                "id": 11,
                "title": "Day 11 - Bundi / Jaipur (206Kms)",
                "Description": "Morning visit Bundi - The Taragarh fort this interesting fort, built in 1354, is approached by climbing a steep hill, to reach the gigantic gate. On the Bhim Burj, the biggest battlements, there are famous cannon. There are great views of the area from the walls. After that proceed to Jaipur, welcome to the Capital city of Rajasthan which is also known as “Pink City” .This afternoon, set off to see the City Palace --still the home of the former royalty H.H Bhawani Singh Ji of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory - situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory,on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 12,
                "title": "Day 12 - Jaipur",
                "Description": "A full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace are Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the“Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy a Bollywood movie at “Rajmandir” the Second best Cinema in Asia. In the evening then enjoy dinner with folk dances and music by typical Rajasthani women in an Indian Restaurant.",
            },
            {
                "id": 13,
                "title": "Day 13 - Jaipur / Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is  54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti, Jodha Bai and Birbal’s house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",
            },
            {
                "id": 14,
                "title": "Day 14 - Agra / Delhi (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Delhi, visit Sikandara - the last resting place of the Mughal emperor Akbar. Upon arrival in Delhi, before taking your International Flight, you can walk around Cannaught Place or visit Sikh Temple. Take a dinner in the restaurant, later you will transferred to the International airport to board the International flight 3 hours before the scheduled flight time for your journey back to your country.",
            },
        ],
        "popular_tour": false,
        "image": "bg-royalRajasthan",
        "related_tours": [11, 12, 13, 15],
    },
    {
        "id": 15,
        "titleName": "Exotic Rajasthan",
        "Days": "16",
        "Nights": "15",
        "Banner": "chittorgarh_fort_exotic_rajasthan.jpeg",
        "Destinations": "Delhi – Mandawa – Bikaner – Jaisalmer – Jodhpur – Luni – Ranakpur – Udaipur – Chittaurgarh - Bundi – Pushkar -  Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. Overnight stay in Hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi",
                "Description": "After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 3,
                "title": "Day 03 - Delhi / Mandawa (260kms)",
                "Description": "After breakfast at the hotel drive to Mandawa upon arrival check-in hotel and later we leave to see Mandawa - often called the “open air art gallery of Rajasthan the region is famous for the high quality frescoes. See the beautifully painted havelis on the ancient and the famous silk route built in the 18th and 19th centuary by the rich merchants. Later take a heritage walk in this beautiful village. You can also see the traditional lifestyle of the local people. Overnight in Hotel.",

            },
            {
                "id": 4,
                "title": "Day 04 - Mandawa / Bikaner (180 Km)",
                "Description": "After breakfast, drive towards to Bikaner. On the way visit Fatehpur Village which is also famous for the splendid havelis of the Shekhawati region. Reach Bikaner by afternoon later set of for the sight seen tour of Bikaner includes visiting the impregnable 15th century Junagarh Fort reputed to have remained unconquered till date, the fort has a number of gates- Karan Pol (gate) provides access to the fort, next to it is Suraj Pol (sun gate Also visit the Camel Breeding Farm which carries out breeding and training of camels (closed on Sundays). Evening time is to visit Deshnoke Rat Temple. Overnight stay at your hotel in Bikaner.",

            },
            {
                "id": 5,
                "title": "Day 05 - Bikaner / Jaisalmer (330kms)",
                "Description": "After early breakfast, leave through the Thar Desert for Jaisalmer, en-route visit Pokharan- the birth place of Akbar the Great (3rd Mughal Empror). Visiting the local market in the heart of the village and after taking lunch we proceed towards Jaisalmer. Arrive there by early evening. Let the Sun set view of this desert city weaves magic on you, leaving you speechless and mesmerized. Overnight stay at hotel.",

            },
            {
                "id": 6,
                "title": "Day 06 - Jaisalmer",
                "Description": "Sightseeing tour of the Golden City –Jaisalmer includes Sonar Fort or the Golden fort, rises from the sand and merges with the golden hues of the desert ambience. Badal Niwas, Manak Chowk, Patwon Ki Haveli - one of the largest and most extensively carved  Haveli with remnants of mirror work and paintings on the walls, Nathmal Ji Ki Haveli known for its unique miniature style paintings and mighty tuskers carved out of yellow sandstone stand guard to the haveli & Deewan Salim Singh Ki Haveli- Prime Minister’s residence of 18th  century.Visit the Gadisar lake then in evening camel carts safari for sunset onto Sam or Khuri Sand Dunes where you can enjoy dinner in mud huts listen to the folk tunes  by the local artists and musicians. Overnight stay at hotel in Jaisalmer.",

            },
            {
                "id": 7,
                "title": "Day 07 - Jaisalmer / Jodhpur (280 kms)",
                "Description": "Early Morning depart to Jodhpur, en-route visit Mandore Gardens. Check into hotel. Post lunch seightseeing tour includes Mehrangarh fort - pride of Jodhpur, still stand high witnessing the battles fought. Museum inside the fort displays a rich collection of old royal palanquins, arms, paintings, costumes, etc. Fort has number of palaces - Moti Mahal, Phool Mahal, Sheesh Mahal, Takht Vilas and Jhanki Mahal. On the way down from the fort, towards left is Jaswant Thada - intricately carved marble stone temple shaped cenotaph of Maharaja Jaswant Singh II. Umaid Bhawan Palace or Chittar Palace - palace with no mortar bindings, only chiselled  sandstone blocks are interlocked together. Evening is leisure or you can walk along the famous spice market and feel the flavours of indian spices. Overnight stay at hotel.",

            },
            {
                "id": 8,
                "title": "Day 08 - Jodhpur / Luni (35 kms)",
                "Description": "An hour drive brings you to a charming village Luni that reflects the royal past of Rajasthan, located on the bed of river Luni. The artisans inhabiting the village are still pursuing their ancestral profession of fashioning metal, clay or wood into intricate forms, Fort Chanwa of Luni is an exceptional example of the master craftsmanship. The entire fortress is carved out of the famous red sandstone of Jodhpur and with its ornately carved lattice work friezes and intricate Jharokas, it exquisitely captures the romance and grace of a bygone age. The fort is composed of a lyrical complex of courtyards towers, water, wheels, stables, passages and unexpected stairways to secret pavilions and panoramic roof tops spanning the village below and the Thar horizon beyond. The traditional paintings on the walls harmoniously reflect the skill of the master craftsmen and the bygone days.  Overnighy stay at hotel.",

            },
            {
                "id": 9,
                "title": "Day 09 - Luni / Udaipur (250km)",
                "Description": " After breakfast drive to Udaipur. On the way visit Ranakpur, the city named after Rana Kumbha, known for its Jain temple. The four images of Adinath are enshrined in the main temple called 'Chamukh', having 29 halls and 1444 columns all with differently carved sculpturs. The complex also includes Sun Temple, Amba Mata Temple,  Parsvanath and Neminath temples. Reach Udaipur in afternoon and check in hotel. Later, see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter. After that move around the narrow streets of Udaipur Rest of the time free for your leisure.",

            },
            {
                "id": 10,
                "title": "Day 10 - Udaipur",
                "Description": "After breakfast move out for the city tour of Udaipur visit  the City Palace, a grand palace with beautiful architecture, the Jagdish Temple, dedicated to Lord Vishnu, and the folk art museum with a colorful collection of puppets, musical instruments and paintings. In the evening you can wander through the city or take an  boat ride on Lake Pichola and see the fabulous lake palaces of Udaipur, the Jag Mandir Palace and the Jag Niwas Palace now known as the Taj Lake Palace, where you can enjoy your dinner in a regal ambience. Return to your hotel to stay overnight.",

            },
            {
                "id": 11,
                "title": "Day 11 - Udaipur / Chittaurgarh / Bundi (233Kms)",
                "Description": "After Breakfast drive to Bundi. On the way visit Chittaurgarh Built on a 180 metre high hill, the Chittaurgarh fort occupies 280 hectares and soars up to a height of 150 m. Later see the Rani Padmini Palace. Also see the Vijay Stambh – “Victory Tower” and Kirti Stambh. Then proceed to Bundi. On arrival check in hotel and rest of the day free for leisure. Overnight in Hotel.",

            },
            {
                "id": 12,
                "title": "Day 12 - Bundi / Pushkar (190 Kms)",
                "Description": "Morning visit Bundi - The Taragarh fort this interesting fort, built in 1354, is approached by climbing a steep hill, to reach the gigantic gate. On the Bhim Burj, the biggest battlements, there are famous cannon. There are great views of the area from the walls. After that proceed to Pushkar. -  Pushkar, the place where Lord Brahma (Creator of the Hindu Trinity) and Savitri (His Wife) perform “Yagnas”. Here we visit Brahama Temple and Lake Pushkar, where devotees take holy bath in lake water. The legand says that the lake sprout on the place where the lotus petals fell on earth from the hands of Lord Brahma. Pushkar is popular for the Pushkar Fair or Camel Fair that held every year in the month of November. In evening enjoy the walk along the colourful and bustling local market.",
            },
            {
                "id": 13,
                "title": "Day 13 - Pushkar / Jaipur (150 kms)",
                "Description": "On the way to Jaipur in Ajmer Visit Nasiya Golden Jain Temple, “Dargah Sharif” and Adhai Din Ka Jhopra. After that welcome to the capital city of Rajasthan which is also known as “Pink City”. This afternoon, set off to see the City Palace --still the home of the former royalty H.H Bhawani Singh Ji of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory - situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory, on the way back visit Birla Temple. Evening at leisure.",
            },
            {
                "id": 14,
                "title": "Day 14 - Jaipur",
                "Description": "A full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace are Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the“Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy a Bollywood movie at “Rajmandir” the Second best Cinema in Asia.In the evening then enjoy dinner with folk dances and music by typical Rajasthani women in an Indian Restaurant.",
            },
            {
                "id": 15,
                "title": "Day 15 - Jaipur / Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is 54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti,  Jodha Bai and  Birbal's house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",
            },
            {
                "id": 16,
                "title": "Day 16 - Agra / Delhi (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Delhi, visit Sikandara - the last resting place of the Mughal emperor Akbar. Upon arrival in Delhi, before taking your International Flight, you can walk around Cannaught Place or visit Sikh Temple, take a dinner in the restaurant, later you will transferred to the International airport to board the International flight 3 hours before the scheduled flight time for your journey back to your country.",
            },
        ],
        "popular_tour": false,
        "image": "bg-exoticRajasthan",
        "related_tours": [11, 12, 13, 14],
    },
    {
        "id": 16,
        "titleName": "Buddhist Pilgrimage Tour",
        "Days": "11",
        "Nights": "10",
        "Banner": "Buddhist_Pilgrimage.jpeg",
        "Destinations": "Delhi – Patna – Bodhgaya – Varanasi – Kushinagar – Lumbini – Sravasti – Lucknow -  – Agra – Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. Overnight stay in Hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi",
                "Description": "After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 3,
                "title": "Day 03 - Delhi / Patna (By Flight) / Bodhgaya (130 km)",
                "Description": "After breakfast transfer to the airport to catch the flight for Patna. Upon arrival drive to Bodhgaya where the Buddha attained enlightenment and which is one of the most sacred pilgrimage places for the Buddhist. Enroute visit Nalanda and Rajgir. Nalanda-a great center of Buddhist learning and one of the most famed university citadels of ancient times. Rajgir was once the ancient capital of the powerful kingdom of Maghada. From the out skirts of Rajgir, a historic road leads to Gridha - Kuta or vultures peak where the Buddha preached, here the first Buddhist Council was held after the Lord's Mahaparinirvana. Overnight stay in the hotel.",

            },
            {
                "id": 4,
                "title": "Day 04 - Bodhgaya",
                "Description": "After breakfast sightseeing of Bodhgaya. Here under the Bodhi Tree, Shakyamuni Gautama attained supreme knowledge to become Buddha, the enlightened one the profounder of one of the great religious of the world. River Nirajnana in which the Buddha bathed after attaining elightment flows quietly outside the tiny hamlet. Overnight stay in the hotel.",

            },
            {
                "id": 5,
                "title": "Day 05 - Bodhgaya / Varanasi (247 Km)",
                "Description": "After breakfast drive to Varanasi. Upon arrival transfer to the hotel. Evening we take you for a Boat cruise at the river Ganges to observe the way of life of pilgrims by the Ghats. The boat ride starts from Dasashwamedh and ends at Manikarnika Ghat. Also attend the holy Aarti. Overnight stay in the hotel.",

            },
            {
                "id": 6,
                "title": "Day 06 - Varanasi",
                "Description": "In the early morning we take you for a Boat cruise at the river Ganges to observe the way of life of pilgrims by the Ghats. The boat ride starts from Dasashwamedh and ends at Manikarnika Ghat  also you visit Assi Ghat, Harish Chandra Ghat and Tulsi Ghat. After that, visit the world famous Banaras Hindu University. Later visit Sarnath Temple, where Lord Buddha preached his first sermon. Sarnath's temples and Archaeological Museum are full of Buddhist art treasures. The 20 meter high Ashoka Pillar, set up by the Emperor Ashoka in 258 B.C. Overnight stay at the hotel.",

            },
            {
                "id": 7,
                "title": "Day 07 - Varanasi / Kushinagar (265 Km)",
                "Description": "After breakfast drive to Kushinagar.  Upon arrival transfer to the hotel. Later visit Nirvana Stupa. This huge brickwork stupa, exposed by Carlleyl in 1867, stands at a height of 2.74 meter. A copper vessel was unearthed at this site. It bore an inscription in ancient Brahmi, Japanese Temple a beautiful Ashta Dhatu (eight metlas) statue of Lord Buddha, which came from Japan, can be seen here. Overnight stay will be in hotel.",

            },
            {
                "id": 8,
                "title": "Day 08 - Kushinagar / Lumbini (170 Kms)",
                "Description": "Morning visit Mahaparinirvana Temple which is famous for the Mahaparinirvana (death) of Lord Buddha, has been included in the famous Buddhist trail encompassing Bihar, Uttar Pradesh, and Nepal. And Rambhar Stupa, this large stupa rises to a height of 49 ft. It marks the site where the Lord Buddha was cremated. Later drive to Lumbini. Upon arrival transfer to the hotel. Later Sightseeing of Lumbini where the Lord Buddha was born. Visit the pillar raised by Emperor Ashok, in the twenty first years after his coronation at the birthplace. Overnight stay in the hotel.",

            },
            {
                "id": 9,
                "title": "Day 09 - Lumbini / Sravasti (170 Km)",
                "Description": "After early breakfast, drive to Sravasti. Upon arrival transfer to the hotel. Later precede for the sight seeing visit Sravasti (Sahet-Mahet) capital of the ancient kingdom of Kosala, Ananda Bodhi Tree Lord Buddha meditated under this tree for a night. It is believed that the tree blesses devotees who meditate under it, an active centre for Buddhism. Overnight at hotel.",

            },
            {
                "id": 10,
                "title": "Day 10 - Sravasti / Lucknow / Night Train to Agra",
                "Description": "Morning free for leisure. Later drive to Lucknow (195 kms / 5 hrs) and board train to Agra. Overnight in Train.",

            },
            {
                "id": 11,
                "title": "Day 11 - Agra / Delhi and Departure",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Delhi, visit Sikandara - the last resting place of the Mughal emperor Akbar. Upon arrival in Delhi, before taking your International Flight, you can walk around Cannaught Place or visit Sikh Temple. Take a dinner in the restaurant, later you will transferred to the International airport to board the International flight 3 hours before the scheduled flight time for your journey back to your country.",

            },
        ],
        "popular_tour": false,
        "image": "bg-buddhistTour",
        "related_tours": [17, 18, 19, 20],
    },
    {
        "id": 17,
        "titleName": "Ajanta Ellora with Royal Rajasthan Tour",
        "Days": "12",
        "Nights": "11",
        "Banner": "Ajanta_Ellora.jpeg",
        "Destinations": "Delhi – Agra -  Jaipur – Pushkar – Jodhpur - Ranakpur – Udaipur – Flight – Aurangabad – Flight - Mumbai",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": "On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. Overnight stay in Hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi",
                "Description": "After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 3,
                "title": "Day 03 - Delhi / Agra (205 Km)",
                "Description": "After breakfast at the hotel drive to Agra. On the way visit Sikandara - the last resting place of the Mughal emperor Akbar. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 4,
                "title": "Day 04 - Agra / Fatehpur Sikri / Jaipur (232 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is the 54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti, Jodha Bai and Birbal’s house are the other attractions. welcome to The Capital city of Rajasthan which is also known as “Pink City” This afternoon, set off to see The City Palace --still the home of the former royalty H.H Bhawani Singh Ji of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory - situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory, on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 5,
                "title": "Day 05 - Jaipur",
                "Description": "A full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace are Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the“Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy a Bollywood movie at “Rajmandir” the Second best Cinema in Asia. In the evening then enjoy dinner with folk dances and music by typical Rajasthani women in an Indian Restaurant.",

            },
            {
                "id": 6,
                "title": "Day 06 - Jaipur / Pushkar (150 Km)",
                "Description": "Leave Jaipur and head towards Pushkar on the way visit in Ajmer Nasiya Golden Jain Temple, “Dargah Sharif” and Adhai Din Ka Jhopra. Afternoon reach the Holy City -  Pushkar, the place where Lord Brahma (Creator of the Hindu Trinity) and Savitri (His Wife) perform “Yagnas”. Here we visit Brahama Temple and Lake Pushkar, where devotees take holy bath in lake water. The legand says that the lake sprout on the place where the lotus petals fell on earth from the hands of Lord Brahma. Pushkar is popular for the Pushkar Fair or Camel Fair that held every year in the month of November. In evening enjoy the walk along the colourful and bustling local market.",

            },
            {
                "id": 7,
                "title": "Day 07 - Pushkar / Jodhpur ( 214 Kms)",
                "Description": "After Breakfast drive to Jodhpur. Upon arrival check into hotel. Post lunch seightseeing tour includes Mehrangarh fort - pride of Jodhpur, still stand high witnessing the battles fought.  Museum inside the fort displays a rich collection of old royal palanquins, arms, paintings, costumes, etc. Fort has number of palaces - Moti Mahal, Phool Mahal, Sheesh Mahal, Takht Vilas and Jhanki Mahal. On the way down from the fort, towards left is Jaswant Thada - intricately carved marble stone temple shaped cenotaph of Maharaja Jaswant Singh II. Umaid Bhawan Palace or Chittar Palace - palace with no mortar bindings, only chiselled  sandstone blocks are interlocked together. Evening is leisure or you can walk along the famous spice market and feel the flavours of indian spices. Overnight stay at hotel.",

            },
            {
                "id": 8,
                "title": "Day 08 - Jodhpur / Ranakpur / Udaipur (330 km)",
                "Description": "After breakfast, drive to Udaipur. On the way visit Ranakpur, the city named after Rana Kumbha, known for its Jain temple. The four images of Adinath are enshrined in the main temple called 'Chamukh', having 29 halls and 1444 columns all with differently carved sculpturs. The complex also includes Sun Temple, Amba Mata Temple,  Parsvanath and Neminath temples. Reach Udaipur in afternoon and check in hotel. Later, see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter. After that move around the narrow streets of Udaipur, rest of the time free for your leisure.",

            },
            {
                "id": 9,
                "title": "Day 09 - Udaipur",
                "Description": "After breakfast move out for the city tour of Udaipur visit  the City Palace, a grand palace with beautiful architecture, the Jagdish Temple, dedicated to Lord Vishnu, and the folk art museum with a colorful collection of puppets, musical instruments and paintings.In the evening you can wander through the city or take an  boat ride on Lake Pichola and see the fabulous lake palaces of Udaipur, the Jag Mandir Palace and the Jag Niwas Palace now known as the Taj Lake Palace, where you can enjoy your dinner in a regal ambience. Return to your hotel to stay overnight.",

            },
            {
                "id": 10,
                "title": "Day 10 - Udaipur / Aurangabad (By Flight)",
                "Description": "After breakfast you will be transferred to airport to take the flight to Aurangabad. Upon arrival received by our representative and transfer to hotel. Later move for the sight seen of aurangabad which includes “Bibi Ka Maqbara” was built by Prince Azam Shah, son of Emperor Aurangzeb, in the late 17th century as a loving tribute to his mother, Dilras Bano Begam. The monument's name translates literally to 'Tomb of the Lady', but has earned the nickname 'poor man’s Taj' because it was made to rival the Taj Mahal.  “Panchakki” The Water Mill complex. This is very good example of long indian science history, water force use to rotate the mill stone for grinding wheat. Overnight in hotel.",

            },
            {
                "id": 11,
                "title": "Day 11 - Aurangabad",
                "Description": "After breakfast take an excursion to Ajanta caves: Ajanta Caves are a series of 29Buddhist cave temples. Some of which date from the 2nd century BC. Encompassing both Theravada and Mahayana Buddhist traditions, the Ajanta caves preserve some of the best masterpieces of Buddhist art in India. The Caves were carved over many years out of a horseshoe-shaped cliff along the Waghora River. They were used by Buddhist monks as prayer halls (chaitya grihas) and monasteries (viharas) for about nine centuries, and then were abruptly abandoned. They fell into oblivion until they were rediscovered in 1819.",

            },
            {
                "id": 12,
                "title": "Day 12 - Aurangabad / Mumbai (By Flight)",
                "Description": "After breakfast take an excursion to Ellora caves. The Ellora Caves are an impressive complex of Buddhist, Hindu and Jain cave temples built between the 6th and 10th centuries AD near the ancient Indian village of Ellora. The caves have a slightly less dramatic setting than those at Ajanta, but more exquisite sculptures. Ellora is a World Heritage Site and the most visited ancient monument in Maharashtra State. The caves at Ellora were carved out of the vertical face of the Charanandri hills between the 6th and 10th centuries. The carving work began around 550 AD, about the same time the Ajanta Caves (100km northeast) were abandoned.Later transfer to airport to take the flight to Mumbai On arrival the driver will receive you and than you will transferred to International airport 3 hours before your international flight time to your home country.",

            },
        ],
        "popular_tour": false,
        "image": "bg-ajantaElloraTour",
        "related_tours": [16, 18, 19, 20],
    },
    {
        "id": 18,
        "titleName": "Pushkar Fair Tour",
        "Days": "14",
        "Nights": "13",
        "Banner": "Pushkar_Fair.png",
        "Destinations": "Delhi – Mandawa – Bikaner – Jaisalmer – Jodhpur – Ranakpur – Udaipur – Pushkar -Jaipur – Amber Fort – Fatehpur Sikari – Agra – Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": " On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi / Mandawa (260kms)",
                "Description": "After breakfast at the hotel drive to Mandawa upon arrival check-in hotel and later we leave to see Mandawa - often called the open air art gallery of Rajasthan the region is famous for the high quality frescoes. See the beautifully painted havelis on the ancient and the famous silk route built in the 18th and 19th centuary by the rich merchants. Later take a heritage walk in this beautiful village. You can also see the traditional lifestyle of the local people. Overnight in Hotel.",

            },
            {
                "id": 3,
                "title": "Day 03 - Mandawa / Bikaner (180 Km)",
                "Description": "After breakfast, drive towards to Bikaner. On the way visit Fatehpur Village which is also famous for the splendid havelis of the Shekhawati region. Reach Bikaner by afternoon later set of for the sight seen tour of Bikaner includes visiting the impregnable 15th century Junagarh Fort reputed to have remained unconquered till date, the fort has a number of gates- Karan Pol (gate) provides access to the fort, next to it is Suraj Pol (sun gate Also visit the Camel Breeding Farm which carries out breeding and training of camels (closed on Sundays). Evening time is to visit Deshnoke Rat Temple. Overnight stay at your hotel in Bikaner.",

            },
            {
                "id": 4,
                "title": "Day 04 - Bikaner / Jaisalmer (330kms)",
                "Description": "After early breakfast, leave through the Thar Desert for Jaisalmer, en-route visit Pokharan- the birth place of Akbar the Great (3rd Mughal Empror). Visiting the local market in the heart of the village and after taking lunch we proceed towards Jaisalmer. Arrive there by early evening. Let the Sun set view of this desert city weaves magic on you, leaving you speechless and mesmerized. Overnight stay at hotel.",

            },
            {
                "id": 5,
                "title": "Day 05 - Jaisalmer",
                "Description": "Sightseeing tour of the Golden City –Jaisalmer includes Sonar Fort or the Golden fort, rises from the sand and merges with the golden hues of the desert ambience. Badal Niwas, Manak Chowk, Patwon Ki Haveli - one of the largest and most extensively carved  Haveli with remnants of mirror work and paintings on the walls, Nathmal Ji Ki Haveli known for its unique miniature style paintings and mighty tuskers carved out of yellow sandstone stand guard to the haveli & Deewan Salim Singh Ki Haveli- Prime Minister’s residence of 18th  century. Visit the Gadisar lake then in evening camel carts safari for sunset onto Sam or Khuri Sand Dunes where you can enjoy dinner in mud huts listen to the folk tunes  by the local artists and musicians. Overnight stay at hotel in Jaisalmer.",

            },
            {
                "id": 6,
                "title": "Day 06 - Jaisalmer / Jodhpur (280 kms)",
                "Description": "Early Morning depart to Jodhpur, en-route visit Mandore Gardens. Check into hotel. Post lunch seightseeing tour includes Mehrangarh fort - pride of Jodhpur, still stand high witnessing the battles fought. Museum inside the fort displays a rich collection of old royal palanquins, arms, paintings, costumes, etc. Fort has number of palaces - Moti Mahal, Phool Mahal, Sheesh Mahal, Takht Vilas and Jhanki Mahal. On the way down from the fort, towards left is Jaswant Thada - intricately carved marble stone temple shaped cenotaph of Maharaja Jaswant Singh II. Umaid Bhawan Palace or Chittar Palace - palace with no mortar bindings, only chiselled sandstone blocks are interlocked together. Evening is leisure or you can walk along the famous spice market and feel the flavours of indian spices. Overnight stay at hotel.",

            },
            {
                "id": 7,
                "title": "Day 07 - Jodhpur / Udaipur (330 km)",
                "Description": "After breakfast, drive to Udaipur enroute visit  Ranakpur, the city named after Rana Kumbha, known for its Jain temple. The four images of Adinath are enshrined in the main temple called 'Chamukh', having 29 halls and 1444 columns all with differently carved sculpturs. The complex also includes Sun Temple, Amba Mata Temple,  Parsvanath and Neminath temples. Overnight stay at hotel. Later Kumbhalgarh Fort – One of the few impregnated forts in Rajasthan, with 2nd longest wall after china around Kumbhalgarh, the Muchchal Mahavir temple is wisely popular. This fort stands on a rocky hill commanding an excellent view of the wild and rugged scenery of the Aravallis and the sandy desert of Marwar. Reach Udaipur in afternoon and check in hotel. Later, see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter. After that move around the narrow streets of Udaipur, rest of the time free for your leisure.",

            },
            {
                "id": 8,
                "title": "Day 08 - Udaipur",
                "Description": "After breakfast move out for the city tour of Udaipur visit  the City Palace, a grand palace with beautiful architecture, the Jagdish Temple, dedicated to Lord Vishnu, and the folk art museum with a colorful collection of puppets, musical instruments and paintings. In the evening you can wander through the city or take an  boat ride on Lake Pichola and see the fabulous lake palaces of Udaipur, the Jag Mandir Palace and the Jag Niwas Palace now known as the Taj Lake Palace, where you can enjoy your dinner in a regal ambience. Return to your hotel to stay overnight.",

            },
            {
                "id": 9,
                "title": "Day 09 - Udaipur / Pushkar (275 km)",
                "Description": "Leave Udaipur and head towards Pushkar on the way visit the Eklingji and Nagda Jain Temple. Also visit the Saas and Bahu Temple dedicated to Lord Shiva and Vishnu. Afternoon reach the Holy City -  Pushkar, the place where Lord Brahma  (Creator of the Hindu Trinity) and Savitri (His Wife) perform “Yagnas”. Here we visit Brahama Temple and Lake Pushkar, where devotees take holy bath in lake water. The legand says that the lake sprout on the place where the lotus petals fell on earth from the hands of Lord Brahma. Pushkar is popular for the Pushkar Fair or Camel Fair that held every year in the month of November. In evening enjoy the walk along the colourful and bustling local market.",

            },
            {
                "id": 10,
                "title": "Day 10 - Pushkar (Camel Fair)",
                "Description": "Spend full day enjoying the Pushkar Fair. Enjoy the people crowd the stadium where camels, horses, and cows are paraded and raced. Camels are bought and sold during the Pushkar fair.",

            },
            {
                "id": 11,
                "title": "Day 11 - Pushkar / Jaipur (150 kms)",
                "Description": "On the way to Jaipur in Ajmer Visit Nasiya Golden Jain Temple, “Dargah Sharif” and Adhai Din Ka Jhopra. After that welcome to the capital city of Rajasthan which is also known as “Pink City”. This afternoon, set off to see the City Palace --still the home of the former royalty H.H Bhawani Singh Ji of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory - situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory,on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 12,
                "title": "Day 12 - Jaipur",
                "Description": "A full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace are Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the“Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy a Bollywood movie at “Rajmandir” the Second best Cinema in Asia. In the evening then enjoy dinner with folk dances and music by typical Rajasthani women in an Indian Restaurant.",

            },
            {
                "id": 13,
                "title": "Day 13 - Jaipur / Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is 54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti,  Jodha Bai and  Birbal's house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 14,
                "title": "Day 14 - Agra / Delhi (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Delhi, visit Sikandara - the last resting place of the Mughal emperor Akbar. Upon arrival in Delhi, before taking your International Flight, you can walk around Cannaught Place or visit Sikh Temple. Take a dinner in the restaurant, later you will transferred to the International airport to board the International flight 3 hours before the scheduled flight time for your journey back to your country.",

            },
        ],
        "popular_tour": false,
        "image": "bg-pushkarFair",
        "related_tours": [16, 17, 19, 20],
    },
    {
        "id": 19,
        "titleName": "Royal Fort and Palaces Tour",
        "Days": "16",
        "Nights": "15",
        "Banner": "Royal_Fort_and_Palaces.jpeg",
        "Destinations": "Delhi – Neemrana – Samode -  Mandawa – Khimsar – Jaisalmer - Jodhpur -  Udaipur – Deogarh  – Jaipur – Fatehpur Sikri – Agra - Delhi",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": " On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. Overnight stay in Hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi",
                "Description": "After enjoying the hearty breakfast, leave for the sightseeing tour of Old & New Delhi. Including Jama Masjid -- one of Asia's largest mosques, Red Fort- the magnificent and most opulent fort built during the years 1638 - 1648 by the Moghul Empire, walk through the silver street of Chandni Chowk, Raj Ghat - the cremation site of Mahatma Gandhi, A massive temple - Akshar Dham, The Hindu temple - Birla Mandir, The Humayun’s Tomb & Qutab Minar - a 73 m high victory tower and The Bahai Temple - shaped like a lotus flower and a proof of India's secularism. The India  Gate -war  memorial,  The Rashtrapati Bhawan - President's House, Parliament House and Government Secretariat Buildings. Overnight at the hotel",

            },
            {
                "id": 3,
                "title": "Day 03 - Delhi / Neemrana(122 Kms)",
                "Description": "After breakfast drive to Neemrana. Upon arrival check in the Neemrana Fort and Palace. Neemrana Fort-Palace is among India's oldest heritage resorts. Neemrana became the third capital of the descendants of Prithviraj Chauhan III, who was killed in battle by Mohammad Ghori in 1192 AD. This picturesque site was chosen by Raja Rajdeo and Neemrana derived its name from a brave local chieftain Nimola Meo, who when defeated by the Chauhans, pleaded that his name be given to his lost kingdom. Also Visit the old and very magnificent nine storied baori (step well). Rest of the day free for your leisure you can visit the village and explore the rural life of India.",

            },
            {
                "id": 4,
                "title": "Day 04 - Neemrana / Samode (140 kms)",
                "Description": "After breakfast drive to Samode. Upon arrival check in Hotel Samode Palace. The fort is an old fortified residence of the Nathawat family (hailing from chomu) that served as the Prime Ministers of the Jaipur Royal Court in the range of Aravali. This magnificent fort is as charming and romantic in itself, and exhibits grandeur, good taste, class and elegance. The way to the fort's main entrance is through the inside of the village, which can be covered from the highway by a camel safari, with the locals welcoming the guests with smiles and thrilling expressions. The inside of the fort reflects expressions of the medieval architecture that is renovated a bit for the oriental formality The Durbar hall of Samode Palace is one of the most beautifully painted chambers with glass & minakari work and the same goes for the private restricted spaces- meant for the zannanis,  women of the family.",

            },
            {
                "id": 5,
                "title": "Day 05 - Samode / Mandawa( 130 Kms)",
                "Description": "After breakfast drive to Mandawa. Upon arrival check in Hotel Castle Mandawa. later we leave to see Mandawa - often called the open air art gallery of Rajasthan the region is famous for the high quality frescoes. See the beautifully painted havelis on the ancient and the famous silk route built in the 18th and 19th centuary by the rich merchants. Later take a heritage walk in this beautiful village. You can also see the traditional lifestyle of the local people. Overnight in hotel.",

            },
            {
                "id": 6,
                "title": "Day 06 - Mandawa / Khimsar(180 kms)",
                "Description": "After Breakfst drive to Khimsar. Perched on the edge on the great Thar Desert in the heart of Rural India, the unique fort at Khimsar offers a wide range of experiences. The Battle scarred walls and turrets remained one of the Glorious past. The initial Construction began in 1523 AD, the family moved in during the 18th Century when a new 'Zenana' (Ladies Wing) was constructed. Thakur Onkar Singh built a regal wing for himself in 1940s. Today the khimsar fort ranks high amongest the premier classified heritage hotels of India. Overnight in hotel",

            },
            {
                "id": 7,
                "title": "Day 07 - Khimsar / Jaisalmer (302 Kms)",
                "Description": "After early breakfast, leave through the Thar Desert for Jaisalmer, en-route visit Pokharan- the birth place of Akbar the Great (3rd Mughal Empror). Visiting the local market in the heart of the village and after taking lunch we proceed towards Jaisalmer. Arrive there by early evening. Let the Sun set view of this desert city weaves magic on you, leaving you speechless and mesmerized. Overnight stay at hotel.",

            },
            {
                "id": 8,
                "title": "Day 08 - Jaisalmer",
                "Description": "Sightseeing tour of the Golden City –Jaisalmer includes Sonar Fort or the Golden fort, rises from the sand and merges with the golden hues of the desert ambience. Badal Niwas, Manak Chowk, Patwon Ki Haveli - one of the largest and most extensively carved  Haveli with remnants of mirror work and paintings on the walls, Nathmal Ji Ki Haveli known for its unique miniature style paintings and mighty tuskers carved out of yellow sandstone stand guard to the haveli & Deewan Salim Singh Ki Haveli- Prime Minister’s residence of 18th  century. Visit the Gadisar lake then in evening camel carts safari for sunset onto Sam or Khuri Sand Dunes where you can enjoy dinner in mud huts listen to the folk tunes  by the local artists and musicians. Overnight stay at hotel in Jaisalmer.",

            },
            {
                "id": 9,
                "title": "Day 09 - Jaisalmer / Jodhpur (280 kms)",
                "Description": "Early Morning depart to Jodhpur, en-route visit Mandore Gardens. Check into hotel. Post lunch seightseeing tour includes Mehrangarh fort - pride of Jodhpur, still stand high witnessing the battles fought.  Museum inside the fort displays a rich collection of old royal palanquins, arms, paintings, costumes, etc. Fort has number of palaces - Moti Mahal, Phool Mahal, Sheesh Mahal, Takht Vilas and Jhanki Mahal. On the way down from the fort, towards left is Jaswant Thada - intricately carved marble stone temple shaped cenotaph of Maharaja Jaswant Singh II. Umaid Bhawan Palace or Chittar Palace - palace with no mortar bindings, only chiselled  sandstone blocks are interlocked together. Evening is leisure or you can walk along the famous spice market and feel the flavours of indian spices. Overnight stay at hotel.",

            },
            {
                "id": 10,
                "title": "Day 10 - Jodhpur / Ranakpur / Udaipur(330 kms)",
                "Description": "After breakfast drive to Udaipur. On the way visit Ranakpur, the city named after Rana Kumbha, known for its Jain temple. The four images of Adinath are enshrined in the main temple called 'Chamukh', having 29 halls and 1444 columns all with differently carved sculpturs. The complex also includes Sun Temple, Amba Mata Temple,  Parsvanath and Neminath temples. Reach Udaipur in afternoon and check in hotel. Later, see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter. After that move around the narrow streets of Udaipur, rest of the time free for your leisure.",

            },
            {
                "id": 11,
                "title": "Day 11 - Udaipur",
                "Description": "After breakfast move out for the city tour of Udaipur visit  the City Palace, a grand palace with beautiful architecture, the Jagdish Temple, dedicated to Lord Vishnu, and the folk art museum with a colorful collection of puppets, musical instruments and paintings. In the evening you can wander through the city or take an  boat ride on Lake Pichola and see the fabulous lake palaces of Udaipur, the Jag Mandir Palace and the Jag Niwas Palace now known as the Taj Lake Palace, where you can enjoy your dinner in a regal ambience. Return to your hotel to stay overnight.",

            },
            {
                "id": 12,
                "title": "Day 12 - Udaipur / Deogarh (143 Km)",
                "Description": "After breakfast drive to Deogath. Upon arrival check in Hotel Deogarh Mahal. Deogarh Mahal of Rajasthan is an impressive palace built in the 17th century. Surrounded by the Aravallis and speckled with lakes, the palace presents a magnificent sight. The main attractions of the Deogarh Palace are its graying battlements, domes, turrets, jharokhas, massive gateways and a rich collection of miniature paintings. Standing at a height of about 2100 ft above sea level,",

            },
            {
                "id": 13,
                "title": "Day 13 - Deogarh / Jaipur (275 Km)",
                "Description": "After breakfast leave for Jaipur. Reach by afternoon than set off to see the City Palace --still the home of the former royalty H.H Bhawani Singh Ji of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory - situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory, on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 14,
                "title": "Day 14 - Jaipur",
                "Description": "A full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace are Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the“Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy a Bollywood movie at “Rajmandir” the Second best Cinema in Asia. In the evening then enjoy dinner with folk dances and music by typical Rajasthani women in an Indian Restaurant.",

            },
            {
                "id": 15,
                "title": "Day 15 - Jaipur / Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is 54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti,  Jodha Bai and  Birbal's house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 16,
                "title": "Day 16 - Agra / Delhi (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete it a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Delhi, visit Sikandara - the last resting place of the Mughal emperor Akbar.Upon arrival in Delhi, before taking your International Flight, you can walk around Cannaught Place or visit Sikh Temple.Take a dinner in the restaurant, later you will transferred to the International airport to board the International flight 3 hours before the scheduled flight time for your journey back to your country.",

            },
        ],
        "popular_tour": false,
        "image": "bg-forts",
        "related_tours": [16, 17, 18, 20],
    },
    {
        "id": 20,
        "titleName": "Best of India Tour",
        "Days": "25",
        "Nights": "24",
        "Banner": "Best_of_India.jpeg",
        "Destinations": "Delhi – Mandawa – Bikaner – Pokran – Jaisalmer – Jodhpur – Ranakpur – Kumbhalgarh – Udaipur – Pushkar -  Jaipur – Amber Fort – Fatehpur Sikari – Agra – Gwalior – Orchha – Khajuraho - (Flight) – Varanasi (Flight) – Mumbai – Flight – Cochin - Kumarakom – Allepey – Kovalam – Trivandrum – (Flight) Delhi / Mumbai",
        "Day": [
            {
                "id": 1,
                "title": "Day 01 - Arrive Delhi",
                "Description": " On arrival at Delhi, you are traditionally welcome by our representative and transfer to hotel by your Exclusive Car. In the hotel have a brief view of the tour from our representative. Overnight stay in Hotel",

            },
            {
                "id": 2,
                "title": "Day 02 - Delhi / Mandawa (260 kms)",
                "Description": "After breakfast at the hotel drive to Mandawa upon arrival check-in hotel and later we leave to see Mandawa - often called the open air art gallery of Rajasthan the region is famous for the high quality frescoes. See the beautifully painted havelis on the ancient and the famous silk route built in the 18th and 19th centuary by the rich merchants. Later take a heritage walk in this beautiful village. You can also see the traditional lifestyle of the local people. Overnight in Hotel.",

            },
            {
                "id": 3,
                "title": "Day 03 - Mandawa / Bikaner (180 Km)",
                "Description": "After breakfast, drive towards to Bikaner. On the way visit Fatehpur Village which is also famous for the splendid havelis of the Shekhawati region. Reach Bikaner by afternoon later set of for the sight seen tour of Bikaner includes visiting the impregnable 15th century Junagarh Fort reputed to have remained unconquered till date,the fort has a number of gates- Karan Pol (gate) provides access to the fort, next to it is Suraj Pol (sun gate Also visit the Camel Breeding Farm which carries out breeding and training of camels (closed on Sundays). Evening time is to visit Deshnoke Rat Temple. Overnight stay at your hotel in Bikaner.",

            },
            {
                "id": 4,
                "title": "Day 04 - Bikaner / Jaisalmer (330kms)",
                "Description": "After early breakfast, leave through the Thar Desert for Jaisalmer, en-route visit Pokharan- the birth place of Akbar the Great (3rd Mughal Empror). Visiting the local market in the heart of the village and after taking lunch we proceed towards Jaisalmer. Arrive there by early evening. Let the Sun set view of this desert city weaves magic on you, leaving you speechless and mesmerized. Overnight stay at hotel.",

            },
            {
                "id": 5,
                "title": "Day 05 - Jaisalmer",
                "Description": "Sightseeing tour of the Golden City –Jaisalmer includes Sonar Fort or the Golden fort, rises from the sand and merges with the golden hues of the desert ambience. Badal Niwas, Manak Chowk, Patwon Ki Haveli - one of the largest and most extensively carved  Haveli with remnants of mirror work and paintings on the walls, Nathmal Ji Ki Haveli known for its unique miniature style paintings and mighty tuskers carved out of yellow sandstone stand guard to the haveli & Deewan Salim Singh Ki Haveli- Prime Minister’s residence of 18th  century. Visit the Gadisar lake then in evening camel carts safari for sunset onto Sam or Khuri Sand Dunes where you can enjoy dinner in mud huts listen to the folk tunes  by the local artists and musicians. Overnight stay at hotel in Jaisalmer.",

            },
            {
                "id": 6,
                "title": "Day 06 - Jaisalmer / Jodhpur (280 kms)",
                "Description": " Early Morning depart to Jodhpur, en-route visit Mandore Gardens. Check into hotel. Post lunch seightseeing tour includes Mehrangarh fort - pride of Jodhpur, still stand high witnessing the battles fought. Museum inside the fort displays a rich collection of old royal palanquins, arms, paintings, costumes, etc. Fort has number of palaces - Moti Mahal, Phool Mahal, Sheesh Mahal, Takht Vilas and Jhanki Mahal. On the way down from the fort, towards left is Jaswant Thada - intricately carved marble stone temple shaped cenotaph of Maharaja Jaswant Singh II. Umaid Bhawan Palace or Chittar Palace - palace with no mortar bindings, only chiselled sandstone blocks are interlocked together. Evening is leisure or you can walk along the famous spice market and feel the flavours of indian spices. Overnight stay at hotel.",

            },
            {
                "id": 7,
                "title": "Day 07 - Jodhpur / Bishnoi Villages /  Ranakpur (185 km)",
                "Description": "After breakfast, drive to Ranakpur via Guda Bishnoi Village. Bishnoi tribe lead a vegetarian lifestyle, they respect and conserve nature in all aspect. Blackbucks, antelopes, chinkaras, peacocks and even cranes can be seen wandering  freely and are worshipped by the Bishnoi tribes. On arrival at Ranakpur, the city named after Rana Kumbha, known for its Jain temple. The four images of Adinath are enshrined in the main temple called 'Chamukh', having 29 halls and 1444 columns all with differently carved sculpturs. The complex also includes Sun Temple, Amba Mata Temple,  Parsvanath and Neminath temples. Overnight stay at hotel.",

            },
            {
                "id": 8,
                "title": "Day 08 - Ranakpur / Kumbhalgarh / Udaipur (140 km)",
                "Description": "After breakfast drive to the City of Lakes - Udaipur, enroute visits Kumbhalgarh Fort – One of the few impregnated forts in Rajasthan, with 2nd longest wall after china around Kumbhalgarh, the Muchchal Mahavir temple is wisely popular. This fort stands on a rocky hill commanding an excellent view of the wild and rugged scenery of the Aravallis and the sandy desert of Marwar. Reach Udaipur in afternoon and check in hotel. Later, see the Sahelion ki Bari where Maharana Sangram Singh build this garden complex with fountains and lakes for his beautiful daughter. After that move around the narrow streets of Udaipur Rest of the time free for your leisure.",

            },
            {
                "id": 9,
                "title": "Day 09 - Udaipur",
                "Description": "After breakfast move out for the city tour of Udaipur visit  the City Palace, a grand palace with beautiful architecture, the Jagdish Temple, dedicated to Lord Vishnu, and the folk art museum with a colorful collection of puppets, musical instruments and paintings.In the evening you can wander through the city or take an  boat ride on Lake Pichola and see the fabulous lake palaces of Udaipur, the Jag Mandir Palace and the Jag Niwas Palace now known as the Taj Lake Palace, where you can enjoy your dinner in a regal ambience. Return to your hotel to stay overnight.",

            },
            {
                "id": 10,
                "title": "Day 10 - Udaipur / Pushkar (275 km)",
                "Description": "Leave Udaipur and head towards Pushkar on the way visit the Eklingji and Nagda Jain Temple. Also visit the Saas and Bahu Temple dedicated to Lord Shiva and Vishnu. Afternoon reach the Holy City -  Pushkar, the place where Lord Brahma (Creator of the Hindu Trinity) and Savitri (His Wife) perform “Yagnas”. Here we visit Brahama Temple and Lake Pushkar, where devotees take holy bath in lake water. The legand says that the lake sprout on the place where the lotus petals fell on earth from the hands of Lord Brahma. Pushkar is popular for the Pushkar Fair or Camel Fair that held every year in the month of November. In evening enjoy the walk along the colourful and bustling local market.",

            },
            {
                "id": 11,
                "title": "Day 11 - Pushkar / Jaipur (150 kms)",
                "Description": "On the way to Jaipur in Ajmer visit Nasiya Golden Jain Temple, “Dargah Sharif” and Adhai Din Ka Jhopra. After that welcome to the capital city of Rajasthan which is also known as “Pink City”. This afternoon, set off to see the City Palace --still the home of the former royalty H.H Bhawani Singh Ji of Jaipur housing an extensive collection of art, carpets and old weapons. Jantar Mantar Observatory - situated in the heart of the city where time has been accurately measured since the 17th century the largest and best preserved stone observatory,on the way back visit Birla Temple. Evening at leisure.",

            },
            {
                "id": 12,
                "title": "Day 12 - Jaipur",
                "Description": "A full day sightseeing stopping to view the dramatic hilltop fortress at Amber - ascend the imposing fort on a painted elephant. We wander among glittering inlays and the Hall of Mirrors ‘Sheesh Mahal’. Within the palace are Diwan-e-Aam the Hall of Public Audience, Diwan-e-Khas the Hall of Private Audience and the Sukh Niws where a cool breeze blows, the Jai Mandir or the Temple of Victory. Enroute visit Jal Mahal the“Water Palace” built in centre of Maota Lake which was a resting place of the kings. Hawa Mahal -- The Palace of Winds, the facade of which is all pink windows and filigree screens. After Lunch visit “Monkey Temple”, Galta Sun Temple -- beautiful place sorrounded by mountains. Enjoy a Bollywood movie at “Rajmandir” the Second best Cinema in Asia. In the evening then enjoy dinner with folk dances and music by typical Rajasthani women in an Indian Restaurant.",

            },
            {
                "id": 13,
                "title": "Day 13 - Jaipur / Fatehpur Sikri / Agra (232 Km)",
                "Description": "After a morning at leisure we drive to the city of Taj, Agra, stopping enroute at ornithological reserve at Bharatpur – Keoladeo National Park. Post lunch tour of the Phantom city Fatehpur Sikri built by Akbar in the late 16th century, it was abandoned after only 14 years due to shortage of water. The entire city of Red sand stone (sand stone palaces and temples) is virtually intact and a site worth seeing is 54 m high main entrance. Jami Mosque, Buland Darwaza, Tomb of Shaikh Salim Chishti, Jodha Bai and  Birbal's house are the other attractions. On reaching Agra, visit the imposing Agra Fort, built of red sandstone and marble is a hilltop citadel with fabulous courtyards, fountains and gates, where Shahjehan imprisoned by his own son Aurangzeb. Thereafter, evening is free to explore this vibrant colourful city and its local markets.",

            },
            {
                "id": 14,
                "title": "Day 14 - Agra / Gwalior / Orchha (205 Km)",
                "Description": "Early morning visit incomparable Taj Mahal which is among the seven wonders in the world. Taj Mahal, erected by Shah Jahan in memory of his beloved wife, Queen Mumtaz Mahal. Begun in 1631, 20000 workmen took 22 years to complete a dream in white marble which lies in perfect proportion to the elegant garden of fountains on the bank of Yamuna. Then on the way to Orchha, precede on a Gwalior city tour visiting the 9th century Teli ka Mandir, the 11th century Saas Bahu ka Mandir and the Jiwaji Rao Scindia museum. Continue the tour visiting the Tomb of Tansen - Akbar's foremost court musician followed by the memorials of the earliest freedom fighters Tatya Tope, Datia Palace. Datia and Orchha are two twin cities from the medieval times. In these villages time has stopped.  Orchha is a medieval city on the bank of river Betwa founded by Bundela rulers known for its palaces and temples of the 16th and 17th century. In Orchha, see the beautiful Palace Complex –  Jahangiri Mahal, Raj Mahal, Raj Parveen Mahal known for  the intricacies of the mural paintings. In evening arrive to Orchha check into hotel.  ",

            },
            {
                "id": 15,
                "title": "Day 15 - Orchha / Khajuraho (182 Km)",
                "Description": "After breakfast, visit the Laxminarayana Temple, Chaturbhuj Temple, Phool Bagh and finally the newly constructed Sun Temple. Post lunch continue towards Khajuraho. Khajuraho temple has achieved fame for the sensual appeal of its erotic sculptures built during the 9th to the 11th centuries by the powerful dynasty of Chandela rajputs. Visit the largest and grandest temple of Khajuraho is the immortal Kandariya Mahadeva, other Eastern and Western groups of temples are Visvanatha, Parsvanatha, Vaidyanatha, Jagadambi, Chitragupta. Return to your hotel to stay overnight. In the evening, you can take a dinner in a restaurant having typical Indian Dance shows in Khajuraho.  ",

            },
            {
                "id": 16,
                "title": "Day 16 - Khajuraho / Varanasi (By Flight)",
                "Description": "After the leisure breakfast in the morning, you will be transferred to the airport to take the flight to Varanasi. On Arrival received by representative and transfer to the hotel. The rest of the day is at leisure. ",

            },
            {
                "id": 17,
                "title": "Day 17 - Varanasi",
                "Description": "In the early morning we take you for a Boat cruise at the river Ganges to observe the way of life of pilgrims by the Ghats. The boat ride starts from Dasashwamedh and ends at Manikarnika Ghat  also you visit Dasashwamedh Ghat, Assi Ghat,Harish Chandra Ghat, Tulsi Ghat and Manikarnika Ghat. After that visit the world famous Banaras Hindu University. Later visit Sarnath Temple, where Lord Buddha preached his first sermon. Sarnath's temples and Archaeological Museum are full of Buddhist art treasures. The 20 meter high Ashoka Pillar, set up by the Emperor Ashoka in 258 B.C Overnight stay at the hotel.",

            },
            {
                "id": 18,
                "title": "Day 18 - Varanasi / Mumbai (By Flight)",
                "Description": " In the Morning after breakfast you can move to visit the Ghats. Later you will be transferd to airport to take your flight to Mumbai. On arrival transfer to Hotel. Rest of the day free for your leisure.",

            },
            {
                "id": 19,
                "title": "Day 19 - Mumbai",
                "Description": "After Breakfast take full day tour of Mumbai which includes, Kamla Nehru Park and Hanging Gardens situated on the slopes of Malabar Hill, offering a panoramic view of Marine drive & Chowpatty Beach, Prince of Wales Museum, Mani bhawan, Dhobi Ghat, Gateway of India and drive through the Crawford market, Marine drive & Flora Fountain. Overnight stay in Hotel.",

            },
            {
                "id": 20,
                "title": "Day 20 - Mumbai / Cochin (By Flight)",
                "Description": "Early morning you will be transferred to airport to board the flight for Cochin. On arrival at the airport in Cochin, you will be met and transferred to the hotel at Cochin. Later we visit Cochin, Kerala's commercial centre; it has a fine natural harbour. Cochin (Kochi) gateway for Kerala is famous for Mattanchery Palace, St. Francis Church, Wellington Island, Bolghatty Island, Chinese Fishing nets and Jewish Synagogue. Cruising on the back-waters provides glimpses of the rural life, fine view of the harbour and three islands. Evening witness Kathakali Dance Show in a theatre. Overnight at the hotel.",

            },
            {
                "id": 21,
                "title": "Day 21 - Cochin / Kumarakom (90 Km)",
                "Description": "Morning after breakfast sightseeing of Cochin which includes Pareekshith Thampuran Museum and Museum of Kerala History. Afternoon proceed to Kumarakom, on arrival check-in to the resort on the bank of Backwater. Overnight stay at the resort.",

            },
            {
                "id": 22,
                "title": "Day 22 - Kumarakom / Allepey (Houseboat)",
                "Description": "Morning after breakfasts proceed to Alleppey. Check-in to the waiting houseboat. Enroute you can see many romantic beauty of the nature which will remind you that you are in the God's Own Country. You can view coconut palm fringed canals and local people going for a fresh catch of the day & Tapping of toddy (Local beverages) from palm trees. Rest of the day at leisure in Kumarakom, Kerala. Overnight stay at the houseboat.",

            },
            {
                "id": 23,
                "title": "Day 23 - Allepey / Kovalam (180 Km)",
                "Description": "Morning after breakfast proceeds to Kovalam. Arrive Kovalam on arrival check-in to the hotel. Rest of the day at leisure at Kovalam Beach. Over night stay at Kovalam.",

            },
            {
                "id": 24,
                "title": "Day 24 - Kovalam",
                "Description": "Morning after breakfast sight seeing of Thiruvananthapuram covering Museum Complex, Padmanabha Swamy Temple, Science & Technology Museum, Veli Tourist village, Aakulam boat club, Shangmugham Beach. Over night stay at Kovalam.",

            },
            {
                "id": 25,
                "title": "Day 25 - Kovalam / Trivandrum (16 km) / Delhi / Mumbai (By Flight)",
                "Description": "Morning after breakfast drive to Trivandrum airport to board the flight to Delhi or Mumbai. Upon arrival assistance and transferred to International airport to board your International flight to your home country.",

            },
        ],
        "popular_tour": false,
        "image": "bg-bestofIndia",
        "related_tours": [16, 17, 18, 19],
    },

]