import React from "react";
import styles from "./styles.module.scss";

function InputSelectField({ label, name, option, isBlur, onChange }) {
    return (
        <div>
            <h4 className={`${isBlur ? styles.label : styles.blackLabel}`}>
                {label}
            </h4>
            <select
                name={name}
                id={name}
                className={`${isBlur ? styles.selectField : styles.nonBlur}`}
                onChange={onChange}
            >
                <option value="" selected>
                    Select
                </option>
                {option.map((item, index) => (
                    <option value={item} key={index}>
                        {item}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default InputSelectField;
