import React from "react";
import styles from "./styles.module.scss";

function TopBanner({ label, img, imgAlt, showBanner }) {
    return (
        <div className="mt-32">
            <h1 className="text-center text text-5xl font-bold text-secondaryColor md:text-5xl sm:text-3xl 2xs:text-3xl">
                {label}
            </h1>
            {showBanner && (
                <div className="mt-5">
                    <img src={img} alt={imgAlt} className={styles.banner} />
                </div>
            )}
        </div>
    );
}

export default TopBanner;
