import React from "react";
import OwlCarousel from "react-owl-carousel";
import { useHistory } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./styles.module.scss";
import Button from "../../utilitiesComponents/Button/Button";
import "./index.scss";

function CardsCarousel({ cardsList }) {
    const history = useHistory();

    const options = {
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        nav: true,
        responsive: {
            0: {
                items: 1,
            },
            900: {
                items: 2,
            },
            1200: {
                items: 3,
            },
        },
    };
    return (
        <div className="mt-16">
            <OwlCarousel className="owl-theme relative MySlider" {...options}>
                {" "}
                {cardsList.map((item, index) => (
                    <div className={`item ${styles.owlSlider}`} key={index}>
                        <div style={{ height: "220px" }}>
                            <div
                                className={`${styles.tourBanner} ${item.image}`}
                            >
                                <p className="invisible">tour image</p>
                            </div>
                        </div>
                        <div className="px-8 py-5">
                            <p className="text-xl font-semibold text-secondaryColor">
                                {item.titleName}
                            </p>
                            <div className="flex flex-wrap justify-start mt-3">
                                <p className="text-sm text-secondaryColor font-normal">{item.Destinations}</p>
                            </div>
                            <p className="text-secondaryColor font-semibold mt-3">
                                {" "}
                                <span>Duration :</span> {item.Days} Days/ {item.Nights} Nights
                            </p>
                            <div className="flex justify-center items-center mt-5">
                                {" "}
                                <Button
                                    title="View Tour"
                                    onClick={() => history.push(`/package-details/${item.id}`)}
                                    style={{
                                        border: "1px solid var(--primaryColor)",
                                        color: "var(--primaryColor)",
                                        borderRadius: "10px",
                                        width: "160px",
                                        height: "40px",
                                        fontSize: "14px",
                                        transition: "all 0.3s ease",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
        </div>
    );
}

export default CardsCarousel;
