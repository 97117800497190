import bundig from "../Assets/Image/Festivals/bundig.jpg";
import camel from "../Assets/Image/Festivals/camel.jpg";
import elephant from "../Assets/Image/Festivals/elephant.jpg";
import marwar from "../Assets/Image/Festivals/marwar.jpg";
import mewar from "../Assets/Image/mevar.png";
import bundi2 from "../Assets/Image/Festivals/marwar.jpg";
import bundi3 from "../Assets/Image/Festivals/bundi3.jpg";
export const fairAndFestivalsList = [
    {
        topPlaces: [
            {
                id: 1,
                title: {
                    "en": "Bundi Utsav",
                    "es": "bandi utsav",
                    "fr": "Bandi Utsav",
                    "it": "Bandi Utsav",
                    "de": "Bandi Utsav"
                },
                img: bundig,
            },
            {
                id: 2,
                "title": {
                    "en": "Camel Festival",
                    "es": "fiesta del camello",
                    "fr": "Fête du chameau",
                    "it": "Festa dei cammelli",
                    "de": "Kamelfest"
                },
                img: camel,
            },
        ],
        bottomPlaces: [
            {
                id: 3,
                title: {
                    "en": "Desert Festival",
                    "es": "Festival del desierto",
                    "fr": "Fête du désert",
                    "it": "Festa del deserto",
                    "de": "Wüstenfest"
                },
                img: marwar,
            },
            {
                id: 4,
                title: {
                    "en": "Elephant Festival",
                    "es": "fiesta del elefante",
                    "fr": "Fête des éléphants",
                    "it": "Festa degli elefanti",
                    "de": "Elefantenfest"
                },
                img: elephant,
            },
            {
                id: 5,
                title: {
                    "en": "Ganguar Festival",
                    "es": "Festival de Ganguar",
                    "fr": "Fête du Gange",
                    "it": "Festa del Gange",
                    "de": "Ganguar-Fest"
                },
                img: mewar,
            },
        ],
    },
];

export const fairAndFestivalsDetailsList = [
    {
        id: 1,
        title: {
            "en": "Bundi Utsav",
            "es": "bandi utsav",
            "fr": "Bandi Utsav",
            "it": "Bandi Utsav",
            "de": "Bandi Utsav"
        },
        banner: bundig,
        label1: {
            "en": "Best time to Visit :",
            "es": "Mejor tiempo para visitar :",
            "fr": "Meilleur moment pour visiter :",
            "it": "Il periodo migliore per visitare :",
            "de": "Beste Zeit für einen Besuch :"
        },
        label2: {
            "en": "Place :",
            "es": "Lugar :",
            "fr": "Place :",
            "it": "Posto :",
            "de": "Ort :"
        },
        label3: {
            "en": "Duration :",
            "es": "Duración :",
            "fr": "Durée :",
            "it": "Durata :",
            "de": "Dauer :"
        },
        time: {
            place: "Bundi (Rajasthan)",
            duration: {
                "en": "3 Days",
                "es": "3 Días",
                "fr": "3 Journées",
                "it": "3 Giorni",
                "de": "3 Tage"
            },
            time: "November / December",
        },
        description: {
            "en": ["Bundi Utsav a pageant of resplendent heritage of the esteemed Hadoti has made the place even more famous . An extraordinary effort to present the culture , folklore , traditions and craft of the egion and believe in the treasure that lies ahead to explore and  savour. The intresting events includes a colorful Shobha Yatra , Arts & Crafts Fair, Ethnic Sports, Cultural exhibition , Deep daan.An intresting part is the Turban competition, Bridal Attire , Musical Band Competition Followed by  Folk  and Classical Music & Dance , sparkling fireworks display etc. ",
                "The small township of nearby Keshorai Patan wears the look of  a  little 'Pushkar' on the night of the full moon in the month of Kartika. Early in the morning, the banks of the river Chambal witness a colourful scene of men and women, clad in fantastic costumes mirrored in the crystal clear waters of the river. Women float lighted diyas (lamps prepared by them from flour dough), into the river and seek boons with prayers. The temple and the scenic beauty arrest the attention of visitors and speak of its grandeur and glory in silence."],
            "es": ["Bundi Utsav, un desfile de herencia resplandeciente del estimado Hadoti, ha hecho que el lugar sea aún más famoso. Un esfuerzo extraordinario para presentar la cultura, el folklore, las tradiciones y la artesanía de la región y creer en el tesoro que está por explorar y saborear. Los eventos interesantes incluyen un colorido Shobha Yatra, feria de artes y artesanías, deportes étnicos, exhibición cultural, Deep daan. Una parte interesante es la competencia de turbantes, vestimenta nupcial, competencia de banda musical seguida de música y danza folclórica y clásica, exhibición de fuegos artificiales brillantes, etc. .",
                "El pequeño municipio cercano a Keshorai Patan luce el aspecto de un pequeño 'Pushkar' en la noche de luna llena del mes de Kartika. Temprano en la mañana, las orillas del río Chambal son testigos de una colorida escena de hombres y mujeres, ataviados con fantásticos trajes reflejados en las aguas cristalinas del río. Las mujeres flotan diyas encendidas (lámparas preparadas por ellas con masa de harina) en el río y buscan bendiciones con oraciones. El templo y la belleza escénica captan la atención de los visitantes y hablan de su grandeza y gloria en silencio."],
            "fr": ["Bundi Utsav, une reconstitution historique du patrimoine resplendissant de l'estimé Hadoti, a rendu l'endroit encore plus célèbre. Un effort extraordinaire pour présenter la culture, le folklore, les traditions et l'artisanat de la région et croire au trésor qui nous attend à explorer et à savourer. Les événements intéressants comprennent un Shobha Yatra coloré, une foire des arts et de l'artisanat, des sports ethniques, une exposition culturelle, Deep daan. Une partie intéressante est le concours de turbans, la tenue de mariée, le concours de groupes musicaux suivi de la musique et de la danse folkloriques et classiques, un feu d'artifice étincelant, etc. .",
                "Le petit canton de Keshorai Patan, à proximité, ressemble à un petit « Pushkar » la nuit de la pleine lune du mois de Kartika. Tôt le matin, les rives de la rivière Chambal sont témoins d'une scène colorée d'hommes et de femmes vêtus de costumes fantastiques qui se reflètent dans les eaux cristallines de la rivière. Les femmes font flotter des diyas allumées (lampes préparées par elles à partir de pâte de farine) dans la rivière et recherchent des avantages avec des prières. Le temple et la beauté des paysages attirent l'attention des visiteurs et parlent de sa grandeur et de sa gloire en silence."],
            "it": ["Bundi Utsav, una rievocazione storica della splendente eredità degli stimati Hadoti, ha reso il luogo ancora più famoso. Uno sforzo straordinario per presentare la cultura, il folklore, le tradizioni e l'artigianato dell'area e credere nel tesoro che ci attende da esplorare e assaporare. Gli eventi interessanti includono un colorato Shobha Yatra, una fiera dell'arte e dell'artigianato, sport etnici, mostra culturale, Deep daan. Una parte interessante è la competizione del turbante, l'abbigliamento da sposa, la competizione della banda musicale seguita da musica e danza popolare e classica, fuochi d'artificio scintillanti ecc. .",
                "La piccola cittadina della vicina Keshorai Patan indossa l'aspetto di un piccolo 'Pushkar' nella notte di luna piena del mese di Kartika. Al mattino presto, le rive del fiume Chambal sono testimoni di una scena colorata di uomini e donne, vestiti con fantastici costumi che si specchiano nelle acque cristalline del fiume. Le donne galleggiano illuminate diyas (lampade preparate da loro con pasta di farina), nel fiume e cercano doni con le preghiere. Il tempio e la bellezza paesaggistica catturano l'attenzione dei visitatori e parlano in silenzio della sua grandezza e gloria."],
            "de": ["Bundi Utsav, ein Festzug des prächtigen Erbes des geschätzten Hadoti, hat den Ort noch berühmter gemacht. Eine außergewöhnliche Anstrengung, die Kultur, Folklore, Traditionen und das Handwerk der Region zu präsentieren und an den Schatz zu glauben, der vor uns liegt, um ihn zu entdecken und zu genießen. Zu den interessanten Veranstaltungen gehören ein farbenfrohes Shobha Yatra, eine Kunst- und Handwerksmesse, ethnische Sportarten, eine Kulturausstellung und Deep Daan. Ein interessanter Teil ist der Turban-Wettbewerb, die Brautkleidung, der Musikband-Wettbewerb, gefolgt von Volks- und klassischer Musik und Tanz, einem funkelnden Feuerwerk usw .",
                "Die kleine Gemeinde Keshorai Patan in der Nähe trägt in der Nacht des Vollmonds im Monat Kartika das Aussehen eines kleinen „Pushkar“. Am frühen Morgen werden die Ufer des Flusses Chambal Zeuge einer farbenfrohen Szene von Männern und Frauen, die in fantastische Kostüme gekleidet sind und sich im kristallklaren Wasser des Flusses spiegeln. Frauen lassen beleuchtete Diyas (von ihnen aus Mehlteig hergestellte Lampen) in den Fluss treiben und bitten um Segen mit Gebeten. Der Tempel und die landschaftliche Schönheit fesseln die Aufmerksamkeit der Besucher und sprechen schweigend von seiner Pracht und Herrlichkeit."]
        },
        about: {
            img: bundi2,
            labelAbout: {
                "en": "About",
                "es": "Sobre",
                "fr": "À propos de",
                "it": "Di",
                "de": "Um"
            },
            desctiption: {
                "en": ["Bundi is a beautiful town and have importance in the history for Rajasthan. Located  206 kms from Jaipur and 36 kms from Kota, ruled by the Had Chauhans - an offshoots of the famous Chauhan clan who ruled Delhi and Ajmer. In 1193 A.D. when Prithviraj Chauhan was defeated by Sultan Mohammed Ghauri, some Chauhan nobles sought shelter in Mewar and became allies to the Rana while other young warriors move towards the Chambal valley and overpowered the Meena and Bhil tribals - thus establishing their own kingdom of Hadoti. Later, two branches of Hadas formed two separates states of Kota and Bundi, on either side of the river Chambal.",
                    "Bundi is surrounded by the Aravalli hills on the three sides and is circumscribed by a massive wall with four gateways. Interesting monuments including impressive medieval forts, palaces, havelis, temples with beautiful stone idols and chattris with carved pillars, along with a picturesque lake in the heart of the town, add to its charm. Bundi is very famous for its intricate carvings and murals."],
                "es": ["Bundi es una ciudad hermosa y tiene importancia en la historia de Rajasthan. Ubicado a 206 km de Jaipur ya 36 km de Kota, gobernado por Had Chauhans, una rama del famoso clan Chauhan que gobernó Delhi y Ajmer. En 1193 d. C., cuando Prithviraj Chauhan fue derrotado por el sultán Mohammed Ghauri, algunos nobles de Chauhan buscaron refugio en Mewar y se convirtieron en aliados de Rana, mientras que otros jóvenes guerreros se dirigieron hacia el valle de Chambal y dominaron a las tribus Meena y Bhil, estableciendo así su propio reino de Hadoti. . Más tarde, dos ramas de Hadas formaron dos estados separados de Kota y Bundi, a ambos lados del río Chambal.",
                    "Bundi está rodeado por las colinas de Aravalli en los tres lados y está circunscrito por un muro macizo con cuatro puertas de entrada. Interesantes monumentos que incluyen impresionantes fortalezas medievales, palacios, havelis, templos con hermosos ídolos de piedra y chattris con pilares tallados, junto con un pintoresco lago en el corazón de la ciudad, se suman a su encanto. Bundi es muy famoso por sus intrincados tallados y murales."],
                "fr": ["Bundi est une belle ville et a une importance dans l'histoire du Rajasthan. Situé à 206 km de Jaipur et à 36 km de Kota, gouverné par les Had Chauhans - une ramification du célèbre clan Chauhan qui a gouverné Delhi et Ajmer. En 1193 après JC, lorsque Prithviraj Chauhan a été vaincu par le sultan Mohammed Ghauri, certains nobles Chauhan ont cherché refuge à Mewar et sont devenus des alliés des Rana tandis que d'autres jeunes guerriers se dirigent vers la vallée de Chambal et ont maîtrisé les tribus Meena et Bhil - établissant ainsi leur propre royaume de Hadoti. . Plus tard, deux branches d'Hadas ont formé deux états séparés de Kota et Bundi, de part et d'autre de la rivière Chambal.",
                    "Bundi est entouré par les collines Aravalli sur les trois côtés et est circonscrit par un mur massif avec quatre portes. Des monuments intéressants, notamment d'impressionnants forts médiévaux, des palais, des havelis, des temples avec de belles idoles en pierre et des chattris avec des piliers sculptés, ainsi qu'un lac pittoresque au cœur de la ville, ajoutent à son charme. Bundi est très célèbre pour ses sculptures et peintures murales complexes."],
                "it": ["Bundi è una bellissima città e ha un'importanza nella storia del Rajasthan. Situato a 206 km da Jaipur ea 36 km da Kota, governato dagli Had Chauhan - una propaggine del famoso clan Chauhan che governava Delhi e Ajmer. Nel 1193 d.C., quando Prithviraj Chauhan fu sconfitto dal sultano Mohammed Ghauri, alcuni nobili Chauhan cercarono rifugio a Mewar e divennero alleati dei Rana mentre altri giovani guerrieri si spostarono verso la valle di Chambal e sopraffarono i tribali Meena e Bhil, stabilendo così il proprio regno di Hadoti . Successivamente, due rami di Hadas formarono due stati separati di Kota e Bundi, su entrambi i lati del fiume Chambal.",
                    "Bundi è circondato dai colli Aravalli sui tre lati ed è circoscritto da un massiccio muro a quattro porte. Interessanti monumenti tra cui imponenti fortezze medievali, palazzi, haveli, templi con splendidi idoli di pietra e chattris con pilastri scolpiti, insieme a un pittoresco lago nel cuore della città, aggiungono fascino. Bundi è molto famosa per i suoi intricati intagli e murales."],
                "de": ["Bundi ist eine schöne Stadt und hat Bedeutung in der Geschichte von Rajasthan. Das Hotel liegt 206 km von Jaipur und 36 km von Kota entfernt und wird von den Had Chauhans regiert - einem Ableger des berühmten Chauhan-Clans, der Delhi und Ajmer regierte. Als Prithviraj Chauhan 1193 n. Chr. von Sultan Mohammed Ghauri besiegt wurde, suchten einige Chauhan-Adlige in Mewar Zuflucht und wurden Verbündete der Rana, während andere junge Krieger in Richtung Chambal-Tal zogen und die Stämme Meena und Bhil überwältigten – und so ihr eigenes Königreich Hadoti gründeten . Später bildeten zwei Zweige von Hadas zwei getrennte Staaten von Kota und Bundi auf beiden Seiten des Flusses Chambal.",
                    "Bundi ist auf drei Seiten von den Aravalli-Hügeln umgeben und wird von einer massiven Mauer mit vier Toren umschrieben. Interessante Denkmäler wie beeindruckende mittelalterliche Festungen, Paläste, Havelis, Tempel mit wunderschönen Steinidolen und Chattris mit geschnitzten Säulen sowie ein malerischer See im Herzen der Stadt tragen zu ihrem Charme bei. Bundi ist sehr berühmt für seine kunstvollen Schnitzereien und Wandmalereien."]
            },
        },
        attractions: {
            banner: bundi3,
            labelAttractions: {
                "en": "Attractions",
                "es": "Atracciones",
                "fr": "Attractions",
                "it": "Attrazioni",
                "de": "Sehenswürdigkeiten"
            },
            placesToVisit: [
                {
                    titlePTV1: {
                        "en": "ROCK PAINTINGS",
                        "es": "PINTURAS RUPESTRES",
                        "fr": "PEINTURES ROCHES",
                        "it": "PITTURE RUPESTRI",
                        "de": "FELSMALEREIEN"
                    },
                    descriptionPTV1: {
                        "en": "Few kms from Bundi, the caves depict paintings belonging to the mesolithic period. They show hunting scenes, daily life of the period, human figures, dancing postures along with bison, deer and tiger. The painting at Gararda has been estimated to be 5000 years old bearing ample proof of the fact that Hadoti region sustained early man.",
                        "es": "A pocos kilómetros de Bundi, las cuevas muestran pinturas pertenecientes al período mesolítico. Muestran escenas de caza, vida cotidiana de la época, figuras humanas, posturas danzantes junto a bisontes, venados y tigres. Se ha estimado que la pintura de Gararda tiene una antigüedad de 5000 años, lo que demuestra ampliamente el hecho de que la región de Hadoti sustentó al hombre primitivo.",
                        "fr": "A quelques kilomètres de Bundi, les grottes présentent des peintures appartenant à la période mésolithique. Ils montrent des scènes de chasse, la vie quotidienne de l'époque, des figures humaines, des postures de danse ainsi que des bisons, des cerfs et des tigres. La peinture de Gararda a été estimée à 5000 ans, ce qui prouve amplement que la région de Hadoti a soutenu l'homme primitif.",
                        "it": "A pochi km da Bundi, le grotte raffigurano dipinti appartenenti al periodo mesolitico. Mostrano scene di caccia, vita quotidiana dell'epoca, figure umane, posture danzanti insieme a bisonti, cervi e tigri. Si stima che il dipinto di Gararda abbia 5000 anni, a dimostrazione del fatto che la regione di Hadoti ha sostenuto l'uomo primitivo.",
                        "de": "Nur wenige Kilometer von Bundi entfernt zeigen die Höhlen Malereien aus der Mittelsteinzeit. Sie zeigen Jagdszenen, das tägliche Leben der Zeit, menschliche Figuren, Tanzhaltungen sowie Bisons, Hirsche und Tiger. Das Gemälde in Gararda wird auf 5000 Jahre geschätzt und ist ein deutlicher Beweis dafür, dass die Region Hadoti den frühen Menschen unterstützt hat."

                    },
                },
                {
                    titlePTV1: {
                        "en": "BHIMLAT WATERFALL",
                        "es": "CASCADA BHIMLAT",
                        "fr": "CHUTE DE BHIMLAT",
                        "it": "CASCATA BHIMLAT",
                        "de": "BHIMLAT-WASSERFALL"
                    },
                    descriptionPTV1: {
                        "en": "30 kms from Bundi, spread over an area of 6kms and cascading 60 metres into a deep pool, Bhimlat Waterfall is a place of grand natural scenic beauty.",
                        "es": "A 30 km de Bundi, repartidas en un área de 6 km y con una cascada de 60 metros en una piscina profunda, la cascada de Bhimlat es un lugar de gran belleza escénica natural.",
                        "fr": "À 30 km de Bundi, répartie sur une superficie de 6 km et tombant à 60 mètres dans une piscine profonde, la cascade de Bhimlat est un lieu d'une grande beauté naturelle.",
                        "it": "A 30 km da Bundi, distribuita su un'area di 6 km e con una cascata di 60 metri in una piscina profonda, la cascata di Bhimlat è un luogo di grande bellezza paesaggistica naturale.",
                        "de": "30 km von Bundi entfernt, über eine Fläche von 6 km verteilt und 60 Meter in einen tiefen Pool stürzend, ist der Bhimlat-Wasserfall ein Ort von großartiger natürlicher landschaftlicher Schönheit."

                    },
                },
            ],
            places: [
                {
                    title: {
                        "en": "TARA GARH FORT - 14th CENTURY",
                        "es": "FUERTE DE TARA GARH - SIGLO XIV",
                        "fr": "FORT DE TARA GARH - 14e SIECLE",
                        "it": "FORTE DI TARA GARH - XIV SECOLO",
                        "de": "TARA GARH FORT - 14. JAHRHUNDERT"
                    },
                    description: {
                        "en": "Taragarh means the 'Star Fort', constructed in1354 AD upon a steep hillside, This is rather a ramshackle fort, with its overgrown vegetation. The view over the town and surrounding countryside from the top are magical, especially at sun set. Inside the ramparts are huge reservoirs carved out of solid rock, built to cater to the water requirements of the fort and the Bhim Burj, the largest of the battle-fields, on which was once mounted a particularly large cannon called Garbh Gunjam, or 'Thunder from the Womb'.",
                        "es": "Taragarh significa el 'Fuerte de la estrella', construido en 1354 d. C. sobre una ladera empinada. Este es más bien un fuerte destartalado, con su vegetación cubierta de maleza. Las vistas sobre la ciudad y el campo circundante desde la cima son mágicas, especialmente al atardecer. Dentro de las murallas hay enormes depósitos excavados en roca sólida, construidos para satisfacer las necesidades de agua del fuerte y el Bhim Burj, el campo de batalla más grande, en el que una vez estuvo montado un cañón particularmente grande llamado Garbh Gunjam, o ' Trueno del útero'.",
                        "fr": "Taragarh signifie le « Star Fort », construit en 1354 après JC sur une colline escarpée, c'est plutôt un fort délabré, avec sa végétation envahie. La vue sur la ville et la campagne environnante depuis le sommet est magique, surtout au coucher du soleil. À l'intérieur des remparts se trouvent d'immenses réservoirs creusés dans la roche solide, construits pour répondre aux besoins en eau du fort et du Bhim Burj, le plus grand des champs de bataille, sur lequel était autrefois monté un canon particulièrement gros appelé Garbh Gunjam, ou ' Thunder from the Womb'.",
                        "it": "Taragarh significa 'Star Fort', costruito nel 1354 d.C. su una ripida collina. Questo è piuttosto un forte sgangherato, con la sua vegetazione ricoperta di vegetazione. La vista sulla città e sulla campagna circostante dall'alto è magica, soprattutto al tramonto. All'interno dei bastioni si trovano enormi serbatoi scavati nella roccia solida, costruiti per soddisfare il fabbisogno idrico del forte e del Bhim Burj, il più grande dei campi di battaglia, su cui un tempo era montato un cannone particolarmente grande chiamato Garbh Gunjam, o ' Tuono dal grembo'.",
                        "de": "Taragarh bedeutet „Star Fort“, das 1354 n. Chr. auf einem steilen Hang erbaut wurde. Dies ist eher ein baufälliges Fort mit seiner überwucherten Vegetation. Die Aussicht über die Stadt und die umliegende Landschaft von oben ist magisch, besonders bei Sonnenuntergang. Innerhalb der Wälle befinden sich riesige, aus massivem Fels gehauene Stauseen, die gebaut wurden, um den Wasserbedarf der Festung und des Bhim Burj, des größten der Schlachtfelder, zu decken, auf dem einst eine besonders große Kanone namens Garbh Gunjam oder ' Donner aus dem Mutterleib“."
                    },
                },
                {
                    title: {
                        "en": "KESHAR BAGH 16TH CENTURY",
                        "es": "Keshar Bagh Siglo XVI",
                        "fr": "Keshar Bagh XVIe siècle",
                        "it": "Keshar Bagh del XVI secolo",
                        "de": "Keshar Bagh aus dem 16. Jahrhundert"
                    },

                    description: {
                        "en": "Kesharbagh is the resting place of Bundi's kings and queens, the cenotaphs are beautifully crafted from fine yellow sandstone and marble.",
                        "es": "Kesharbagh es el lugar de descanso de los reyes y reinas de Bundi, los cenotafios están bellamente elaborados con fina piedra arenisca amarilla y mármol.",
                        "fr": "Kesharbagh est le lieu de repos des rois et des reines de Bundi, les cénotaphes sont magnifiquement fabriqués à partir de grès jaune fin et de marbre.",
                        "it": "Kesharbagh è il luogo di riposo dei re e delle regine di Bundi, i cenotafi sono splendidamente realizzati con fine arenaria gialla e marmo.",
                        "de": "Kesharbagh ist die Ruhestätte der Könige und Königinnen von Bundi, die Kenotaphe sind wunderschön aus feinem gelbem Sandstein und Marmor gefertigt."
                    },
                },
                {
                    title: {
                        "en": "EIGHTY FOUR PILLARED CENOTAPH",
                        "es": "CENOTAFIO OCHENTA Y CUATRO PILARES",
                        "fr": "CÉNOTAPHE À QUATRE-VINGT-QUATRE PILIERS",
                        "it": "OTTANTAQUATTRO CENOTAFI A PILASTRI",
                        "de": "VIERUNDACHTIG SÄULEN KERNTAPH"
                    },
                    description: {
                        "en": "An amazingly magnificent with 84 pillars in as single cenotaph  along with Shiva lingam it was erected by Rao Anirudha singh.",
                        "es": "Un increíblemente magnífico con 84 pilares en un solo cenotafio junto con Shiva lingam fue erigido por Rao Anirudha singh.",
                        "fr": "Un étonnamment magnifique avec 84 piliers en un seul cénotaphe avec Shiva lingam, il a été érigé par Rao Anirudha singh.",
                        "it": "Un sorprendentemente magnifico con 84 pilastri in un unico cenotafio insieme a Shiva lingam è stato eretto da Rao Anirudha singh.",
                        "de": "Ein erstaunlich prächtiger Kenotaph mit 84 Säulen in einem einzigen Kenotaph, zusammen mit Shiva Lingam, wurde von Rao Anirudha Singh errichtet."
                    },
                },
                {
                    title: {
                        "en": "NAVAL SAGAR LAKE - 18TH CENTURY",
                        "es": "LAGO DE SAGAR NAVAL - SIGLO XVIII",
                        "fr": "LAC NAVAL SAGAR - 18E SIÈCLE",
                        "it": "LAGO NAVALE DI SAGAR - XVIII SECOLO",
                        "de": "MARINE-SAGAR-SEE - 18. JAHRHUNDERT"
                    },
                    description: {
                        "en": "This is a square shaped artificial lake, broken by islets and has a temple of Varuna submerged in a corner. Reflection of the entire city and its palaces can be seen in the lake, making it a fascinating sight at sunset.",
                        "es": "Este es un lago artificial de forma cuadrada, roto por islotes y tiene un templo de Varuna sumergido en una esquina. En el lago se puede ver el reflejo de toda la ciudad y sus palacios, lo que lo convierte en una vista fascinante al atardecer.",
                        "fr": "Il s'agit d'un lac artificiel de forme carrée, interrompu par des îlots et doté d'un temple de Varuna submergé dans un coin. Le reflet de toute la ville et de ses palais peut être vu dans le lac, ce qui en fait un spectacle fascinant au coucher du soleil.",
                        "it": "Si tratta di un lago artificiale di forma quadrata, interrotto da isolotti e ha un tempio di Varuna sommerso in un angolo. Nel lago si vede il riflesso dell'intera città e dei suoi palazzi, che lo rendono uno spettacolo affascinante al tramonto.",
                        "de": "Dies ist ein quadratischer künstlicher See, der von kleinen Inseln unterbrochen wird und in dessen Ecke ein Varuna-Tempel untergetaucht ist. Die Spiegelung der gesamten Stadt und ihrer Paläste ist im See zu sehen, was ihn bei Sonnenuntergang zu einem faszinierenden Anblick macht."
                    },
                },
                {
                    title: {
                        "en": "RANI JI KI BAORI - 17TH CENTURY",
                        "es": "Grava de Rani ji - siglo XVII",
                        "fr": "Gravier de Rani ji - 17ème siècle",
                        "it": "La ghiaia di Rani ji - 17° secolo",
                        "de": "Kies von Rani ji - 17. Jahrhundert"
                    },
                    description: {
                        "en": "Rani Ji Ki Baori, 46 mtr deep stepwell built in 1699 by mother-queen Nathavati Ji during her son Budh Singh's time it is adorned with finely sculpted pillars and arches. It is a multistoried structure with places of worship on each floor, served as water reservoirs when there was a scarcity of water. More than hundred of baoris exist in and around Bundi and are a marvel of craftsmanship and architecture.",
                        "es": "Rani Ji Ki Baori, pozo escalonado de 46 metros de profundidad construido en 1699 por la reina madre Nathavati Ji durante la época de su hijo Budh Singh, está adornado con pilares y arcos finamente esculpidos. Es una estructura de varios pisos con lugares de culto en cada piso, que servían como depósitos de agua cuando había escasez de agua. Existen más de cien baoris en Bundi y sus alrededores y son una maravilla de la artesanía y la arquitectura.",
                        "fr": "Rani Ji Ki Baori, puits à degrés de 46 mètres de profondeur construit en 1699 par la reine-mère Nathavati Ji à l'époque de son fils Budh Singh, il est orné de piliers et d'arches finement sculptés. C'est une structure à plusieurs étages avec des lieux de culte à chaque étage, servant de réservoirs d'eau en cas de pénurie d'eau. Plus d'une centaine de baoris existent dans et autour de Bundi et sont une merveille d'artisanat et d'architecture.",
                        "it": "Rani Ji Ki Baori, pozzo a gradini profondo 46 metri costruito nel 1699 dalla regina madre Nathavati Ji durante il periodo di suo figlio Budh Singh, è adornato con pilastri e archi finemente scolpiti. È una struttura a più piani con luoghi di culto su ogni piano, fungeva da serbatoi d'acqua quando c'era scarsità d'acqua. Esistono più di centinaia di baori dentro e intorno a Bundi e sono una meraviglia dell'artigianato e dell'architettura.",
                        "de": "Rani Ji Ki Baori, ein 46 m tiefer Stufenbrunnen, der 1699 von Mutter-Königin Nathavati Ji während der Zeit ihres Sohnes Budh Singh erbaut wurde, ist mit fein gemeißelten Säulen und Bögen geschmückt. Es ist ein mehrstöckiges Gebäude mit Gebetsstätten auf jeder Etage, die bei Wasserknappheit als Wasserspeicher dienten. Mehr als hundert Baoris existieren in und um Bundi und sind ein Wunderwerk der Handwerkskunst und Architektur."
                    },
                },
                {
                    title: {
                        "en": "CHOGAN GATE",
                        "es": "CHOGAN GATE",
                        "fr": "CHOGAN GATE",
                        "it": "CHOGAN GATE",
                        "de": "CHOGAN GATE"
                    },
                    description: {
                        "en": "Chogan gate is a main entry for Bundi city. Entring the Chougan gate you can wander through the narrow streets.",
                        "es": "La puerta de Chogan es una entrada principal a la ciudad de Bundi. Al entrar por la puerta de Chougan, puede pasear por las calles estrechas.",
                        "fr": "La porte Chogan est une entrée principale pour la ville de Bundi. En entrant par la porte Chougan, vous pourrez vous promener dans les rues étroites.",
                        "it": "Il cancello di Chogan è l'ingresso principale per la città di Bundi. Entrando dal cancello Chougan puoi passeggiare per le stradine.",
                        "de": "Das Chogan-Tor ist ein Haupteingang zur Stadt Bundi. Wenn Sie das Chougan-Tor betreten, können Sie durch die engen Gassen schlendern."
                    },
                },
                {
                    title: {
                        "en": "THE BUNDI PALACE",
                        "es": "El Palacio Bundy",
                        "fr": "Le Palais Bundy",
                        "it": "Il Palazzo Bundy",
                        "de": "Der Bundy-Palast"
                    },
                    description: {
                        "en": "It is situated on the hillside adjacent to the Taragarh Fort and is notable for its lavish traditional murals and frescoes. The Chitrashala (picture gallery) of the palace is open to the general public. The palace is entered through the Hathi Pole (Elephant Gate) into a small courtyard. You then come to the Ratan Daulat, the Diwan-e-aam (Hall of Public Audience), where there is a marble throne.Some of the famous and excellent Bundi murals are located on the upper floor.",
                        "es": "Está situado en la ladera adyacente al Fuerte de Taragarh y se destaca por sus lujosos murales y frescos tradicionales. La Chitrashala (galería de imágenes) del palacio está abierta al público en general. Se ingresa al palacio a través del Hathi Pole (Puerta del Elefante) hacia un pequeño patio. Luego llega al Ratan Daulat, el Diwan-e-aam (Salón de audiencia pública), donde hay un trono de mármol. Algunos de los famosos y excelentes murales de Bundi se encuentran en el piso superior.",
                        "fr": "Il est situé sur la colline adjacente au fort de Taragarh et se distingue par ses somptueuses peintures murales et fresques traditionnelles. Le Chitrashala (galerie de photos) du palais est ouvert au grand public. Le palais est entré par le pôle Hathi (porte de l'éléphant) dans une petite cour. Vous arrivez ensuite au Ratan Daulat, le Diwan-e-aam (salle d'audience publique), où se trouve un trône en marbre. Certaines des célèbres et excellentes peintures murales de Bundi sont situées à l'étage supérieur.",
                        "it": "Si trova sulla collina adiacente al Forte di Taragarh ed è noto per i suoi sontuosi murales e affreschi tradizionali. La Chitrashala (galleria) del palazzo è aperta al pubblico. Si accede al palazzo attraverso l'Hathi Pole (Porta degli elefanti) in un piccolo cortile. Si arriva quindi al Ratan Daulat, il Diwan-e-aam (Sala delle udienze pubbliche), dove si trova un trono di marmo.Al piano superiore si trovano alcuni dei famosi ed eccellenti murales di Bundi.",
                        "de": "Es liegt auf einem Hügel neben dem Fort Taragarh und zeichnet sich durch seine üppigen traditionellen Wandmalereien und Fresken aus. Die Chitrashala (Bildergalerie) des Palastes ist für die Öffentlichkeit zugänglich. Der Palast wird durch den Hathi Pole (Elefantentor) in einen kleinen Innenhof betreten. Sie kommen dann zum Ratan Daulat, dem Diwan-e-aam (Saal des öffentlichen Publikums), wo sich ein Marmorthron befindet. Einige der berühmten und hervorragenden Bundi-Wandmalereien befinden sich im Obergeschoss."
                    },
                },
                {
                    title: {
                        "en": "THE DABHAI KUND",
                        "es": "THE DABHAI KUND",
                        "fr": "THE DABHAI KUND",
                        "it": "THE DABHAI KUND",
                        "de": "THE DABHAI KUND"
                    },
                    description: {
                        "en": "THE DABHAI KUND is also known as the jail kund, the largest kund in Bundi. Though slightly overgrown, it is well worth a visit for the spectacular carvings on the numerous steps leading down to the water level.",
                        "es": "EL DABHAI KUND también se conoce como el kund de la cárcel, el kund más grande de Bundi. Aunque un poco cubierto de vegetación, vale la pena visitarlo por las espectaculares tallas en los numerosos escalones que conducen al nivel del agua.",
                        "fr": "LE DABHAI KUND est également connu sous le nom de prison kund, le plus grand kund de Bundi. Bien que légèrement envahi par la végétation, il vaut bien une visite pour les sculptures spectaculaires sur les nombreuses marches menant au niveau de l'eau.",
                        "it": "IL DABHAI KUND è anche conosciuto come il jail kund, il più grande kund di Bundi. Anche se leggermente ricoperta di vegetazione, merita una visita per le spettacolari incisioni sui numerosi gradini che scendono al livello dell'acqua.",
                        "de": "THE DABHAI KUND ist auch als Gefängnis-Kund bekannt, der größte Kund in Bundi. Obwohl leicht überwuchert, ist es wegen der spektakulären Schnitzereien auf den zahlreichen Stufen, die zum Wasserspiegel führen, einen Besuch wert."
                    },
                },
            ],
        },
    },
    {
        id: 2,
        "title": {
            "en": "Camel Festival",
            "es": "fiesta del camello",
            "fr": "Fête du chameau",
            "it": "Festa dei cammelli",
            "de": "Kamelfest"
        },
        banner: camel,
        label1: {
            "en": "Best time to Visit :",
            "es": "Mejor tiempo para visitar :",
            "fr": "Meilleur moment pour visiter :",
            "it": "Il periodo migliore per visitare :",
            "de": "Beste Zeit für einen Besuch :"
        },
        label2: {
            "en": "Place :",
            "es": "Lugar :",
            "fr": "Place :",
            "it": "Posto :",
            "de": "Ort :"
        },
        label3: {
            "en": "Duration :",
            "es": "Duración :",
            "fr": "Durée :",
            "it": "Durata :",
            "de": "Dauer :"
        },
        time: {
            place: "Bikaner (Rajasthan)",
            duration: {
                "en": "2 Days",
                "es": "2 Días",
                "fr": "2 Journées",
                "it": "2 Giorni",
                "de": "2 Tage"
            },
            time: "January",
        },
        description: {
            "en": ["Festival is a lively and colourful event which takes place in the month of January. The Camel Festival begins with a colourful procession of bedecked camels against the beautiful rugged backdrop of the Junagarh Fort. The festival progresses to the open sand-spreads of the grounds, followed by the best breed competition, the tug-of-war contest, camel races,camel dance, the bumpy, neck shaking camel rides and acrobatics, etc. ",
                "The Camel Pageant is held on the first day wherein the camel owners show off their Camels' decorations and jewellery.  A competition for best decorated camel, fur cutting design, camel milking and the best camel hair cut is organized. The camels display amazing footwork, dancing gracefully to the slightest direction of their trainers. Bridal, bridles, bejewelled necks, jingling anklets and long, lanky camel shadows on dusky sands cast a magical spell. tea and sweets prepared by camel milk are made available to the visitors interested in enjoying them. Hundreds of tourists and thousands of locals and dignitaries revel in this man-and-animal affair organised especially for the tourists. ",
                "On the second day, the fleetest camels of the region take part in the camel races. The competition is fierce as the best camels vie for the honors. Thousands of excited tourists and locals cheer the favorites.",
                "The evenings end with a traditional rendezvous with renowned artistes of Rajasthan and the local folk performers. The jubilant skirt-swirling dancers, the amazing fire-dance and the dazzling fireworks light up the fortified desert city of Bikaner.",],
            "es": ["El festival es un evento animado y colorido que tiene lugar en el mes de enero. El Festival del camello comienza con una colorida procesión de camellos engalanados contra el hermoso telón de fondo escarpado del fuerte de Junagarh. El festival avanza hacia las extensiones de arena abiertas de los terrenos. , seguida de la competencia de la mejor raza, el concurso de tira y afloja, carreras de camellos, baile de camellos, paseos en camello llenos de baches y sacudidas de cuello y acrobacias, etc.",
                "El desfile de camellos se lleva a cabo el primer día en el que los dueños de camellos muestran las decoraciones y joyas de sus camellos. Se organiza una competencia por el camello mejor decorado, el diseño de corte de piel, el ordeño de camellos y el mejor corte de pelo de camello. Los camellos muestran un juego de pies increíble. , bailando con gracia a la menor dirección de sus entrenadores. Nupciales, bridas, cuellos enjoyados, tobilleras tintineantes y sombras largas y larguiruchas de camellos sobre arenas oscuras lanzan un hechizo mágico. Té y dulces preparados con leche de camello están disponibles para los visitantes interesados ​​en disfrutar Cientos de turistas y miles de lugareños y dignatarios se deleitan con este asunto de hombre y animal organizado especialmente para los turistas ",
                "En el segundo día, los camellos más veloces de la región participan en las carreras de camellos. La competencia es feroz ya que los mejores camellos compiten por los honores. Miles de turistas y lugareños entusiasmados animan a los favoritos",
                "Las veladas terminan con una cita tradicional con artistas de renombre de Rajasthan y los artistas folclóricos locales. Los jubilosos bailarines que giran faldas, la asombrosa danza del fuego y los deslumbrantes fuegos artificiales iluminan la ciudad fortificada del desierto de Bikaner",

            ],
            "fr": ["Festival est un événement vivant et coloré qui se déroule au mois de janvier. Le festival du chameau commence par une procession colorée de chameaux ornés sur le magnifique fond accidenté du fort de Junagarh. Le festival progresse vers les étendues de sable ouvertes du terrain, suivi du concours de la meilleure race, du concours de tir à la corde, des courses de chameaux, de la danse des chameaux, des promenades et des acrobaties à dos de chameau, etc.",
                "Le Camel Pageant a lieu le premier jour où les propriétaires de chameaux montrent les décorations et les bijoux de leurs chameaux. Un concours pour le meilleur chameau décoré, la conception de la coupe de fourrure, la traite des chameaux et la meilleure coupe de poils de chameau est organisé. Les chameaux affichent un jeu de jambes incroyable, dansant gracieusement à la moindre direction de leurs entraîneurs. Les mariées, les brides, les cous ornés de bijoux, les bracelets de cheville qui tintent et les longues ombres de chameaux dégingandées sur le sable sombre jettent un sort magique. du thé et des douceurs préparés au lait de chamelle sont mis à la disposition des visiteurs intéressés à en profiter. Des centaines de touristes et des milliers d'habitants et de dignitaires se délectent de cette affaire d'hommes et d'animaux organisée spécialement pour les touristes.",
                "Le deuxième jour, les chameaux les plus rapides de la région participent aux courses de chameaux. La compétition est féroce alors que les meilleurs chameaux se disputent les honneurs. Des milliers de touristes et d'habitants enthousiastes encouragent les favoris.",
                "Les soirées se terminent par un rendez-vous traditionnel avec des artistes renommés du Rajasthan et des artistes folkloriques locaux. Les danseurs jubilatoires aux jupes tourbillonnantes, l'étonnante danse du feu et les feux d'artifice éblouissants illuminent la ville fortifiée du désert de Bikaner."
            ],
            "it": ["Il Festival è un evento vivace e colorato che si svolge nel mese di gennaio. Il Camel Festival inizia con una colorata processione di cammelli addobbati sullo sfondo bellissimo e aspro del forte di Junagarh. Il festival prosegue verso le distese di sabbia all'aperto dei terreni, seguite dalla competizione della migliore razza, la gara di tiro alla fune, le corse dei cammelli, la danza dei cammelli, le corse in cammello e le acrobazie sconnesse e agitate, ecc.",
                "Il primo giorno in cui i proprietari di cammelli sfoggiano decorazioni e gioielli dei loro cammelli si tiene il concorso dei cammelli. Viene organizzato un concorso per il miglior cammello decorato, il design del taglio della pelliccia, la mungitura del cammello e il miglior taglio dei capelli del cammello. I cammelli mostrano un incredibile gioco di gambe, ballando con grazia nella minima direzione dei loro addestratori. Spose, briglie, colli ingioiellati, cavigliere tintinnanti e lunghe e allampanate ombre di cammello sulle sabbie scure lanciano un incantesimo magico. tè e dolci preparati con latte di cammello sono messi a disposizione dei visitatori interessati a gustarli. Centinaia di turisti e migliaia di locali e dignitari si divertono in questo affare uomo e animale organizzato appositamente per i turisti.",
                "Il secondo giorno, i cammelli più veloci della regione partecipano alle corse dei cammelli. La competizione è agguerrita poiché i migliori cammelli si contendono il titolo. Migliaia di turisti entusiasti e gente del posto acclamano i favoriti.",
                "Le serate si concludono con un tradizionale appuntamento con rinomati artisti del Rajasthan e artisti folk locali. I ballerini esultanti che vorticano le gonne, l'incredibile danza del fuoco e gli abbaglianti fuochi d'artificio illuminano la città fortificata nel deserto di Bikaner."
            ],
            "de": ["Festival ist eine lebhafte und farbenfrohe Veranstaltung, die im Januar stattfindet. Das Kamelfest beginnt mit einer farbenfrohen Prozession geschmückter Kamele vor der wunderschönen schroffen Kulisse des Junagarh Fort. Das Festival geht weiter zu den offenen Sandflächen des Geländes, gefolgt vom Wettbewerb der besten Rassen, dem Tauziehen-Wettbewerb, Kamelrennen, Kameltanz, den holprigen, halsschüttelnden Kamelritten und Akrobatik usw.",
                "Der Kamelwettbewerb findet am ersten Tag statt, bei dem die Kamelbesitzer die Dekorationen und den Schmuck ihrer Kamele zeigen. Es wird ein Wettbewerb für das beste geschmückte Kamel, das Pelzschneidedesign, das Kamelmelken und den besten Kamelhaarschnitt organisiert. Die Kamele zeigen eine erstaunliche Beinarbeit und tanzen anmutig in die geringste Richtung ihrer Trainer. Braut, Zaumzeug, juwelenbesetzte Hälse, klirrende Fußkettchen und lange, schlaksige Kamelschatten auf dunklem Sand ziehen einen magischen Zauber aus. Tee und Süßigkeiten aus Kamelmilch werden den interessierten Besuchern zur Verfügung gestellt. Hunderte von Touristen und Tausende von Einheimischen und Würdenträgern schwelgen in dieser speziell für Touristen organisierten Angelegenheit zwischen Mensch und Tier",
                "Am zweiten Tag nehmen die flinksten Kamele der Region an den Kamelrennen teil. Die Konkurrenz ist hart, da die besten Kamele um die Ehre wetteifern. Tausende begeisterte Touristen und Einheimische feuern die Favoriten an.",
                "Die Abende enden mit einem traditionellen Rendezvous mit renommierten Künstlern aus Rajasthan und den lokalen Volkskünstlern. Die jubelnden Röcke wirbelnden Tänzer, der erstaunliche Feuertanz und das schillernde Feuerwerk erhellen die befestigte Wüstenstadt Bikaner."
            ]
        },
    },
    {
        id: 3,
        title: {
            "en": "Desert Festival",
            "es": "Festival del desierto",
            "fr": "Fête du désert",
            "it": "Festa del deserto",
            "de": "Wüstenfest"
        },
        banner: marwar,
        label1: {
            "en": "Best time to Visit :",
            "es": "Mejor tiempo para visitar :",
            "fr": "Meilleur moment pour visiter :",
            "it": "Il periodo migliore per visitare :",
            "de": "Beste Zeit für einen Besuch :"
        },
        label2: {
            "en": "Place :",
            "es": "Lugar :",
            "fr": "Place :",
            "it": "Posto :",
            "de": "Ort :"
        },
        label3: {
            "en": "Duration :",
            "es": "Duración :",
            "fr": "Durée :",
            "it": "Durata :",
            "de": "Dauer :"
        },
        time: {
            place: "Jaisalmer (Rajasthan)",
            duration: {
                "en": "3 Days",
                "es": "3 Días",
                "fr": "3 Journées",
                "it": "3 Giorni",
                "de": "3 Tage"
            },
            time: "January",
        },
        description: {
            "en": ["During your Rajasthan travel experience the vibrancy of the Thar. The desert pulsates with a myriad of Rajasthani dances: Ghoomer, Gangaur, Gair Dhap, Moria, Chari and Tehratal  The Gair and the Fire dances are the special attractions of the festival. Folk performers and the artists dressed in brilliantly hued costumes, the people of the desert dance and sing haunting ballads of valor, romance and tragedy. The fair has snake charmers, puppeteers, acrobats all exhibit their traditional skills.",
                "Camels are the integral part of the desert life and the camel events during the Desert Festival confirm this fact. There are exciting camel dances, camel acrobatics, camel races, and camel polo, competitions for the best decorated camel.",
                "Other interesting competitions on the fringes are  Mr. Desert contest, tug-of-war between musclemen, the moustache and turban tying competitions, which not only demonstrate a glorious tradition but also inspire its preservation. Both the turban and the moustache have been centuries old symbols of honor in Rajasthan. ",
                " Desert Festival  held against the backdrop of the awesome Jaisalmer fort is one of its kind in the world. Evenings are meant for the main shows of music and dance. Nearly all the variations of performing arts of these desert people are displayed. The number of spectators swells up each night and the grand finale, on the full moon night, takes place by silvery sand dunes. Fireworks displays at night light up the area, makes the Desert Festival at Jaisalmer  not-to-be missed event."],
            "es": ["Durante su viaje a Rajasthan, experimente la vitalidad del Thar. El desierto vibra con una miríada de danzas Rajasthani: Ghoomer, Gangaur, Gair Dhap, Moria, Chari y Tehratal. Las danzas Gair y Fire son las atracciones especiales del festival. Los artistas folclóricos y los artistas vestidos con trajes de colores brillantes, la gente del desierto baila y canta inquietantes baladas de valor, romance y tragedia. La feria cuenta con encantadores de serpientes, titiriteros, acróbatas, todos exhiben sus habilidades tradicionales.",
                "Los camellos son una parte integral de la vida del desierto y los eventos de camellos durante el Festival del Desierto confirman este hecho. Hay emocionantes bailes de camellos, acrobacias de camellos, carreras de camellos y polo de camellos, competiciones para el camello mejor decorado.",
                "Otras competencias interesantes al margen son el concurso Mr. Desert, el tira y afloja entre musculosos, las competencias de atado de turbantes y bigotes, que no solo demuestran una gloriosa tradición sino que también inspiran su preservación. Tanto el turbante como el bigote han sido símbolos de honor de siglos de antigüedad en Rajasthan.",
                "Desert Festival, que se celebra con el impresionante fuerte de Jaisalmer como telón de fondo, es único en el mundo. Las noches están pensadas para los principales espectáculos de música y danza. Se muestran casi todas las variaciones de las artes escénicas de esta gente del desierto. El número de espectadores aumenta cada noche y la gran final, en la noche de luna llena, tiene lugar en las dunas de arena plateada. Los espectáculos de fuegos artificiales en la noche iluminan el área, lo que hace que el Festival del Desierto en Jaisalmer sea un evento imperdible.",

            ],
            "fr": ["Au cours de votre voyage au Rajasthan, découvrez le dynamisme du Thar. Le désert vibre d'une myriade de danses du Rajasthan : Ghoomer, Gangaur, Gair Dhap, Moria, Chari et Tehratal Le Gair et les danses du feu sont les attractions spéciales du festival. Les artistes folkloriques et les artistes vêtus de costumes aux teintes brillantes, les habitants du désert dansent et chantent des ballades obsédantes de bravoure, de romance et de tragédie. La foire a des charmeurs de serpents, des marionnettistes, des acrobates qui exposent tous leurs savoir-faire traditionnels.",
                "Les chameaux font partie intégrante de la vie dans le désert et les événements chameliers lors du Festival du désert confirment ce fait. Il y a des danses de chameaux passionnantes, des acrobaties de chameaux, des courses de chameaux et du polo de chameaux, des compétitions pour le chameau le mieux décoré.",
                "D'autres compétitions intéressantes en marge sont le concours de Mr. Desert, tir à la corde entre hommes musclés, les concours de nouage de moustaches et de turbans, qui non seulement démontrent une glorieuse tradition mais inspirent également sa préservation. Le turban et la moustache sont des symboles d'honneur séculaires au Rajasthan.",
                "Le festival du désert organisé dans le contexte de l'impressionnant fort de Jaisalmer est unique en son genre dans le monde. Les soirées sont destinées aux principaux spectacles de musique et de danse. Presque toutes les variantes des arts du spectacle de ces peuples du désert sont présentées. Le nombre de spectateurs gonfle chaque nuit et la grande finale, la nuit de pleine lune, a lieu par des dunes de sable argentées. Des feux d'artifice la nuit illuminent la région, faisant du Festival du Désert à Jaisalmer un événement à ne pas manquer."
            ],
            "it": ["Durante il tuo viaggio in Rajasthan vivi la vivacità del Thar. Il deserto pulsa con una miriade di danze del Rajasthan: Ghoomer, Gangaur, Gair Dhap, Moria, Chari e Tehratal Le danze Gair e the Fire sono le attrazioni speciali del festival. Artisti folk e artisti vestiti con costumi dai colori brillanti, la gente del deserto balla e canta ballate ossessionanti di valore, romanticismo e tragedia. La fiera ha incantatori di serpenti, burattinai, acrobati tutti esibiscono le loro abilità tradizionali.",
                "I cammelli sono parte integrante della vita nel deserto e gli eventi sui cammelli durante il Desert Festival lo confermano. Ci sono emozionanti balli di cammelli, acrobazie di cammelli, corse di cammelli e polo di cammelli, gare per il cammello meglio decorato.",
                "Altre gare interessanti ai margini sono la gara di Mr. Desert, il tiro alla fune tra musclemen, le gare di legatura di baffi e turbanti, che non solo dimostrano una gloriosa tradizione ma ne ispirano anche la conservazione. Sia il turbante che i baffi sono stati simboli d'onore secolari in Rajasthan.",
                "Il Festival del deserto che si tiene sullo sfondo del fantastico forte di Jaisalmer è unico al mondo. Le serate sono destinate ai principali spettacoli di musica e danza. Vengono visualizzate quasi tutte le varianti delle arti dello spettacolo di queste persone del deserto. Il numero di spettatori aumenta ogni notte e il gran finale, nella notte di luna piena, si svolge tra dune di sabbia argentata. I fuochi d'artificio di notte illuminano l'area, rendendo il Festival del deserto a Jaisalmer un evento da non perdere."
            ],
            "de": ["Die Abende enden mit einem traditionellen Rendezvous mit renommierten Künstlern aus Rajasthan und den lokalen Volkskünstlern. Die jubelnden Röcke wirbelnden Tänzer, der erstaunliche Feuertanz und das schillernde Feuerwerk erhellen die befestigte Wüstenstadt Bikaner.",
                "Kamele sind ein fester Bestandteil des Wüstenlebens und die Kamelveranstaltungen während des Wüstenfestes bestätigen diese Tatsache. Es gibt aufregende Kameltänze, Kamelakrobatik, Kamelrennen und Kamelpolo, Wettbewerbe um das am besten geschmückte Kamel.",
                "Andere interessante Wettbewerbe am Rande sind der Mr. Desert-Wettbewerb, das Tauziehen zwischen Muskelmännern, die Schnurrbart- und Turban-Bindewettbewerbe, die nicht nur eine glorreiche Tradition demonstrieren, sondern auch zu ihrer Erhaltung inspirieren. Sowohl der Turban als auch der Schnurrbart sind jahrhundertealte Ehrensymbole in Rajasthan.",
                "Das Desert Festival, das vor dem Hintergrund der beeindruckenden Festung von Jaisalmer stattfindet, ist weltweit einzigartig. Die Abende sind für die Hauptshows von Musik und Tanz gedacht. Nahezu alle Variationen der darstellenden Künste dieser Wüstenbewohner werden gezeigt. Die Zahl der Zuschauer schwillt jede Nacht an und das große Finale findet in der Vollmondnacht vor silbrigen Sanddünen statt. Feuerwerke in der Nacht erhellen die Gegend und machen das Wüstenfest in Jaisalmer zu einem Ereignis, das man sich nicht entgehen lassen sollte."
            ]
        },
    },
    {
        id: 4,
        "title": {
            "en": "Elephant Festival",
            "es": "fiesta del elefante",
            "fr": "Fête des éléphants",
            "it": "Festa degli elefanti",
            "de": "Elefantenfest"
        },
        banner: elephant,
        label1: {
            "en": "Best time to Visit :",
            "es": "Mejor tiempo para visitar :",
            "fr": "Meilleur moment pour visiter :",
            "it": "Il periodo migliore per visitare :",
            "de": "Beste Zeit für einen Besuch :"
        },
        label2: {
            "en": "Place :",
            "es": "Lugar :",
            "fr": "Place :",
            "it": "Posto :",
            "de": "Ort :"
        },
        label3: {
            "en": "Duration :",
            "es": "Duración :",
            "fr": "Durée :",
            "it": "Durata :",
            "de": "Dauer :"
        },
        time: {
            place: "Jaipur (Rajasthan)",
            duration: {
                "en": "5 Days",
                "es": "5 Días",
                "fr": "5 Journées",
                "it": "5 Giorni",
                "de": "5 Tage"
            },
            time: "February / march (on Holi)",
        },
        description: {
            "en": ["The Elephant Festival is one of the most popular festivals of Jaipur, Rajasthan. Elephant festival has its own charm and is celebrated every year in March on the occasion of Holi, the festival of colors. The colorful festival of Holi is celebrated throughout India, but the festival gets some more spice added to it, when it combines with the Elephant festival of Jaipur, Rajasthan. The Elephants become the major attraction of this festival in their classy attires. ",
                "The famous Chaugan ground of Jaipur makes the perfect venue for the occasion. Elephant Festival commences with a parade of decorated Elephants marching towards the ground. Different competitions are organized to enjoy the festival to its full. Elephants engaged in races, polo-matches and tug-of-war with men make the spectators spellbound. Here one can enjoy seeing Elephants at their best. The Elephant keepers (mahouts) decorate their Elephants from head to toe with an exceptional care. The 'Best Decorated Elephant' contest is another feature of this cultural fest. ",
                "It is a festival time for Elephants, so they enjoy the festivity by dancing and playing. Live Folk dances and music programs are also arranged for the overall entertainment of people. This festival of Elephants revives the royalty of Jaipur, when people enjoy the glorious Elephant ride in the ground. The Elephants beautifully decked with floral motifs, make ultimate picture for the tourists. Being the time for Holi, tourists play Holi mounting on these painted Elephants. Elephant Festival of Jaipur is unusual, something different and definitely a special treat for the tourists."],
            "es": ["El Festival del Elefante es uno de los festivales más populares de Jaipur, Rajasthan. La fiesta de los elefantes tiene su propio encanto y se celebra cada año en marzo con motivo de Holi, la fiesta de los colores. El colorido festival de Holi se celebra en toda la India, pero se le agrega un poco más de sabor cuando se combina con el festival de elefantes de Jaipur, Rajasthan. Los Elefantes se convierten en la principal atracción de este festival con sus elegantes atuendos.",
                "El famoso campo Chaugan de Jaipur es el lugar perfecto para la ocasión. El Festival del Elefante comienza con un desfile de elefantes decorados que marchan hacia el suelo. Se organizan diferentes concursos para disfrutar al máximo de la fiesta. Los elefantes participando en carreras, partidos de polo y tira y afloja con los hombres cautivan a los espectadores. Aquí uno puede disfrutar viendo a los elefantes en su mejor momento. Los cuidadores de Elefantes (mahouts) decoran a sus Elefantes de pies a cabeza con un cuidado excepcional. El concurso 'Elefante Mejor Decorado' es otra de las características de esta fiesta cultural.",
                "Es una época festiva para los elefantes, por lo que disfrutan de la festividad bailando y jugando. También se organizan bailes folclóricos en vivo y programas de música para el entretenimiento general de la gente. Este festival de elefantes revive la realeza de Jaipur, cuando la gente disfruta del glorioso paseo en elefante por el suelo. Los elefantes, bellamente adornados con motivos florales, son la mejor imagen para los turistas. Siendo la época de Holi, los turistas juegan a Holi montados en estos Elefantes pintados. El Festival de Elefantes de Jaipur es inusual, algo diferente y definitivamente un regalo especial para los turistas.",

            ],
            "fr": ["Le Festival des éléphants est l'un des festivals les plus populaires de Jaipur, au Rajasthan. La fête des éléphants a son propre charme et est célébrée chaque année en mars à l'occasion de Holi, la fête des couleurs. Le festival coloré de Holi est célébré dans toute l'Inde, mais le festival est encore plus épicé lorsqu'il se combine avec le festival des éléphants de Jaipur, au Rajasthan. Les éléphants deviennent la principale attraction de ce festival dans leurs tenues élégantes.",
                "Le célèbre terrain Chaugan de Jaipur est le lieu idéal pour l'occasion. Le festival des éléphants commence par un défilé d'éléphants décorés marchant vers le sol. Différentes compétitions sont organisées pour profiter pleinement du festival. Les éléphants engagés dans des courses, des matchs de polo et des tirs à la corde avec des hommes envoûtent les spectateurs. Ici, on peut voir les éléphants sous leur meilleur jour. Les gardiens d'éléphants (cornacs) décorent leurs éléphants de la tête aux pieds avec un soin exceptionnel. Le concours du « meilleur éléphant décoré » est une autre caractéristique de ce festival culturel.",
                "C'est une période de fête pour les éléphants, alors ils profitent de la fête en dansant et en jouant. Des danses folkloriques en direct et des programmes de musique sont également organisés pour le divertissement général des gens. Ce festival des éléphants fait revivre la royauté de Jaipur, lorsque les gens apprécient la glorieuse balade à dos d'éléphant dans le sol. Les éléphants joliment ornés de motifs floraux constituent une image ultime pour les touristes. Étant le temps de Holi, les touristes jouent à Holi en montant sur ces éléphants peints. Le festival des éléphants de Jaipur est inhabituel, quelque chose de différent et certainement un régal pour les touristes.",
            ],
            "it": ["L'Elephant Festival è uno dei festival più popolari di Jaipur, nel Rajasthan. La festa dell'elefante ha un suo fascino e si celebra ogni anno a marzo in occasione di Holi, la festa dei colori. Il colorato festival di Holi viene celebrato in tutta l'India, ma il festival viene aggiunto un po' di spezie in più, quando si combina con il festival degli elefanti di Jaipur, nel Rajasthan. Gli elefanti diventano l'attrazione principale di questo festival nei loro abiti di classe.",
                "Il famoso terreno Chaugan di Jaipur è il luogo perfetto per l'occasione. Il Festival degli elefanti inizia con una sfilata di elefanti decorati che marciano verso terra. Vengono organizzati diversi concorsi per godersi appieno il festival. Gli elefanti impegnati in gare, partite di polo e tiro alla fune con uomini incantano gli spettatori. Qui ci si può divertire a vedere gli elefanti al meglio. I guardiani degli elefanti (mahout) decorano i loro elefanti dalla testa ai piedi con una cura eccezionale. Il concorso 'Best Decorated Elephant' è un'altra caratteristica di questa festa culturale.",
                "È un periodo di festa per gli elefanti, quindi si godono la festa ballando e giocando. Vengono organizzati anche balli popolari dal vivo e programmi musicali per l'intrattenimento generale delle persone. Questo festival degli elefanti fa rivivere la regalità di Jaipur, quando le persone si godono il glorioso giro dell'elefante nel terreno. Gli elefanti splendidamente addobbati con motivi floreali, fanno l'immagine definitiva per i turisti. Essendo il momento di Holi, i turisti giocano a Holi montando su questi elefanti dipinti. Il Festival degli elefanti di Jaipur è insolito, qualcosa di diverso e sicuramente un regalo speciale per i turisti.",
            ],
            "de": ["Das Elephant Festival ist eines der beliebtesten Festivals von Jaipur, Rajasthan. Das Elefantenfest hat seinen ganz eigenen Charme und wird jedes Jahr im März anlässlich von Holi, dem Fest der Farben, gefeiert. Das farbenfrohe Holi-Fest wird in ganz Indien gefeiert, aber das Fest bekommt noch mehr Würze, wenn es sich mit dem Elefantenfest von Jaipur, Rajasthan, verbindet. Die Elefanten werden in ihren edlen Gewändern zur Hauptattraktion dieses Festivals.",
                "Der berühmte Chaugan-Platz von Jaipur ist der perfekte Ort für diesen Anlass. Das Elefantenfest beginnt mit einer Parade geschmückter Elefanten, die zum Boden marschieren. Um das Festival in vollen Zügen genießen zu können, werden verschiedene Wettbewerbe organisiert. Elefantenrennen, Polospiele und Tauziehen mit Männern ziehen die Zuschauer in ihren Bann. Hier kann man Elefanten von ihrer besten Seite sehen. Die Elefantenpfleger (Mahouts) schmücken ihre Elefanten von Kopf bis Fuß mit außergewöhnlicher Sorgfalt. Der Wettbewerb „Bester geschmückter Elefant“ ist ein weiteres Merkmal dieses Kulturfestes.",
                "Es ist eine Festzeit für Elefanten, also genießen sie das Fest durch Tanzen und Spielen. Live-Volkstanz und Musikprogramme werden ebenfalls für die allgemeine Unterhaltung der Menschen arrangiert. Dieses Fest der Elefanten belebt das Königtum von Jaipur wieder, wenn die Menschen den glorreichen Elefantenritt im Boden genießen. Die wunderschön mit Blumenmotiven geschmückten Elefanten machen das ultimative Bild für die Touristen. Da die Zeit für Holi ist, spielen Touristen Holi, die auf diese bemalten Elefanten steigen. Das Elefantenfest von Jaipur ist ungewöhnlich, etwas anderes und definitiv ein besonderes Vergnügen für die Touristen.",
            ]
        },
    },
    {
        id: 5,
        "title": {
            "en": "Ganguar Festival",
            "es": "Festival de Ganguar",
            "fr": "Fête du Gange",
            "it": "Festa del Gange",
            "de": "Ganguar-Fest"
        },
        banner: mewar,
        label1: {
            "en": "Best time to Visit :",
            "es": "Mejor tiempo para visitar :",
            "fr": "Meilleur moment pour visiter :",
            "it": "Il periodo migliore per visitare :",
            "de": "Beste Zeit für einen Besuch :"
        },
        label2: {
            "en": "Place :",
            "es": "Lugar :",
            "fr": "Place :",
            "it": "Posto :",
            "de": "Ort :"
        },
        label3: {
            "en": "Duration :",
            "es": "Duración :",
            "fr": "Durée :",
            "it": "Durata :",
            "de": "Dauer :"
        },
        time: {
            place: "Jaipur (Rajasthan)",
            duration: {
                "en": "16 Days",
                "es": "16 Días",
                "fr": "16 Journées",
                "it": "16 Giorni",
                "de": "16 Tage"
            },
            time: "March/ April",
        },

        description: {
            "en": ["Gangaur is a significant festival of Jaipur, especially meant for the women. The word Gangaur is derived from two words, 'Gan' and 'Gauri' which are synonyms of 'Siva' and his consort 'Parvati' respectively. The festival of Gangaur starts with the subsequent day of Holi and persists for about 2 weeks. Gangaur is celebrated in the honor of Goddess Gauri, who is considered as the symbol of virtue, devotion, fertility and a perfect married woman.",
                "The womenfolk of Rajasthan worship Gauri with all the means of devotion, for the well-being of their husband, while the unmarried girls worship the goddess to get the husband of their choice. The festival begins with the custom of gathering ashes from Holika Dahan (Holi Fire) and burying the seeds of barley in it. After it, the seeds are watered everyday awaiting the germination.",
                "The ritual is performed with the praiseful songs for Isar (Siva) and Gauri. The women apply henna on their hands to celebrate the auspicious festival. They carry painted matkas (water pots) on their heads. They also make images of Gauri and Isar with clay. On the seventh evening after Holi, unmarried girls assemble and take out a procession with 'Ghudlia' (an earthen pot with holes around and a lamp inside) on their heads. The ceremony continues for a fortnight. The girls are gifted with sweets, ghee and cash by the elders.",
                "During the last days of the festival, the celebration reaches to its height. On the final day, lively images of Gauri are taken out in procession escorted by traditionally dressed camels, bullock carts, horses and elephants decorated in traditional finery.. Songs are sung about the departure of Gauri to her husband's home. The procession is wrapped up with the breaking of pots and throwing the trash into a tank or pond. This traditional festival of Gangor is celebrated in Jaipur from ages and forms a special attraction for any visitor",
                "Another unique characteristic of this colorful festival is that on this occasion, tribal men and women have an opportunity to meet and interact freely. They can select a life partner of their choice and marry only by eloping."],
            "es": ["Gangaur es un festival importante de Jaipur, especialmente destinado a las mujeres. La palabra Gangaur se deriva de dos palabras, 'Gan' y 'Gauri', que son sinónimos de 'Siva' y su consorte 'Parvati' respectivamente. El festival de Gangaur comienza el día siguiente de Holi y persiste durante unas dos semanas. Gangaur se celebra en honor a la diosa Gauri, a quien se considera el símbolo de la virtud, la devoción, la fertilidad y la mujer casada perfecta.",
                "Las mujeres de Rajasthan adoran a Gauri con todos los medios de devoción, por el bienestar de su esposo, mientras que las muchachas solteras adoran a la diosa para conseguir el esposo de su elección. El festival comienza con la costumbre de recoger cenizas de Holika Dahan (Holi Fire) y enterrar las semillas de cebada en ellas. Después de eso, las semillas se riegan todos los días a la espera de la germinación.",
                "El ritual se realiza con cantos de alabanza a Isar (Siva) y Gauri. Las mujeres se aplican henna en las manos para celebrar el auspicioso festival. Llevan matkas (tinajas de agua) pintadas en la cabeza. También hacen imágenes de Gauri e Isar con arcilla. En la séptima noche después de Holi, las chicas solteras se reúnen y salen en procesión con 'Ghudlia' (una olla de barro con agujeros alrededor y una lámpara dentro) sobre sus cabezas. La ceremonia continúa durante quince días. Los mayores regalan a las niñas dulces, ghee y dinero en efectivo.",
                "Durante los últimos días del festival, la celebración alcanza su punto máximo. El último día, se sacan en procesión animadas imágenes de Gauri escoltadas por camellos, carretas tiradas por bueyes, caballos y elefantes ataviados con galas tradicionales, vestidos tradicionalmente. Se cantan canciones sobre la partida de Gauri hacia la casa de su marido. La procesión se cierra con el rompimiento de ollas y el lanzamiento de la basura a un tanque o estanque. Esta fiesta tradicional de Gangor se celebra en Jaipur desde hace siglos y constituye un atractivo especial para cualquier visitante",
                "Otra característica única de este colorido festival es que en esta ocasión, los hombres y mujeres tribales tienen la oportunidad de conocerse e interactuar libremente. Pueden seleccionar un compañero de vida de su elección y casarse solo mediante la fuga.",

            ],
            "fr": ["Gangaur est un festival important de Jaipur, spécialement destiné aux femmes. Le mot Gangaur est dérivé de deux mots, « Gan » et « Gauri », qui sont respectivement synonymes de « Siva » et de son épouse « Parvati ». Le festival de Gangaur commence le lendemain de Holi et dure environ 2 semaines. Gangaur est célébré en l'honneur de la déesse Gauri, considérée comme le symbole de la vertu, de la dévotion, de la fertilité et d'une femme mariée parfaite.",
                "Les femmes du Rajasthan adorent Gauri avec tous les moyens de dévotion, pour le bien-être de leur mari, tandis que les filles célibataires adorent la déesse pour obtenir le mari de leur choix. Le festival commence par la coutume de recueillir les cendres de Holika Dahan (Holi Fire) et d'y enterrer les graines d'orge. Après cela, les graines sont arrosées tous les jours en attendant la germination.",
                "Le rituel est exécuté avec les chants de louange pour Isar (Siva) et Gauri. Les femmes appliquent du henné sur leurs mains pour célébrer la fête de bon augure. Ils portent sur la tête des matkas (pots à eau) peints. Ils fabriquent également des images de Gauri et d'Isar avec de l'argile. Le septième soir après Holi, des filles célibataires se rassemblent et sortent en procession avec « Ghudlia » (un pot en terre avec des trous autour et une lampe à l'intérieur) sur la tête. La cérémonie se poursuit pendant une quinzaine de jours. Les filles sont douées de bonbons, de ghee et d'argent par les aînés.",
                "Durant les derniers jours du festival, la fête atteint son paroxysme. Le dernier jour, des images animées de Gauri sont sorties en procession escortées par des chameaux vêtus de façon traditionnelle, des chars à bœufs, des chevaux et des éléphants décorés de parures traditionnelles. Des chansons sont chantées sur le départ de Gauri vers la maison de son mari. La procession se termine par le bris de pots et le lancement des ordures dans un réservoir ou un étang. Ce festival traditionnel de Gangor est célébré à Jaipur depuis des siècles et constitue une attraction particulière pour tout visiteur",
                "Une autre caractéristique unique de ce festival coloré est qu'à cette occasion, les hommes et les femmes des tribus ont l'occasion de se rencontrer et d'interagir librement. Ils peuvent choisir un partenaire de vie de leur choix et se marier uniquement en s'enfuyant.",
            ],
            "it": ["Gangaur è un festival importante di Jaipur, pensato soprattutto per le donne. La parola Gangaur deriva da due parole, 'Gan' e 'Gauri' che sono sinonimi rispettivamente di 'Siva' e della sua consorte 'Parvati'. La festa di Gangaur inizia con il giorno successivo di Holi e persiste per circa 2 settimane. Gangaur è celebrato in onore della dea Gauri, considerata il simbolo di virtù, devozione, fertilità e una perfetta donna sposata.",
                "Le donne del Rajasthan adorano Gauri con tutti i mezzi di devozione, per il benessere del marito, mentre le ragazze non sposate adorano la dea per ottenere il marito di loro scelta. La festa inizia con l'usanza di raccogliere le ceneri da Holika Dahan (Holi Fire) e di seppellirvi i semi d'orzo. Successivamente, i semi vengono annaffiati tutti i giorni in attesa della germinazione.",
                "Il rituale viene eseguito con i canti di lode per Isar (Siva) e Gauri. Le donne si applicano l'henné sulle mani per celebrare la festa di buon auspicio. Portano sulla testa matka (vasi per l'acqua) dipinti. Fanno anche immagini di Gauri e Isar con l'argilla. La settima sera dopo Holi, le ragazze non sposate si riuniscono e portano fuori una processione con 'Ghudlia' (un vaso di terracotta con dei buchi intorno e una lampada all'interno) in testa. La cerimonia continua per quindici giorni. Le ragazze sono dotate di dolci, burro chiarificato e contanti dagli anziani.",
                "Durante gli ultimi giorni della festa, la festa raggiunge il suo apice. L'ultimo giorno, le immagini vivaci di Gauri vengono portate in processione scortate da cammelli vestiti in modo tradizionale, carri trainati da buoi, cavalli ed elefanti decorati con raffinatezze tradizionali. Vengono cantate canzoni sulla partenza di Gauri a casa di suo marito. La processione si conclude con la rottura delle pentole e il lancio della spazzatura in una vasca o in un laghetto. Questa festa tradizionale di Gangor è celebrata a Jaipur da secoli e costituisce un'attrazione speciale per ogni visitatore",
                "Un'altra caratteristica unica di questo colorato festival è che in questa occasione, uomini e donne tribali hanno l'opportunità di incontrarsi e interagire liberamente. Possono selezionare un compagno di vita a loro scelta e sposarsi solo fuggendo.",
            ],
            "de": ["Gangaur ist ein bedeutendes Fest von Jaipur, das besonders für die Frauen gedacht ist. Das Wort Gangaur leitet sich von zwei Wörtern ab, „Gan“ und „Gauri“, die Synonyme von „Siva“ bzw. seiner Gemahlin „Parvati“ sind. Das Fest von Gangaur beginnt mit dem darauffolgenden Tag von Holi und dauert etwa 2 Wochen. Gangaur wird zu Ehren der Göttin Gauri gefeiert, die als Symbol für Tugend, Hingabe, Fruchtbarkeit und eine perfekt verheiratete Frau gilt.",
                "Die Frauen von Rajasthan verehren Gauri mit allen Mitteln der Hingabe für das Wohlergehen ihres Ehemanns, während die unverheirateten Mädchen die Göttin anbeten, um den Ehemann ihrer Wahl zu bekommen. Das Fest beginnt mit dem Brauch, Asche von Holika Dahan (Holi-Feuer) zu sammeln und die Gerstensamen darin zu vergraben. Danach werden die Samen jeden Tag gegossen und warten auf die Keimung.",
                "Das Ritual wird mit den Lobgesängen für Isar (Siva) und Gauri durchgeführt. Die Frauen tragen Henna auf ihre Hände auf, um das verheißungsvolle Fest zu feiern. Auf dem Kopf tragen sie bemalte Matkas (Wasserkrüge). Sie machen auch Bilder von Gauri und Isar mit Ton. Am siebten Abend nach Holi versammeln sich unverheiratete Mädchen und führen eine Prozession mit 'Ghudlia' (ein irdener Topf mit Löchern herum und einer Lampe darin) auf ihren Köpfen aus. Die Zeremonie dauert zwei Wochen. Die Mädchen werden von den Ältesten mit Süßigkeiten, Ghee und Bargeld beschenkt.",
                "Durant les derniers jours du festival, la fête atteint son paroxysme. Le dernier jour, des images animées de Gauri sont sorties en procession escortées par des chameaux vêtus de façon traditionnelle, des chars à bœufs, des chevaux et des éléphants décorés de parures traditionnelles. Des chansons sont chantées sur le départ de Gauri vers la maison de son mari. La procession se termine par le bris de pots et le lancement des ordures dans un réservoir ou un étang. Ce festival traditionnel de Gangor est célébré à Jaipur depuis des siècles et constitue une attraction particulière pour tout visiteur",
                "Ein weiteres einzigartiges Merkmal dieses farbenfrohen Festivals ist, dass Stammesmänner und -frauen bei dieser Gelegenheit die Möglichkeit haben, sich zu treffen und frei zu interagieren. Sie können sich einen Lebenspartner ihrer Wahl aussuchen und nur durch Flucht heiraten.",
            ]
        },
    },
];
