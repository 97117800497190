import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';

import './style.css';

function Home() {
    return (
        <>
            <a href="/reservation-form" className="float cursor-pointer">
                <h1 className="p-3 text-center cursor-pointer"><FormatAlignLeftIcon /> Reservation</h1>
            </a>

        </>
    );
}
export default withRouter(Home);
