import React from "react";
import NavBar from "../../Components/Navbar/NavBar";
import logo2 from "../../Assets/Image/logo2.png";
import ParentContainer from "../../Components/ParentContainer/ParentContainer";
import TopBanner from "../../Components/TopBanner/TopBanner";
import { nationalLifeList } from "../../JsonData/NationalLife";
import { Grid } from "@mui/material";
import styles from "./styles.module.scss";
import arrow from "../../Assets/Svg/arrow2.svg";
import Footer from "../../Components/Footer/Footer";
import MobileFooter from "../../Components/Footer/MobileFooter";
import { useHistory } from "react-router-dom";
import FloatingButton from "../../Components/FloatingButton/FloatingButton";

const GridComponent = ({ item, size, history }) => {
    return (
        <Grid item md={size} className="w-full">
            <div
                className={styles.banner}
                onClick={() =>
                    history.push(`/national-life/details/${item.id}`)
                }
            >
                <div
                    className={`${styles.innerBanner} relative flex justify-center items-center`}
                    style={{
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${item?.img})`,
                    }}
                >
                    <div>
                        <img
                            src={arrow}
                            width={40}
                            alt="arrow"
                            className="absolute top-2 right-2"
                        />
                    </div>
                    <p className="text-white text-2xl font-bold">
                        {item?.title}
                    </p>
                </div>
            </div>
        </Grid>
    );
};
function NationalLife() {
    const history = useHistory();
    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton/>
            <ParentContainer>
                <TopBanner showBanner={false} label={"Wildlife"} />
            </ParentContainer>

            {nationalLifeList?.map((item, index) => (
                <div className="bg-greyBackgorund" key={index}>
                    <ParentContainer>
                        <div className="py-10 sm:py-10 2xs:py-10">
                            <div className="mt-10"></div>
                            <Grid container spacing={4}>
                                {item?.topPlaces?.map((item1, index1) => (
                                    <GridComponent
                                        size={4}
                                        item={item1}
                                        key={index1}
                                        history={history}
                                    />
                                ))}{" "}
                                {item?.bottomPlaces?.map((item1, index1) => (
                                    <GridComponent
                                        size={4}
                                        item={item1}
                                        key={index1}
                                        history={history}
                                    />
                                ))}
                            </Grid>
                        </div>
                    </ParentContainer>
                </div>
            ))}
            <Footer />
            <MobileFooter />
        </div>
    );
}

export default NationalLife;
