import React from "react";
import styles from "./styles.module.scss";
function Button({
    title,
    type,
    style,
    classes,
    img,
    imgAlt,
    imageStyle,
    onClick,
    tailwindClasses,
}) {
    return (
        <button
            type={type}
            style={style}
            className={` ${styles.button} ${styles[classes]} ${tailwindClasses}`}
            onClick={() => type == "submit" ? "" : onClick()}
        >
            <img src={img} alt={imgAlt} style={imageStyle} />
            <p>{title}</p>
        </button>
    );
}

export default Button;
