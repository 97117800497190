export default {
    "heading1": {
        "en": "Seating Capacity",
        "es": "Número de plazas",
        "fr": "Nombre de places",
        "it": "Capacità di posti a sedere",
        "de": "Sitzplatzkapazität"
    },
    "heading2": {
        "en": "including Drivers",
        "es": "incluyendo conductores",
        "fr": "y compris les pilotes",
        "it": "compresi i driver",
        "de": "inklusive Treiber"
    },
}