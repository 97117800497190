export default {
    "title": {
        "en": "Distance Calculator",
        "es": "Calculadora de distancia",
        "fr": "Calculateur de distance",
        "it": "Calcolatore di distanza",
        "de": "Entfernungsrechner"
    },
    "From": {
        "en": "From",
        "es": "De",
        "fr": "De",
        "it": "Da",
        "de": "Aus"
    },
    "To": {
        "en": "To",
        "es": "A",
        "fr": "À",
        "it": "Per",
        "de": "Zu"
    },
}