import React from "react";
import styles from "./styles.module.scss";

function FilledBackground({ children, greyBackground }) {
    return (
        <div
            className={`mx-32 mt-8 md:mx-16 sm:mx-8 sm:mt-8 2xs:mt-8 2xs:mx-4 ${styles.filledBackground} `}
        >
            <div>{children}</div>
        </div>
    );
}

export default FilledBackground;
