import React from "react";

function Heading({ children }) {
    return (
        <p className="text-5xl text-center text-secondaryColor font-semibold md:text-4xl sm:text-3xl 2xs:text-3xl">
            {children}
        </p>
    );
}

export default Heading;
