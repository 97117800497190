import React from "react";
import styles from "./styles.module.scss";

function Heading2({ heading, isVerticalLine, blueText, isDanger }) {
    return (
        <div
            className={`${isVerticalLine && styles.heading} ${
                isDanger && styles.dangerRed
            }`}
        >
            <h5
                className={`text-4xl font-semibold sm:text-2xl 2xs:text-2xl ${
                    blueText
                        ? isDanger
                            ? "text-dangerColor"
                            : "text-primaryColor"
                        : "text-secondaryColor"
                }`}
            >
                {heading}
            </h5>
        </div>
    );
}

export default Heading2;
