import React from "react";
import styles from "./styles.module.scss";
function InputField({ type="text", label, name, placeholder, isBlur, onChange }) {
    return (
        <div>
            <h4 className={`${isBlur ? styles.label : styles.blackLabel}`}>
                {label}
            </h4>
            <input
                type={type}
                name={name}
                placeholder={placeholder}
                className={`${isBlur ? styles.inputField : styles.nonBlur}`}
                onChange={onChange}
            />
        </div>
    );
}

export default InputField;
