import tajMahal from "../Assets/Image/TajMahal.jpg";
import varanasi from "../Assets/Image/varanasi.png";
import kerelaDetails from "../Assets/Image/SouthIndiaHome.webp";
import goa from "../Assets/Image/GoaHomePage.jpg";
import banner1 from "../Assets/Image/banner.png";
import banner3 from "../Assets/Image/banner3.png";
import udaipur from "../Assets/Image/udaipur.png";
import pushkar from "../Assets/Image/Pushkar_Fair.png";
import nepal from "../Assets/Image/NepalHome.webp";
import tiger from "../Assets/Image/tiger.png";
import mevar from "../Assets/Image/Neemrana-Fort_royal_rajasthan.jpeg";
import hotels from "../Assets/Image/hotels.png";
import temple from "../Assets/Image/Buddhist_Pilgrimage.jpeg";
import golden from "../Assets/Image/golden.png";
import ajanta from "../Assets/Image/Ajanta_Ellora.jpeg";
import fort from "../Assets/Image/Royal_Fort_and_Palaces.jpeg";
import india from "../Assets/Image/Best_of_India.jpeg";

export const popularTour = [
    {
        id: 1,
        tag: "golden",
        label: "Golden Triangle Tours",
        topPlaces: [
            {
                id: 1,
                title: "Golden Triangle Tour",
                path: "/package-details/6",
                img: tajMahal,
            },
            {
                id: 2,
                title: "Golden Triangle with Varanasi",
                path: "/package-details/8",
                img: varanasi,
            },
        ],
        bottomPlaces: [
            {
                id: 3,
                title: "Golden Triangle with Nepal",
                path: "/package-details/9",
                img: nepal,
            },
            {
                id: 4,
                title: "Golden Triangle with Golden Temple",
                path: "/package-details/7",
                img: golden,
            },
            {
                id: 5,
                title: "Golden Triangle with Wildlife",
                path: "/package-details/10",
                img: tiger,
            },
        ],
    },
    {
        id: 2,
        tag: "north",
        label: "North India Tours",
        topPlaces: [
            {
                id: 6,
                title: "North India with Taj Mahal",
                path: "/package-details/1",
                img: tajMahal,
            },
            {
                id: 7,
                title: "North India with Varanasi",
                path: "/package-details/2",
                img: varanasi,
            },
        ],
        bottomPlaces: [
            {
                id: 8,
                title: "North India with Nepal",
                path: "/package-details/5",
                img: nepal,
            },
            {
                id: 9,
                title: "North India with South India",
                path: "/package-details/4",
                img: kerelaDetails,
            },
            {
                id: 10,
                title: "North India with Goa",
                path: "/package-details/3",
                img: goa,
            },
        ],
    },
    {
        id: 3,
        tag: "rajasthan",
        label: "Rajasthan Tours",
        topPlaces: [
            {
                id: 11,
                title: "Best of Rajasthan",
                path: "/package-details/11",
                img: banner3,
            },
            {
                id: 12,
                title: "Kingdom Rajasthan",
                path: "/package-details/12",
                img: udaipur,
            },
        ],
        bottomPlaces: [
            {
                id: 13,
                title: "Historical Rajasthan",
                path: "/package-details/13",
                img: banner1,
            },
            {
                id: 14,
                title: "Royal Rajasthan",
                path: "/package-details/14",
                img: mevar,
            },
            {
                id: 15,
                title: "Exotic Rajasthan",
                path: "/package-details/15",
                img: hotels,
            },
        ],
    },
    {
        id: 4,
        tag: "exclusive",
        label: "Exclusive Tours",
        topPlaces: [
            {
                id: 16,
                title: "Buddhist Pilgrimage Tour",
                path: "/package-details/16",
                img: temple,
            },
            {
                id: 17,
                title: "Ajanta Ellora with Royal Rajasthan",
                path: "/package-details/17",
                img: ajanta,
            },
        ],
        bottomPlaces: [
            {
                id: 18,
                title: "Best of India Tour",
                path: "/package-details/20",
                img: india,
            },
            {
                id: 19,
                title: "Royal Fort and Palaces Tour",
                path: "/package-details/19",
                img: fort,
            },
            {
                id: 20,
                title: "Pushkar Fair Tour",
                path: "/package-details/18",
                img: pushkar,
            },
        ],
    },
];
