import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Context } from '../../Store';
import styles from "./styles.module.scss";
import hamburger from "../../Assets/Svg/hamburger.svg";
import cross from "../../Assets/Svg/cross.svg";
import check from "../../Assets/Svg/check.svg";
import down from "../../Assets/Svg/down.svg";
import logo from "../../Assets/Image/logo.png";
import england from "../../Assets/Svg/england.svg";
import Sidebar from "../Sidebar/Sidebar";
import { navLinks } from "../../JsonData/NavbarLinks";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FlagLink } from "../../JsonData/FlagLinks";

function NavBar({ logo2, isHome }) {
    const history = useHistory();
    const [state, dispatch] = useContext(Context);
    const [showSideBar, setShowSideBar] = useState(false);
    const [selectedFlag, setselectedFlag] = useState(FlagLink.find((e) => e.code == state.language).title || "English");
    const [showFlags, setShowFlags] = useState(false);
    const [selected, setSelected] = useState(history.location.pathname);

    useEffect(() => {
        setSelected(history.location.pathname);
    }, [history.location.pathname]);

    useEffect(() => {
        window.addEventListener("resize", () => {
            if (window.innerWidth > 1000) {
                setShowSideBar(false);
            }
        });
        return () => {
            window.addEventListener("resize", () => {
                if (window.innerWidth > 1000) {
                    setShowSideBar(false);
                }
            });
        };
    }, []);

    const [ThemeChange, setThemeChange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 500) {
            setThemeChange(true);
        } else {
            setThemeChange(false);
        }
    };
    window.addEventListener("scroll", changeNavbarColor);
    return (
        <>
            {" "}
            <div
                className={`fixed top-0 ${
                    ThemeChange
                        ? `${styles.blurBackground} ${styles.navbar}`
                        : styles.navbar
                } ${!isHome && styles.blurBackground}
                } py-5 px-10 sm:px-4 2xs:px-4 flex justify-between items-center w-full`}
            >
                <div className={styles.logoSection}>
                    <Link to="/">
                        <img
                            src={ThemeChange ? logo : !isHome ? logo : logo2}
                            alt="logo"
                            height="auto"
                            className={styles.logo}
                        />
                    </Link>
                    <div className={styles.hamburgerIcon}>
                        <div className="flex">
                            {" "}
                            <img
                                src={hamburger}
                                alt="hamburger menu"
                                className="mr-6"
                                onClick={() => setShowSideBar(!showSideBar)}
                            />
                            <div className="relative">
                                <div
                                    onClick={() => setShowFlags(!showFlags)}
                                    className="flex"
                                >
                                    {" "}
                                    <img src={FlagLink.find((e) => e.title == selectedFlag).img || england} alt="flag" />
                                    <img
                                        src={down}
                                        alt="down"
                                        style={{
                                            width: "20px",
                                            marginLeft: "10px",
                                        }}
                                        color="white"
                                    />
                                </div>

                                <div
                                    className={
                                        showFlags
                                            ? `${styles.flagBox} ${styles.activeFlag} `
                                            : styles.flagBox
                                    }
                                >
                                    {FlagLink.map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                setselectedFlag(item.title);
                                                dispatch({ type: 'SET_LANGUAGE', payload: item.code });
                                                setShowFlags(!showFlags);
                                            }}
                                            className={`flex items-center ${styles.mainBox}`}
                                        >
                                            <img
                                                src={item.img}
                                                alt="flag"
                                                width={"25"}
                                            />
                                            <p className={styles.flagLinks}>
                                                {item.title}
                                            </p>
                                            {selectedFlag === item.title && (
                                                <div className="absolute right-4">
                                                    <img
                                                        src={check}
                                                        alt="check"
                                                        width={"20"}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ))}

                                    <div className={styles.line}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.navbarSection}`}>
                    <ul
                        className={`${
                            ThemeChange ? "text-subheader" : "text-white"
                        } flex justify-between items-center ${
                            !isHome && "text-subheader"
                        }`}
                    >
                        {navLinks.map((item, index) => {
                            if (!item.mobileView) {
                                return (
                                    <li
                                        className={`${selected === item.path &&
                                            "text-primaryColor"
                                            } hover:text-primaryColor`}
                                        key={index}
                                        onClick={() => setSelected(item.path)}
                                    >
                                        <Link to={item.path}>{item.label}</Link>
                                    </li>
                                )
                            }
                        }
                        )}
                        <li className="cursor-pointer">
                            <Link to="/search">
                                <FontAwesomeIcon
                                    icon={faSearch}
                                    color={
                                        ThemeChange
                                            ? "var(--heading)"
                                            : !isHome
                                            ? "var(--heading)"
                                            : "white"
                                    }
                                />
                            </Link>
                        </li>
                        <li className="cursor-pointer relative">
                            <div
                                onClick={() => setShowFlags(!showFlags)}
                                className="flex"
                            >
                                {" "}
                                <img src={FlagLink.find((e) => e.title == selectedFlag).img || england} alt="flag" width="25" />
                                <img
                                    src={down}
                                    alt="down"
                                    style={{
                                        width: "15px",
                                        marginLeft: "10px",
                                    }}
                                    color="white"
                                />
                            </div>
                            <div
                                className={
                                    showFlags
                                        ? `${styles.flagBox} ${styles.activeFlag} `
                                        : styles.flagBox
                                }
                            >
                                {FlagLink.map((item, index) => (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            setselectedFlag(item.title);
                                            dispatch({ type: 'SET_LANGUAGE', payload: item.code });
                                            setShowFlags(!showFlags);
                                        }}
                                        className={`flex items-center ${styles.mainBox}`}
                                    >
                                        <img
                                            src={item.img}
                                            alt="flag"
                                            width={"25"}
                                        />
                                        <p className={styles.flagLinks}>
                                            {item.title}
                                        </p>
                                        {selectedFlag === item.title && (
                                            <div className="absolute right-4">
                                                <img
                                                    src={check}
                                                    alt="check"
                                                    width={"20"}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className={`${
                    showSideBar
                        ? `${styles.overlay} ${styles.overlayActive}`
                        : styles.overlay
                }`}
                onClick={() => setShowSideBar(false)}
            ></div>
            <Sidebar
                setShowSideBar={setShowSideBar}
                showSideBar={showSideBar}
                cross={cross}
                styles={styles}
                navLinks={navLinks}
                selected={selected}
                setSelected={setSelected}
            />
        </>
    );
}

export default NavBar;
