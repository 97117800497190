export const destinationCardCarousel = [
    {
        id: 1,
        image: "bg-agra",
        title: "Agra",
    },
    { 
        id: 2, 
        image: "bg-delhi", 
        title: "Delhi", 
    },
    {
        id: 3,
        image: "bg-goa1",
        title: "Goa",
    },
    {
        id: 4,
        image: "bg-jaipur",
        title: "Jaipur",
    },
    {
        id: 5,
        image: "bg-kerela",
        title: "Kerela",
    },
    {
        id: 6,
        image: "bg-varanasi1",
        title: "Varanasi",
    },
];
export const DestinationCardOptions = {
    loop: true,
    margin: 5,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    responsive: {
        0: {
            items: 1,
        },
        900: {
            items: 2,
        },
        1200: {
            items: 4,
        },
    },
};
