import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";

import Store from "./Store";
import Routes from "./routes";
import "./App.css";

const theme = createTheme({
    typography: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
    },
});

function App() {
    return (
        <Store>
            <ThemeProvider theme={theme}>
                <Router>
                    <Routes />
                </Router>
            </ThemeProvider>
        </Store>
    );
}

export default App;
