import React from "react";
import ParentContainer from "../../Components/ParentContainer/ParentContainer";
import TopBanner from "../../Components/TopBanner/TopBanner";
import banner from "../../Assets/Image/banner2.png";
import NavBar from "../../Components/Navbar/NavBar";
import logo2 from "../../Assets/Image/logo2.png";
import { Grid } from "@mui/material";
import Subheader from "../../Components/SubHeader/Subheader";
import prize from "../../Assets/Svg/prize.svg";
import Heading2 from "../../Components/Heading2/Heading2";
import { aboutUsText } from "../../JsonData/AboutUsText";
import Footer from "../../Components/Footer/Footer";
import MobileFooter from "../../Components/Footer/MobileFooter";
import InnerContent from "../../Components/InnerContent/InnerContent";

import messages from "./messages";
import FloatingButton from "../../Components/FloatingButton/FloatingButton";
import owner from "../../Assets/Image/Owner 1.png";

function AboutUs({ lang }) {
    return (
        <div className="relative bg-white">
            <NavBar logo2={logo2} isBanner={true} isHome={false} />
            <FloatingButton/>

            <ParentContainer>
                <TopBanner
                    showBanner={true}
                    img={banner}
                    label={messages.title[lang]}
                    imgAlt="banner"
                />
            </ParentContainer>

            <ParentContainer>
                <Grid container spacing={3}>
                    <Grid item md={6} className="flex justify-center relative">
                        <div>
                            <img
                                src={owner}
                                alt="owner_image"
                                width={400}
                            />
                            <img
                                src={prize}
                                alt="prize"
                                width={50}
                                className="absolute top-8"
                            />
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <div>
                            <h1 className="mt-1 text-secondaryColor font-medium text-4xl  md:text-4xl sm:text-3xl 2xs:text-2xl">
                                {messages.heading1[lang]}
                            </h1>
                            <h1 className=" mt-1 text-secondaryColor font-medium text-4xl ml-14 md:text-4xl sm:text-3xl 2xs:text-2xl">
                                {messages.heading2[lang]}
                            </h1>
                            <h1 className=" mt-1 text-secondaryColor font-medium text-4xl md:text-4xl sm:text-3xl 2xs:text-2xl">
                                {messages.heading3[lang]}...
                            </h1>
                            <Subheader>
                                {messages.subheading[lang]}
                            </Subheader>
                        </div>
                    </Grid>
                </Grid>
            </ParentContainer>

            <div className="bg-greyBackgorund pb-10">
                <ParentContainer>
                    <div className="pt-10 pb-0">
                        <Heading2 heading={messages.questionheading[lang]} isVerticalLine={false} />
                    </div>

                    {aboutUsText.map((item, index) => (
                        <InnerContent
                            isSvg={false}
                            key={index}
                            img={item.img}
                            details={item.details[lang]}
                        />
                    ))}
                </ParentContainer>
            </div>
            <div className="pb-14 sm:pb-10 2xs:pb-10">
                <ParentContainer>
                    <h1 className="text-center font-medium text-secondaryColor text-3xl sm:text-2xl 2xs:text-2xl">
                        {messages.thankyounote[lang]}
                    </h1>
                </ParentContainer>
            </div>

            <Footer />
            <MobileFooter />
        </div>
    );
}

export default AboutUs;
