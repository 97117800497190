import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./styles.module.scss";
import "./styles.scss";

function Slider() {
    const options = {
        loop: true,
        margin: 0,
        items: 1,
        autoplay: true,
        // autoWidth: true,
        // responsiveClass: true,

        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };
    return (
        <div className="relative mySlider">
            <OwlCarousel className="owl-theme relative " {...options}>
                <div
                    className={`item ${styles.owlSlider} bg-slider1 ${styles.owlSlide}`}
                >
                    <div className={`h-full ${styles.insideBanner}`}>
                        {" "}
                        <p className="invisible">hidden text</p>
                    </div>
                </div>
                <div
                    className={`item ${styles.owlSlider} bg-slider2 ${styles.owlSlide}`}
                >
                    <div className={`h-full ${styles.insideBanner}`}>
                        {" "}
                        <p className="invisible ">hidden text</p>
                    </div>
                </div>
                <div
                    className={`item ${styles.owlSlider} bg-slider3 ${styles.owlSlide}`}
                >
                    <div className={`h-full ${styles.insideBanner}`}>
                        {" "}
                        <p className="invisible ">hidden text</p>
                    </div>
                </div>
                <div
                    className={`item ${styles.owlSlider} bg-slider4 ${styles.owlSlide}`}
                >
                    <div className={`h-full ${styles.insideBanner}`}>
                        {" "}
                        <p className="invisible ">hidden text</p>
                    </div>
                </div>
            </OwlCarousel>
        </div>
    );
}

export default Slider;
